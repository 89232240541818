import type { FilteringProperty } from '@cloudscape-design/components/property-filter/interfaces';
import dayjs from 'dayjs';
import { dateFormats } from 'src/pages/dashboards/UniversalWidget/util';

import { DateTimeForm } from '@/components/DateTimeFilter/DateTimeForm';
import { toLocalDate } from '@/utils/dateUtils';

import type { AllowedField } from '../utils';
import type { ReportFieldType } from './types';

export const date: ReportFieldType = {
  cell: ({ fieldData }) => {
    const value = fieldData.value;
    if (!value) {
      return null;
    }

    return toLocalDate(value as string);
  },
  getChartLabel: ({ fieldData, fieldDef, groupByDatePrecision }) => {
    if (fieldDef.dataType !== 'date') {
      throw new Error(`Incorrect field type date`);
    }
    const value = fieldData.value as null | string;
    if (!value) {
      return '';
    }

    return dayjs(value).format(dateFormats[groupByDatePrecision ?? 'day']);
  },
  propertyConfig: function (field: AllowedField): FilteringProperty {
    return {
      key: field.value,
      groupValuesLabel: '',
      propertyLabel: field.label,
      operators: ['<', '>', '<=', '>='].map((op) => ({
        operator: op,
        form: DateTimeForm,
        format: (value) => {
          if (!value) {
            return '';
          }

          return toLocalDate(value);
        },
      })),
      defaultOperator: '>',
    };
  },
};
