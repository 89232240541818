import type {
  Filter,
  FilterGroup,
} from '@risksmart-app/shared/reporting/api/schema';
import { notEmpty } from 'src/utilityTypes';

import type {
  DataSource,
  FieldRequest,
  ReportingDataInput,
} from '@/generated/graphql';

import type {
  Filters,
  ReportDefinitionFormData,
  Token,
} from './definitionSchema';
import type { RelatedDataSource } from './utils';
import {
  getFieldFromValue,
  getFieldValue,
  getFlattenedDataSources,
  getTreeDataSources,
} from './utils';

const tokenToPropertyFilter = (t: Token) => {
  const field = getFieldFromValue(t.propertyKey);

  return {
    value: t.value as unknown as string,
    operator: t.operator,
    field,
  };
};

export type CustomDataSource = {
  Title: string;
  Datasources: Array<DataSource>;
  Fields: Array<FieldRequest>;
  Filters: FilterGroup;
};

export const mapServerDataToFormData = (
  savedCustomDatasource: CustomDataSource
): ReportDefinitionFormData => {
  const formData: ReportDefinitionFormData = {
    title: savedCustomDatasource.Title,
    dataSource: getTreeDataSources(
      savedCustomDatasource.Datasources as RelatedDataSource[]
    ),

    fields: savedCustomDatasource.Fields.map((f) => ({
      ...f,
      value: getFieldValue(f),
    })),
    filters: {
      operation: savedCustomDatasource.Filters.operation,
      tokens: [],
      tokenGroups: savedCustomDatasource.Filters.filters.map((fg) => {
        if ('filters' in fg) {
          return {
            operation: fg.operation,
            tokens: fg.filters
              .map((f) => {
                if ('filters' in f) {
                  // UI does not support nested groups yet
                  return null;
                }

                return mapFilterToToken(f);
              })
              .filter(notEmpty),
          };
        }

        return mapFilterToToken(fg);
      }),
    },
  };

  return formData;
};

const mapFilterToToken = (filter: Filter) => {
  const token: Token = {
    operator: filter.operator,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: filter.value as any,
    propertyKey: getFieldValue(filter.field),
  };

  return token;
};

export const mapFromDataToServerVariables = (
  formData: ReportDefinitionFormData,
  paging: { offset: number; limit: number }
): ReportingDataInput => {
  // TODO: move flatting to be part of TreeDatasource (possible with option to choose format)
  const dataSources = getFlattenedDataSources(formData.dataSource);
  const fields = formData.fields;

  return {
    offset: paging.offset,
    limit: paging.limit,
    dataSources,
    fields: fields.map((f) => ({
      fieldId: f.fieldId,
      dataSourceIndex: f.dataSourceIndex,
    })),

    filters: mapQueryToFilterGroup(formData.filters),
  };
};

// TODO: move mapping to PropertyFilter component?
export const mapQueryToFilterGroup = (filters: Filters) => {
  return {
    operation: filters.operation,
    filters:
      filters.tokenGroups?.map((tg) => {
        if ('tokens' in tg) {
          const filterGroup: FilterGroup = {
            operation: tg.operation,
            filters: tg.tokens.map((t) => tokenToPropertyFilter(t)),
          };

          return filterGroup;
        } else {
          const filterGroup: FilterGroup = {
            operation: 'and',
            filters: [tokenToPropertyFilter(tg)],
          };

          return filterGroup;
        }
      }) ?? [],
  };
};
