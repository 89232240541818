import { useLayoutEffect } from 'react';

import { useHelpStore } from './useHelpStore';

export const usePopoverHelp = () => {
  const { setShowFieldHelpInPopover } = useHelpStore();
  useLayoutEffect(() => {
    setShowFieldHelpInPopover(true);

    return () => {
      setShowFieldHelpInPopover(false);
    };
  }, [setShowFieldHelpInPopover]);
};
