import type { GroupedRecords } from 'src/pages/attestations/types';

import { Attestation_Record_Status_Enum } from '@/generated/graphql';

export const calculateAttestationsCompletePercentage = (
  groupedRecords: GroupedRecords
) => {
  Object.values(groupedRecords).forEach((groupedRecord) => {
    const activeAttestations = groupedRecord.records.filter(
      (record) =>
        record.AttestationStatus === Attestation_Record_Status_Enum.Pending ||
        record.AttestationStatus === Attestation_Record_Status_Enum.Attested
    );

    const percentageComplete = activeAttestations.length
      ? activeAttestations
          .reduce(
            (percentageComplete, record) =>
              record.AttestationStatus ===
              Attestation_Record_Status_Enum.Attested
                ? percentageComplete
                : percentageComplete - 100 / activeAttestations.length,
            100
          )
          .toFixed()
          .replace('-0', '0')
      : '0';

    groupedRecord.AttestationsCompleted = Number(percentageComplete);
  });
};
