import { useRating } from '@risksmart-app/components/hooks/useRating';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import ActionsStatusBadge from '@/components/ActionStatusBadge/ActionsStatusBadge';
import type { CustomAttributeSchema } from '@/components/Form/CustomAttributes/CustomAttributeSchema';
import Link from '@/components/Link';
import SimpleRatingBadge from '@/components/SimpleRatingBadge';
import { useGetContributorsFieldConfig } from '@/utils/table/hooks/useGetContributorsFieldConfig';
import { useGetDepartmentFieldConfig } from '@/utils/table/hooks/useGetDepartmentFieldConfig';
import { useGetOwnersFieldConfig } from '@/utils/table/hooks/useGetOwnersFieldConfig';
import type {
  StatefulTableOptions,
  UseGetTablePropsOptions,
} from '@/utils/table/hooks/useGetStatelessTableProps';
import { useGetStatelessTableProps } from '@/utils/table/hooks/useGetStatelessTableProps';
import { useGetTableProps } from '@/utils/table/hooks/useGetTableProps';
import { useGetTablePropsWithoutUrlHash } from '@/utils/table/hooks/useGetTablePropsWithoutUrlHash';
import { useGetTagFieldConfig } from '@/utils/table/hooks/useGetTagFieldConfig';
import { useLinkArrayField } from '@/utils/table/hooks/useLinkArrayField';
import type { TableFields, TablePropsWithActions } from '@/utils/table/types';
import { dateColumn } from '@/utils/table/utils/dateColumn';
import { yesNoCell } from '@/utils/table/utils/yesNoCell';
import { actionDetailsUrl } from '@/utils/urls';

import type { ActionFields, ActionTableFields } from './types';
import { useLabelledFields } from './useLabelledFields';

const useGetFieldConfig = ({
  useAbsoluteUrls,
}: {
  useAbsoluteUrls?: boolean;
}): TableFields<ActionTableFields> => {
  const allOwners = useGetOwnersFieldConfig<ActionTableFields>();
  const allContributors = useGetContributorsFieldConfig<ActionTableFields>();
  const tagField = useGetTagFieldConfig<ActionTableFields>();
  const departmentField = useGetDepartmentFieldConfig<ActionTableFields>(
    (r) => r.departments
  );
  const { getByValue: getPriority } = useRating('priority');
  const { t: st } = useTranslation(['common'], {
    keyPrefix: 'actions.columns',
  });
  const { t } = useTranslation(['common'], {
    keyPrefix: 'columns',
  });
  const associations = useLinkArrayField<ActionTableFields>(
    t('associations'),
    (r) => r.ParentTitle
  );

  return useMemo(
    () => ({
      SequentialIdLabel: { header: t('id'), sortingField: 'SequentialId' },
      Title: {
        header: st('title'),
        cell: (item) => (
          <Link
            variant={'secondary'}
            href={useAbsoluteUrls ? actionDetailsUrl(item.Id) : item.Id}
            isRelativeUrl={!useAbsoluteUrls}
          >
            {item.Title}
          </Link>
        ),
        isRowHeader: true,
      },
      allOwners,
      allContributors,
      ParentTitle: associations,
      DateRaised: dateColumn(st('date_raised'), 'DateRaised'),
      DateDue: dateColumn(st('due_date'), 'DateDue'),
      ClosedDate: dateColumn(st('closed_date'), 'ClosedDate'),
      StatusLabelled: {
        header: st('status'),
        cell: (item) => (
          <ActionsStatusBadge
            item={{
              Status: item.Status,
              DateDue: item.DateDue,
            }}
          />
        ),
      },
      PriorityLabelled: {
        header: st('priority'),
        cell: (item) => {
          return <SimpleRatingBadge rating={getPriority(item.Priority)} />;
        },
        sortingField: 'Priority',
      },
      tags: tagField,
      departments: departmentField,
      Id: { header: t('guid') },
      ModifiedAtTimestamp: dateColumn(t('updated_on'), 'ModifiedAtTimestamp'),
      ModifiedByUser: { header: t('updated_by_id') },
      ModifiedByUserName: { header: st('modified_by_username') },
      CreatedByUserName: { header: st('created_by_username') },
      Overdue: { header: st('overdue'), cell: yesNoCell('Overdue') },
      UpdateCount: { header: st('updateCount') },
      LatestUpdateCreatedAtTimestamp: dateColumn(
        st('latestUpdateCreatedAtTimestamp'),
        'LatestUpdateCreatedAtTimestamp'
      ),
      LatestUpdateDescription: { header: st('latestUpdateDescription') },
      LatestUpdateTitle: { header: st('latestUpdateTitle') },
      Description: { header: st('description') },
    }),
    [
      allContributors,
      allOwners,
      associations,
      departmentField,
      getPriority,
      st,
      t,
      tagField,
      useAbsoluteUrls,
    ]
  );
};

const useGetActionTableProps = (
  records: ActionFields[] | undefined,
  customAttributeSchema?: CustomAttributeSchema | null,
  useAbsoluteUrls: boolean = false
): UseGetTablePropsOptions<ActionTableFields> => {
  const labelledFields = useLabelledFields(records);
  const { t } = useTranslation(['common'], { keyPrefix: 'actions' });
  const fields = useGetFieldConfig({ useAbsoluteUrls });

  return {
    tableId: 'actionRegister',
    data: labelledFields,
    customAttributeSchema: customAttributeSchema,
    entityLabel: t('entity_name'),
    emptyCollectionAction: <></>,
    preferencesStorageKey: 'ActionsRegisterTable-PreferencesV1',
    enableFiltering: true,
    initialColumns: [
      'Title',
      'allOwners',
      'ParentTitle',
      'DateRaised',
      'DateDue',
      'ClosedDate',
      'StatusLabelled',
      'PriorityLabelled',
      'tags',
    ],
    fields,
    defaultSortingState: {
      sortingColumn: 'DateRaised',
      sortingDirection: 'desc',
    },
  };
};

export const useGetCollectionTableProps = (
  records: ActionFields[] | undefined,
  customAttributeSchema?: CustomAttributeSchema | null
): TablePropsWithActions<ActionTableFields> => {
  const props = useGetActionTableProps(records, customAttributeSchema);

  return useGetTableProps(props);
};

export const useGetCollectionStatelessTableProps = (
  records: ActionFields[] | undefined,
  customAttributeSchema?: CustomAttributeSchema | null
): TablePropsWithActions<ActionTableFields> => {
  const props = useGetActionTableProps(records, customAttributeSchema);

  return useGetTablePropsWithoutUrlHash(props);
};

export const useGetActionSmartWidgetTableProps = (
  records: ActionFields[] | undefined,
  customAttributeSchema: CustomAttributeSchema | null,
  statefulTableOptions: StatefulTableOptions<ActionTableFields>
): TablePropsWithActions<ActionTableFields> => {
  const props = useGetActionTableProps(records, customAttributeSchema, true);

  return useGetStatelessTableProps<ActionTableFields>({
    ...props,
    ...statefulTableOptions,
    enableFiltering: false,
  });
};
