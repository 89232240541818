import { useGetGuidParam } from '@risksmart-app/components/routes/routes.utils';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { PageLayout } from 'src/layouts';

import ControlledTabs from '@/components/ControlledTabs';
import { useGetAssessmentByIdQuery } from '@/generated/graphql';

import { useTabs } from '../../../assessment-results/update/useTabs';

const Page: FC = () => {
  const { t } = useTranslation(['common'], { keyPrefix: 'assessmentResults' });
  const title = t('create_title');

  const assessmentId = useGetGuidParam('assessmentId');
  const { data, error } = useGetAssessmentByIdQuery({
    variables: {
      Id: assessmentId,
    },
  });
  if (error) {
    throw error;
  }

  const assessment = data?.assessment[0];
  const tabs = useTabs('rating', assessment, 'add', false, true);

  return (
    <PageLayout title={title}>
      <ControlledTabs tabs={tabs} variant={'container'} />
    </PageLayout>
  );
};

export default Page;
