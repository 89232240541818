import SpaceBetween from '@cloudscape-design/components/space-between';
import Button from '@risksmart-app/components/Button';
import { useEntityFilter } from '@risksmart-app/components/contexts/entityFilterContext';
import { useNotifications } from '@risksmart-app/components/Notifications/useNotifications';
import Table from '@risksmart-app/components/Table';
import type { FC } from 'react';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Parent_Type_Enum,
  useGetAppetitesGroupedByImpactQuery,
  useGetRisksFlatQuery,
} from 'src/generated/graphql';
import { PageLayout } from 'src/layouts';
import { Permission } from 'src/rbac/Permission';

import CustomisableRibbon from '@/components/CustomisableRibbon/CustomisableRibbon';
import { useRiskScores } from '@/hooks/useRiskScore';
import { getCounter } from '@/utils/collectionUtils';
import { addRiskUrl } from '@/utils/urls';

import AssessmentResultModal from '../assessments/modals/AssessmentResultModal';
import { useGetCollectionTableProps } from './config';
import { useGetDefaultRibbonFilters } from './defaultRibbonFilters';

const Page: FC = () => {
  const { t: st } = useTranslation(['common'], {
    keyPrefix: 'risks',
  });
  const { t } = useTranslation(['common'], {});
  const title = st('register_title');
  const { addNotification } = useNotifications();
  const { entityIds } = useEntityFilter();

  const whereFilter = useMemo(() => {
    if (!entityIds || !entityIds.length) {
      return {};
    }

    return {
      enterpriseRiskInstance: {
        EntityId: { _in: entityIds },
      },
    };
  }, [entityIds]);

  const { data, loading, refetch } = useGetRisksFlatQuery({
    fetchPolicy: 'no-cache',
    onError: (error) => {
      addNotification({
        type: 'error',
        content: <>{error.message}</>,
      });
    },
    variables: {
      where: whereFilter,
    },
  });
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [openRatingResultId, setOpenRatingResultId] = useState<
    string | undefined
  >();
  const handleRatingResultModalClose = () => {
    setOpenRatingResultId(undefined);
    setIsEditOpen(false);
    refetch();
  };
  const { loading: loadingScores, scores } = useRiskScores();

  const { data: impactAppetites, loading: loadingImpactAppetites } =
    useGetAppetitesGroupedByImpactQuery();

  const tableProps = useGetCollectionTableProps(
    data?.risk,
    scores,
    impactAppetites?.impact,
    data?.form_configuration?.map(
      (schemaData) => schemaData.customAttributeSchema!
    ) || [],
    (ratingId) => {
      setOpenRatingResultId(ratingId);
      setIsEditOpen(true);
    }
  );
  const counter = getCounter(tableProps.totalItemsCount, loading);

  return (
    <PageLayout
      helpTranslationKey={'risks.registerHelp'}
      title={title}
      counter={counter}
      actions={
        <SpaceBetween direction={'horizontal'} size={'xs'}>
          <Button iconName={'download'} onClick={tableProps.exportToCsv}>
            {t('export')}
          </Button>
          <Permission
            permission={'insert:risk'}
            canHaveAccessAsContributor={true}
          >
            <Button variant={'primary'} href={addRiskUrl()}>
              {st('create_new_button')}
            </Button>
          </Permission>
        </SpaceBetween>
      }
    >
      <CustomisableRibbon
        items={tableProps.allItems}
        propertyFilterQuery={tableProps.propertyFilterQuery}
        onFilterQueryChanged={tableProps.actions.setPropertyFiltering}
        filteringProperties={tableProps.filteringProperties}
        filteringOptions={tableProps.propertyFilterProps.filteringOptions}
        parentType={Parent_Type_Enum.Risk}
        defaultFilters={useGetDefaultRibbonFilters()}
      />
      <Table
        {...tableProps}
        loading={loading || loadingScores || loadingImpactAppetites}
      />
      {isEditOpen && (
        <AssessmentResultModal
          id={openRatingResultId}
          onDismiss={handleRatingResultModalClose}
          resultType={Parent_Type_Enum.RiskAssessmentResult}
          hideTypeSelector={true}
          i18n={t('ratings')}
          assessmentMode={'rating'}
        />
      )}
    </PageLayout>
  );
};

export default Page;
