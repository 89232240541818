import SegmentedControl from '@cloudscape-design/components/segmented-control';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useIsFeatureVisibleToOrg } from '@/utils/featureFlags';

import styles from './style.module.scss';
import type { DashboardView, DashboardViewToggleProps } from './types';

const DashboardViewToggle: FC<DashboardViewToggleProps> = ({
  dashboardView,
  setDashboardView,
}) => {
  const { t } = useTranslation(['common'], { keyPrefix: 'dashboard' });
  const showDashboardViewToggle =
    useIsFeatureVisibleToOrg('my_items_dashboard');

  return showDashboardViewToggle ? (
    <div className={styles.dashboardViewToggle}>
      <SegmentedControl
        selectedId={dashboardView}
        onChange={({ detail }) =>
          setDashboardView(detail.selectedId as DashboardView)
        }
        options={[
          { text: t('overall_toggle'), id: 'dashboard' },
          { text: t('my_items_toggle'), id: 'my-items' },
        ]}
      />
    </div>
  ) : null;
};

export default DashboardViewToggle;
