import type { FC } from 'react';

import { useGetCustomDatasourceByIdQuery } from '@/generated/graphql';

type Props = {
  customDatasourceId: string | undefined;
};

const Breadcrumb: FC<Props> = ({ customDatasourceId }) => {
  const { data } = useGetCustomDatasourceByIdQuery({
    variables: {
      Id: customDatasourceId!,
    },
    skip: !customDatasourceId,
  });

  return <>{data?.custom_datasource_by_pk?.Title ?? ''}</>;
};

export default Breadcrumb;
