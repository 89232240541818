import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import ControlledDatePicker from '@/components/Form/ControlledDatePicker';
import ControlledGroupAndUserContributorMultiSelect from '@/components/Form/ControlledGroupAndUserContributorMultiSelect';
import ControlledGroupAndUserSelect from '@/components/Form/ControlledGroupAndUserSelect';
import ControlledInput from '@/components/Form/ControlledInput';
import ControlledRadioGroup from '@/components/Form/ControlledRadioGroup';
import { noTransform } from '@/components/Form/ControlledRadioGroup/radioGroupUtils';
import ControlledRating from '@/components/Form/ControlledRating';
import ControlledTextarea from '@/components/Form/ControlledTextarea';
import DepartmentSelector from '@/components/Form/DepartmentSelector';
import CustomisableForm from '@/components/Form/Form/CustomisableForm/CustomisableForm';
import FormRow from '@/components/Form/Form/FormRow';
import TagSelector from '@/components/Form/TagSelector';
import { Contributor_Type_Enum } from '@/generated/graphql';

import type { AssessmentTypeEnum } from '../../useAssessmentTypeConfig';
import { useAssessmentTypeConfig } from '../../useAssessmentTypeConfig';
import type { AssessmentFormDataFields } from './assessmentSchema';

interface Props {
  readOnly?: boolean;
  assessmentMode: AssessmentTypeEnum;
}

enum TestIds {
  Title = 'Title',
  Summary = 'Summary',
  CompletedByUser = 'CompletedByUser',
  StartDate = 'StartDate',
  TargetCompletionDate = 'TargetCompletionDate',
  ActualCompletionDate = 'ActualCompletionDate',
  NextTestDate = 'NextTestDate',
  Status = 'Status',
  Owners = 'Owners',
}

const AssessmentFormFields = ({ readOnly, assessmentMode }: Props) => {
  const { control } = useFormContext<AssessmentFormDataFields>();
  const { taxonomyKey, outcomeRatingKey } =
    useAssessmentTypeConfig(assessmentMode);
  const { t } = useTranslation(['common']);
  const { t: st } = useTranslation(['common'], {
    keyPrefix: `${taxonomyKey}.fields`,
  });
  const { t: ast } = useTranslation(['common'], {
    keyPrefix: taxonomyKey,
  });
  const statusTypes = ast('status', { returnObjects: true });
  const statusTypesOptions = Object.keys(statusTypes).map((key) => ({
    value: key,
    label: statusTypes[key as keyof typeof statusTypes],
  }));

  return (
    <CustomisableForm readOnly={readOnly}>
      <ControlledInput
        key={'title'}
        testId={TestIds.Title}
        forceRequired={true}
        disabled={readOnly}
        name={'Title'}
        label={st('Title')}
        placeholder={st('Title_placeholder')}
        description={st('Title_help')}
        control={control}
      />

      <ControlledTextarea
        key={'summary'}
        testId={TestIds.Summary}
        disabled={readOnly}
        defaultRequired={true}
        name={'Summary'}
        label={st('Summary')}
        placeholder={st('Summary_placeholder')}
        description={st('Summary_help')}
        control={control}
      />

      <ControlledGroupAndUserSelect
        key={'completion-by'}
        testId={TestIds.CompletedByUser}
        disabled={readOnly}
        defaultRequired={false}
        name={'CompletedByUser'}
        label={st('CompletedBy')}
        placeholder={st('CompletedBy_placeholder')}
        description={st('CompletedBy_help')}
        control={control}
        includeGroups={false}
      />

      <ControlledDatePicker
        key={'start-date'}
        testId={TestIds.StartDate}
        disabled={readOnly}
        name={'StartDate'}
        label={st('StartDate')}
        description={st('StartDate_help')}
        control={control}
      />

      <ControlledDatePicker
        key={'target-completion-date'}
        testId={TestIds.TargetCompletionDate}
        disabled={readOnly}
        name={'TargetCompletionDate'}
        label={st('TargetCompletionDate')}
        description={st('TargetCompletionDate_help')}
        control={control}
      />

      <ControlledDatePicker
        key={'actual-completion-date'}
        testId={TestIds.ActualCompletionDate}
        disabled={readOnly}
        name={'ActualCompletionDate'}
        label={st('ActualCompletionDate')}
        description={st('ActualCompletionDate_help')}
        control={control}
      />

      <ControlledDatePicker
        key={'next-test-date'}
        testId={TestIds.NextTestDate}
        disabled={readOnly}
        name={'NextTestDate'}
        label={st('NextTestDate')}
        description={st('NextTestDate_help')}
        control={control}
      />

      <ControlledRadioGroup
        key={'status'}
        testId={TestIds.Status}
        forceRequired={true}
        label={st('Status')}
        description={st('Status_help')}
        name={'Status'}
        control={control}
        items={statusTypesOptions}
        transform={noTransform}
        disabled={readOnly}
      />

      <ControlledRating
        forceRequired={false}
        key={'outcome'}
        name={'Outcome'}
        label={st('Outcome')}
        description={st('Outcome_help')}
        placeholder={st('Outcome_placeholder')}
        type={outcomeRatingKey}
        control={control}
        addEmptyOption={true}
        disabled={readOnly}
      />

      <FormRow key={'owners'}>
        <ControlledGroupAndUserContributorMultiSelect
          forceRequired={true}
          includeGroups={true}
          testId={TestIds.Owners}
          control={control}
          label={t('fields.Owner')}
          inheritedContributorsName={'ancestorContributors'}
          contributorType={Contributor_Type_Enum.Owner}
          name={'Owners'}
          placeholder={t('fields.Owner_placeholder')}
          description={st('Owner_help')}
          disabled={readOnly}
        />
      </FormRow>

      <FormRow key={'contributors'}>
        <ControlledGroupAndUserContributorMultiSelect
          key={'contributors'}
          control={control}
          includeGroups={true}
          inheritedContributorsName={'ancestorContributors'}
          contributorType={Contributor_Type_Enum.Contributor}
          label={t('fields.Contributor')}
          name={'Contributors'}
          placeholder={t('fields.Contributor_placeholder')}
          description={st('Contributor_help')}
          disabled={readOnly}
        />
      </FormRow>

      <FormRow size={'xl'} key={'tags'}>
        <TagSelector disabled={readOnly} name={'tags'} control={control} />
      </FormRow>

      <DepartmentSelector
        key={'departments'}
        disabled={readOnly}
        name={'departments'}
        control={control}
      />
    </CustomisableForm>
  );
};

export default AssessmentFormFields;
