import Button from '@risksmart-app/components/Button';
import Table from '@risksmart-app/components/Table';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useGetThirdPartyResponsesSubscription } from '@/generated/graphql';
import { getCounter } from '@/utils/collectionUtils';

import { SpaceBetween } from '../../../../theme/risksmart-themed/components';
import { PageLayout } from '../../layouts';
import { useGetCollectionTableProps } from './config';

const Page: FC = () => {
  const { t } = useTranslation(['common']);
  const { t: st } = useTranslation(['common'], {
    keyPrefix: 'third_party_responses',
  });
  const title = st('register_title');

  const { data, loading } = useGetThirdPartyResponsesSubscription({
    fetchPolicy: 'no-cache',
  });

  const tableProps = useGetCollectionTableProps(data?.third_party_response);

  const counter = getCounter(tableProps.totalItemsCount, loading);

  return (
    <PageLayout
      helpTranslationKey={'third_party_responses.registerHelp'}
      title={title}
      counter={counter}
      actions={
        <SpaceBetween direction={'horizontal'} size={'xs'}>
          <Button iconName={'download'} onClick={tableProps.exportToCsv}>
            {t('export')}
          </Button>
        </SpaceBetween>
      }
    >
      <Table {...tableProps} loading={loading} />
    </PageLayout>
  );
};

export default Page;
