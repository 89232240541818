import { t } from 'i18next';

import { SharedFields } from './types';

export const getAuditColumns = () =>
  ({
    createdAtTimestamp: {
      dataType: 'date',
      displayType: 'date',
      label: t('columns.created_on'),
    },
    modifiedAtTimestamp: {
      dataType: 'date',
      displayType: 'date',
      label: t('columns.updated_on'),
    },
    createdById: {
      dataType: 'text',
      displayType: 'text',
      label: t('columns.created_by_id'),
    },
    modifiedById: {
      dataType: 'text',
      displayType: 'text',
      label: t('columns.updated_by_id'),
    },
    createdByFriendlyName: {
      dataType: 'text',
      displayType: 'text',
      label: t('columns.updated_by_username'),
    },
    modifiedByFriendlyName: {
      dataType: 'text',
      displayType: 'text',
      label: t('columns.created_by_username'),
    },
  }) satisfies SharedFields;

export const getTagAndDepartmentsColumns = () =>
  ({
    tags: {
      dataType: 'textArray',
      label: t('columns.tags'),
      displayType: 'badgeList',
    },
    departments: {
      dataType: 'textArray',
      label: t('columns.departments'),
      displayType: 'badgeList',
    },
  }) satisfies SharedFields;

export const getOwnersColumns = () =>
  ({
    owners: {
      dataType: 'textArray',
      label: t('columns.owners'),
      displayType: 'badgeList',
    },
  }) satisfies SharedFields;

export const getContributorsColumns = () =>
  ({
    contributors: {
      dataType: 'textArray',
      label: t('columns.contributors'),
      displayType: 'badgeList',
    },
  }) satisfies SharedFields;
