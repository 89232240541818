import { z } from 'zod';

export const dataSourceTypes = [
  'risks',
  'actions',
  'issues',
  'controls',
  'tags',
  'appetites',
  'acceptances',
  'indicators',
  'indicatorResults',
  'causes',
  'consequences',
  'testResults',
  'riskAssessmentResults',
  'assessments',
] as const;

export const dataSourceTypeSchema = z.enum(dataSourceTypes, {
  invalid_type_error: 'Required',
});

export type DataSourceType = z.infer<typeof dataSourceTypeSchema>;

export const selectedDatasourceSchema = z.object({
  type: dataSourceTypeSchema,
  /**
   * Parent datasource to join with
   */
  parentIndex: z.number().int().optional(),
});

export type SelectedDatasource = z.infer<typeof selectedDatasourceSchema>;

export const aggregateType = z.enum(['min', 'max', 'count', 'avg', 'sum']);

export type AggregateType = z.infer<typeof aggregateType>;
