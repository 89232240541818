import type { FC } from 'react';

import { useGetThirdPartyResponseByIdSubscription } from '@/generated/graphql';

type Props = {
  responseId: string | undefined;
};

const Breadcrumb: FC<Props> = ({ responseId }) => {
  if (!responseId) {
    throw new Error('Missing id param');
  }

  const { data } = useGetThirdPartyResponseByIdSubscription({
    variables: {
      Id: responseId,
    },
  });

  return (
    <>
      {`${
        data?.third_party_response_by_pk?.questionnaireTemplateVersion?.parent
          ?.Title
      } - ${data?.third_party_response_by_pk?.questionnaireTemplateVersion?.Version}`}
    </>
  );
};

export default Breadcrumb;
