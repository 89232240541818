import type { CollectionPreferencesProps } from '@cloudscape-design/components/collection-preferences';
import CloudscapeCollectionPreferences from '@cloudscape-design/components/collection-preferences';
import i18next from '@risksmart-app/i18n/i18n';
import type { ParseKeys } from 'i18next/typescript/t';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { labelWithPlural } from '@/utils/utils';

import type { TableFields, TablePreferences, TableRecord } from '../types';

type Props<T extends TableRecord> = {
  preferences: TablePreferences<T>;
  setPreferences: (preferences: TablePreferences<T>) => void;
  // TODO: Got a max of ParseKeys and string usage and the translation keys are working correctly. Need a better solution
  entityLabel: ParseKeys<'common'> | string;
  fields: TableFields<T>;
};

function CollectionPreferences<T extends TableRecord>({
  preferences,
  setPreferences,
  entityLabel,
  fields,
}: Props<T>) {
  const { t } = useTranslation(['common']);

  const PAGE_SIZE_OPTIONS = [10, 25, 50, 100, 200].map((size) => ({
    value: size,
    label: i18next.t('tables.paging_option', {
      entity: labelWithPlural(entityLabel).plural,
      size,
    }),
  }));

  const collectionPreferencesProps: CollectionPreferencesProps = {
    title: t('tables.preferences'),
    preferences: preferences,
    confirmLabel: t('tables.confirm'),
    cancelLabel: t('tables.cancel'),
    pageSizePreference: {
      title: t('tables.page_size'),
      options: PAGE_SIZE_OPTIONS,
    },
    contentDisplayPreference: {
      title: t('tables.preferences_columns_title'),
      options: Object.entries(fields).map(([fieldName, fieldConfig]) => ({
        id: fieldName,
        label: fieldConfig.header,
      })),
    },
    onConfirm: (preferences) => {
      setPreferences(preferences.detail);
    },
    stickyColumnsPreference: {
      firstColumns: {
        title: t('tables.stick_first_columns_title'),
        description: t('tables.stick_first_columns_description'),

        options: [
          { label: t('tables.stick_column_options.none'), value: 0 },
          { label: t('tables.stick_column_options.first'), value: 1 },
          { label: t('tables.stick_column_options.first_two'), value: 2 },
        ],
      },
      lastColumns: {
        title: t('tables.stick_last_columns_title'),
        description: t('tables.stick_last_columns_description'),

        options: [
          { label: t('tables.stick_column_options.none'), value: 0 },
          { label: t('tables.stick_column_options.last'), value: 1 },
        ],
      },
    },
    contentDensityPreference: t('tables.content_density'),
    wrapLinesPreference: t('tables.wrap_lines'),
    stripedRowsPreference: t('tables.striped_rows'),
  };

  return (
    <CloudscapeCollectionPreferences
      {...collectionPreferencesProps}
      preferences={preferences}
    />
  );
}

export default CollectionPreferences;
