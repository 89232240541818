import SpaceBetween from '@cloudscape-design/components/space-between';
import type { FC } from 'react';
import type { ReactNode } from 'react';

import type { Parent_Type_Enum } from '@/generated/graphql';

import { FormMenu } from './FormMenu';

type Props = {
  actions: ReactNode;
  footerDetails?: ReactNode;
  readOnly?: boolean;
  parentType?: Parent_Type_Enum;
};

const ModalFooter: FC<Props> = ({
  actions,
  footerDetails,
  readOnly,
  parentType,
}) => (
  <div className={'flex justify-between w-full'}>
    <div className={'flex-1'}>{actions}</div>
    <SpaceBetween direction={'horizontal'} alignItems={'center'} size={'m'}>
      <div>{footerDetails}</div>
      {!readOnly && parentType && <FormMenu parentType={parentType} />}
    </SpaceBetween>
  </div>
);

export default ModalFooter;
