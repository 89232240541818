import type { TabsProps } from '@cloudscape-design/components/tabs';
import { useTranslation } from 'react-i18next';
import { useHasPermission } from 'src/rbac/useHasPermission';

import { useIsFeatureVisibleToOrg } from '@/utils/featureFlags';
import { filterEmptyTabs } from '@/utils/tabUtils';

import { ApprovalsTab } from './tabs/approvals/Tab';
import AuditTab from './tabs/audit/Tab';
import AuthenticationTab from './tabs/authentication/Tab';
import DataImportTab from './tabs/dataImport/Tab';
import DepartmentsTab from './tabs/departments/Tab';
import EntitiesTab from './tabs/entities/Tab';
import GroupsTab from './tabs/groups/Tab';
import TagsTab from './tabs/tags/Tab';
import TaxonomyTab from './tabs/taxonomy/Tab';
import UsersTab from './tabs/users/Tab';

export const useTabs = (): TabsProps['tabs'] => {
  const { t } = useTranslation(['common']);
  const settingsEnabled = useIsFeatureVisibleToOrg('settings');
  const enterpriseRiskEnabled = useIsFeatureVisibleToOrg('enterprise_risk');

  const canUpdateUsers = useHasPermission('update:settings_users');
  const canUpdateUserGroups = useHasPermission('update:settings_user_groups');
  const canUpdateTags = useHasPermission('update:settings_tags');
  const canUpdateDepartments = useHasPermission('update:settings_departments');
  const canViewInternalAuditLog = useHasPermission('read:settings_audit');
  const canUpdateApprovals = useHasPermission('update:settings_approvals');

  const approvalsEnabled = useIsFeatureVisibleToOrg('approvers');
  const authenticationEnabled = useIsFeatureVisibleToOrg('authentication');
  const canUpdateScimConfiguration = useHasPermission(
    'update:scim_configuration'
  );
  const canUpdateTaxonomy = useHasPermission('update:taxonomy');
  const canUseDataImport = useHasPermission('insert:data_import');
  const canUseEntities = useHasPermission('update:entity');
  const tabs = [];

  if (!settingsEnabled) {
    return filterEmptyTabs([]);
  }

  if (canUpdateUsers) {
    tabs.push({
      label: t('userSettings.usersTableTitle'),
      id: 'users',
      href: '/settings/users',
      content: <UsersTab />,
    });
  }

  if (canUpdateUserGroups) {
    tabs.push({
      label: t('userGroups.groupsTableTitle'),
      id: 'groups',
      href: '/settings/groups',
      content: <GroupsTab />,
    });
  }

  if (canUpdateTaxonomy) {
    tabs.push({
      label: t('taxonomy.taxonomyTableTitle'),
      id: 'taxonomy',
      href: '/settings/taxonomy',
      content: <TaxonomyTab />,
    });
  }

  if (canUpdateTags) {
    tabs.push({
      label: t('tags.tagsTableTitle'),
      id: 'tags',
      href: '/settings/tags',
      content: <TagsTab />,
    });
  }

  if (enterpriseRiskEnabled && canUseEntities) {
    tabs.push({
      label: t('entity.entityTabTitle'),
      id: 'entities',
      href: '/settings/entities',
      content: <EntitiesTab />,
    });
  }

  if (canUpdateDepartments) {
    tabs.push({
      label: t('departments.departmentsTableTitle'),
      id: 'departments',
      href: '/settings/departments',
      content: <DepartmentsTab />,
    });
  }

  if (approvalsEnabled && canUpdateApprovals) {
    tabs.push({
      label: t('approvals.approvalTableTitle'),
      id: 'approvals',
      href: '/settings/approvals',
      content: <ApprovalsTab />,
    });
  }

  if (authenticationEnabled && canUpdateScimConfiguration) {
    tabs.push({
      label: t('authenticationSettings.authenticationTableTitle'),
      id: 'authentication',
      href: '/settings/authentication',
      content: <AuthenticationTab />,
    });
  }

  if (canViewInternalAuditLog) {
    tabs.push({
      label: t('auditLog.auditTableTitle'),
      id: 'audit',
      href: '/settings/audit',
      content: <AuditTab />,
    });
  }

  if (canUseDataImport) {
    tabs.push({
      label: t('dataImport.tabTitle'),
      id: 'dataImport',
      href: '/settings/data-import',
      content: <DataImportTab />,
    });
  }

  return filterEmptyTabs(tabs);
};
