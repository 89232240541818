import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { LabelledIdArray } from 'src/rbac/types';

import Link from '@/components/Link';
import type {
  DepartmentPartsFragment,
  GetMyItemsQuery,
  TagPartsFragment,
} from '@/generated/graphql';
import { useGetContributorsFieldConfig } from '@/utils/table/hooks/useGetContributorsFieldConfig';
import { useGetDepartmentFieldConfig } from '@/utils/table/hooks/useGetDepartmentFieldConfig';
import { useGetOwnersFieldConfig } from '@/utils/table/hooks/useGetOwnersFieldConfig';
import { useGetTableProps } from '@/utils/table/hooks/useGetTableProps';
import { useGetTagFieldConfig } from '@/utils/table/hooks/useGetTagFieldConfig';
import type { TableFields, TablePropsWithActions } from '@/utils/table/types';
import {
  actionDetailsUrl,
  assessmentDetailsUrl,
  controlDetailsUrl,
  indicatorDetailsUrl,
  issueDetailsUrl,
  obligationDetailsUrl,
  policyDetailsUrl,
  riskDetailsUrl,
} from '@/utils/urls';

import {
  getAllContributorsCellValue,
  getAllOwnersCellValue,
} from '../../rbac/contributorHelper';

export type MyItemFields = {
  Id: string;
  Name: string;
  Type: string;
  Description: null | string | undefined;
  tags: TagPartsFragment[];
  departments: DepartmentPartsFragment[];
  url: string;
  allOwners: LabelledIdArray;
  allContributors: LabelledIdArray;
};

const useGetFieldConfig = (): TableFields<MyItemFields> => {
  const tagField = useGetTagFieldConfig<MyItemFields>();
  const allOwners = useGetOwnersFieldConfig<MyItemFields>();
  const allContributors = useGetContributorsFieldConfig<MyItemFields>();
  const { t: st } = useTranslation(['common'], {
    keyPrefix: 'myItems.columns',
  });
  const { t } = useTranslation(['common'], {
    keyPrefix: 'columns',
  });

  const departmentField = useGetDepartmentFieldConfig<MyItemFields>(
    (r) => r.departments
  );

  return {
    Name: {
      header: st('name'),
      cell: (item) => (
        <Link variant={'secondary'} href={item.url}>
          {item.Name}
        </Link>
      ),
      isRowHeader: true,
    },
    Type: {
      header: st('type'),
    },
    Description: {
      header: st('description'),
    },
    allOwners,
    allContributors,
    tags: tagField,
    departments: departmentField,
    Id: {
      header: t('guid'),
    },
  };
};

interface GetTypes {
  action: string;
  obligation: string | undefined;
  issue: string;
  risk: string;
  indicator: string;
  document: string | undefined;
  control: string;
  assessment: string;
}

export const useGetTypes = (): GetTypes => {
  const { t, i18n } = useTranslation(['taxonomy']);

  return {
    action: i18n.format(t('action_one'), 'capitalizeAll'),
    obligation: i18n.format(t('obligation_one'), 'capitalizeAll'),
    issue: i18n.format(t('issue_one'), 'capitalizeAll'),
    risk: i18n.format(t('risk_one'), 'capitalizeAll'),
    indicator: i18n.format(t('indicator_one'), 'capitalizeAll'),
    document: i18n.format(t('document_one'), 'capitalizeAll'),
    control: i18n.format(t('control'), 'capitalizeAll'),
    assessment: i18n.format(t('assessment_one'), 'capitalizeAll'),
  };
};

const useFlattenData = (
  data: GetMyItemsQuery | undefined
): MyItemFields[] | undefined => {
  const types = useGetTypes();

  return useMemo<MyItemFields[] | undefined>(() => {
    const obligations = data?.obligation?.map((d) => {
      return {
        Id: d.Id,
        Name: d.Title,
        Type: types.obligation ?? '',
        Description: d.Description ?? '',
        tags: d.tags,
        departments: d.departments,
        url: obligationDetailsUrl(d.Id),
        allOwners: getAllOwnersCellValue(d),
        allContributors: getAllContributorsCellValue(d),
      };
    });
    const actions = data?.action?.map((d) => {
      return {
        Id: d.Id,
        Name: d.Title,
        Type: types.action,
        Description: d.Description ?? '',
        tags: d.tags,
        departments: d.departments,
        url: actionDetailsUrl(d.Id),
        allOwners: getAllOwnersCellValue(d),
        allContributors: getAllContributorsCellValue(d),
      };
    });
    const issues = data?.issue?.map((d) => {
      return {
        Id: d.Id,
        Name: d.Title,
        Type: types.issue,
        Description: d.Details ?? '',
        tags: d.tags,
        departments: d.departments,
        url: issueDetailsUrl(d.Id),
        allOwners: getAllOwnersCellValue(d),
        allContributors: getAllContributorsCellValue(d),
      };
    });
    const risks = data?.risk?.map((d) => {
      return {
        Id: d.Id,
        Name: d.Title,
        Type: types.risk,
        Description: d.Description ?? '',
        tags: d.tags,
        departments: d.departments,
        url: riskDetailsUrl(d.Id),
        allOwners: getAllOwnersCellValue(d),
        allContributors: getAllContributorsCellValue(d),
      };
    });
    const indicators = data?.indicator?.map((d) => {
      return {
        Id: d.Id,
        Name: d.Title,
        Type: types.indicator,
        Description: d.Description ?? '',
        tags: d.tags,
        departments: d.departments,
        url: indicatorDetailsUrl(d.Id),
        allOwners: getAllOwnersCellValue(d),
        allContributors: getAllContributorsCellValue(d),
      };
    });
    const documents = data?.document?.map((d) => {
      return {
        Id: d.Id,
        Name: d.Title,
        Type: types.document ?? '',
        Description: d.Purpose ?? '',
        tags: d.tags,
        departments: d.departments,
        url: policyDetailsUrl(d.Id),
        allOwners: getAllOwnersCellValue(d),
        allContributors: getAllContributorsCellValue(d),
      };
    });

    const controls = data?.control?.map((d) => {
      return {
        Id: d.Id,
        Name: d.Title,
        Type: types.control,
        Description: d.Description ?? '',
        tags: d.tags,
        departments: d.departments,
        url: controlDetailsUrl(d.Id),
        allOwners: getAllOwnersCellValue(d),
        allContributors: getAllContributorsCellValue(d),
      };
    });

    const assessments = data?.assessment?.map((d) => {
      return {
        Id: d.Id,
        Name: d.Title,
        Type: types.assessment,
        Description: d.Summary ?? '',
        tags: d.tags,
        departments: d.departments,
        url: assessmentDetailsUrl(d.Id),
        allOwners: getAllOwnersCellValue(d),
        allContributors: getAllContributorsCellValue(d),
      };
    });

    return [
      ...(obligations ?? []),
      ...(actions ?? []),
      ...(issues ?? []),
      ...(risks ?? []),
      ...(indicators ?? []),
      ...(documents ?? []),
      ...(controls ?? []),
      ...(assessments ?? []),
    ];
  }, [
    data?.action,
    data?.control,
    data?.document,
    data?.indicator,
    data?.issue,
    data?.obligation,
    data?.risk,
    data?.assessment,
    types.action,
    types.control,
    types.document,
    types.indicator,
    types.issue,
    types.obligation,
    types.risk,
    types.assessment,
  ]);
};

export const useGetCollectionTableProps = (
  data: GetMyItemsQuery | undefined
): TablePropsWithActions<MyItemFields> => {
  const { t } = useTranslation(['common']);
  const labelledFields = useFlattenData(data);
  const fields = useGetFieldConfig();

  return useGetTableProps({
    tableId: 'myItemRegister',
    data: labelledFields,
    entityLabel: t('my_item_one'),
    emptyCollectionAction: <></>,
    preferencesStorageKey: 'MyItems-PreferencesV1',
    enableFiltering: true,
    initialColumns: ['Name', 'Type', 'Description', 'tags', 'departments'],
    fields,
  });
};
