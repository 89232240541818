import type { OptionDefinition } from '@cloudscape-design/components/internal/components/option/interfaces';
import type { AggregateType } from '@risksmart-app/shared/reporting/schema';
import { t } from 'i18next';

export const getAggregateTypeConfig = (): Record<
  AggregateType,
  Pick<OptionDefinition, 'label'> & { yFieldRequired: boolean }
> => ({
  count: {
    label: t('dashboard.aggregationTypes.count'),
    yFieldRequired: false,
  },
  min: {
    label: t('dashboard.aggregationTypes.min'),
    yFieldRequired: true,
  },
  max: {
    label: t('dashboard.aggregationTypes.max'),
    yFieldRequired: true,
  },
  sum: {
    label: t('dashboard.aggregationTypes.sum'),
    yFieldRequired: true,
  },
  avg: {
    label: t('dashboard.aggregationTypes.avg'),
    yFieldRequired: true,
  },
});

export const getAggregateTypeOptions = () => {
  const aggregateTypeConfig = getAggregateTypeConfig();

  return Object.keys(aggregateTypeConfig)
    .map((aggregateType) => ({
      value: aggregateType,
      ...aggregateTypeConfig[aggregateType as AggregateType],
    }))
    .sort((a, b) => a.label?.localeCompare(b.label ?? '') ?? 0);
};
