import FormField from '@cloudscape-design/components/form-field';
import Textarea from '@cloudscape-design/components/textarea';
import type { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import ControlledDatePicker from '@/components/Form/ControlledDatePicker';
import ControlledGroupAndUserSelect from '@/components/Form/ControlledGroupAndUserSelect';
import ControlledImpactSelect from '@/components/Form/ControlledImpactSelect';
import ControlledRating from '@/components/Form/ControlledRating';
import ControlledRiskSelect from '@/components/Form/ControlledRiskSelect';
import ConditionalField from '@/components/Form/Form/CustomisableForm/ConditionalField';
import CustomisableForm from '@/components/Form/Form/CustomisableForm/CustomisableForm';
import { useGetImpactListQuery } from '@/generated/graphql';

import { TestIds } from './ImpactRatingFormFieldsTestIds';
import type { ImpactRatingFormFieldData } from './impactRatingFormSchema';

type Props = {
  readOnly?: boolean;
  hideImpact: boolean;
  hideRatedItem: boolean;
};

const ImpactRatingFormFields: FC<Props> = ({
  readOnly,
  hideImpact,
  hideRatedItem,
}) => {
  const { control, watch } = useFormContext<ImpactRatingFormFieldData>();
  const { t: st } = useTranslation(['common'], {
    keyPrefix: 'impactRatings',
  });

  const impactId = watch('ImpactId');
  const { data: impacts } = useGetImpactListQuery();
  const selectedImpact = impacts?.impact.find(
    (impact) => impact.Id === impactId
  );
  const showImpact = !hideImpact;

  return (
    <CustomisableForm readOnly={readOnly}>
      <ConditionalField condition={showImpact} key={'impactId'}>
        <ControlledImpactSelect
          name={'ImpactId'}
          testId={TestIds.ImpactId}
          forceRequired={true}
          label={st('fields.Impact')}
          description={st('fields.Impact_help')}
          control={control}
          disabled={readOnly}
        />
        {selectedImpact && (
          <div className={'pb-6'}>
            <FormField label={st('fields.Rationale')}>
              <Textarea
                value={selectedImpact.Rationale ?? ''}
                readOnly={true}
              />
            </FormField>
          </div>
        )}
      </ConditionalField>

      <ConditionalField
        condition={!hideRatedItem && !!impactId}
        key={'ratedItemId'}
      >
        <ControlledRiskSelect
          name={'RatedItemId'}
          testId={TestIds.RatedItemId}
          forceRequired={true}
          label={st('fields.Risk')}
          description={st('fields.Risk_help')}
          control={control}
          disabled={readOnly}
        />
      </ConditionalField>

      <ConditionalField condition={!!impactId} key={'completion-by'}>
        <ControlledGroupAndUserSelect
          testId={TestIds.CompletedBy}
          disabled={readOnly}
          defaultRequired={true}
          name={'CompletedBy'}
          label={st('fields.CompletedBy')}
          description={st('fields.CompletedBy_help')}
          control={control}
          includeGroups={false}
          addEmptyOption={true}
        />
      </ConditionalField>

      <ConditionalField condition={!!impactId} key={'testDate'}>
        <ControlledDatePicker
          testId={TestIds.TestDate}
          forceRequired={true}
          name={'TestDate'}
          label={st('fields.TestDate')}
          description={st('fields.TestDate_help')}
          control={control}
          disabled={readOnly}
        />
      </ConditionalField>

      <ConditionalField condition={!!impactId} key={'likelihood'}>
        <ControlledRating
          testId={TestIds.Likelihood}
          forceRequired={true}
          control={control}
          name={'Likelihood'}
          type={'likelihood'}
          showValue={true}
          label={st('fields.Likelihood')}
          description={st('fields.Likelihood_help')}
          disabled={readOnly}
        />
      </ConditionalField>

      <ConditionalField condition={!!impactId} key={'rating'}>
        <ControlledRating
          testId={TestIds.Rating}
          forceRequired={true}
          control={control}
          name={'Rating'}
          type={'impact'}
          showValue={true}
          label={st('fields.Rating')}
          description={st('fields.Rating_help')}
          disabled={readOnly}
        />
      </ConditionalField>
    </CustomisableForm>
  );
};

export default ImpactRatingFormFields;
