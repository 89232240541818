import Button from '@risksmart-app/components/Button';
import { useGetGuidParam } from '@risksmart-app/components/routes/routes.utils';
import type { FC } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { PageLayout } from 'src/layouts';

import ControlledTabs from '@/components/ControlledTabs';
import DeleteModal from '@/components/DeleteModal';
import {
  useDeleteQuestionnaireTemplateMutation,
  useGetQuestionnaireTemplateByIdQuery,
} from '@/generated/graphql';
import { useDeleteResultNotification } from '@/hooks/useMutationResultNotification';
import { evictField } from '@/utils/graphqlUtils';

import { SpaceBetween } from '../../../../../theme/risksmart-themed/components';
import { Permission } from '../../../rbac/Permission';
import {
  useGetDetailParentPath,
  useGetDetailPath,
} from '../../../routes/useGetDetailParentPath';
import { useTabs } from './useTabs';

interface Props {
  activeTabId?: 'details' | 'versions';
}

const Page: FC<Props> = ({ activeTabId }: Props) => {
  const questionnaireTemplateId = useGetGuidParam('questionnaireTemplateId');
  const entityUrl = useGetDetailPath(questionnaireTemplateId);
  const parentUrl = useGetDetailParentPath(questionnaireTemplateId);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const { data } = useGetQuestionnaireTemplateByIdQuery({
    variables: { Id: questionnaireTemplateId },
  });

  const navigate = useNavigate();

  const title = data?.questionnaire_template?.Title || '';
  const tabs = useTabs(data?.questionnaire_template, entityUrl);
  const { t: st } = useTranslation(['common'], {
    keyPrefix: 'questionnaire_templates',
  });

  const [mutate, deleteResult] = useDeleteQuestionnaireTemplateMutation({
    update: (cache) => {
      evictField(cache, 'questionnaire_template');
      evictField(cache, 'questionnaire_template_by_pk');
    },
  });

  const onDelete = useDeleteResultNotification({
    entityName: st('entity_name'),
    asyncAction: async () => {
      if (!data) {
        throw new Error('Data is not available');
      }

      await mutate({
        variables: {
          Id: questionnaireTemplateId,
        },
      });

      await navigate(parentUrl);

      return true;
    },
  });

  return (
    <PageLayout
      title={title}
      actions={
        <SpaceBetween direction={'horizontal'} size={'xs'}>
          <Permission permission={'delete:questionnaire_template'}>
            <Button
              variant={'normal'}
              formAction={'none'}
              onClick={() => setIsDeleteModalVisible(true)}
            >
              {st('delete_button')}
            </Button>
          </Permission>
        </SpaceBetween>
      }
    >
      <ControlledTabs
        tabs={tabs}
        activeTabId={activeTabId}
        variant={'container'}
      />
      <DeleteModal
        loading={deleteResult.loading}
        isVisible={isDeleteModalVisible}
        header={st('delete_modal_title')}
        onDelete={onDelete}
        onDismiss={() => setIsDeleteModalVisible(false)}
        size={'medium'}
      >
        {st('confirm_delete_message')}
      </DeleteModal>
    </PageLayout>
  );
};

export default Page;
