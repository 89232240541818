import type {
  OptionDefinition,
  OptionGroup,
} from '@cloudscape-design/components/internal/components/option/interfaces';
import type { SelectProps } from '@cloudscape-design/components/select';
import { useMemo } from 'react';
import type { FieldValues } from 'react-hook-form';

import { Controller } from '../FieldController/Controller';
import { useIsFieldReadOnly } from '../Form/CustomisableForm/hooks/useIsFieldReadOnly';
import type { ControlledBaseProps } from '../types';
import { SelectWithFormField } from './SelectWithFormField';

export type StatusType = 'error' | 'finished' | 'loading' | 'pending';

interface Props<T extends FieldValues> extends ControlledBaseProps<T> {
  options: SelectProps.Options | undefined;
  filteringType?: SelectProps.FilteringType;
  addEmptyOption?: boolean;
  type?: 'number' | 'string';
  constraintText?: React.ReactNode;
  disabled?: boolean;
  statusType?: StatusType;
  onChange?: (value: null | number | string | undefined) => void;
  testId?: string;
  className?: string;
  onBlur?: () => void;
}

export const ControlledSelect = <T extends FieldValues>({
  name,
  control,
  label,
  options,
  addEmptyOption,
  type,
  constraintText,
  forceRequired,
  defaultRequired,
  allowDefaultValue,
  description,
  className,
  onBlur,
  ...props
}: Props<T>) => {
  const { error } = control.getFieldState(name);
  const optionItems = useGetOptionsWithEmptyOption(options, addEmptyOption);
  const readOnly = useIsFieldReadOnly(name);

  return (
    <Controller
      defaultRequired={defaultRequired}
      name={name}
      forceRequired={forceRequired}
      allowDefaultValue={allowDefaultValue}
      defaultValueOptions={optionItems}
      control={control}
      render={({
        field: { ref, onChange, onBlur: onControllerBlur, value },
      }) => {
        return (
          <SelectWithFormField
            className={className}
            type={type}
            innerRef={ref}
            label={label}
            description={description}
            value={value}
            options={optionItems}
            errorMessage={error?.message}
            constraintText={constraintText}
            {...props}
            disabled={readOnly || props.disabled}
            onBlur={() => {
              onControllerBlur();
              onBlur?.();
            }}
            onChange={(e) => {
              onChange(e);
              props.onChange?.(e);
            }}
          />
        );
      }}
    />
  );
};

const useGetOptionsWithEmptyOption = (
  options: SelectProps.Options | undefined,
  addEmptyOption: boolean | undefined
) => {
  const optionItems = useMemo<(OptionDefinition | OptionGroup)[]>(
    () => [
      ...(addEmptyOption
        ? [
            {
              value: '',
              label: '-',
            },
          ]
        : []),
      ...(options || []),
    ],
    [options, addEmptyOption]
  );

  return optionItems;
};
