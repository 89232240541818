import Button from '@risksmart-app/components/Button';
import Table from '@risksmart-app/components/Table';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useGetQuestionnaireTemplatesQuery } from '@/generated/graphql';
import { getCounter } from '@/utils/collectionUtils';
import { addQuestionnaireTemplateUrl } from '@/utils/urls';

import { SpaceBetween } from '../../../../theme/risksmart-themed/components';
import { PageLayout } from '../../layouts';
import { Permission } from '../../rbac/Permission';
import { useGetCollectionTableProps } from './config';

const Page: FC = () => {
  const { t: st } = useTranslation(['common'], {
    keyPrefix: 'questionnaire_templates',
  });

  const { data, loading } = useGetQuestionnaireTemplatesQuery();
  const tableProps = useGetCollectionTableProps(data?.questionnaire_template);
  const counter = getCounter(tableProps.totalItemsCount, loading);

  return (
    <PageLayout
      helpTranslationKey={'third_party.registerHelp'}
      title={st('register_title')}
      counter={counter}
      actions={
        <SpaceBetween direction={'horizontal'} size={'xs'}>
          <Permission permission={'insert:questionnaire_template'}>
            <Button variant={'primary'} href={addQuestionnaireTemplateUrl()}>
              {st('create_new_button')}
            </Button>
          </Permission>
        </SpaceBetween>
      }
    >
      <Table {...tableProps} loading={loading} />
    </PageLayout>
  );
};

export default Page;
