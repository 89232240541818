import { useCollection } from '@cloudscape-design/collection-hooks';
import SpaceBetween from '@cloudscape-design/components/space-between';
import Button from '@risksmart-app/components/Button';
import { useNotifications } from '@risksmart-app/components/Notifications/useNotifications';
import { useGetGuidParam } from '@risksmart-app/components/routes/routes.utils';
import Table from '@risksmart-app/components/Table';
import type { FC } from 'react';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import IndicatorResultModel from 'src/pages/indicators/modals/IndicatorResultModal';
import { Permission } from 'src/rbac/Permission';
import { useHasPermission } from 'src/rbac/useHasPermission';

import DeleteModal from '@/components/DeleteModal/DeleteModal';
import EmptyEntityCollection from '@/components/EmptyCollection/EmptyEntityCollection';
import { useI18NSummaryHelpContent } from '@/components/HelpPanel/useSummaryHelpContent';
import TabHeader from '@/components/TabHeader';
import type { GetIndicatorByIdQuery } from '@/generated/graphql';
import {
  Indicator_Type_Enum,
  useDeleteIndicatorResultsMutation,
  useGetIndicatorResultsByIndicatorIdQuery,
} from '@/generated/graphql';
import { useDeleteResultNotification } from '@/hooks/useMutationResultNotification';
import { evictField } from '@/utils/graphqlUtils';

import type { IndicatorResultFields } from './config';
import { useResultColumnDefinitions } from './config';
import ResultsChart from './ResultsChart';

interface Props {
  indicatorType: Indicator_Type_Enum;
  parent: GetIndicatorByIdQuery['indicator'][number];
}

const Tab: FC<Props> = ({ indicatorType, parent }) => {
  useI18NSummaryHelpContent('indicator_results.tabHelp');
  const { t } = useTranslation(['common'], { keyPrefix: 'indicator_results' });
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const indicatorId = useGetGuidParam('indicatorId');

  const [isEditOpen, setIsEditOpen] = useState(false);
  const { addNotification } = useNotifications();

  const [selectedIndicatorResults, setSelectedIndicatorResults] = useState<
    IndicatorResultFields[]
  >([]);

  const [openIndicatorResultId, setOpenIndicatorResultId] = useState<
    string | undefined
  >();

  const userCanDeleteIndicatorResult = useHasPermission(
    'delete:indicator_result',
    parent
  );

  const [deleteIndicatorResult, deleteResult] =
    useDeleteIndicatorResultsMutation({
      update: (cache) => evictField(cache, 'indicator_result'),
    });

  const { data, loading, refetch } = useGetIndicatorResultsByIndicatorIdQuery({
    variables: {
      indicatorId: indicatorId,
    },
    onError: (error) => {
      addNotification({
        type: 'error',
        content: <>{error.message}</>,
      });
    },
  });

  const handleModalOpen = () => {
    setIsEditOpen(true);
  };

  const handleModalClose = () => {
    setOpenIndicatorResultId(undefined);
    setIsEditOpen(false);
  };

  const indicatorResultColumnDefinitions = useResultColumnDefinitions(
    parent,
    (indicatorResult) => {
      setOpenIndicatorResultId(indicatorResult.Id);
      setIsEditOpen(true);
    }
  );

  const onDelete = useDeleteResultNotification({
    entityName: t('entity_name'),
    failureAction: () => {
      setIsDeleteModalVisible(false);
    },
    asyncAction: async () => {
      await deleteIndicatorResult({
        variables: {
          ids: selectedIndicatorResults.map((result) => result.Id),
        },
      });
      setSelectedIndicatorResults([]);
      setIsDeleteModalVisible(false);

      return true;
    },
  });

  const labelledFields = useMemo<IndicatorResultFields[]>(() => {
    // indicator_result is pre-sorted by ResultDate
    return (
      data?.indicator_result.map((r, i) => ({
        ...r,
        previous: data.indicator_result[i - 1],
      })) || []
    );
  }, [data?.indicator_result]);

  const { items, collectionProps } = useCollection(labelledFields, {
    propertyFiltering: {
      filteringProperties: [],
      empty: (
        <EmptyEntityCollection
          entityLabel={t('entity_name')}
          action={
            <Permission
              permission={'insert:indicator_result'}
              parentObject={parent}
            >
              <Button formAction={'none'} onClick={handleModalOpen}>
                {t('create_new_button')}
              </Button>
            </Permission>
          }
        />
      ),
    },
    sorting: {
      defaultState: {
        sortingColumn: indicatorResultColumnDefinitions[0],
      },
    },
  });

  return (
    <>
      <Table
        {...collectionProps}
        selectionType={userCanDeleteIndicatorResult ? 'multi' : undefined}
        selectedItems={selectedIndicatorResults}
        trackBy={'Id'}
        onSelectionChange={({ detail }) => {
          setSelectedIndicatorResults(detail.selectedItems);
        }}
        resizableColumns={true}
        header={
          <SpaceBetween size={'m'}>
            <TabHeader
              variant={'h2'}
              actions={
                <SpaceBetween direction={'horizontal'} size={'xs'}>
                  <Permission
                    permission={'delete:indicator_result'}
                    parentObject={parent}
                  >
                    <Button
                      formAction={'none'}
                      variant={'normal'}
                      disabled={
                        !selectedIndicatorResults.length ||
                        !userCanDeleteIndicatorResult
                      }
                      onClick={() => setIsDeleteModalVisible(true)}
                    >
                      {t('delete_button')}
                    </Button>
                  </Permission>
                  <Permission
                    permission={'insert:indicator_result'}
                    parentObject={parent}
                  >
                    <Button
                      variant={'primary'}
                      formAction={'none'}
                      onClick={handleModalOpen}
                    >
                      {t('create_new_button')}
                    </Button>
                  </Permission>
                </SpaceBetween>
              }
            >
              {t('tab_title')}
            </TabHeader>

            {indicatorType === Indicator_Type_Enum.Number && (
              <ResultsChart
                data={data?.indicator_result || []}
                upperAppetite={parent.UpperAppetiteNum}
                lowerAppetite={parent.LowerAppetiteNum}
                upperTolerance={parent.UpperToleranceNum}
                lowerTolerance={parent.LowerToleranceNum}
              />
            )}
          </SpaceBetween>
        }
        variant={'embedded'}
        loading={loading}
        columnDefinitions={indicatorResultColumnDefinitions}
        items={items}
        loadingText={t('loading_message') ?? ''}
        sortingDisabled={false}
      />
      {isEditOpen && indicatorId && (
        <IndicatorResultModel
          Id={openIndicatorResultId}
          onDismiss={handleModalClose}
          parentIndicatorId={indicatorId}
          parentIndicatorType={indicatorType}
          indicator={parent}
        />
      )}
      <DeleteModal
        loading={deleteResult.loading}
        isVisible={isDeleteModalVisible}
        header={t('delete_modal_title')}
        onDelete={onDelete}
        onDismiss={() => {
          refetch();
          setOpenIndicatorResultId(undefined);
          setSelectedIndicatorResults([]);
          setIsDeleteModalVisible(false);
        }}
      >
        {t('confirm_delete_message')}
      </DeleteModal>
    </>
  );
};

export default Tab;
