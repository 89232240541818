import { Dotpoints01, LogIn01 } from '@untitled-ui/icons-react';
import clsx from 'clsx';
import type { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import Link from '../Link';
import OrganisationLogo from './OrganisationLogo';

type Props = {
  username: string | undefined;
  organisation: string | undefined;
  role: string | undefined;
  logoKey: string | undefined;
  customLogoUrl?: () => Promise<string>;
  logoutUrl: string;
  handleOrgSwitch: () => void;
};

const UserMenuPopup: FC<Props> = ({
  username,
  organisation,
  role,
  logoKey,
  customLogoUrl,
  logoutUrl,
  handleOrgSwitch,
}) => {
  const { t } = useTranslation(['common']);

  return (
    <div className={'bg-navy_mid rounded-[10px] font-sans text-xs w-[275px]'}>
      <UserMenuRow showSeparator={true}>
        <div className={'text-white font-semibold text-sm'}>{username}</div>
        <div className={'text-grey500'}>{role}</div>
      </UserMenuRow>
      <UserMenuRow showSeparator={true} className={'flex'}>
        <OrganisationLogo logoKey={logoKey} customLogoUrl={customLogoUrl} />
        <div className={'flex flex-col justify-center'}>
          <div className={'text-white font-bold text-sm'}>{organisation}</div>
          <div className={'text-grey500'}>{t('signedIn')}</div>
        </div>
      </UserMenuRow>
      <UserMenuRow
        showSeparator={true}
        showHoverState={false}
        className={'p-[8px]'}
      >
        <Link onFollow={handleOrgSwitch}>
          <span
            className={
              'text-white cursor-pointe text-xs flex cursor-pointer px-[8px] py-3 rounded-md hover:bg-navy_light'
            }
          >
            <Dotpoints01 className={'text-teal'} />
            <div className={'ml-3 flex flex-col justify-center text-sm'}>
              {t('switchOrg')}
            </div>
          </span>
        </Link>
        <Link href={logoutUrl}>
          <span
            className={
              'text-white cursor-pointe text-xs flex cursor-pointer px-[8px] py-3 rounded-md hover:bg-navy_light'
            }
          >
            <LogIn01 className={'text-teal'} />

            <div className={'ml-3 flex flex-col justify-center text-sm'}>
              {t('signOut')}
            </div>
          </span>
        </Link>
      </UserMenuRow>
      <UserMenuRow className={'rounded-b-[10px]'} showSeparator={true}>
        <div className={'text-grey500'}>
          {/* Ignore 'cant find name' ts2304 as we want to use the env variable */}
          {/* @ts-ignore */}
          {`${t('appVersionTitle')}: ${__COMMIT_HASH__}`}
        </div>
      </UserMenuRow>
    </div>
  );
};

const UserMenuRow: FC<{
  children: ReactNode;
  showSeparator: boolean;
  className?: string;
  showHoverState?: boolean;
}> = ({ children, showSeparator, className, showHoverState }) => {
  return (
    <div
      className={clsx(
        'p-5 border-0 border-grey650 border-solid',
        {
          'border-b-[1px]': showSeparator,
          'hover:bg-navy_light': showHoverState,
        },
        className
      )}
    >
      {children}
    </div>
  );
};

export default UserMenuPopup;
