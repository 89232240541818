import { useRating } from '@risksmart-app/components/hooks/useRating';
import _ from 'lodash';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getCorrectedAttestationStatus } from 'src/pages/attestations/useLabelledFields';
import { getAllOwnersCellValue } from 'src/rbac/contributorHelper';

import type { DocumentFile, DocumentFileTableFields } from './types';

export const useLabelledFields = (
  records: DocumentFile[] | undefined
): DocumentFileTableFields[] | undefined => {
  const { t: pt } = useTranslation('common', {
    keyPrefix: 'policy',
  });
  const documentTypes = pt('types', { returnObjects: true });
  const { getLabel: getDocumentFileStatusLabel } = useRating(
    'document_file_status'
  );
  const { getByValue: getAttestationRecordStatusByValue } = useRating(
    'attestation_record_status'
  );

  return useMemo<DocumentFileTableFields[] | undefined>(
    () =>
      records?.map((d) => {
        const attestation: DocumentFile['attestations'][number] | undefined =
          d.attestations.length > 0 ? d.attestations?.[0] : undefined;
        const attestationStatus = attestation
          ? getCorrectedAttestationStatus(attestation)
          : undefined;

        return {
          ModifiedAtTimestamp: d.ModifiedAtTimestamp,
          Id: d.Id,
          Type: d.Type,
          TypeLabel:
            documentTypes[d.parent?.DocumentType as keyof typeof documentTypes],
          Title: d.parent?.Title || '-',
          Status: d.Status ?? '',
          StatusLabelled: getDocumentFileStatusLabel(d.Status ?? ''),
          ReviewDue: d.NextReviewDate || '-',
          ReviewDate: d.ReviewDate || '-',
          FileId: d.file?.Id,
          Link: d.Link,
          Version: d.Version,
          Summary: d.Summary || '-',
          ParentDocumentId: d.ParentDocumentId,
          allOwners: getAllOwnersCellValue(d.parent!),
          AttestationStatusLabel: attestation
            ? (getAttestationRecordStatusByValue(attestationStatus)?.label ??
              '-')
            : '-',
          AttestationStatus: attestationStatus,
          departments: d.parent?.departments ?? [],
        };
      }),
    [
      records,
      documentTypes,
      getDocumentFileStatusLabel,
      getAttestationRecordStatusByValue,
    ]
  );
};
