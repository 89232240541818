import { useRating } from '@risksmart-app/components/hooks/useRating';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import type { CustomAttributeSchema } from '@/components/Form/CustomAttributes/CustomAttributeSchema';
import Link from '@/components/Link';
import SimpleRatingBadge from '@/components/SimpleRatingBadge';
import { useGetContributorsFieldConfig } from '@/utils/table/hooks/useGetContributorsFieldConfig';
import { useGetDepartmentFieldConfig } from '@/utils/table/hooks/useGetDepartmentFieldConfig';
import { useGetOwnersFieldConfig } from '@/utils/table/hooks/useGetOwnersFieldConfig';
import type {
  StatefulTableOptions,
  UseGetTablePropsOptions,
} from '@/utils/table/hooks/useGetStatelessTableProps';
import { useGetStatelessTableProps } from '@/utils/table/hooks/useGetStatelessTableProps';
import { useGetTableProps } from '@/utils/table/hooks/useGetTableProps';
import { useGetTagFieldConfig } from '@/utils/table/hooks/useGetTagFieldConfig';
import type { TableFields, TablePropsWithActions } from '@/utils/table/types';
import { dateColumn } from '@/utils/table/utils/dateColumn';
import { assessmentDetailsUrl } from '@/utils/urls';

import type { AssessmentFields, AssessmentRegisterFields } from './types';
import type { AssessmentTypeEnum } from './useAssessmentTypeConfig';
import { useAssessmentTypeConfig } from './useAssessmentTypeConfig';
import { useLabelledFields } from './useLabelledFields';

const useGetFieldConfig = (
  assessmentMode: AssessmentTypeEnum,
  useAbsoluteUrls?: boolean
): TableFields<AssessmentRegisterFields> => {
  const { taxonomyKey, outcomeRatingKey } =
    useAssessmentTypeConfig(assessmentMode);
  const { t } = useTranslation('common', {
    keyPrefix: `${taxonomyKey}.columns`,
  });
  const allOwners = useGetOwnersFieldConfig<AssessmentRegisterFields>();
  const allContributors =
    useGetContributorsFieldConfig<AssessmentRegisterFields>();
  const { getByValue: statusGetByValue } = useRating('assessment_status');
  const { getByValue: outcomeGetByValue } = useRating(outcomeRatingKey);
  const tagField = useGetTagFieldConfig<AssessmentRegisterFields>();
  const departmentField = useGetDepartmentFieldConfig<AssessmentRegisterFields>(
    (r) => r.departments
  );
  const { t: gt } = useTranslation(['common'], { keyPrefix: 'columns' });

  return useMemo(
    () => ({
      Title: {
        header: t('Title'),
        cell: (item) => {
          return (
            <Link
              isRelativeUrl={!useAbsoluteUrls}
              variant={'secondary'}
              href={useAbsoluteUrls ? assessmentDetailsUrl(item.Id) : item.Id}
            >
              {item.Title}
            </Link>
          );
        },
        isRowHeader: true,
      },
      StartDate: dateColumn(t('StartDate'), 'StartDate'),
      ActualCompletionDate: dateColumn(
        t('CompletionDate'),
        'ActualCompletionDate'
      ),
      StatusLabelled: {
        header: t('Status'),
        cell: (item) => (
          <SimpleRatingBadge rating={statusGetByValue(item.Status)} />
        ),
      },
      OutcomeLabelled: {
        header: t('Outcome'),
        cell: (item) => (
          <SimpleRatingBadge rating={outcomeGetByValue(item.Outcome)} />
        ),
      },
      AssessedItems: {
        header: t('AssessedItems'),
      },
      TargetCompletionDate: dateColumn(
        t('TargetCompletionDate'),
        'TargetCompletionDate'
      ),
      completedByUser: {
        header: t('CompletionBy'),
        cell: (item) => item.completedByUser?.FriendlyName,
      },
      CreatedAtTimestamp: dateColumn(t('created_on'), 'CreatedAtTimestamp'),
      ModifiedByUser: {
        header: t('updated_by_id'),
      },
      SequentialIdLabel: {
        header: t('id'),
        sortingField: 'SequentialId',
      },
      Id: {
        header: gt('guid'),
      },

      NextTestDate: dateColumn(t('NextTestDate'), 'NextTestDate'),
      ModifiedAtTimestamp: dateColumn(t('updated_on'), 'ModifiedAtTimestamp'),
      CreatedByUser: {
        header: t('created_by_id'),
      },
      allOwners,
      allContributors,
      tags: tagField,
      departments: departmentField,
    }),
    [
      allContributors,
      allOwners,
      departmentField,
      gt,
      outcomeGetByValue,
      statusGetByValue,
      t,
      tagField,
      useAbsoluteUrls,
    ]
  );
};

export const enum AssessmentStatus {
  Complete = 'complete',
  NotStarted = 'notstarted',
  Inprogress = 'inprogress',
}

export const getStatusByDate = (
  now: Dayjs,
  startDate?: null | string,
  completionDate?: null | string
): AssessmentStatus => {
  if (dayjs(completionDate).isBefore(now)) {
    return AssessmentStatus.Complete;
  }
  if (!startDate) {
    return AssessmentStatus.NotStarted;
  }

  return AssessmentStatus.Inprogress;
};

const useGetProps = (
  assessmentMode: AssessmentTypeEnum,
  records: AssessmentFields[] | undefined,
  customAttributeSchema?: CustomAttributeSchema | null,
  useAbsoluteUrls?: boolean
): UseGetTablePropsOptions<AssessmentRegisterFields> => {
  const { registerStorageKey, taxonomyKey } =
    useAssessmentTypeConfig(assessmentMode);
  const { t: at } = useTranslation('common', { keyPrefix: taxonomyKey });
  const fields = useGetFieldConfig(assessmentMode, useAbsoluteUrls);
  const labelledFields = useLabelledFields(assessmentMode, records);

  return useMemo(
    () => ({
      tableId: 'assessmentRegister',
      data: labelledFields,
      customAttributeSchema: customAttributeSchema,
      entityLabel: at('entity'),
      emptyCollectionAction: <></>,
      preferencesStorageKey: registerStorageKey,
      enableFiltering: true,
      initialColumns: [
        'Title',
        'StartDate',
        'ActualCompletionDate',
        'StatusLabelled',
        'allOwners',
      ],
      fields,
    }),
    [at, customAttributeSchema, fields, labelledFields, registerStorageKey]
  );
};

export const useGetCollectionTableProps = (
  assessmentMode: AssessmentTypeEnum,
  records: AssessmentFields[] | undefined,
  customAttributeSchema?: CustomAttributeSchema | null
): TablePropsWithActions<AssessmentRegisterFields> => {
  const props = useGetProps(assessmentMode, records, customAttributeSchema);

  return useGetTableProps(props);
};

export const useGetAssessmentSmartWidgetTableProps = (
  assessmentMode: AssessmentTypeEnum,
  records: AssessmentFields[] | undefined,
  customAttributeSchema: CustomAttributeSchema | null,
  statefulTableOptions: StatefulTableOptions<AssessmentRegisterFields>
): TablePropsWithActions<AssessmentRegisterFields> => {
  const props = useGetProps(
    assessmentMode,
    records,
    customAttributeSchema,
    true
  );

  return useGetStatelessTableProps<AssessmentRegisterFields>({
    ...props,
    ...statefulTableOptions,
    enableFiltering: false,
  });
};
