import type { FC } from 'react';
import { useState } from 'react';

import type { GetDashboardByIdQuery } from '@/generated/graphql';
import { useIsFeatureVisibleToOrg } from '@/utils/featureFlags';

import DashboardViewToggle from './DashboardViewToggle/DashboardViewToggle';
import type { DashboardView } from './DashboardViewToggle/types';
import MyItemsDashboard from './MyItems/Dashboard';
import OverallDashboard from './OverallDashboard';

export type Dashboard = GetDashboardByIdQuery['dashboard_by_pk'];

const Page: FC = () => {
  const showDashboardViewToggle =
    useIsFeatureVisibleToOrg('my_items_dashboard');
  const [dashboardView, setDashboardView] = useState<DashboardView>(
    showDashboardViewToggle ? 'my-items' : 'dashboard'
  );

  const Toggle = (
    <DashboardViewToggle
      dashboardView={dashboardView}
      setDashboardView={setDashboardView}
    />
  );

  return dashboardView === 'dashboard' ? (
    <OverallDashboard dashboardViewToggle={Toggle} />
  ) : (
    <MyItemsDashboard dashboardViewToggle={Toggle} />
  );
};

export default Page;
