import Link from '@risksmart-app/components/Link';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import SimpleRatingBadge from '@/components/SimpleRatingBadge';
import type { UseGetTablePropsOptions } from '@/utils/table/hooks/useGetStatelessTableProps';
import {
  type StatefulTableOptions,
  useGetStatelessTableProps,
} from '@/utils/table/hooks/useGetStatelessTableProps';
import { useGetTableProps } from '@/utils/table/hooks/useGetTableProps';
import type { TableFields, TablePropsWithActions } from '@/utils/table/types';
import { attestationCardsUrl } from '@/utils/urls';

import type { AttestationFlatField, AttestationRegisterFields } from './types';
import { useLabelledFields } from './useLabelledFields';

const useGetFieldConfig = (): TableFields<AttestationRegisterFields> => {
  const { t } = useTranslation(['common'], { keyPrefix: 'columns' });

  return useMemo(
    () => ({
      User: {
        header: t('user'),
        cell: (item) => (
          <Link href={attestationCardsUrl(item.UserId)}>{item.User}</Link>
        ),
      },
      Email: {
        header: 'Email',
        cell: (item) => item.Email,
      },
      AttestationsCompleted: {
        header: 'Attestations Completed',
        cell: (item) => (
          <SimpleRatingBadge
            rating={{
              label: `${item.AttestationsCompleted?.toString()}%`,
              tooltip: `${item.AttestationsCompleted?.toString()}%`,
              color: 'charts-grey-450',
            }}
          />
        ),
        filterOptions: {
          filteringProperties: {
            operators: ['!=', '>', '<', '>=', '<=', '='],
          },
        },
      },
    }),
    [t]
  );
};

const useGetAttestationTableProps = (
  records: AttestationFlatField[] | undefined
): UseGetTablePropsOptions<AttestationRegisterFields> => {
  const { t } = useTranslation(['common']);
  const data = useLabelledFields(records);
  const fields = useGetFieldConfig();

  return useMemo(
    () => ({
      data,
      tableId: 'attestationRegister',
      customAttributeSchema: [],
      entityLabel: t('attestation_one'),
      emptyCollectionAction: <></>,
      preferencesStorageKey: 'AttestationRegisterTable-PreferencesV1',
      enableFiltering: true,
      defaultSortingState: {
        sortingColumn: 'User',
        sortingDirection: 'desc',
      },
      initialColumns: ['User', 'Email', 'AttestationsCompleted'],
      fields,
    }),
    [fields, t, data]
  );
};

export const useGetRegisterTableProps = (
  records: AttestationFlatField[] | undefined
): TablePropsWithActions<AttestationRegisterFields> => {
  const props = useGetAttestationTableProps(records);

  return useGetTableProps(props);
};

export const useGetAttestationSmartWidgetTableProps = (
  records: AttestationFlatField[] | undefined,
  statefulTableOptions: StatefulTableOptions<AttestationRegisterFields>
): TablePropsWithActions<AttestationRegisterFields> => {
  const props = useGetAttestationTableProps(records);

  return useGetStatelessTableProps<AttestationRegisterFields>({
    ...props,
    ...statefulTableOptions,
    enableFiltering: false,
  });
};
