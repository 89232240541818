import i18n from '@risksmart-app/i18n/i18n';
import { t } from 'i18next';

import { DataSourceType } from '../../reporting/schema';
import { SharedDataset } from './types';

export const getTags = () =>
  ({
    label: i18n.format(t('tag_other'), 'capitalize'),
    parentTypes: new Set<DataSourceType>(['risks']),
    fields: {
      id: { dataType: 'guid', displayType: 'text', label: t('columns.id') },
      name: {
        dataType: 'text',
        displayType: 'text',
        label: t('tags.columns.name'),
      },
    },
  }) satisfies SharedDataset;
