import Container from '@cloudscape-design/components/container';
import Header from '@cloudscape-design/components/header';
import SpaceBetween from '@cloudscape-design/components/space-between';
import { JsonForms } from '@jsonforms/react';
import Button from '@risksmart-app/components/Button';
import renderers from '@risksmart-app/components/FormBuilder/renderers/registry';
import { useRating } from '@risksmart-app/components/hooks/useRating';
import Loading from '@risksmart-app/components/Loading';
import { useGetGuidParam } from '@risksmart-app/components/routes/routes.utils';
import { getIsActionAllowed } from '@risksmart-app/shared/thirdParty/responses/responseUtils';
import type { FC } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { PageLayout } from 'src/layouts';

import ActionsButton from '@/components/ActionsButton';
import type { ActionItem } from '@/components/ActionsButton/ActionsButton';
import SimpleRatingBadge from '@/components/SimpleRatingBadge';
import {
  Third_Party_Response_Enum_Action,
  Third_Party_Response_Status_Enum,
  useGetThirdPartyResponseByIdSubscription,
  useUpdateThirdPartyResponseStatusMutation,
} from '@/generated/graphql';

import type { UpdateStatusSchemaFields } from '../../third-party/update/tabs/questionnaires/modals/schema';
import { RequestTypeOptions } from '../../third-party/update/tabs/questionnaires/modals/schema';
import { UpdateStatusModal } from '../../third-party/update/tabs/questionnaires/modals/UpdateStatusModal';
import type { ThirdPartyResponseRegisterFields } from '../../third-party/update/tabs/questionnaires/types';

const Page: FC = () => {
  const Id = useGetGuidParam('responseId');
  const navigate = useNavigate();
  const { t: rt } = useTranslation(['common'], {
    keyPrefix: 'questionnaire_invite',
  });

  const [isDisabled, setIsDisabled] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [action, setAction] = useState<Third_Party_Response_Enum_Action>(
    Third_Party_Response_Enum_Action.RequestMoreInformation
  );
  const [actions, setActions] = useState<ActionItem[]>([]);

  const thirdPartyId = useGetGuidParam('id');
  const { getByValue } = useRating('third_party_response_status');
  const { data, loading } = useGetThirdPartyResponseByIdSubscription({
    variables: { Id },
  });

  useEffect(() => {
    const status = data?.third_party_response_by_pk?.Status;
    const isDisabled =
      status !== Third_Party_Response_Status_Enum.AwaitingReview;

    setIsDisabled(isDisabled);

    setActions([
      {
        text: rt('actions.recall'),
        id: 'recall',
        disabled: !getIsActionAllowed(
          Third_Party_Response_Enum_Action.Recall,
          status
        ),
        onItemClick: () => {
          setAction(Third_Party_Response_Enum_Action.Recall);
          setIsModalVisible(true);
        },
      },
      {
        text: rt('actions.reject'),
        id: 'reject',
        disabled: !getIsActionAllowed(
          Third_Party_Response_Enum_Action.Reject,
          status
        ),
        onItemClick: () => {
          setAction(Third_Party_Response_Enum_Action.Reject);
          setIsModalVisible(true);
        },
      },
      {
        text: rt('actions.moreInformation'),
        id: 'moreInformation',
        disabled: !getIsActionAllowed(
          Third_Party_Response_Enum_Action.RequestMoreInformation,
          status
        ),
        onItemClick: () => {
          setAction(Third_Party_Response_Enum_Action.RequestMoreInformation);
          setIsModalVisible(true);
        },
      },
    ]);
  }, [data, rt]);

  const { Title } =
    data?.third_party_response_by_pk?.questionnaireTemplateVersion?.parent ||
    {};

  const { Schema, UISchema } =
    data?.third_party_response_by_pk?.questionnaireTemplateVersion || {};

  const [handleUpdateStatus, { loading: handleUpdateStatusLoading }] =
    useUpdateThirdPartyResponseStatusMutation();

  const onUpdateStatus = async (data: UpdateStatusSchemaFields) => {
    await handleUpdateStatus({
      variables: {
        Action: action,
        ResponseIds: [Id],
        Reason: data.Reason,
        RequestType: data.RequestType
          ? RequestTypeOptions[data.RequestType]
          : '',
        ShareWithRespondents: data.ShareWithRespondents,
        ThirdPartyId: thirdPartyId,
      },
    });
  };

  const onApprove = () => {
    setAction(Third_Party_Response_Enum_Action.Approve);
    setIsModalVisible(true);
  };

  if (loading) {
    return (
      <PageLayout title={Title}>
        <Loading />
      </PageLayout>
    );
  }

  const selectedItems: ThirdPartyResponseRegisterFields[] =
    !data?.third_party_response_by_pk
      ? []
      : ([
          { Id, ...data.third_party_response_by_pk },
        ] as unknown as ThirdPartyResponseRegisterFields[]);

  return (
    <PageLayout title={Title}>
      <Container>
        <SpaceBetween size={'m'} direction={'vertical'}>
          <SpaceBetween size={'s'} direction={'horizontal'}>
            <Header variant={'h2'}>{'Status:'}</Header>
            <div className={'flex h-full items-center justify-center'}>
              <SimpleRatingBadge
                rating={getByValue(data?.third_party_response_by_pk?.Status)}
              />
            </div>
          </SpaceBetween>
          <JsonForms
            schema={Schema}
            uischema={UISchema}
            data={{
              ...data?.third_party_response_by_pk?.ResponseData,
              files: data?.third_party_response_by_pk?.files,
            }}
            renderers={renderers}
            readonly={true}
          />
          <SpaceBetween size={'s'} direction={'horizontal'}>
            <Button
              disabled={isDisabled}
              variant={'primary'}
              onClick={onApprove}
            >
              {rt('actions.approve')}
            </Button>
            <ActionsButton
              disabled={actions.every((action) => action.disabled)}
              buttonText={rt('actions.moreActions')}
              items={actions}
            />
            <Button
              disabled={false}
              variant={'normal'}
              onClick={() => {
                navigate(-1);
              }}
            >
              {'Cancel'}
            </Button>
          </SpaceBetween>
        </SpaceBetween>
      </Container>
      <UpdateStatusModal
        onDismiss={() => setIsModalVisible(false)}
        onUpdateStatus={onUpdateStatus}
        action={action}
        loading={handleUpdateStatusLoading}
        isVisible={isModalVisible}
        selectedItems={selectedItems}
      />
    </PageLayout>
  );
};

export default Page;
