import type { TableProps } from '@cloudscape-design/components/table';
import { useRating } from '@risksmart-app/components/hooks/useRating';
import Table from '@risksmart-app/components/Table';
import type { FC, ReactNode } from 'react';

import type {
  GetReportingDataQuery,
  ReportingDataInput,
} from '@/generated/graphql';
import useEntityInfo from '@/hooks/getEntityInfo';
import { useCommonLookupLazy } from '@/hooks/useCommonLookupLazy';

import { fieldTypes } from './fieldTypes';
import Pager from './Pager';
import type { CustomAttributeSchemaLookup } from './types';
import type { RelatedDataSource } from './utils';
import { getAllowedFieldsFromDataSourceArray } from './utils';

export type Props = {
  variant?: TableProps.Variant;
  definition: Omit<ReportingDataInput, 'limit' | 'offset'> | undefined;
  items: GetReportingDataQuery['reportingData'];
  onPageChangeClick?: (e: { requestedPageIndex: number }) => void;
  currentPageIndex: number;
  pageSize: number;
  loading: boolean;
  customAttributeSchemaLookup: CustomAttributeSchemaLookup;
  filter?: ReactNode;
};

const ReportTable: FC<Props> = ({
  definition,
  items,
  onPageChangeClick,
  currentPageIndex,
  pageSize,
  loading,
  customAttributeSchemaLookup,
  variant,
  filter,
}) => {
  const {
    getByValueAndRatingKey: getRatingByValue,
    getOptionsByRatingKey: getRatingOptions,
  } = useRating();

  const {
    getByValue: getCommonLookupByValue,
    getOptions: getCommonLookupOptions,
  } = useCommonLookupLazy();

  const allowedFields = getAllowedFieldsFromDataSourceArray(
    (definition?.dataSources as RelatedDataSource[]) ?? [],
    customAttributeSchemaLookup
  );
  const getEntityInfo = useEntityInfo();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const columnDefinitions: readonly TableProps.ColumnDefinition<any>[] =
    definition?.fields.map((field, index) => {
      const allowedField = allowedFields.find(
        (af) =>
          af.fieldId === field.fieldId &&
          af.dataSourceIndex === field.dataSourceIndex
      );

      return {
        header: allowedField?.label ?? '-',
        cell: (item) => {
          const fieldDef = allowedField?.fieldDef;
          if (!fieldDef) {
            throw new Error('Missing field def');
          }

          return fieldTypes[fieldDef.displayType].cell({
            field: allowedField,
            fieldData: item[index],
            helpers: {
              getRatingByValue,
              getCommonLookupByValue,
              getCommonLookupOptions,
              getRatingOptions,
              getEntityInfo,
            },
          });
        },
      };
    }) ?? [];

  return (
    <Table
      filter={filter}
      loading={loading}
      variant={variant}
      pagination={
        onPageChangeClick && (
          <Pager
            loading={loading}
            currentPageIndex={currentPageIndex}
            pageSize={pageSize}
            currentPageSize={items?.length ?? 0}
            onPageChangeClick={onPageChangeClick}
          />
        )
      }
      items={items ?? []}
      columnDefinitions={columnDefinitions}
      empty={'No records found'}
    />
  );
};

export default ReportTable;
