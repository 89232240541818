import {
  AcceptanceCreatePage,
  AcceptancesPage,
  AcceptanceUpdatePage,
  AccessDeniedPage,
  ActionsPage,
  AppetiteBreadcrumb,
  AppetiteCreatePage,
  AppetitesPage,
  AppetiteUpdatePage,
  AssessmentCreatePage,
  AssessmentResultsRegisterPage,
  AssessmentsPage,
  AttestationsPage,
  AuthErrorPage,
  CausesPage,
  ComplianceMonitoringAssessmentResultsPage,
  ComplianceMonitoringAssessmentsPage,
  ConsequencesPage,
  ControlGroupsPage,
  ControlsPage,
  ControlTestsPage,
  CustomDatasourceBreadcrumb,
  CustomDatasourceDetailsPage,
  CustomDatasourcesPage,
  CustomDatasourceUpdatePage,
  EnterpriseRiskDashboardPage,
  ErrorPage,
  HomePage,
  ImpactBreadcrumb,
  ImpactPage,
  ImpactRatingsPage,
  ImpactsPage,
  IndicatorsPage,
  InternalAuditCreatePage,
  InternalAuditDashboardPage,
  InternalAuditPage,
  InternalAuditReportPage,
  InternalAuditReportResultsPage,
  InvitationExpiredPage,
  IssuesPage,
  LoginPage,
  LogoutPage,
  MyItemsPage,
  NotFoundPage,
  ObligationCreatePage,
  ObligationDashboardPage,
  ObligationsPage,
  ObligationUpdateBreadcrumb,
  ObligationUpdatePage,
  OpenPublicPolicyFilePage,
  OpenPublicPolicyFileRedirect,
  OrgNotFoundPage,
  PolicyCreatePage,
  PolicyPage,
  ProtectedErrorPage,
  PublicPoliciesPage,
  QuestionnaireTemplateCreatePage,
  QuestionnaireTemplatePage,
  ReportAnIssuePage,
  ReportAnIssueSuccessPage,
  RequestsPage,
  RiskDashboardPage,
  RisksCreatePage,
  RisksPage,
  RisksUpdateBreadcrumb,
  RisksUpdatePage,
  SlackCallbackPage,
  ThirdPartyCreatePage,
  ThirdPartyInvitePage,
  ThirdPartyPage,
  ThirdPartyResponseDetailsPage,
  ThirdPartyResponsesPage,
  ThirdPartyUpdatePage,
  UpdateAssessmentResultPage,
  UpdateInternalAuditResultPage,
  UpdateMonitoringAssessmentsResultPage,
  UserAttestationsPage,
  UserNotFoundPage,
} from '@pages';
import type { HandleOptions } from '@risksmart-app/components/hooks/use-breadcrumbs';
import i18n from '@risksmart-app/i18n/i18n';
import type { RouteObject } from 'react-router';
import OrgFeatureFlaggedRoute from 'src/rbac/OrgFeatureFlaggedRoute';
import ProtectedRoute from 'src/rbac/ProtectedRoute';

import { IssueTypeMapping } from '@/utils/issueVariantUtils';
import {
  accessDeniedUrl,
  complianceDashboardUrl,
  customDatasourcesUrl,
  enterpriseRiskDashboardUrl,
  impactRatingsUrl,
  impactsUrl,
  internalAuditDashboardUrl,
  loginUrl,
  logoutUrl,
  myItemsUrl,
  policyRegisterUrl,
  publicPoliciesUrl,
  questionnaireTemplateRegisterUrl,
  reportAnIssueSuccessUrl,
  reportAnIssueUrl,
  riskDashboardUrl,
  settingsUrl,
  slackCallbackUrl,
  thirdPartyRegisterUrl,
} from '@/utils/urls';

import { ProtectedLayout } from '../layouts';
import AcceptanceBreadcrumb from '../pages/acceptances/detail/update/Breadcrumb';
import AttestationsUserBreadcrumb from '../pages/attestations/user/BreadCrumb';
import ThirdPartyBreadcrumb from '../pages/third-party/update/Breadcrumb';
import ThirdPartyResponseDetailsBreadcrumb from '../pages/third-party-responses/update/Breadcrumb';
import Providers from '../Providers';
import { actionRoute } from './actionRoutes.config';
import { assessmentsRoute } from './assessmentRoutes.config';
import { complianceMonitoringAssessmentRoute } from './complianceMonitoringAssessmentRoutes.config';
import { controlGroupRoute } from './controlGroupRoutes.config';
import { controlRoute } from './controlRoute.routes.config';
import { documentRoute } from './documentRoutes.config';
import { enterpriseRiskRoute } from './enterpriseRiskRoutes.config';
import { indicatorRoute } from './indicatorRoutes.config';
import { internalAuditReportsRoute } from './internalAuditReportRoutes.config';
import { internalAuditRoute } from './internalAuditRoutes.config';
import { issueRoute } from './issueRoutes.config';
import { questionnaireTemplateRoute } from './questionnaireTemplateRoutes.config';
import { settingsRoute } from './settingRoutes.config';

const routes: RouteObject[] = [
  {
    element: <Providers />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: loginUrl(),
        element: <LoginPage />,
        handle: {
          // TODO: translation
          title: 'Login',
        },
      },
      {
        children: [
          {
            path: '/error',
            element: <ErrorPage />,
            handle: {
              // TODO: translation
              title: 'Error',
            },
          },
          {
            path: '/auth-error',
            element: <AuthErrorPage />,
          },
          {
            path: '/noorg',
            element: <OrgNotFoundPage />,
            handle: {
              // TODO: translation
              title: 'No organisation found',
            },
          },
          {
            path: '/user-not-found',
            element: <UserNotFoundPage />,
            handle: {
              // TODO: translation
              title: 'User not found',
            },
          },
          {
            path: '/invitation-expired',
            element: <InvitationExpiredPage />,
            handle: {
              // TODO: translation
              title: 'Invitation expired',
            },
          },
          {
            path: accessDeniedUrl(),
            element: <AccessDeniedPage />,
            handle: {
              // TODO: translation
              title: 'Access denied',
            },
          },
        ],
      },

      {
        path: logoutUrl(),
        element: <LogoutPage loginUrl={loginUrl} />,
        handle: {
          // TODO: translation
          title: 'Logout',
        },
      },
      {
        path: '/',
        element: <ProtectedLayout />,
        errorElement: <ErrorPage />,
        children: [
          {
            errorElement: <ProtectedErrorPage />,
            children: [
              {
                path: slackCallbackUrl(),
                element: <SlackCallbackPage />,
              },
              {
                path: '/',
                element: <HomePage />,
              },
              {
                path: myItemsUrl(),
                element: (
                  <ProtectedRoute permission={'read:my_items'}>
                    <MyItemsPage />
                  </ProtectedRoute>
                ),
              },
              {
                handle: {
                  title: () => i18n.t('customDatasources.register_title'),
                },
                path: customDatasourcesUrl(),
                children: [
                  {
                    path: '',
                    element: (
                      <OrgFeatureFlaggedRoute featureFlag={'multi_reporting'}>
                        <ProtectedRoute permission={'read:custom_datasource'}>
                          <CustomDatasourcesPage />
                        </ProtectedRoute>
                      </OrgFeatureFlaggedRoute>
                    ),
                  },
                  {
                    handle: {
                      title: () => i18n.t('customDatasources.create_title'),
                    },
                    path: 'add',
                    element: (
                      <OrgFeatureFlaggedRoute featureFlag={'multi_reporting'}>
                        <ProtectedRoute permission={'insert:custom_datasource'}>
                          <CustomDatasourceUpdatePage />
                        </ProtectedRoute>
                      </OrgFeatureFlaggedRoute>
                    ),
                  },
                  {
                    handle: {
                      breadcrumb: CustomDatasourceBreadcrumb,
                    },
                    path: ':customDatasourceId/edit',
                    element: (
                      <OrgFeatureFlaggedRoute featureFlag={'multi_reporting'}>
                        <ProtectedRoute permission={'read:custom_datasource'}>
                          <CustomDatasourceUpdatePage />
                        </ProtectedRoute>
                      </OrgFeatureFlaggedRoute>
                    ),
                  },
                  {
                    handle: {
                      breadcrumb: CustomDatasourceBreadcrumb,
                    },
                    path: ':customDatasourceId',
                    element: (
                      <OrgFeatureFlaggedRoute featureFlag={'multi_reporting'}>
                        <ProtectedRoute permission={'read:custom_datasource'}>
                          <CustomDatasourceDetailsPage />
                        </ProtectedRoute>
                      </OrgFeatureFlaggedRoute>
                    ),
                  },
                ],
              },
              {
                path: riskDashboardUrl(),
                element: (
                  <ProtectedRoute
                    permission={'read:risk'}
                    canHaveAccessAsContributor={true}
                  >
                    <RiskDashboardPage />
                  </ProtectedRoute>
                ),
              },
              {
                path: reportAnIssueUrl(),
                element: (
                  <ProtectedRoute permission={'read:public_issue_form'}>
                    <ReportAnIssuePage />
                  </ProtectedRoute>
                ),
              },
              {
                path: publicPoliciesUrl(),
                element: (
                  <ProtectedRoute permission={'read:public_policies'}>
                    <PublicPoliciesPage />
                  </ProtectedRoute>
                ),
              },
              {
                path: 'policy/:documentId/files/:fileId',
                element: <OpenPublicPolicyFileRedirect />,
              },
              {
                path: `${publicPoliciesUrl()}/:documentId/files/:fileId`,
                element: (
                  <ProtectedRoute
                    permission={['read:public_policies', 'read:document']}
                  >
                    <OpenPublicPolicyFilePage />
                  </ProtectedRoute>
                ),
              },
              {
                path: settingsUrl(),
                children: [settingsRoute],
              },
              {
                path: reportAnIssueSuccessUrl(':sequentialId'),
                element: (
                  <ProtectedRoute permission={'read:public_issue_form'}>
                    <ReportAnIssueSuccessPage />
                  </ProtectedRoute>
                ),
              },
              {
                path: policyRegisterUrl(),
                handle: {
                  title: () => i18n.t('policy.policy'),
                },
                children: [
                  {
                    path: '',
                    element: (
                      <OrgFeatureFlaggedRoute featureFlag={'policy'}>
                        <ProtectedRoute
                          permission={'read:document'}
                          canHaveAccessAsContributor={true}
                        >
                          <PolicyPage />
                        </ProtectedRoute>
                      </OrgFeatureFlaggedRoute>
                    ),
                  },
                  {
                    path: 'add',
                    element: (
                      <OrgFeatureFlaggedRoute featureFlag={'policy'}>
                        <ProtectedRoute permission={'insert:document'}>
                          <PolicyCreatePage />
                        </ProtectedRoute>
                      </OrgFeatureFlaggedRoute>
                    ),
                    handle: {
                      title: () => i18n.t('policy.create_title'),
                    },
                  },
                  documentRoute,
                ],
              },
              {
                path: `${policyRegisterUrl()}/attestations`,
                handle: {
                  title: () =>
                    i18n.format(i18n.t('attestation_other'), 'capitalize'),
                },
                children: [
                  {
                    index: true,
                    element: (
                      <OrgFeatureFlaggedRoute featureFlag={'attestations'}>
                        <ProtectedRoute permission={'read:attestation_record'}>
                          <AttestationsPage />
                        </ProtectedRoute>
                      </OrgFeatureFlaggedRoute>
                    ),
                  },
                  {
                    path: 'user/:userId',
                    handle: {
                      breadcrumb: AttestationsUserBreadcrumb,
                    },
                    element: (
                      <OrgFeatureFlaggedRoute featureFlag={'attestations'}>
                        <ProtectedRoute permission={'read:attestation_record'}>
                          <UserAttestationsPage />
                        </ProtectedRoute>
                      </OrgFeatureFlaggedRoute>
                    ),
                  },
                ],
              },
              {
                path: '/controls',
                handle: {
                  title: () =>
                    i18n.format(i18n.t('control_other'), 'capitalize'),
                },
                children: [
                  {
                    path: '',
                    element: (
                      <ProtectedRoute
                        permission={'read:control'}
                        canHaveAccessAsContributor={true}
                      >
                        <ControlsPage />
                      </ProtectedRoute>
                    ),
                  },
                  controlRoute,
                ],
              },
              {
                path: '/controls/tests',
                element: (
                  <ProtectedRoute
                    permission={'read:control'}
                    canHaveAccessAsContributor={true}
                  >
                    <ControlTestsPage />
                  </ProtectedRoute>
                ),
              },
              {
                path: '/control-groups',
                handle: {
                  // TODO: translation
                  title: 'Control Groups',
                },
                children: [
                  {
                    path: '',
                    element: (
                      <ProtectedRoute
                        permission={'read:control_group'}
                        canHaveAccessAsContributor={true}
                      >
                        <ControlGroupsPage />
                      </ProtectedRoute>
                    ),
                  },
                  controlGroupRoute,
                ],
              },
              {
                path: '/indicator',
                handle: {
                  title: () =>
                    // TODO: translation
                    'Indicators',
                },
                children: [
                  {
                    path: '',
                    element: (
                      <ProtectedRoute
                        permission={'read:indicator'}
                        canHaveAccessAsContributor={true}
                      >
                        <IndicatorsPage />
                      </ProtectedRoute>
                    ),
                  },
                  indicatorRoute,
                ],
              },
              {
                path: '/assessments/findings',
                handle: {
                  title: () =>
                    i18n.format(i18n.t('finding_other'), 'capitalizeAll'),
                },
                children: [
                  {
                    index: true,
                    element: (
                      <ProtectedRoute
                        permission={'read:assessment'}
                        canHaveAccessAsContributor={true}
                      >
                        <AssessmentResultsRegisterPage />
                      </ProtectedRoute>
                    ),
                  },
                  {
                    path: ':findingId',
                    handle: {
                      title: () =>
                        i18n.format(i18n.t('finding_one'), 'capitalizeAll'),
                    },
                    element: (
                      <ProtectedRoute
                        permission={'read:assessment'}
                        canHaveAccessAsContributor={true}
                      >
                        <UpdateAssessmentResultPage />
                      </ProtectedRoute>
                    ),
                  },
                ],
              },
              {
                path: '/assessments',
                handle: {
                  title: () =>
                    i18n.format(i18n.t('assessment_other'), 'capitalize'),
                },
                children: [
                  {
                    path: '',
                    element: (
                      <ProtectedRoute
                        permission={'read:assessment'}
                        canHaveAccessAsContributor={true}
                      >
                        <AssessmentsPage />
                      </ProtectedRoute>
                    ),
                  },
                  {
                    path: 'add',
                    element: (
                      <ProtectedRoute permission={'insert:assessment'}>
                        <AssessmentCreatePage assessmentMode={'rating'} />
                      </ProtectedRoute>
                    ),
                    handle: {
                      // TODO: translation
                      title: 'Add assessment',
                    },
                  },
                  assessmentsRoute,
                ],
              },
              {
                path: internalAuditDashboardUrl(),
                element: (
                  <OrgFeatureFlaggedRoute featureFlag={'internal_audit'}>
                    <ProtectedRoute
                      permission={'read:internal_audit_entity'}
                      canHaveAccessAsContributor={true}
                    >
                      <InternalAuditDashboardPage />
                    </ProtectedRoute>
                  </OrgFeatureFlaggedRoute>
                ),
              },
              {
                path: '/internal-audits',
                handle: {
                  title: () =>
                    i18n.format(i18n.t('internal_audit_other'), 'capitalize'),
                },
                children: [
                  {
                    path: '',
                    element: (
                      <ProtectedRoute
                        permission={'read:internal_audit_entity'}
                        canHaveAccessAsContributor={true}
                      >
                        <InternalAuditPage />
                      </ProtectedRoute>
                    ),
                  },
                  {
                    path: 'add',
                    element: (
                      <ProtectedRoute
                        permission={'insert:internal_audit_entity'}
                        canHaveAccessAsContributor={true}
                      >
                        <InternalAuditCreatePage />
                      </ProtectedRoute>
                    ),
                    handle: {
                      title: () => i18n.t('internalAudits.add_button'),
                    },
                  },
                  internalAuditRoute,
                ],
              },
              {
                path: '/internal-audits/reports',
                handle: {
                  title: () =>
                    i18n.format(
                      i18n.t('internal_audit_report_other'),
                      'capitalize'
                    ),
                },
                children: [
                  {
                    path: '',
                    element: (
                      <ProtectedRoute
                        permission={'read:internal_audit_report'}
                        canHaveAccessAsContributor={true}
                      >
                        <InternalAuditReportPage />
                      </ProtectedRoute>
                    ),
                  },
                  {
                    path: 'add',
                    element: (
                      <ProtectedRoute
                        permission={'insert:internal_audit_report'}
                        canHaveAccessAsContributor={true}
                      >
                        <AssessmentCreatePage
                          assessmentMode={'internal_audit_report'}
                        />
                      </ProtectedRoute>
                    ),
                    handle: {
                      title: () => i18n.t('internalAuditReports.add_button'),
                    },
                  },
                  internalAuditReportsRoute,
                ],
              },

              {
                path: '/internal-audits/reports/findings',
                handle: {
                  title: () =>
                    i18n.t('internalAuditAssessmentResults.register_title'),
                },
                children: [
                  {
                    index: true,
                    element: (
                      <ProtectedRoute
                        permission={'read:internal_audit_report'}
                        canHaveAccessAsContributor={true}
                      >
                        <InternalAuditReportResultsPage />
                      </ProtectedRoute>
                    ),
                  },
                  {
                    path: ':findingId',
                    handle: {
                      title: () =>
                        i18n.format(i18n.t('finding_one'), 'capitalizeAll'),
                    },
                    element: (
                      <ProtectedRoute
                        permission={'read:assessment'}
                        canHaveAccessAsContributor={true}
                      >
                        <UpdateInternalAuditResultPage />
                      </ProtectedRoute>
                    ),
                  },
                ],
              },
              {
                path: '/compliance/monitoring-assessments',
                handle: {
                  title: () =>
                    i18n.format(
                      i18n.t('compliance_monitoring_assessment_other'),
                      'capitalize'
                    ),
                },
                children: [
                  {
                    path: '',
                    element: (
                      <ProtectedRoute
                        permission={'read:compliance_monitoring_assessment'}
                        canHaveAccessAsContributor={true}
                      >
                        <ComplianceMonitoringAssessmentsPage />
                      </ProtectedRoute>
                    ),
                  },
                  {
                    path: 'add',
                    element: (
                      <ProtectedRoute
                        permission={'insert:compliance_monitoring_assessment'}
                        canHaveAccessAsContributor={true}
                      >
                        <AssessmentCreatePage
                          assessmentMode={'compliance_monitoring_assessment'}
                        />
                      </ProtectedRoute>
                    ),
                    handle: {
                      title: () =>
                        i18n.t('complianceMonitoringAssessment.add_button'),
                    },
                  },
                  complianceMonitoringAssessmentRoute,
                ],
              },
              {
                path: '/compliance/monitoring-assessments/findings',
                handle: {
                  title: () =>
                    i18n.format(i18n.t('finding_other'), 'capitalizeAll'),
                },
                children: [
                  {
                    index: true,
                    element: (
                      <ProtectedRoute
                        permission={'read:compliance_monitoring_assessment'}
                        canHaveAccessAsContributor={true}
                      >
                        <ComplianceMonitoringAssessmentResultsPage />
                      </ProtectedRoute>
                    ),
                  },
                  {
                    path: ':findingId',
                    handle: {
                      title: () =>
                        i18n.format(i18n.t('finding_one'), 'capitalizeAll'),
                    },
                    element: (
                      <ProtectedRoute
                        permission={'read:assessment'}
                        canHaveAccessAsContributor={true}
                      >
                        <UpdateMonitoringAssessmentsResultPage />
                      </ProtectedRoute>
                    ),
                  },
                ],
              },
              {
                path: '/third-party',
                handle: {
                  title: () =>
                    i18n.format(i18n.t('third_party_other'), 'capitalizeAll'),
                  breadcrumbUrl: () => thirdPartyRegisterUrl(),
                },
                children: [
                  {
                    index: true,
                    element: (
                      <OrgFeatureFlaggedRoute featureFlag={'third_party'}>
                        <ProtectedRoute
                          permission={'read:third_party'}
                          canHaveAccessAsContributor={true}
                        >
                          <ThirdPartyPage />
                        </ProtectedRoute>
                      </OrgFeatureFlaggedRoute>
                    ),
                  },
                  {
                    path: 'add',
                    handle: {
                      title: () => i18n.t('common:third_party.create_title'),
                    },
                    element: (
                      <OrgFeatureFlaggedRoute featureFlag={'third_party'}>
                        <ProtectedRoute permission={'insert:third_party'}>
                          <ThirdPartyCreatePage />
                        </ProtectedRoute>
                      </OrgFeatureFlaggedRoute>
                    ),
                  },
                  {
                    path: ':id',
                    handle: {
                      breadcrumb: ThirdPartyBreadcrumb,
                    },
                    children: [
                      {
                        path: '',
                        element: (
                          <OrgFeatureFlaggedRoute featureFlag={'third_party'}>
                            <ProtectedRoute
                              permission={'read:third_party'}
                              canHaveAccessAsContributor={true}
                            >
                              <ThirdPartyUpdatePage
                                activeTabId={'details'}
                                showDeleteButton={true}
                              />
                            </ProtectedRoute>
                          </OrgFeatureFlaggedRoute>
                        ),
                      },
                      {
                        path: 'questionnaire-responses',
                        children: [
                          {
                            index: true,
                            element: (
                              <OrgFeatureFlaggedRoute
                                featureFlag={'questionnaire_builder'}
                              >
                                <ProtectedRoute
                                  permission={'update:third_party'}
                                  canHaveAccessAsContributor={true}
                                >
                                  <ThirdPartyUpdatePage
                                    activeTabId={'questionnaires'}
                                  />
                                </ProtectedRoute>
                              </OrgFeatureFlaggedRoute>
                            ),
                          },
                          {
                            path: ':responseId',
                            handle: {
                              breadcrumb: ThirdPartyResponseDetailsBreadcrumb,
                            },
                            element: (
                              <OrgFeatureFlaggedRoute
                                featureFlag={'third_party'}
                              >
                                <ProtectedRoute
                                  permission={'read:third_party_response'}
                                  canHaveAccessAsContributor={true}
                                >
                                  <ThirdPartyResponseDetailsPage />
                                </ProtectedRoute>
                              </OrgFeatureFlaggedRoute>
                            ),
                          },
                        ],
                      },
                      {
                        path: 'questionnaire-responses/invite',
                        element: (
                          <OrgFeatureFlaggedRoute
                            featureFlag={'questionnaire_builder'}
                          >
                            <ProtectedRoute
                              permission={'update:third_party'}
                              canHaveAccessAsContributor={true}
                            >
                              <ThirdPartyInvitePage />
                            </ProtectedRoute>
                          </OrgFeatureFlaggedRoute>
                        ),
                      },
                      {
                        path: 'controls',
                        children: [
                          {
                            path: '',
                            element: (
                              <OrgFeatureFlaggedRoute
                                featureFlag={'third_party'}
                              >
                                <ProtectedRoute
                                  permission={'read:control'}
                                  canHaveAccessAsContributor={true}
                                >
                                  <ThirdPartyUpdatePage
                                    activeTabId={'controls'}
                                  />
                                </ProtectedRoute>
                              </OrgFeatureFlaggedRoute>
                            ),
                          },
                          controlRoute,
                        ],
                      },
                      ...Object.entries(IssueTypeMapping).map(([_, itm]) => ({
                        path: itm.uriPath,
                        children: [
                          {
                            path: '',
                            element: (
                              <OrgFeatureFlaggedRoute
                                featureFlag={'third_party'}
                              >
                                <ProtectedRoute
                                  permission={'read:issue'}
                                  canHaveAccessAsContributor={true}
                                >
                                  <ThirdPartyUpdatePage
                                    activeTabId={itm.taxonomy}
                                  />
                                </ProtectedRoute>
                              </OrgFeatureFlaggedRoute>
                            ),
                          },
                          issueRoute(itm.type),
                        ],
                      })),
                      {
                        path: 'actions',
                        children: [
                          {
                            path: '',
                            element: (
                              <OrgFeatureFlaggedRoute
                                featureFlag={'third_party'}
                              >
                                <ProtectedRoute
                                  permission={'read:action'}
                                  canHaveAccessAsContributor
                                >
                                  <ThirdPartyUpdatePage
                                    activeTabId={'actions'}
                                  />
                                </ProtectedRoute>
                              </OrgFeatureFlaggedRoute>
                            ),
                          },
                          actionRoute,
                        ],
                      },
                      {
                        path: 'linked-items',
                        element: (
                          <OrgFeatureFlaggedRoute featureFlag={'third_party'}>
                            <ProtectedRoute
                              permission={'read:linked_item'}
                              canHaveAccessAsContributor={true}
                            >
                              <ThirdPartyUpdatePage
                                activeTabId={'linkedItems'}
                              />
                            </ProtectedRoute>
                          </OrgFeatureFlaggedRoute>
                        ),
                      },
                    ],
                  },
                ],
              },
              {
                path: 'third-party/questionnaire-responses',
                handle: {
                  title: () =>
                    i18n.format(i18n.t('response_other'), 'capitalize'),
                },
                children: [
                  {
                    index: true,
                    element: (
                      <OrgFeatureFlaggedRoute featureFlag={'third_party'}>
                        <ProtectedRoute
                          permission={'read:third_party_response'}
                        >
                          <ThirdPartyResponsesPage />
                        </ProtectedRoute>
                      </OrgFeatureFlaggedRoute>
                    ),
                  },
                  {
                    path: ':id',
                    handle: {
                      breadcrumb: ThirdPartyResponseDetailsBreadcrumb,
                    },
                    element: (
                      <OrgFeatureFlaggedRoute featureFlag={'third_party'}>
                        <ProtectedRoute
                          permission={'read:third_party_response'}
                        >
                          <ThirdPartyResponseDetailsPage />
                        </ProtectedRoute>
                      </OrgFeatureFlaggedRoute>
                    ),
                  },
                ],
              },
              {
                path: 'third-party/questionnaire',
                handle: {
                  title: () =>
                    `${i18n.format(i18n.t('questionnaire_one'), 'capitalizeAll')} `,
                  breadcrumbUrl: () => questionnaireTemplateRegisterUrl(),
                },
                children: [
                  {
                    index: true,
                    element: (
                      <OrgFeatureFlaggedRoute
                        featureFlag={'questionnaire_builder'}
                      >
                        <ProtectedRoute
                          permission={'read:questionnaire_template'}
                          canHaveAccessAsContributor={true}
                        >
                          <QuestionnaireTemplatePage />
                        </ProtectedRoute>
                      </OrgFeatureFlaggedRoute>
                    ),
                  },
                  {
                    path: 'add',
                    handle: {
                      title: () =>
                        i18n.t('common:questionnaire_templates.create_title'),
                    },
                    element: (
                      <OrgFeatureFlaggedRoute
                        featureFlag={'questionnaire_builder'}
                      >
                        <ProtectedRoute
                          permission={'insert:questionnaire_template'}
                        >
                          <QuestionnaireTemplateCreatePage />
                        </ProtectedRoute>
                      </OrgFeatureFlaggedRoute>
                    ),
                  },
                  questionnaireTemplateRoute,
                ],
              },
              {
                path: 'third-party/questionnaire-responses',
                element: (
                  <OrgFeatureFlaggedRoute featureFlag={'third_party'}>
                    <ProtectedRoute permission={'read:third_party_response'}>
                      <ThirdPartyResponsesPage />
                    </ProtectedRoute>
                  </OrgFeatureFlaggedRoute>
                ),
              },
              {
                path: '/actions',
                handle: {
                  title: () =>
                    i18n.format(i18n.t('action_other'), 'capitalize'),
                },
                children: [
                  {
                    path: '',
                    element: (
                      <ProtectedRoute
                        permission={'read:action'}
                        canHaveAccessAsContributor={true}
                      >
                        <ActionsPage />
                      </ProtectedRoute>
                    ),
                  },
                  actionRoute,
                ],
              },
              {
                path: '/causes',
                element: (
                  <ProtectedRoute
                    permission={'read:cause'}
                    canHaveAccessAsContributor={true}
                  >
                    <CausesPage />
                  </ProtectedRoute>
                ),
              },
              {
                path: '/consequences',
                element: (
                  <ProtectedRoute
                    permission={'read:consequence'}
                    canHaveAccessAsContributor={true}
                  >
                    <ConsequencesPage />
                  </ProtectedRoute>
                ),
              },
              ...Object.entries(IssueTypeMapping).map(([_, itm]) => ({
                path: itm.uriPath,
                handle: {
                  title: () => i18n.t(`${itm.taxonomy}.tab_title`),
                },
                children: [
                  {
                    index: true,
                    element: itm.featureFlag ? (
                      <OrgFeatureFlaggedRoute featureFlag={itm.featureFlag}>
                        <ProtectedRoute
                          permission={'read:issue'}
                          canHaveAccessAsContributor={true}
                        >
                          <IssuesPage issueType={itm.type} />
                        </ProtectedRoute>
                      </OrgFeatureFlaggedRoute>
                    ) : (
                      <ProtectedRoute
                        permission={'read:issue'}
                        canHaveAccessAsContributor={true}
                      >
                        <IssuesPage issueType={itm.type} />
                      </ProtectedRoute>
                    ),
                  },
                  issueRoute(itm.type),
                ],
              })),
              {
                path: '/acceptances',
                handle: {
                  title: () =>
                    i18n.format(i18n.t('acceptance_other'), 'capitalize'),
                },
                children: [
                  {
                    index: true,
                    element: (
                      <ProtectedRoute
                        permission={'read:acceptance'}
                        canHaveAccessAsContributor={true}
                      >
                        <AcceptancesPage />
                      </ProtectedRoute>
                    ),
                  },
                  {
                    path: ':acceptanceId',
                    handle: {
                      breadcrumb: AcceptanceBreadcrumb,
                    },
                    element: <AcceptanceUpdatePage />,
                  },
                ],
              },
              {
                path: '/appetites',
                handle: {
                  title: () =>
                    i18n.format(i18n.t('appetite_other'), 'capitalize'),
                },
                children: [
                  {
                    index: true,
                    element: (
                      <ProtectedRoute
                        permission={'read:appetite'}
                        canHaveAccessAsContributor={true}
                      >
                        <AppetitesPage />
                      </ProtectedRoute>
                    ),
                  },
                  {
                    path: ':appetiteId',
                    handle: {
                      breadcrumb: AppetiteBreadcrumb,
                    },
                    element: <AppetiteUpdatePage />,
                  },
                ],
              },
              {
                path: impactRatingsUrl(),
                children: [
                  {
                    index: true,
                    handle: {
                      title: () =>
                        i18n.format(
                          i18n.t('impact_rating_other'),
                          'capitalize'
                        ),
                    },
                    element: (
                      <OrgFeatureFlaggedRoute featureFlag={'impacts'}>
                        <ProtectedRoute
                          permission={'read:impact_rating'}
                          canHaveAccessAsContributor={true}
                        >
                          <ImpactRatingsPage />
                        </ProtectedRoute>
                      </OrgFeatureFlaggedRoute>
                    ),
                  },
                ],
              },
              {
                path: impactsUrl(),
                handle: {
                  title: () =>
                    i18n.format(i18n.t('impact_other'), 'capitalize'),
                },
                children: [
                  {
                    index: true,
                    element: (
                      <OrgFeatureFlaggedRoute featureFlag={'impacts'}>
                        <ProtectedRoute permission={'update:impact'}>
                          <ImpactsPage />
                        </ProtectedRoute>
                      </OrgFeatureFlaggedRoute>
                    ),
                  },
                  {
                    path: ':impactId',
                    handle: {
                      breadcrumb: ImpactBreadcrumb,
                    },
                    children: [
                      {
                        index: true,
                        element: (
                          <OrgFeatureFlaggedRoute featureFlag={'impacts'}>
                            <ProtectedRoute permission={'update:impact'}>
                              <ImpactPage activeTabId={'details'} />
                            </ProtectedRoute>
                          </OrgFeatureFlaggedRoute>
                        ),
                      },
                      {
                        path: 'rating',
                        handle: {
                          title: () =>
                            i18n.format(i18n.t('rating_other'), 'capitalize'),
                        },
                        element: (
                          <OrgFeatureFlaggedRoute featureFlag={'impacts'}>
                            <ProtectedRoute permission={'read:impact'}>
                              <ImpactPage activeTabId={'ratings'} />
                            </ProtectedRoute>
                          </OrgFeatureFlaggedRoute>
                        ),
                      },
                    ],
                  },
                ],
              },
              {
                path: complianceDashboardUrl(),
                element: (
                  <OrgFeatureFlaggedRoute featureFlag={'compliance'}>
                    <ProtectedRoute
                      permission={'read:obligation'}
                      canHaveAccessAsContributor={true}
                    >
                      <ObligationDashboardPage />
                    </ProtectedRoute>
                  </OrgFeatureFlaggedRoute>
                ),
              },
              {
                path: '/compliance',
                handle: {
                  breadcrumbUrl: (options: HandleOptions) => {
                    if (
                      options.location.state?.from === 'compliance-dashboard'
                    ) {
                      return complianceDashboardUrl();
                    }

                    return '/compliance';
                  },
                  title: () => i18n.format(i18n.t('compliance'), 'capitalize'),
                },
                children: [
                  {
                    index: true,
                    element: (
                      <OrgFeatureFlaggedRoute featureFlag={'compliance'}>
                        <ProtectedRoute
                          permission={'read:obligation'}
                          canHaveAccessAsContributor={true}
                        >
                          <ObligationsPage />
                        </ProtectedRoute>
                      </OrgFeatureFlaggedRoute>
                    ),
                  },
                  {
                    path: 'obligation',
                    handle: { isNotParent: true },
                    children: [
                      {
                        index: true,
                        handle: {
                          // TODO: translation
                          title: 'Add Obligation',
                          isNotParent: true,
                        },
                        element: (
                          <OrgFeatureFlaggedRoute featureFlag={'compliance'}>
                            <ProtectedRoute
                              permission={'insert:obligation'}
                              canHaveAccessAsContributor={true}
                            >
                              <ObligationCreatePage />
                            </ProtectedRoute>
                          </OrgFeatureFlaggedRoute>
                        ),
                      },
                      {
                        path: ':obligationId',
                        handle: {
                          // TODO: translation
                          title: 'Edit Obligation',
                          breadcrumb: ObligationUpdateBreadcrumb,
                        },
                        children: [
                          {
                            path: '',
                            element: (
                              <OrgFeatureFlaggedRoute
                                featureFlag={'compliance'}
                              >
                                <ObligationUpdatePage
                                  selectedTabId={'details'}
                                />
                              </OrgFeatureFlaggedRoute>
                            ),
                          },
                          {
                            path: 'impact',
                            handle: {
                              // TODO: translation
                              title: 'Impact',
                            },
                            element: (
                              <OrgFeatureFlaggedRoute
                                featureFlag={'compliance'}
                              >
                                <ObligationUpdatePage
                                  selectedTabId={'impact'}
                                />
                              </OrgFeatureFlaggedRoute>
                            ),
                          },
                          {
                            path: 'ratings',
                            handle: {
                              title: () =>
                                i18n.format(
                                  i18n.t('assessment_other'),
                                  'capitalize'
                                ),
                            },
                            children: [
                              {
                                index: true,
                                element: (
                                  <OrgFeatureFlaggedRoute
                                    featureFlag={'compliance'}
                                  >
                                    <ObligationUpdatePage
                                      selectedTabId={'ratings'}
                                    />
                                  </OrgFeatureFlaggedRoute>
                                ),
                              },
                              assessmentsRoute,
                            ],
                          },
                          {
                            path: 'actions',
                            handle: {
                              title: () =>
                                i18n.format(
                                  i18n.t('action_other'),
                                  'capitalize'
                                ),
                            },
                            children: [
                              {
                                path: '',
                                element: (
                                  <OrgFeatureFlaggedRoute
                                    featureFlag={'compliance'}
                                  >
                                    <ObligationUpdatePage
                                      selectedTabId={'actions'}
                                    />
                                  </OrgFeatureFlaggedRoute>
                                ),
                              },
                              actionRoute,
                            ],
                          },
                          ...Object.entries(IssueTypeMapping).map(
                            ([_, itm]) => ({
                              path: itm.uriPath,
                              handle: {
                                title: () =>
                                  i18n.format(
                                    i18n.t(itm.entityLabelOther),
                                    'capitalize'
                                  ),
                              },
                              children: [
                                {
                                  path: '',
                                  element: (
                                    <OrgFeatureFlaggedRoute
                                      featureFlag={'compliance'}
                                    >
                                      <ObligationUpdatePage
                                        selectedTabId={itm.taxonomy}
                                      />
                                    </OrgFeatureFlaggedRoute>
                                  ),
                                },
                                issueRoute(itm.type),
                              ],
                            })
                          ),
                          {
                            path: 'controls',
                            handle: {
                              title: () =>
                                i18n.format(
                                  i18n.t('control_other'),
                                  'capitalize'
                                ),
                            },
                            children: [
                              {
                                path: '',
                                element: (
                                  <OrgFeatureFlaggedRoute
                                    featureFlag={'compliance'}
                                  >
                                    <ObligationUpdatePage
                                      selectedTabId={'controls'}
                                    />
                                  </OrgFeatureFlaggedRoute>
                                ),
                              },
                              controlRoute,
                            ],
                          },
                          {
                            path: 'linked-items',
                            handle: {
                              title: 'Linked items',
                            },
                            children: [
                              {
                                path: '',
                                element: (
                                  <ObligationUpdatePage
                                    selectedTabId={'linkedItems'}
                                  />
                                ),
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                path: '/requests',
                handle: {
                  title: () =>
                    i18n.format(i18n.t('request_other'), 'capitalize'),
                },
                element: (
                  <OrgFeatureFlaggedRoute featureFlag={'approvers'}>
                    <ProtectedRoute
                      permission={'read:change_request'}
                      canHaveAccessAsContributor={true}
                    >
                      <RequestsPage />
                    </ProtectedRoute>
                  </OrgFeatureFlaggedRoute>
                ),
              },
              {
                path: '/risks',
                handle: {
                  breadcrumbUrl: (options: HandleOptions) => {
                    if (options.location.state?.from === 'risk-dashboard') {
                      return riskDashboardUrl();
                    }

                    return '/risks';
                  },
                  title: () => i18n.format(i18n.t('risk_other'), 'capitalize'),
                },

                children: [
                  {
                    index: true,
                    element: (
                      <ProtectedRoute
                        permission={'read:risk'}
                        canHaveAccessAsContributor={true}
                      >
                        <RisksPage />
                      </ProtectedRoute>
                    ),
                  },
                  {
                    path: 'add',
                    element: (
                      <ProtectedRoute
                        permission={'insert:risk'}
                        canHaveAccessAsContributor={true}
                      >
                        <RisksCreatePage />
                      </ProtectedRoute>
                    ),
                    handle: {
                      // TODO: translation
                      title: 'Add Risk',
                    },
                  },
                  {
                    path: ':riskId',
                    handle: {
                      // TODO: translation
                      title: 'Edit Risk',
                      breadcrumb: RisksUpdateBreadcrumb,
                    },
                    children: [
                      {
                        path: '',
                        children: [
                          {
                            path: '',
                            element: (
                              <ProtectedRoute
                                permission={'read:risk'}
                                canHaveAccessAsContributor={true}
                              >
                                <RisksUpdatePage
                                  selectedTabId={'details'}
                                  showDeleteButton={true}
                                />
                              </ProtectedRoute>
                            ),
                          },
                          {
                            path: 'ratings',
                            handle: {
                              title: () =>
                                i18n.format(
                                  i18n.t('rating_other'),
                                  'capitalize'
                                ),
                            },
                            children: [
                              {
                                path: '',
                                element: (
                                  <RisksUpdatePage selectedTabId={'ratings'} />
                                ),
                              },
                              assessmentsRoute,
                            ],
                          },
                          {
                            path: 'impacts',
                            handle: {
                              title: () =>
                                i18n.format(
                                  i18n.t('impact_other'),
                                  'capitalize'
                                ),
                            },
                            element: (
                              <RisksUpdatePage selectedTabId={'impacts'} />
                            ),
                          },
                          {
                            path: 'actions',
                            handle: {
                              title: () =>
                                i18n.format(
                                  i18n.t('action_other'),
                                  'capitalize'
                                ),
                            },
                            children: [
                              {
                                path: '',
                                element: (
                                  <RisksUpdatePage selectedTabId={'actions'} />
                                ),
                              },
                              actionRoute,
                            ],
                          },
                          {
                            path: 'controls',
                            handle: {
                              title: () =>
                                i18n.format(
                                  i18n.t('control_other'),
                                  'capitalize'
                                ),
                            },
                            children: [
                              {
                                path: '',
                                element: (
                                  <RisksUpdatePage selectedTabId={'controls'} />
                                ),
                              },
                              controlRoute,
                            ],
                          },
                          {
                            path: 'appetite',
                            handle: {
                              title: () =>
                                i18n.format(
                                  i18n.t('appetite_one'),
                                  'capitalize'
                                ),
                            },
                            children: [
                              {
                                index: true,
                                element: (
                                  <RisksUpdatePage
                                    selectedTabId={'appetites'}
                                  />
                                ),
                              },
                              {
                                path: 'add',
                                handle: {
                                  title: () =>
                                    i18n.format(
                                      i18n.t('appetites.create_modal_title'),
                                      'capitalize'
                                    ),
                                },
                                element: <AppetiteCreatePage />,
                              },
                              {
                                path: ':appetiteId',
                                handle: {
                                  breadcrumb: AppetiteBreadcrumb,
                                },
                                element: <AppetiteUpdatePage />,
                              },
                            ],
                          },
                          {
                            path: 'acceptances',
                            handle: {
                              title: () =>
                                i18n.format(
                                  i18n.t('acceptance_other'),
                                  'capitalize'
                                ),
                            },
                            children: [
                              {
                                index: true,
                                element: (
                                  <RisksUpdatePage
                                    selectedTabId={'acceptances'}
                                  />
                                ),
                              },
                              {
                                path: 'add',
                                handle: {
                                  title: () =>
                                    i18n.format(
                                      i18n.t('acceptances.create_modal_title'),
                                      'capitalize'
                                    ),
                                },
                                element: <AcceptanceCreatePage />,
                              },
                              {
                                path: ':acceptanceId',
                                handle: {
                                  breadcrumb: AcceptanceBreadcrumb,
                                },
                                element: <AcceptanceUpdatePage />,
                              },
                            ],
                          },
                          {
                            path: 'indicators',
                            handle: {
                              title: () =>
                                i18n.format(
                                  i18n.t('indicator_other'),
                                  'capitalize'
                                ),
                            },
                            children: [
                              {
                                path: '',
                                element: (
                                  <RisksUpdatePage
                                    selectedTabId={'indicators'}
                                  />
                                ),
                              },
                              indicatorRoute,
                            ],
                          },
                          {
                            path: 'approvals',
                            handle: {
                              title: () => i18n.t('approvals.tab_title'),
                            },
                            element: (
                              <RisksUpdatePage selectedTabId={'approvals'} />
                            ),
                          },
                          {
                            path: 'linked-items',
                            handle: {
                              // translations
                              title: 'Linked items',
                            },
                            children: [
                              {
                                path: '',
                                element: (
                                  <RisksUpdatePage
                                    selectedTabId={'linkedItems'}
                                  />
                                ),
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                path: enterpriseRiskDashboardUrl(),
                element: (
                  <OrgFeatureFlaggedRoute featureFlag={'enterprise_risk'}>
                    <ProtectedRoute permission={'read:enterprise_risk'}>
                      <EnterpriseRiskDashboardPage />
                    </ProtectedRoute>
                  </OrgFeatureFlaggedRoute>
                ),
              },
              {
                ...enterpriseRiskRoute,
              },
              {
                path: '*',
                element: <NotFoundPage />,
              },
            ],
          },
        ],
      },
      {
        path: '*',
        element: <NotFoundPage />,
      },
    ],
  },
];

export default routes;
