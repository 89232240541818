import {
  ComplianceMonitoringAssessmentUpdateBreadcrumb,
  ComplianceMonitoringAssessmentUpdatePage,
  CreateMonitoringAssessmentsResultPage,
  UpdateMonitoringAssessmentsResultPage,
} from '@pages';
import i18n from '@risksmart-app/i18n/i18n';
import type { RouteObject } from 'react-router';

export const complianceMonitoringAssessmentRoute: RouteObject = {
  path: ':assessmentId',
  handle: {
    breadcrumb: ComplianceMonitoringAssessmentUpdateBreadcrumb,
  },
  children: [
    {
      path: '',
      element: (
        <ComplianceMonitoringAssessmentUpdatePage activeTabId={'details'} />
      ),
    },
    {
      path: 'activities',
      handle: {
        title: () => i18n.format(i18n.t('activity_other'), 'capitalizeAll'),
      },

      children: [
        {
          path: '',
          element: (
            <ComplianceMonitoringAssessmentUpdatePage
              activeTabId={'activities'}
              activityMode={'list'}
            />
          ),
        },
        {
          path: 'add',
          handle: {
            title: () => i18n.format(i18n.t('activity_one'), 'capitalizeAll'),
          },
          element: (
            <ComplianceMonitoringAssessmentUpdatePage
              activeTabId={'activities'}
              activityMode={'add'}
            />
          ),
        },
        {
          path: ':activityId',
          element: (
            <ComplianceMonitoringAssessmentUpdatePage
              activeTabId={'activities'}
              activityMode={'update'}
            />
          ),
        },
      ],
    },

    {
      path: 'findings',
      handle: {
        title: () => i18n.format(i18n.t('finding_other'), 'capitalizeAll'),
      },
      children: [
        {
          path: '',
          element: (
            <ComplianceMonitoringAssessmentUpdatePage
              activeTabId={'findings'}
            />
          ),
        },
        {
          path: ':findingId',
          element: <UpdateMonitoringAssessmentsResultPage />,
          handle: {
            title: () => i18n.format(i18n.t('finding_one'), 'capitalizeAll'),
          },
        },
        {
          path: 'add',
          element: <CreateMonitoringAssessmentsResultPage />,
          handle: {
            title: () => i18n.t('assessmentResults.create_title'),
          },
        },
      ],
    },

    {
      path: 'linked-items',
      children: [
        {
          path: '',
          element: (
            <ComplianceMonitoringAssessmentUpdatePage
              activeTabId={'linkedItems'}
            />
          ),
        },
      ],
    },
  ],
};
