import i18n from '@risksmart-app/i18n/i18n';
import { t } from 'i18next';

import { ParentTypeEnum } from '../../../generated/graphql';
import { DataSourceType } from '../../reporting/schema';
import { getAuditColumns, getTagAndDepartmentsColumns } from './shared';
import { SharedDataset } from './types';

export const getIssues = () =>
  ({
    label: i18n.format(t('issue_other'), 'capitalize'),
    parentTypes: new Set<DataSourceType>(['controls']),
    customAttributeFormConfigurationParentTypes: [ParentTypeEnum.Issue],
    fields: {
      ...getAuditColumns(),
      ...getTagAndDepartmentsColumns(),
      title: {
        dataType: 'text',
        displayType: 'text',
        label: t('issues.columns.title'),
      },
      id: { dataType: 'guid', displayType: 'text', label: t('columns.guid') },
      impactsCustomer: {
        dataType: 'bool',
        label: t('issues.columns.impacts_customer'),
        displayType: 'commonLookup',
        i18nKey: 'yesOrNo',
      },
      internalOrExternal: {
        dataType: 'bool',
        label: t('issues.columns.is_external_issue'),
        displayType: 'commonLookup',
        i18nKey: 'issues.isExternalIssue',
      },
      dateOccurred: {
        dataType: 'date',
        label: t('issues.columns.date_occurred'),
        displayType: 'date',
      },
      dateIdentified: {
        dataType: 'date',
        label: t('issues.columns.date_identified'),
        displayType: 'date',
      },
      detailsLink: {
        dataType: 'guid',
        label: t('issues.columns.details_link'),
        displayType: 'detailsLink',
        entityInfoParentType: ParentTypeEnum.Issue,
      },
      details: {
        dataType: 'text',
        displayType: 'text',
        label: t('issues.columns.details'),
      },
    },
  }) satisfies SharedDataset;
