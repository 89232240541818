import useRisksmartUser from '@risksmart-app/components/hooks/useRisksmartUser';
import type { TypedPropertyFilterToken } from '@risksmart-app/components/Table/tableUtils';
import { uniq } from 'lodash';
import { useCallback } from 'react';

import type { GetMyItemsDashboardQuery } from '@/generated/graphql';
import type { CollectionData } from '@/utils/collectionUtils';

export type FilterableRibbonData = {
  ownerGroups: CollectionData<
    GetMyItemsDashboardQuery['action'][0]['ownerGroups']
  >;
  contributorGroups: CollectionData<
    GetMyItemsDashboardQuery['action'][0]['contributorGroups']
  >;
};

export type FilteringOptions = {
  owners?: boolean;
  contributors?: boolean;
  ownerGroups?: boolean;
  contributorGroups?: boolean;
};

export const useGetRibbonFilteringTokens = <T>(
  filteringOptions: FilteringOptions
) => {
  const { user } = useRisksmartUser();
  const getRibbonFilteringTokens = useCallback(
    (filteringDetails: FilterableRibbonData[]) => {
      const tokens = [];

      if (filteringOptions.owners) {
        tokens.push({
          propertyKey: 'allOwners',
          value: user?.userId,
          operator: '=',
        });
      }
      if (filteringOptions.contributors) {
        tokens.push({
          propertyKey: 'allContributors',
          value: user?.userId,
          operator: '=',
        });
      }

      if (filteringOptions.ownerGroups) {
        const flatOwnerGroups = filteringDetails.flatMap((d) => d.ownerGroups);
        const groupTokens = uniq(
          flatOwnerGroups
            .filter((g) =>
              g.group?.users.some((u) => u.UserId === user?.userId)
            )
            .map((g) => ({
              propertyKey: 'allOwners',
              value: g.UserGroupId,
              operator: '=',
            }))
        );
        if (groupTokens.length > 0) {
          tokens.push(...groupTokens);
        }
      }

      if (filteringOptions.contributorGroups) {
        const flatContributorGroups = filteringDetails.flatMap(
          (d) => d.contributorGroups
        );
        const groupTokens = uniq(
          flatContributorGroups
            .filter((g) =>
              g.group?.users.some((u) => u.UserId === user?.userId)
            )
            .map((g) => ({
              propertyKey: 'allContributors',
              value: g.UserGroupId,
              operator: '=',
            }))
        );
        if (groupTokens.length > 0) {
          tokens.push(...groupTokens);
        }
      }

      return tokens as TypedPropertyFilterToken<T>[];
    },
    [
      filteringOptions.contributorGroups,
      filteringOptions.contributors,
      filteringOptions.ownerGroups,
      filteringOptions.owners,
      user?.userId,
    ]
  );

  return { getRibbonFilteringTokens };
};
