import Alert from '@cloudscape-design/components/alert';
import { useRating } from '@risksmart-app/components/hooks/useRating';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { CustomisableFormContext } from '@/components/Form/Form/CustomisableFormContext';
import { PageWrapper } from '@/components/Form/Form/PageWrapper';
import type { FormContextProps } from '@/components/Form/Form/types';
import {
  Appetite_Model_Enum,
  Appetite_Type_Enum,
  Parent_Type_Enum,
} from '@/generated/graphql';
import { useIsFeatureVisibleToOrg } from '@/utils/featureFlags';

import AppetiteFormFields from './AppetiteFormFields';
import type { AppetiteFormFieldsData } from './appetiteSchema';
import { defaultValues, getAppetiteSchema } from './appetiteSchema';

export type Props = Omit<
  FormContextProps<AppetiteFormFieldsData>,
  | 'defaultValues'
  | 'formId'
  | 'i18n'
  | 'parentType'
  | 'renderTemplate'
  | 'schema'
> & {
  appetiteAggregation: Appetite_Model_Enum;
};

const AppetiteForm: FC<Props> = ({
  onDismiss,
  onSave,
  readOnly,
  values,
  appetiteAggregation,
}) => {
  const impactsEnabled = useIsFeatureVisibleToOrg('impacts');
  const { t } = useTranslation(['common'], { keyPrefix: 'appetites' });
  const { t: tt } = useTranslation(['common']);
  const { options: appetiteOptions } = useRating('risk_appetite');

  return (
    <>
      <CustomisableFormContext
        onSave={onSave}
        defaultValues={{
          ...defaultValues,
          AppetiteType: impactsEnabled
            ? Appetite_Type_Enum.Impact
            : Appetite_Type_Enum.Risk,
          LowerAppetite: appetiteOptions[0].value as number,
          UpperAppetite: appetiteOptions[0].value as number,
        }}
        i18n={tt('appetites')}
        values={values}
        onDismiss={onDismiss}
        schema={getAppetiteSchema()}
        header={tt('details')}
        formId={'appetite-form'}
        readOnly={readOnly}
        parentType={Parent_Type_Enum.Appetite}
        renderTemplate={(renderProps) => <PageWrapper {...renderProps} />}
      >
        {readOnly &&
          appetiteAggregation === Appetite_Model_Enum.TopDownCascade && (
            <Alert>{t('appetite_cascade_warning')}</Alert>
          )}
        <AppetiteFormFields readOnly={readOnly} />
      </CustomisableFormContext>
    </>
  );
};

export default AppetiteForm;
