import { FormField } from '@/components/Form/Form/FormField';
import HelpLink from '@/components/HelpPanel/HelpLink';
import type { Content } from '@/components/HelpPanel/useHelpStore';

import {
  SelectWithNumberSupport,
  type SelectWithNumberSupportProps,
} from './SelectWithNumberSupport';

interface SelectWithFormFieldProps extends SelectWithNumberSupportProps {
  label: string;
  disableBottomPadding?: boolean;
  constraintText?: React.ReactNode;
  errorMessage?: string;
  testId?: string;
  description?: Content;
}

export const SelectWithFormField = ({
  label,
  constraintText,
  errorMessage,
  testId,
  disableBottomPadding,
  description,
  ...props
}: SelectWithFormFieldProps) => {
  return (
    <FormField
      disableBottomPadding={disableBottomPadding}
      constraintText={constraintText}
      label={label}
      errorText={errorMessage}
      stretch
      testId={testId}
      info={
        description && (
          <HelpLink title={label} content={description} id={label} />
        )
      }
    >
      <SelectWithNumberSupport {...props} />
    </FormField>
  );
};
