import type { LabelledIdArray } from 'src/rbac/types';

import type { GetIndicatorsQuery } from '@/generated/graphql';
import type { CollectionData } from '@/utils/collectionUtils';

export type IndicatorFlatFields = CollectionData<
  GetIndicatorsQuery['indicator'][0]
>;
export enum ConformanceIndicatorRating {
  NotSet = 0,
  Outside = 1,
  Within = 2,
  OutsideAppetite = 3,
}

export enum ConformanceTrend {
  Improving = 'improving',
  Deteriorating = 'deteriorating',
  Stable = 'stable',
}

export type IndicatorTableFields = IndicatorFlatFields & {
  CreatedByUserName: string | undefined;
  ModifiedByUserName: string | undefined;
  Conformance: ConformanceIndicatorRating;
  ConformanceLabelled: number | string | undefined;
  ConformanceTrend: string | undefined;
  ConformanceTrendValue: ConformanceTrend | null;
  LatestResultLabelled: string | undefined;
  LatestResultDateLabelled: string | undefined;
  TestFrequencyLabelled: string | undefined;
  ParentTitle: string | undefined;
  ParentType: string | undefined;
  SequentialIdLabel: null | string;
  allOwners: LabelledIdArray;
  allContributors: LabelledIdArray;
  NextTestOverdueDate: string | undefined;
  NextTestDate: string | undefined;
};
