import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { PageLayout } from 'src/layouts';

import ControlledTabs from '@/components/ControlledTabs';

import { useTabs } from '../update/useTabs';

const Page: FC = () => {
  const { t: st } = useTranslation(['common'], { keyPrefix: 'policy' });
  const title = st('create_title');
  const tabs = useTabs('create', '', undefined);

  return (
    <PageLayout title={title}>
      <ControlledTabs
        activeTabId={'details'}
        tabs={tabs}
        variant={'container'}
      />
    </PageLayout>
  );
};

export default Page;
