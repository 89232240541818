import type { FieldValues } from 'react-hook-form';

import Tokens from '@/components/Tokens';
import { useGetDocumentListQuery } from '@/generated/graphql';
import { policyDetailsUrl } from '@/utils/urls';

import ControlledMultiselect from '../ControlledMultiselect';
import type { ControlledBaseProps } from '../types';

interface Props<T extends FieldValues> extends ControlledBaseProps<T> {
  addEmptyOption?: boolean;
  disabled?: boolean;
  excludedIds?: string[];
}

export const ControlledDocumentMultiselect = <T extends FieldValues>({
  excludedIds,
  disabled,
  ...props
}: Props<T>) => {
  const { data: documents, loading } = useGetDocumentListQuery({});

  return (
    <ControlledMultiselect
      statusType={loading ? 'loading' : 'finished'}
      disabled={disabled}
      {...props}
      hideTokens={true}
      filteringType={'auto'}
      options={
        documents?.document
          ?.filter((document) => !excludedIds?.includes(document.Id))
          .map((document) => ({
            value: String(document.Id),
            label: String(document.Title),
          })) || []
      }
      renderTokens={true}
      customTokenRender={(options, actions) => (
        <Tokens
          disabled={disabled}
          onRemove={actions.removeToken}
          tokens={options.map((o) => ({
            value: o.value!,
            url: policyDetailsUrl(o.value!),
            label: o.label!,
          }))}
        />
      )}
    />
  );
};
