import { useRating } from '@risksmart-app/components/hooks/useRating';
import { useTranslation } from 'react-i18next';
import { getCorrectedAttestationStatus } from 'src/pages/attestations/useLabelledFields';

import type { AttestationFlatField, AttestationRegisterFields } from './types';

export const useLabelledFields = (
  records: AttestationFlatField[] | undefined
): AttestationRegisterFields[] => {
  const { getByValue } = useRating('attestation_record_status');
  const { t } = useTranslation(['common'], {
    keyPrefix: 'attestations.is_attestation_active',
  });

  return (
    records?.map((record) => {
      const attestationStatus = getCorrectedAttestationStatus(record);

      return {
        ...record,
        AttestationStatusLabel: getByValue(attestationStatus)?.label ?? '-',
        ActiveLabel: record.Active ? t('yes') : t('no'),
        User: record.user?.FriendlyName ?? record.UserId,
        Document: `${record.node.documentFile?.parent?.Title} (${record.node.documentFile?.Version})`,
        AttestationStatus: attestationStatus,
      };
    }) ?? []
  );
};
