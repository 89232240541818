import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import ControlledAutosuggest from '@/components/Form/ControlledAutosuggest';
import ControlledGroupAndUserContributorMultiSelect from '@/components/Form/ControlledGroupAndUserContributorMultiSelect';
import ControlledInput from '@/components/Form/ControlledInput';
import ControlledTextarea from '@/components/Form/ControlledTextarea';
import DepartmentSelector from '@/components/Form/DepartmentSelector';
import CustomisableForm from '@/components/Form/Form/CustomisableForm/CustomisableForm';
import FormRow from '@/components/Form/Form/FormRow';
import TagSelector from '@/components/Form/TagSelector';
import {
  Contributor_Type_Enum,
  Parent_Type_Enum,
  useGetBusinessAreasQuery,
} from '@/generated/graphql';
import { getFriendlyId } from '@/utils/friendlyId';

import { TestIds } from './InternalAuditFormFieldsTestIds';
import type { InternalAuditFormDataFields } from './internalAuditSchema';

interface Props {
  readOnly?: boolean;
}

const InternalAuditFormFields = ({ readOnly }: Props) => {
  const { control } = useFormContext<InternalAuditFormDataFields>();

  const { t } = useTranslation(['common']);
  const { t: st } = useTranslation(['common'], {
    keyPrefix: 'internalAudits.fields',
  });

  const { data } = useGetBusinessAreasQuery();
  const businessAreaOptions =
    data?.business_area?.map((c) => ({
      value: c.Title,
      tags: [getFriendlyId(Parent_Type_Enum.BusinessArea, c.SequentialId)],
    })) ?? [];

  return (
    <CustomisableForm readOnly={readOnly}>
      <ControlledInput
        key={'title'}
        forceRequired={true}
        name={'Title'}
        disabled={readOnly}
        label={st('Title')}
        description={st('Title_help')}
        placeholder={st('Title_placeholder')}
        control={control}
      />
      <ControlledTextarea
        key={'description'}
        disabled={readOnly}
        name={'Description'}
        label={st('Description')}
        placeholder={st('Description_placeholder')}
        control={control}
        description={st('Description_help')}
      />
      <ControlledAutosuggest
        key={'businessArea'}
        disabled={readOnly}
        forceRequired={true}
        name={'BusinessArea'}
        label={st('BusinessArea')}
        placeholder={st('BusinessArea_placeholder')}
        control={control}
        description={st('BusinessArea_help')}
        options={businessAreaOptions}
      />

      <FormRow key={'owners'}>
        <ControlledGroupAndUserContributorMultiSelect
          forceRequired={true}
          control={control}
          includeGroups={true}
          label={t('fields.Owner')}
          inheritedContributorsName={'ancestorContributors'}
          contributorType={Contributor_Type_Enum.Owner}
          name={'Owners'}
          description={st('Owner_help')}
          testId={TestIds.Owners}
          placeholder={t('fields.Owner_placeholder')}
          disabled={readOnly}
        />
      </FormRow>
      <FormRow key={'contributors'}>
        <ControlledGroupAndUserContributorMultiSelect
          key={'contributors'}
          control={control}
          includeGroups={true}
          inheritedContributorsName={'ancestorContributors'}
          contributorType={Contributor_Type_Enum.Contributor}
          label={t('fields.Contributor')}
          name={'Contributors'}
          description={st('Contributor_help')}
          placeholder={t('fields.Contributor_placeholder')}
          disabled={readOnly}
        />
      </FormRow>

      <FormRow size={'xl'} key={'tags'}>
        <TagSelector disabled={readOnly} name={'tags'} control={control} />
      </FormRow>
      <DepartmentSelector
        key={'departments'}
        disabled={readOnly}
        name={'departments'}
        control={control}
      />
    </CustomisableForm>
  );
};

export default InternalAuditFormFields;
