import SpaceBetween from '@cloudscape-design/components/space-between';
import Button from '@risksmart-app/components/Button';
import { useNotifications } from '@risksmart-app/components/Notifications/useNotifications';
import Table from '@risksmart-app/components/Table';
import type { FC } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PageLayout } from 'src/layouts';

import { useGetCausesQuery } from '@/generated/graphql';
import { getCounter } from '@/utils/collectionUtils';

import CauseModal from '../issues/update/tabs/causes/CauseModal';
import { useGetRegisterTableProps } from './config';
import type { CauseFlatField, CauseRegisterFields } from './types';

const Page: FC = () => {
  const [openCause, setOpenCause] = useState<CauseFlatField | null>(null);
  const { t } = useTranslation(['common'], {});
  const { t: st } = useTranslation(['common'], {
    keyPrefix: 'causes',
  });
  const { addNotification } = useNotifications();
  const { data, loading, refetch } = useGetCausesQuery({
    fetchPolicy: 'no-cache',
    onError: (error) => {
      addNotification({
        type: 'error',
        content: <>{error.message}</>,
      });
    },
  });
  const customAttributeSchemas = data?.form_configuration.length
    ? data?.form_configuration.map(
        (schemaData) => schemaData.customAttributeSchema!
      )[0]
    : null;
  const tableProps = useGetRegisterTableProps(
    data?.cause,
    customAttributeSchemas,
    (consequence: CauseRegisterFields) => setOpenCause(consequence)
  );

  const title = st('register_title');
  const counter = getCounter(tableProps.totalItemsCount, loading);

  return (
    <PageLayout
      helpTranslationKey={'causes.registerHelp'}
      title={title}
      counter={counter}
      actions={
        <SpaceBetween direction={'horizontal'} size={'xs'}>
          <Button iconName={'download'} onClick={tableProps.exportToCsv}>
            {t('export')}
          </Button>
        </SpaceBetween>
      }
    >
      <Table {...tableProps} loading={loading} />
      {openCause && (
        <CauseModal
          causeId={openCause.Id}
          onDismiss={(saved) => {
            setOpenCause(null);
            if (saved) {
              refetch();
            }
          }}
          issueId={openCause.ParentIssueId}
        />
      )}
    </PageLayout>
  );
};

export default Page;
