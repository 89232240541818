import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { CustomisableFormContext } from '@/components/Form/Form/CustomisableFormContext';
import type { FormContextProps } from '@/components/Form/Form/types';
import { Parent_Type_Enum } from '@/generated/graphql';

import IndicatorsDetailsFormFields from './IndicatorDetailsFormFields';
import type { IndicatorFormDataFields } from './indicatorSchema';
import { defaultValues, indicatorSchema } from './indicatorSchema';

export type Props = Omit<
  FormContextProps<IndicatorFormDataFields>,
  'defaultValues' | 'formId' | 'i18n' | 'parentType' | 'schema'
> & { isUpdate?: boolean; latestTestDate?: string | undefined };

const IndicatorDetailsForm: FC<Props> = (props) => {
  const { t } = useTranslation('common');

  return (
    <CustomisableFormContext
      {...props}
      schema={indicatorSchema}
      defaultValues={defaultValues}
      i18n={t('indicators')}
      formId={'indicator-form'}
      parentType={Parent_Type_Enum.Indicator}
    >
      <IndicatorsDetailsFormFields
        readOnly={props.readOnly}
        isUpdate={props.isUpdate}
        latestTestDate={props.latestTestDate}
      />
    </CustomisableFormContext>
  );
};

export default IndicatorDetailsForm;
