import Alert from '@cloudscape-design/components/alert';
import SpaceBetween from '@cloudscape-design/components/space-between';
import Button from '@risksmart-app/components/Button';
import { PageNotFound } from '@risksmart-app/components/errors/errors';
import { useGetGuidParam } from '@risksmart-app/components/routes/routes.utils';
import type { FilterGroup } from '@risksmart-app/shared/reporting/api/schema';
import _ from 'lodash';
import { type FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PageLayout from 'src/layouts/PageLayout';
import { useCustomAttributeLookup } from 'src/pages/dashboards/CustomDataSourceWidget/useCustomAttributeLookup';
import { Permission } from 'src/rbac/Permission';

import type { GetReportingDataQueryVariables } from '@/generated/graphql';
import {
  useGetCustomDatasourceByIdQuery,
  useGetReportingDataQuery,
} from '@/generated/graphql';
import { emptyFilterQuery } from '@/utils/collectionUtils';
import { useFiltersFromUrlHash } from '@/utils/table/hooks/useFiltersFromUrlHash';
import { editCustomDatasourceUrl } from '@/utils/urls';

import type { TypedCustomDatasource } from '../types';
import { getCustomDatasourceFields } from '../update//utils';
import CustomDatasourcePropertyFilter from '../update/CustomDatasourcePropertyFilter';
import type { Filters } from '../update/definitionSchema';
import { mapQueryToFilterGroup } from '../update/formDataMapping';
import ReportTable from '../update/ReportTable';

const Page: FC = () => {
  const [pageIndex, setPageIndex] = useState(0);
  const pageSize = 20;
  const customDataSourceId = useGetGuidParam('customDatasourceId');
  const { data: customDatasourceData, loading } =
    useGetCustomDatasourceByIdQuery({
      variables: { Id: customDataSourceId },
      fetchPolicy: 'no-cache',
    });
  const customDatasource: null | TypedCustomDatasource | undefined =
    customDatasourceData?.custom_datasource_by_pk;
  if (!loading && !customDatasource) {
    throw new PageNotFound(
      `Custom datasource with id ${customDataSourceId} not found`
    );
  }

  const { propertyFilter, setPropertyFilter } = useFiltersFromUrlHash({});

  const { t } = useTranslation(['common'], { keyPrefix: 'customDatasources' });

  const { customAttributeSchemaLookup } = useCustomAttributeLookup();
  const getVariables = (
    customDatasource: null | TypedCustomDatasource | undefined
  ): GetReportingDataQueryVariables | undefined => {
    if (!customDatasource) {
      return undefined;
    }
    const filters: FilterGroup['filters'] = [];
    if (customDatasource.Filters) {
      filters.push(customDatasource.Filters);
    }
    if (propertyFilter) {
      filters.push(mapQueryToFilterGroup(propertyFilter as Filters));
    }
    const combinedFilters: FilterGroup = {
      operation: 'and',
      filters,
    };

    return {
      Input: {
        filters: combinedFilters,
        dataSources: customDatasource.Datasources,
        fields: customDatasource.Fields ?? [],
        offset: pageIndex * pageSize,
        limit: pageSize,
      },
    };
  };

  // TODO: potentially replace this with a new api that acceptances a customDatasourceId
  // this will avoid the need to call useGetCustomDatasourceByIdQuery first, and could offer bet security
  // if we want to look down the end point that supports any kind of query
  const {
    data: reportData,
    loading: reportDataLoading,
    error: reportDataError,
  } = useGetReportingDataQuery({
    variables: getVariables(customDatasource),
    fetchPolicy: 'no-cache',
    skip: !customDatasource,
  });

  return (
    <PageLayout
      title={customDatasource?.Title ?? t('create_title')}
      actions={
        customDatasource && (
          <SpaceBetween direction={'horizontal'} size={'xxs'}>
            <Permission permission={'update:custom_datasource'}>
              <Button
                variant={'normal'}
                formAction={'none'}
                href={
                  customDataSourceId &&
                  editCustomDatasourceUrl(customDataSourceId)
                }
              >
                {t('edit_button')}
              </Button>
            </Permission>
          </SpaceBetween>
        )
      }
    >
      {reportDataError && !reportDataLoading && (
        <Alert type={'error'}>{t('data_request_failure_message')}</Alert>
      )}
      {customAttributeSchemaLookup && (
        <ReportTable
          filter={
            customDatasource && (
              <CustomDatasourcePropertyFilter
                query={propertyFilter ?? emptyFilterQuery}
                onChange={(e) => {
                  setPropertyFilter(e);
                }}
                allFields={getCustomDatasourceFields(
                  customDatasource,
                  customAttributeSchemaLookup
                )}
                datasources={customDatasource.Datasources}
              />
            )
          }
          customAttributeSchemaLookup={customAttributeSchemaLookup}
          loading={reportDataLoading}
          pageSize={pageSize}
          currentPageIndex={pageIndex}
          onPageChangeClick={({ requestedPageIndex }) => {
            setPageIndex(requestedPageIndex);
          }}
          definition={{
            // TODO: update hasura config to use lower case field names to avoid having to map
            fields: customDatasource?.Fields ?? [],
            dataSources: customDatasource?.Datasources ?? [],
            filters: customDatasource?.Filters,
          }}
          items={reportData?.reportingData ?? []}
        />
      )}
    </PageLayout>
  );
};

export default Page;
