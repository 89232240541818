import { useRating } from '@risksmart-app/components/hooks/useRating';
import { useMemo } from 'react';

import { Risk_Assessment_Result_Control_Type_Enum } from '@/generated/graphql';
import { useRiskScore } from '@/hooks/useRiskScore';

import { useControlTypeLabel } from '../../../../assessments/forms/useControlTypeLabel';
import { UNRATED } from '../../../../controls/lookupData';
import type {
  ComplianceRiskAssessmentResultFlatFields,
  ComplianceRiskAssessmentResultRegisterFields,
} from './types';

export const useComplianceRatingLabelledFields = (
  riskId: string,
  records: ComplianceRiskAssessmentResultFlatFields[] | undefined
) => {
  const statusRating = useRating('assessment_status');
  const getControlTypeLabel = useControlTypeLabel();
  const uncontrolledRiskRating = useRating('risk_uncontrolled');
  const controlledRiskRating = useRating('risk_controlled');
  const likelihoodRating = useRating('likelihood');
  const impactRating = useRating('impact');
  const riskScores = useRiskScore(riskId);

  return useMemo<ComplianceRiskAssessmentResultRegisterFields[]>(() => {
    return (
      records?.map((d) => {
        const complianceMonitoringAssessment = d.parents.find(
          (p) => p.complianceMonitoringAssessment
        )?.complianceMonitoringAssessment;

        return {
          ...d,
          StatusLabelled:
            complianceMonitoringAssessment !== undefined
              ? (statusRating.getLabel(
                  complianceMonitoringAssessment?.Status
                ) ?? '-"')
              : '-;',
          NextTestDate: complianceMonitoringAssessment?.NextTestDate ?? '-',
          ActualCompletionDate:
            complianceMonitoringAssessment?.ActualCompletionDate ?? '-',
          ParentTitle: complianceMonitoringAssessment?.Title ?? '-',
          ControlTypeLabelled:
            getControlTypeLabel(d.ControlType) || UNRATED.label,
          CompletionDate:
            complianceMonitoringAssessment?.ActualCompletionDate ?? '-',
          RatingLabelled: !riskScores.showScore
            ? d.ControlType ===
              Risk_Assessment_Result_Control_Type_Enum.Controlled
              ? controlledRiskRating.getLabel(d.Rating)
              : (uncontrolledRiskRating.getLabel(d.Rating) ?? UNRATED.label)
            : '-',
          ImpactLabelled: impactRating.getLabel(d.Impact) ?? UNRATED.label,
          LikelihoodLabelled:
            likelihoodRating.getLabel(d.Likelihood) ?? UNRATED.label,
        };
      }) || []
    );
  }, [
    records,
    statusRating,
    getControlTypeLabel,
    controlledRiskRating,
    uncontrolledRiskRating,
    impactRating,
    likelihoodRating,
    riskScores,
  ]);
};
