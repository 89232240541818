import { useGetGuidParam } from '@risksmart-app/components/routes/routes.utils';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { PageLayout } from 'src/layouts';

import ControlledTabs from '@/components/ControlledTabs';
import { useGetInternalAuditReportByIdQuery } from '@/generated/graphql';

import { useTabs } from '../../../../assessment-results/update/useTabs';

const Page: FC = () => {
  const { t } = useTranslation(['common'], { keyPrefix: 'assessmentResults' });
  const title = t('create_title');

  const assessmentId = useGetGuidParam('assessmentId');
  const { data, error } = useGetInternalAuditReportByIdQuery({
    variables: {
      Id: assessmentId,
    },
  });
  if (error) {
    throw error;
  }

  const assessment = data?.internal_audit_report[0];
  const tabs = useTabs('internal_audit_report', assessment, 'add', true, false);

  return (
    <PageLayout title={title}>
      <ControlledTabs tabs={tabs} variant={'container'} />
    </PageLayout>
  );
};

export default Page;
