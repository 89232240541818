import type { ApolloCache } from '@apollo/client';
import { useNotifications } from '@risksmart-app/components/Notifications/useNotifications';
import { useGetGuidParam } from '@risksmart-app/components/routes/routes.utils';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import type { IssueAssessmentFields } from 'src/pages/issues/update/forms/IssueAssessmentForm/issueAssessmentSchema';
import {
  defaultValues,
  IssueAssessmentSchema,
} from 'src/pages/issues/update/forms/IssueAssessmentForm/issueAssessmentSchema';
import { useHasPermission } from 'src/rbac/useHasPermission';

import { PageForm } from '@/components/Form/Form/PageForm';
import { useI18NSummaryHelpContent } from '@/components/HelpPanel/useSummaryHelpContent';
import type { GetIssueByIdQuery } from '@/generated/graphql';
import {
  Parent_Type_Enum,
  useGetIssueAssessmentByParentIdQuery,
  useInsertIssueAssessmentMutation,
  useUpdateIssueAssessmentMutation,
} from '@/generated/graphql';
import { evictField } from '@/utils/graphqlUtils';
import type { ParentIssueTypes } from '@/utils/issueVariantUtils';
import { IssueTypeMapping } from '@/utils/issueVariantUtils';

import IssueAssessmentForm from '../../forms/IssueAssessmentForm/IssueAssessmentForm';
import { getAssociatedControlIds } from './assessmentUtils';

type Props = {
  issue: GetIssueByIdQuery['issue'][number];
  type: ParentIssueTypes;
};

const Tab: FC<Props> = ({ issue, type }) => {
  const navigate = useNavigate();
  useI18NSummaryHelpContent('issueAssessment.help');
  const { addNotification } = useNotifications();
  const issueTypeMapping = IssueTypeMapping[type];
  const { t: st } = useTranslation(['common'], {
    keyPrefix: issueTypeMapping.assessmentTaxonomy,
  });
  const { t } = useTranslation(['common']);
  const issueId = useGetGuidParam('issueId');
  const { data, refetch } = useGetIssueAssessmentByParentIdQuery({
    variables: {
      parentIssueId: issueId,
    },
    fetchPolicy: 'no-cache',
    onError: (error) => {
      addNotification({
        type: 'error',
        content: <>{error.message}</>,
      });
    },
  });
  const canCreateIssueAssessment = useHasPermission(
    'insert:issue_assessment',
    issue
  );
  const canEditIssueAssessment = useHasPermission(
    'update:issue_assessment',
    issue
  );
  const assessment = data?.issue_assessment?.[0];
  const obligationIds =
    data?.issue_parent
      .filter((i) => i.parent?.ObjectType === Parent_Type_Enum.Obligation)
      .map((i) => i.ParentId) ?? [];
  const controlIds = getAssociatedControlIds(data);
  const documentIds =
    data?.issue_parent
      .filter((i) => i.parent?.ObjectType === Parent_Type_Enum.Document)
      .map((i) => i.ParentId) ?? [];

  const canModify = assessment
    ? canEditIssueAssessment
    : canCreateIssueAssessment;

  const clearCache = (cache: ApolloCache<unknown>) => {
    evictField(cache, 'issue');
    evictField(cache, 'control');
    evictField(cache, 'issue_assessment_aggregate');
    evictField(cache, 'issue_assessment_audit');
  };

  const [updateIssueAssessment] = useUpdateIssueAssessmentMutation({
    update: clearCache,
  });
  const [insertIssueAssessment] = useInsertIssueAssessmentMutation({
    update: clearCache,
  });

  const onSave = async (data: IssueAssessmentFields) => {
    const policyBreachVariables: Partial<IssueAssessmentFields> =
      data.PolicyBreach
        ? {}
        : {
            PoliciesBreached: undefined,
            PolicyOwner: undefined,
            PolicyOwnerCommentary: undefined,
          };

    const thirdPartyVariables: Partial<IssueAssessmentFields> =
      data.IssueCausedByThirdParty
        ? {}
        : {
            ThirdPartyResponsible: undefined,
          };

    const systemVariables: Partial<IssueAssessmentFields> =
      data.IssueCausedBySystemIssue
        ? {}
        : {
            SystemResponsible: undefined,
          };

    const regulationVariables: Partial<IssueAssessmentFields> =
      data.RegulatoryBreach
        ? {}
        : {
            Rationale: undefined,
            RegulationsBreached: undefined,
            Reportable: undefined,
          };

    if (assessment) {
      await updateIssueAssessment({
        variables: {
          ...data,
          Id: assessment.Id,
          OriginalTimestamp: assessment.ModifiedAtTimestamp,
          ...thirdPartyVariables,
          ...regulationVariables,
          ...systemVariables,
          ...policyBreachVariables,
          AssociatedControlIds: data.AssociatedControlIds.map((a) => a.value),
          RegulationsBreachedIds: data.RegulationsBreachedIds.map(
            (a) => a.value
          ),
          PoliciesBreachedIds: data.PoliciesBreachedIds.map((a) => a.value),
          PolicyOwner: data.PolicyOwner?.value,
          CertifiedIndividual: data.CertifiedIndividual?.value,
        },
      });
    } else {
      await insertIssueAssessment({
        variables: {
          ...data,
          ParentIssueId: issueId,
          ...thirdPartyVariables,
          ...regulationVariables,
          ...systemVariables,
          ...policyBreachVariables,
          AssociatedControlIds: data.AssociatedControlIds.map((a) => a.value),
          RegulationsBreachedIds: data.RegulationsBreachedIds.map(
            (a) => a.value
          ),
          PoliciesBreachedIds: data.PoliciesBreachedIds.map((a) => a.value),
          PolicyOwner: data.PolicyOwner?.value,
          CertifiedIndividual: data.CertifiedIndividual?.value,
        },
      });
    }
    await refetch();
  };

  const values: IssueAssessmentFields | undefined = assessment
    ? {
        ...defaultValues,
        ...assessment,
        tags: issue.tags,
        AssociatedControlIds: controlIds.map((id) => ({ value: id })) ?? [],
        RegulationsBreachedIds:
          obligationIds.map((id) => ({ value: id })) ?? [],
        PoliciesBreachedIds: documentIds.map((id) => ({ value: id })) ?? [],
        CertifiedIndividual: assessment?.CertifiedIndividual
          ? { value: assessment.CertifiedIndividual, type: 'user' }
          : null,
        PolicyOwner: assessment?.PolicyOwner
          ? { value: assessment.PolicyOwner, type: 'user' }
          : null,
      }
    : undefined;

  return (
    <>
      <PageForm<IssueAssessmentFields>
        formId={'update-issue-assessment-form'}
        values={values}
        defaultValues={defaultValues}
        i18n={t(issueTypeMapping.assessmentTaxonomy)}
        onSave={onSave}
        onDismiss={() => navigate('..')}
        schema={IssueAssessmentSchema}
        readOnly={!canModify}
        header={st('tab_title')}
        parentType={issueTypeMapping.assessmentType}
        approvalConfig={{ object: { Id: assessment?.Id ?? '' } }}
      >
        <IssueAssessmentForm readOnly={!canModify} type={type} />
      </PageForm>
    </>
  );
};

export default Tab;
