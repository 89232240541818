import type { FC } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import DeleteModal from '@/components/DeleteModal/DeleteModal';
import {
  GetCustomAttributeSchemasByParentTypeDocument,
  GetFormFieldOptionsByParentTypeDocument,
  useGetCustomAttributeSchemasByParentTypeQuery,
  useInsertCustomAttributeSchemaMutation,
  useInsertFormFieldOptionsMutation,
  useUpdateCustomAttributeSchemaMutation,
  useUpdateFormFieldOptionsMutation,
} from '@/generated/graphql';
import { useDeleteResultNotification } from '@/hooks/useMutationResultNotification';

import { TextInputWithFormField } from '../ControlledInput/TextInputWithFormField';
import {
  addFieldToSchemaData,
  removeFieldFromSchemaData,
} from '../CustomAttributes/CustomAttributeSchema';
import { NewFieldForm } from '../CustomAttributes/EditFields/NewFieldForm';
import type { NewFieldFormFields } from '../CustomAttributes/EditFields/NewFieldSchema';
import {
  defaultValues,
  fieldOptionsSchema,
  fieldToJsonSchema,
  newFieldSchema,
} from '../CustomAttributes/EditFields/NewFieldSchema';
import { useFieldConfig } from '../Form/CustomisableForm/hooks/useFieldConfig';
import { useRiskSmartForm } from '../Form/CustomisableForm/RiskSmartFormContext';
import { FormContextInner } from '../Form/InnerFormContext';
import { ModalWrapper } from '../Form/ModalWrapper';
import CustomisableFieldOptions from './CustomisableFieldOptions';
import type { EditFieldModalProps } from './EditFieldModalProps';
import { EditMode } from './types';

export const EditFieldModal: FC<EditFieldModalProps> = ({
  onDismiss,
  parentType,
  values,
  fieldPath,
  editMode = EditMode.Create,
  isCustomField = false,
  label,
  fieldId,
  defaultRequired,
  forceRequired,
  defaultValueOptions,
  allowDefaultValue,
}) => {
  const { allFieldIds } = useRiskSmartForm();
  const { t } = useTranslation(['common']);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [insertCustomAttributeField] = useInsertCustomAttributeSchemaMutation({
    refetchQueries: [GetCustomAttributeSchemasByParentTypeDocument],
  });
  const [updateCustomAttributeField, updateResult] =
    useUpdateCustomAttributeSchemaMutation({
      refetchQueries: [GetCustomAttributeSchemasByParentTypeDocument],
    });
  const [insertFieldConfig] = useInsertFormFieldOptionsMutation({
    refetchQueries: [GetFormFieldOptionsByParentTypeDocument],
  });
  const [updateFieldConfig] = useUpdateFormFieldOptionsMutation({
    refetchQueries: [GetFormFieldOptionsByParentTypeDocument],
  });

  const { data, loading } = useGetCustomAttributeSchemasByParentTypeQuery({
    variables: { parentType },
    fetchPolicy: 'no-cache',
  });

  const fieldOptionsData = useFieldConfig(fieldId, {
    defaultRequired: defaultRequired,
  });
  const formConfigExists = !!data?.form_configuration[0];
  const customAttributeSchemaData =
    data?.form_configuration?.[0]?.customAttributeSchema;
  const schemaId = customAttributeSchemaData?.Id;
  const title = `${parentType} custom attributes`;

  const onDelete = useDeleteResultNotification({
    entityName: t('customAttributes.entity_name'),
    asyncAction: async () => {
      if (!values || !schemaId) {
        return false;
      }
      const fieldData = fieldToJsonSchema(values, fieldPath);
      const updatedSchema = removeFieldFromSchemaData(fieldData, {
        uiSchema: customAttributeSchemaData.UiSchema,
        schema: customAttributeSchemaData.Schema,
      });
      await updateCustomAttributeField({
        variables: {
          Title: title,
          UiSchema: updatedSchema.uiSchema,
          Schema: updatedSchema.schema,
          Id: schemaId,
        },
      });
      onDismiss();

      return true;
    },
  });

  const onSave = async (data: NewFieldFormFields) => {
    const newField = newFieldSchema.safeParse(data);
    const fieldOptions = fieldOptionsSchema.safeParse(data);

    if (newField.success) {
      const fieldData = fieldToJsonSchema(newField.data, fieldPath);
      if (schemaId) {
        const updatedSchema = addFieldToSchemaData(fieldData, {
          uiSchema: customAttributeSchemaData.UiSchema,
          schema: customAttributeSchemaData.Schema,
        });
        await updateCustomAttributeField({
          variables: {
            Title: title,
            UiSchema: updatedSchema.uiSchema,
            Schema: updatedSchema.schema,
            Id: schemaId,
          },
        });
      } else {
        const newSchema = addFieldToSchemaData(fieldData);
        await insertCustomAttributeField({
          variables: {
            Title: title,
            UiSchema: newSchema.uiSchema,
            Schema: newSchema.schema,
            ParentType: parentType,
          },
        });
      }
    }

    if (
      fieldOptions.success &&
      !(isCustomField && editMode === EditMode.Create)
    ) {
      if (formConfigExists) {
        await updateFieldConfig({
          variables: {
            parentType,
            fieldIds: allFieldIds,
            fieldConfig: {
              FormConfigurationParentType: parentType,
              FieldId: fieldId,
              form: null,
              ...fieldOptions.data,
            },
          },
        });
      } else {
        await insertFieldConfig({
          variables: {
            parentType,
            fieldIds: allFieldIds,
            fieldConfig: {
              FieldId: fieldId,
              form: null,
              ...fieldOptions.data,
            },
          },
        });
      }
    }
  };

  if (loading || !editMode) {
    return null;
  }

  return (
    <FormContextInner
      testId={'editFieldModal'}
      i18n={t('customAttributes')}
      defaultValues={defaultValues}
      values={{
        ...defaultValues,
        ...values,
        ...fieldOptionsData,
        ...(forceRequired ? { Required: true } : {}),
      }}
      schema={isCustomField ? newFieldSchema : fieldOptionsSchema}
      onSave={onSave}
      onDismiss={onDismiss}
      onDelete={
        !!values && !!fieldPath
          ? async () => setShowDeleteModal(true)
          : undefined
      }
      formFieldOptions={undefined}
      formId={'field-configuration-form'}
      renderTemplate={(renderProps) => (
        <ModalWrapper visible={true} {...renderProps} />
      )}
    >
      {isCustomField ? (
        <NewFieldForm disableTypeField={editMode === EditMode.Update} />
      ) : label ? (
        <TextInputWithFormField
          name={'Label'}
          label={t('customAttributes.fields.label')}
          onChange={() => null}
          value={label}
          disabled={!isCustomField}
        />
      ) : null}
      {!(isCustomField && editMode === EditMode.Create) && (
        <CustomisableFieldOptions
          defaultValueOptions={defaultValueOptions}
          forceRequired={!!forceRequired}
          allowDefaultValue={!!allowDefaultValue}
        />
      )}
      <DeleteModal
        loading={updateResult.loading}
        isVisible={showDeleteModal}
        header={t('delete')}
        onDelete={onDelete}
        onDismiss={onDismiss}
      >
        {t('customAttributes.confirm_delete_message')}
      </DeleteModal>
    </FormContextInner>
  );
};
