import ColumnLayout from '@cloudscape-design/components/column-layout';
import ExpandableSection from '@cloudscape-design/components/expandable-section';
import i18n from '@risksmart-app/i18n/i18n';
import type { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import ControlledDatePicker from '@/components/Form/ControlledDatePicker';
import { ControlledFileUpload } from '@/components/Form/ControlledFileUpload/ControlledFileUpload';
import ControlledImpactSelect from '@/components/Form/ControlledImpactSelect';
import ControlledRating from '@/components/Form/ControlledRating';
import ControlledSelect from '@/components/Form/ControlledSelect';
import ControlledTextarea from '@/components/Form/ControlledTextarea';
import ConditionalField from '@/components/Form/Form/CustomisableForm/ConditionalField';
import CustomisableForm from '@/components/Form/Form/CustomisableForm/CustomisableForm';
import { Appetite_Type_Enum } from '@/generated/graphql';
import { useIsFeatureVisibleToOrg } from '@/utils/featureFlags';

import { TestIds } from './AppetiteFormFieldsTestIds';
import type { AppetiteFormFieldsData } from './appetiteSchema';

type Props = {
  readOnly?: boolean;
};

const AppetiteGuidanceSection: FC<{ header: string; content: string }> = (
  props
) => {
  return (
    <ExpandableSection headerText={props.header} defaultExpanded={true}>
      <p>{props.content}</p>
    </ExpandableSection>
  );
};

const AppetiteFormFields: FC<Props> = ({ readOnly }) => {
  const { control, watch } = useFormContext<AppetiteFormFieldsData>();
  const { t } = useTranslation(['common']);
  const { t: at } = useTranslation('common', {
    keyPrefix: 'appetites.columns',
  });

  const { t: rt } = useTranslation('ratings');
  const appetites = rt('risk_appetite', { returnObjects: true });

  const posture = useIsFeatureVisibleToOrg('posture');
  const impacts = useIsFeatureVisibleToOrg('impacts');

  const appetiteType = watch('AppetiteType');
  const upperAppetite = watch('UpperAppetite');

  const guidanceForAppetite =
    appetites.find((a) => a.value === upperAppetite)?.guidance || [];
  const showGuidance = guidanceForAppetite.length > 0;

  return (
    <ColumnLayout columns={showGuidance ? 2 : 1} borders={'vertical'}>
      <CustomisableForm readOnly={readOnly}>
        {impacts && (
          <ControlledSelect
            forceRequired={true}
            testId={TestIds.AppetiteType}
            key={'appetiteType'}
            name={'AppetiteType'}
            label={at('appetiteType')}
            description={at('appetiteType_help')}
            control={control}
            options={[
              {
                label: i18n.format(t('impact_one'), 'capitalize'),
                value: Appetite_Type_Enum.Impact,
              },
              {
                label: i18n.format(t('likelihood_one'), 'capitalize'),
                value: Appetite_Type_Enum.Likelihood,
              },
            ]}
          />
        )}

        <ControlledTextarea
          key={'statement'}
          defaultRequired={false}
          name={'Statement'}
          label={t('riskAppetiteStatement')}
          description={at('riskAppetiteStatement_help')}
          placeholder={t('enterRiskAppetiteStatement') ?? ''}
          control={control}
          disabled={readOnly}
        />

        {appetiteType === Appetite_Type_Enum.Risk && !posture && (
          <ControlledRating
            key={'lowerAppetite'}
            filteringType={'none'}
            forceRequired={true}
            label={t('lowerAppetite')}
            name={'LowerAppetite'}
            description={at('lowerAppetite_help')}
            placeholder={t('select') ?? ''}
            control={control}
            type={'risk_appetite'}
            testId={TestIds.LowerAppetite}
            disabled={readOnly}
          />
        )}

        {appetiteType === Appetite_Type_Enum.Risk && (
          <ControlledRating
            key={'upperAppetite'}
            testId={TestIds.UpperAppetite}
            forceRequired={true}
            filteringType={'none'}
            label={posture ? at('posture') : t('upperAppetite')}
            name={'UpperAppetite'}
            description={
              posture ? at('posture_help') : at('upperAppetite_help')
            }
            placeholder={t('select') ?? ''}
            control={control}
            type={'risk_appetite'}
            disabled={readOnly}
          />
        )}

        {appetiteType === Appetite_Type_Enum.Likelihood && (
          <ControlledRating
            key={'likelihoodAppetite'}
            testId={TestIds.LikelihoodAppetite}
            forceRequired={true}
            filteringType={'none'}
            label={at('LikelihoodAppetite')}
            name={'LikelihoodAppetite'}
            description={at('LikelihoodAppetiteHelp')}
            placeholder={t('select') ?? ''}
            control={control}
            type={'likelihood_appetite'}
            disabled={readOnly}
          />
        )}

        {impacts && (
          <ConditionalField
            condition={appetiteType === Appetite_Type_Enum.Impact}
            key={'impact'}
          >
            <ControlledImpactSelect
              key={'impact'}
              testId={TestIds.Impact}
              forceRequired={true}
              name={'ImpactId'}
              label={i18n.format(t('impact'), 'capitalize')}
              description={at('impact_help')}
              control={control}
              disabled={readOnly}
            />
          </ConditionalField>
        )}

        {impacts && (
          <ConditionalField
            condition={appetiteType === Appetite_Type_Enum.Impact}
            key={'impactAppetite'}
          >
            <ControlledRating
              testId={'ImpactAppetite'}
              key={'impactAppetite'}
              forceRequired={true}
              filteringType={'none'}
              label={t('impactAppetite')}
              description={at('impactAppetite_help')}
              name={'ImpactAppetite'}
              placeholder={t('select') ?? ''}
              control={control}
              type={'impact_appetite'}
              showValue={true}
              disabled={readOnly}
            />
          </ConditionalField>
        )}

        <ControlledDatePicker
          key={'effectiveDate'}
          defaultRequired={true}
          name={'EffectiveDate'}
          label={t('effectiveDate')}
          description={at('effectiveDate_help')}
          control={control}
          disabled={readOnly}
        />

        <ControlledFileUpload
          key={'attachFiles'}
          label={t('fields.newFiles')}
          description={t('fields.newFiles_help')}
          control={control}
          name={'newFiles'}
          saveFilesName={'files'}
          disabled={readOnly}
        />
      </CustomisableForm>
      {showGuidance && (
        <div className={'px-6'}>
          {/* TODO: translations */}
          <h3>{'Appetite Guidance'}</h3>
          {Array.isArray(guidanceForAppetite) &&
            guidanceForAppetite.map(
              (g: { section: string; content: string }) => (
                <AppetiteGuidanceSection
                  key={g.section}
                  header={g.section}
                  content={g.content}
                />
              )
            )}
        </div>
      )}
    </ColumnLayout>
  );
};

export default AppetiteFormFields;
