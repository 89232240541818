import _ from 'lodash';

export type Rating = {
  label: string;
  value: number;
  range?: [number, number];
};

/**
 * Retrieve a rating option by range
 * @param options
 * @param value
 * @returns
 */
export const getRatingByRange = (options: Rating[], value: number | null) => {
  if (_.isNil(value)) {
    return undefined;
  }

  return options.find((r) => {
    if (!r.range || r.range.length !== 2) {
      return undefined;
    }

    return value >= r.range[0] && Math.floor(value) <= r.range[1];
  });
};
