import { useTools } from '@risksmart-app/components/Tools/useTools';
import { useNavigate } from 'react-router';

import { useWizardStore } from '../store/useWizardStore';
import { StepStatus } from '../types';

export const useNavigateToInProgress = () => {
  const { steps, setBasePath, setCurrentStep } = useWizardStore();
  const [_, setToolsContent] = useTools();

  const navigate = useNavigate();

  return {
    navigateToInProgress: (basePath: string) => {
      setToolsContent('wizard');
      setBasePath(basePath);
      const inProgressStep = steps?.findIndex(
        (step) => step.status === StepStatus.InProgress
      );

      if (inProgressStep !== -1) {
        navigate(`${basePath}/${steps[inProgressStep].tab ?? ''}`);
        setCurrentStep(inProgressStep);

        return;
      }

      setCurrentStep(0);
      navigate(basePath);
    },
  };
};
