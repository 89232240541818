import useRisksmartUser from '@risksmart-app/components/hooks/useRisksmartUser';
import i18next, { mergeCustomI18n } from '@risksmart-app/i18n/i18n';
import type { FC, ReactNode } from 'react';
import { createContext } from 'react';

import Loading from '@/components/Loading';
import { useGetTaxonomyByLocaleAndOrgQuery } from '@/generated/graphql';
import { handleError } from '@/utils/errorUtils';

type TaxonomyContext = {
  loading: boolean;
};

const TaxonomyContext = createContext<null | TaxonomyContext>(null);

const TaxonomyProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { user } = useRisksmartUser();
  const orgKey = user?.orgKey;

  const { data, loading } = useGetTaxonomyByLocaleAndOrgQuery({
    variables: {
      Locale: i18next.language,
      OrgKey: orgKey!,
    },
    fetchPolicy: 'no-cache',
    onError: (error) => {
      console.warn(
        `Error attempting to load translations for ${orgKey}: ${i18next.language}`
      );
      handleError(error);
    },
  });

  mergeCustomI18n(data?.taxonomy_org[0]?.taxonomy);

  return (
    <TaxonomyContext.Provider
      value={{
        loading,
      }}
    >
      {loading ? <Loading /> : children}
    </TaxonomyContext.Provider>
  );
};

export { TaxonomyProvider };
