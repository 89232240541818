import _ from 'lodash';
import type { FieldValues } from 'react-hook-form';

import { useGetFormFieldOptionsByParentTypeQuery } from '@/generated/graphql';

import { EditableFormProvider } from './CustomisableForm/EditableFormProvider';
import { FormContextInner } from './InnerFormContext';
import type { FormContextProps } from './types';

export const CustomisableFormContext = <TFieldValues extends FieldValues>(
  props: FormContextProps<TFieldValues>
) => {
  const { data, loading } = useGetFormFieldOptionsByParentTypeQuery({
    variables: { parentType: props.parentType! },
    skip: !props.parentType,
  });

  // When loading the field options, the form state is lost.
  // For most cases this is fine, and maybe even desirable,
  // but for risk rating for example, the parent type of the form can be changes by the user whilst filling in the form,
  // so we don't want to lose the state
  if (loading && !props.noLoadingOnParentTypeChange) {
    return <div>{'Loading...'}</div>;
  }

  return (
    <FormContextInner
      {...props}
      formFieldOptions={data?.form_field_configuration}
      renderTemplate={(innerProps) => (
        <EditableFormProvider>
          {props.renderTemplate(innerProps)}
        </EditableFormProvider>
      )}
    />
  );
};
