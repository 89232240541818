import Button from '@risksmart-app/components/Button';
import { useEntityFilter } from '@risksmart-app/components/contexts/entityFilterContext';
import { useNotifications } from '@risksmart-app/components/Notifications/useNotifications';
import Table from '@risksmart-app/components/Table';
import { type FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PageLayout } from 'src/layouts';

import {
  Appetite_Status_Enum,
  Appetite_Type_Enum,
  useGetActiveRiskAppetitesQuery,
} from '@/generated/graphql';
import { getCounter } from '@/utils/collectionUtils';

import { useGetCollectionTableProps } from './config';

const Page: FC = () => {
  const { t } = useTranslation(['common']);
  const { t: st } = useTranslation(['common'], { keyPrefix: 'appetites' });
  const title = st('register_title');

  const { addNotification } = useNotifications();
  const { entityIds } = useEntityFilter();
  const whereFilter = useMemo(() => {
    if (!entityIds || !entityIds.length) {
      return {
        Status: { _eq: Appetite_Status_Enum.Active },
        appetite: { AppetiteType: { _eq: Appetite_Type_Enum.Risk } },
      };
    }

    return {
      Status: { _eq: Appetite_Status_Enum.Active },
      appetite: { AppetiteType: { _eq: Appetite_Type_Enum.Risk } },
      risk: {
        enterpriseRiskInstance: {
          EntityId: { _in: entityIds },
        },
      },
    };
  }, [entityIds]);
  const { data, loading } = useGetActiveRiskAppetitesQuery({
    variables: {
      where: whereFilter,
    },
    fetchPolicy: 'no-cache',
    onError: (error) => {
      addNotification({
        type: 'error',
        content: <>{error.message}</>,
      });
    },
  });

  const tableProps = useGetCollectionTableProps(
    data?.appetite_parent,
    data?.form_configuration?.[0]?.customAttributeSchema
  );

  return (
    <PageLayout
      helpTranslationKey={'appetites.help'}
      title={title}
      counter={getCounter(tableProps.totalItemsCount, loading)}
      actions={
        <Button iconName={'download'} onClick={tableProps.exportToCsv}>
          {t('export')}
        </Button>
      }
    >
      <Table {...tableProps} loading={loading} />
    </PageLayout>
  );
};

export default Page;
