import Button from '@risksmart-app/components/Button';
import { DraggableItem } from '@risksmart-app/components/DragableItem/DraggableItem';
import { Reorder } from 'framer-motion';
import type { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import ControlledInput from '../../ControlledInput';
import { TextInputWithFormField } from '../../ControlledInput/TextInputWithFormField';
import ControlledSelect from '../../ControlledSelect';
import ControlledTextarea from '../../ControlledTextarea';
import type { NewFieldFormFields } from './NewFieldSchema';
import { FieldType } from './NewFieldSchema';

interface NewFieldFormProps {
  disableTypeField?: boolean;
}
export const NewFieldForm: FC<NewFieldFormProps> = ({
  disableTypeField = false,
}) => {
  const { t } = useTranslation(['common'], {
    keyPrefix: 'customAttributes.fields',
  });
  const { t: tr } = useTranslation(['ratings']);
  const { control, watch, setValue } = useFormContext<NewFieldFormFields>();
  const fieldType: FieldType = watch('Type') || FieldType.Text;
  const fieldOptions = watch('Options') ?? [];

  const onAddOptionField = () => {
    const newOptions = [
      ...fieldOptions,
      { value: '', generatedId: crypto.randomUUID() },
    ];
    setOptions(newOptions);
  };

  const updateOption = (generatedId: string, value: null | number | string) => {
    const options = [...fieldOptions];
    const option = options.find((o) => o.generatedId === generatedId);
    if (option) {
      option.value = `${value}`;
      setOptions(options);
    }
  };

  const deleteOption = (generatedId: string) => {
    const options = [...fieldOptions];
    const optionIndex = options.findIndex((o) => o.generatedId === generatedId);

    if (optionIndex > -1) {
      options.splice(optionIndex, 1);
      setOptions(options);
    }
  };

  const setOptions = (options: { value: string; generatedId: string }[]) => {
    setValue('Options', options);
  };

  return (
    <div>
      <ControlledSelect
        control={control}
        disabled={disableTypeField}
        name={'Type'}
        testId={'fieldType'}
        label={t('type')}
        placeholder={t('type_placeholder')}
        options={tr('new_field_type').map(({ label, value, disabled }) => ({
          label,
          value,
          disabled,
        }))}
      />
      <ControlledInput
        name={'Label'}
        label={t('label')}
        testId={'label'}
        control={control}
        placeholder={t('label_placeholder')}
      />
      {[FieldType.Select, FieldType.MultiSelect].includes(fieldType) && (
        <div className={'pb-6'}>
          <Reorder.Group
            axis={'y'}
            className={'p-0'}
            values={fieldOptions}
            onReorder={setOptions}
          >
            {fieldOptions.map((fieldOption, index) => (
              <DraggableItem
                value={fieldOption}
                key={fieldOption.generatedId}
                deleteOption={() => deleteOption(fieldOption.generatedId)}
              >
                <TextInputWithFormField
                  key={fieldOption.generatedId}
                  testId={'Option'}
                  label={`${t('option')} ${index + 1}`}
                  value={fieldOption.value}
                  placeholder={t('option_placeholder')}
                  onChange={(val) => updateOption(fieldOption.generatedId, val)}
                />
              </DraggableItem>
            ))}
          </Reorder.Group>

          <Button
            onClick={(e) => {
              e.preventDefault();
              onAddOptionField();
            }}
          >
            {t('add_dropdown_option')}
          </Button>
        </div>
      )}
      <ControlledTextarea
        name={'Description'}
        label={t('description')}
        control={control}
        placeholder={t('description_placeholder')}
      />
    </div>
  );
};
