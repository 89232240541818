import Link from '@risksmart-app/components/Link';
import i18n from '@risksmart-app/i18n/i18n';

import type { ReportFieldType } from './types';

export const detailsLink: ReportFieldType = {
  cell: ({ field, fieldData, helpers }) => {
    const fieldDef = field.fieldDef;
    if (fieldDef.displayType !== 'detailsLink') {
      throw new Error('Unsupported display type');
    }
    const value = fieldData.value;
    const entityInfo = helpers.getEntityInfo(fieldDef.entityInfoParentType);

    return (
      <Link href={entityInfo.url(value as string)}>
        {i18n.t('customDatasources.view_details', {
          entity: entityInfo.singular,
        })}
      </Link>
    );
  },
};
