import { DataSourceType } from '../../reporting/schema';
import { getAcceptances } from './acceptances';
import { getActions } from './actions';
import { getAppetites } from './appetites';
import { getAssessments } from './assessments';
import { getCauses } from './causes';
import { getConsequences } from './consequences';
import { getControls } from './controls';
import { getIndicatorResults } from './indicatorResults';
import { getIndicators } from './indicators';
import { getIssues } from './issues';
import { getRiskAssessmentResults } from './riskAssessmentResults';
import { getRisks } from './risks';
import { getTags } from './tags';
import { getTestResults } from './testResults';
import { SharedDatasets } from './types';

export const getSharedDatasets = (): SharedDatasets => ({
  assessments: getAssessments(),
  risks: getRisks(),
  actions: getActions(),
  issues: getIssues(),
  controls: getControls(),
  tags: getTags(),
  appetites: getAppetites(),
  acceptances: getAcceptances(),
  indicators: getIndicators(),
  indicatorResults: getIndicatorResults(),
  causes: getCauses(),
  consequences: getConsequences(),
  testResults: getTestResults(),
  riskAssessmentResults: getRiskAssessmentResults(),
});

/**
 * Create lookup of data source children
 */
const createChildLookup = (): Map<DataSourceType, Set<DataSourceType>> => {
  const lookup = new Map<DataSourceType, Set<DataSourceType>>();
  const sharedDatasets = getSharedDatasets();
  for (const type in sharedDatasets) {
    const childDatasource = type as keyof typeof sharedDatasets;
    const dataset = sharedDatasets[type as keyof typeof sharedDatasets];
    dataset.parentTypes.forEach((parentType) => {
      if (!lookup.has(parentType)) {
        lookup.set(parentType, new Set<DataSourceType>());
      }
      lookup.get(parentType)?.add(childDatasource);
    });
  }

  return lookup;
};
let childDataSourceLookup: Map<DataSourceType, Set<DataSourceType>>;

/**
 * Retrieve possible children of a data source
 * @param dataSource
 * @returns
 */
export const getChildDataSources = (
  dataSource: DataSourceType
): Set<DataSourceType> => {
  if (!childDataSourceLookup) {
    childDataSourceLookup = createChildLookup();
  }

  return childDataSourceLookup.get(dataSource) ?? new Set();
};
