import { DataType } from './datasets/types';
import { AggregateType } from './schema';

/**
 * Map of data types support which types of aggregation
 */
export const aggregateTypeSupportedDataTypes: {
  [aggregationType in AggregateType]: DataType[];
} = {
  min: ['number', 'text', 'date'],
  max: ['number', 'text', 'date'],
  count: ['number'],
  avg: ['number'],
  sum: ['number'],
};
