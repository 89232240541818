import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { CustomisableFormContext } from '@/components/Form/Form/CustomisableFormContext';
import { PageWrapper } from '@/components/Form/Form/PageWrapper';
import { type SubmitButton } from '@/components/Form/Form/types';

import { CustomDatasourceFormFields } from './CustomDatasourceFormFields';
import type { ReportDefinitionFormData } from './definitionSchema';
import { defaultValues, ReportDefinitionFormSchema } from './definitionSchema';
import type { CustomAttributeSchemaLookup } from './types';

export type Props = {
  onDismiss: (saved: boolean) => Promise<void>;
  onSave: (data: ReportDefinitionFormData) => Promise<void>;
  onPreview: (data: ReportDefinitionFormData) => Promise<void>;
  values?: ReportDefinitionFormData;
  customAttributeSchemaLookup: CustomAttributeSchemaLookup;
  readOnly: boolean;
  mode: 'create' | 'update';
};

export const CustomDatasourceForm: FC<Props> = ({
  onSave,
  onPreview,
  onDismiss,
  values,
  customAttributeSchemaLookup,
  readOnly,
  mode,
}) => {
  const { t } = useTranslation(['common'], { keyPrefix: 'customDatasources' });

  const defaultSubmitActions: SubmitButton<ReportDefinitionFormData>[] = [
    {
      label: t('save_button'),
      action: onSave,
    },
    {
      label: t('preview_button'),
      disableNotification: true,
      action: onPreview,
    },
  ];

  return (
    <CustomisableFormContext
      defaultValues={defaultValues}
      i18n={{
        entity_name: t('entity_name'),
      }}
      submitActions={defaultSubmitActions}
      values={values}
      onSave={onSave}
      readOnly={readOnly}
      schema={ReportDefinitionFormSchema}
      renderTemplate={(renderProps) => <PageWrapper {...renderProps} />}
      formId={''}
      onDismiss={onDismiss}
    >
      <CustomDatasourceFormFields
        mode={mode}
        customAttributeSchemaLookup={customAttributeSchemaLookup}
      />
    </CustomisableFormContext>
  );
};
