import Button from '@risksmart-app/components/Button';
import { type FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useInsertWizardMutation } from '@/generated/graphql';

import { useNavigateToInProgress } from './hooks/useNavigateToInProgress';
import { useWizardStore } from './store/useWizardStore';

type WizardButtonProps = {
  basePath: string;
  riskId: string;
};

export const WizardButton: FC<WizardButtonProps> = ({ basePath, riskId }) => {
  const { navigateToInProgress } = useNavigateToInProgress();
  const { activeWizards, addActiveWizard } = useWizardStore();
  const { t } = useTranslation('common', { keyPrefix: 'wizard' });

  const [insertWizardMutation, { loading: insertLoading }] =
    useInsertWizardMutation({
      variables: {
        object: { RiskId: riskId },
      },
    });

  const startWizard = () => {
    navigateToInProgress(basePath);
    addActiveWizard(riskId);

    if (!activeWizards.has(riskId)) {
      insertWizardMutation();
    }
  };

  return (
    <Button
      loading={insertLoading}
      variant={'primary'}
      onClick={() => {
        startWizard();
      }}
    >
      {`${activeWizards.has(riskId) ? 'Continue' : 'Start'} ${t('wizardName')}`}
    </Button>
  );
};
