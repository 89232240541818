import { operatorSchema } from '@risksmart-app/shared/reporting/api/schema';
import { dataSourceTypeSchema } from '@risksmart-app/shared/reporting/schema';
import { z } from 'zod';

const dataSourceBaseSchema = z.object({
  type: dataSourceTypeSchema,
});

export type TreeDataSource = z.infer<typeof dataSourceBaseSchema> & {
  children: TreeDataSource[];
};

export const dataSourceSchema: z.ZodType<TreeDataSource> =
  dataSourceBaseSchema.extend({
    children: z.lazy(() => dataSourceSchema.array()),
  });
const token = z.object({
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: z.custom<Required<any>>((x) => x !== undefined),
  propertyKey: z.string(),
  operator: operatorSchema,
});
export type Token = z.infer<typeof token>;

const operationEnum = z.enum(['and', 'or']);

export type Operation = z.infer<typeof operationEnum>;

const tokenGroup = z.object({
  operation: operationEnum,
  tokens: z.array(token),
});
export type TokenGroup = z.infer<typeof tokenGroup>;

const filters = z.object({
  operation: operationEnum,
  tokens: z.array(z.any()).max(0), // Don't use this
  tokenGroups: z.array(token.or(tokenGroup)).optional(),
});
export type Filters = z.infer<typeof filters>;

export const ReportDefinitionFormSchema = z.object({
  dataSource: dataSourceSchema,
  title: z.string().min(1, 'Required'),
  fields: z
    .array(
      z.object({
        value: z.string(),
        dataSourceIndex: z.number().int(),
        fieldId: z.string(),
      })
    )
    .min(1, 'Required'),
  filters,
});

export type ReportDefinitionFormData = z.infer<
  typeof ReportDefinitionFormSchema
>;

export const defaultValues: ReportDefinitionFormData = {
  title: '',
  dataSource: { type: null as unknown as 'risks', children: [] },
  fields: [],
  filters: {
    operation: 'and',
    tokens: [],
    tokenGroups: [],
  },
};
