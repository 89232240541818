import { Reorder, useDragControls } from 'framer-motion';
import type { FC, ReactNode } from 'react';

import Button from '../Button';

interface DraggableItemProps {
  value: unknown;
  deleteOption: (generatedId: string) => void;
  children: ReactNode;
}

export const DraggableItem: FC<DraggableItemProps> = ({
  value,
  deleteOption,
  children,
}) => {
  const controls = useDragControls();

  return (
    <Reorder.Item
      value={value}
      dragListener={false}
      dragControls={controls}
      className={'flex items-center gap-x-2'}
    >
      <div onPointerDown={(e) => controls.start(e)}>
        <Button variant={'icon'} iconName={'drag-indicator'} />
      </div>
      <div className={'flex-grow'}>{children}</div>
      {deleteOption ? (
        <Button
          variant={'icon'}
          iconName={'close'}
          onClick={(e) => {
            e.stopPropagation();
            deleteOption(value as string);
          }}
        />
      ) : null}
    </Reorder.Item>
  );
};
