import SpaceBetween from '@cloudscape-design/components/space-between';
import Table from '@cloudscape-design/components/table';
import Button from '@risksmart-app/components/Button';
import { useNotifications } from '@risksmart-app/components/Notifications/useNotifications';
import type { FC } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useI18NSummaryHelpContent } from '@/components/HelpPanel/useSummaryHelpContent';
import TabHeader from '@/components/TabHeader';
import { useGetAuthUsersQuery } from '@/generated/graphql';

import { useGetUsersTableProps } from './config';
import UserDetailsModal from './UserDetailsModal';

const UsersTab: FC = () => {
  useI18NSummaryHelpContent('userSettings.help');
  const { t } = useTranslation(['common']);
  const { addNotification } = useNotifications();
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState<string | undefined>(
    undefined
  );

  const { data, loading, refetch } = useGetAuthUsersQuery({
    variables: {
      where: {
        _or: [
          { RoleKey: { _neq: 'ThirdPartyRespondent' } },
          { RoleKey: { _is_null: true } },
        ],
      },
    },
    fetchPolicy: 'no-cache',
    onError: (error) => {
      addNotification({
        type: 'error',
        content: <>{error.message}</>,
      });
    },
  });
  const tableProps = useGetUsersTableProps(data?.auth_user ?? [], (user) => {
    setSelectedUserId(user.Id);
    setIsEditOpen(true);
  });

  const handleUserModalClose = () => {
    setSelectedUserId(undefined);
    setIsEditOpen(false);
    refetch();
  };

  return (
    <>
      <Table
        {...tableProps}
        header={
          <SpaceBetween size={'m'}>
            <TabHeader
              actions={
                <SpaceBetween direction={'horizontal'} size={'xs'}>
                  <Button
                    iconName={'download'}
                    onClick={tableProps.exportToCsv}
                  >
                    {t('export')}
                  </Button>
                </SpaceBetween>
              }
            >
              {t('userSettings.usersTableTitle')}
            </TabHeader>
          </SpaceBetween>
        }
        variant={'embedded'}
        loading={loading}
        trackBy={'Id'}
      />
      {isEditOpen && selectedUserId && (
        <UserDetailsModal
          id={selectedUserId}
          onDismiss={handleUserModalClose}
        />
      )}
    </>
  );
};

export default UsersTab;
