import 'pdfmake/build/vfs_fonts';

import { useRating } from '@risksmart-app/components/hooks/useRating';
import { useTranslation } from 'react-i18next';
import useActionExportTable from 'src/pages/actions/update/useActionExportTable';
import useControlsExportTable from 'src/pages/controls/update/useControlsExportTable';
import useIssuesExportTable from 'src/pages/issues/update/useIssuesExportTable';

import {
  Parent_Type_Enum,
  useGetObligationByIdLazyQuery,
} from '@/generated/graphql';
import { getFriendlyId } from '@/utils/friendlyId';
import { getContributorValue } from '@/utils/pdf/contributorValue';
import { getDepartmentsValue } from '@/utils/pdf/departmentValue';
import { createDocument } from '@/utils/pdf/document';
import { download } from '@/utils/pdf/downloader';
import { createField } from '@/utils/pdf/field';
import { createHeading, createSubHeading } from '@/utils/pdf/headings';
import { getOwnerValue } from '@/utils/pdf/ownerValue';
import { createTable } from '@/utils/pdf/table';
import { optionalTableSection } from '@/utils/pdf/tableSection';
import { getTagsValue } from '@/utils/pdf/tagsValue';
import { twoColumns } from '@/utils/pdf/twoColumns';
import useCustomAttributeDataForExport from '@/utils/pdf/useCustomAttributeDataForExport';

import useAssessmentsExportTable from './useAssessmentsExportTable';
import useComplianceMonitoringAssessmentsExportTable from './useComplianceMonitoringAssessmentsExportTable';
import useImpactsExportTable from './useImpactsExportTable';
import useInternalAuditAssessmentsExportTable from './useInternalAuditAssessmentsExportTable';

const useExporter = (
  obligationId: string,
  includeInternalAudit: boolean,
  includeComplianceMonitoring: boolean
): [() => void, { loading: boolean }] => {
  const { getLabel: getAdherenceLabel } = useRating('adherence');
  const [getCustomAttribute, customAttributesLoading] =
    useCustomAttributeDataForExport(Parent_Type_Enum.Obligation);

  const [createControlsTable, controlsLoading] =
    useControlsExportTable(obligationId);
  const [createActionTable, actionsLoading] =
    useActionExportTable(obligationId);
  const [createImpactsTable, impactsLoading] =
    useImpactsExportTable(obligationId);
  const [createAssessmentsTable, assessmentsLoading] =
    useAssessmentsExportTable(obligationId);
  const [createComplianceAssessmentsTable, complianceAssessmentsLoading] =
    useComplianceMonitoringAssessmentsExportTable(obligationId);
  const [createInternalAuditsTable, internalAuditsLoading] =
    useInternalAuditAssessmentsExportTable(obligationId);

  const [createIssueTable, issuesLoading] = useIssuesExportTable(
    obligationId,
    Parent_Type_Enum.Issue
  );
  const { t: obligationFields } = useTranslation(['common'], {
    keyPrefix: 'obligations.fields',
  });
  const { t } = useTranslation(['common']);
  const { t: fields } = useTranslation(['common'], { keyPrefix: 'fields' });
  const types = obligationFields('types', { returnObjects: true });

  const [getObligation, getObligationResult] = useGetObligationByIdLazyQuery({
    variables: {
      _eq: obligationId,
    },
  });

  const loading =
    getObligationResult.loading ||
    customAttributesLoading ||
    actionsLoading ||
    controlsLoading ||
    issuesLoading ||
    impactsLoading ||
    assessmentsLoading ||
    complianceAssessmentsLoading ||
    internalAuditsLoading;
  const exportFunc = async () => {
    const { data: obligationData } = await getObligation();
    const createActionTablePromise = createActionTable();
    const createControlsTablePromise = createControlsTable();
    const createIssueTablePromise = createIssueTable();
    const createImpactsTablePromise = createImpactsTable();
    const createAssessmentsTablePromise = createAssessmentsTable();
    let complianceAssessmentsTable = createTable({ body: [[]] });
    if (includeComplianceMonitoring) {
      const createComplianceAssessmentsTablePromise =
        createComplianceAssessmentsTable();
      complianceAssessmentsTable =
        await createComplianceAssessmentsTablePromise;
    }

    let internalAuditsTable = createTable({ body: [[]] });
    if (includeInternalAudit) {
      const createInternalAuditsTablePromise = createInternalAuditsTable();
      internalAuditsTable = await createInternalAuditsTablePromise;
    }

    const obligation = obligationData?.obligation?.[0];
    const actionTable = await createActionTablePromise;
    const controlsTable = await createControlsTablePromise;
    const issueTable = await createIssueTablePromise;
    const impactTable = await createImpactsTablePromise;
    const assessmentsTable = await createAssessmentsTablePromise;

    if (!obligation) {
      return;
    }

    const title = `${obligation.Title} (${getFriendlyId(
      Parent_Type_Enum.Obligation,
      obligation.SequentialId
    )})`;

    const detailFields = [
      createField(fields('Title'), obligation.Title),

      createField(
        obligationFields('Type'),
        types[String(obligation.Type) as keyof typeof types]
      ),
      createField(
        obligationFields('ParentId'),
        obligation.Parent?.Title ?? '-'
      ),
      createField(fields('Description'), obligation.Description),
      createField(
        obligationFields('Interpretation'),
        obligation.Interpretation
      ),
      createField(
        obligationFields('Adherence'),
        getAdherenceLabel(obligation.Adherence)
      ),
      createField(fields('Owner'), getOwnerValue(obligation)),
      createField(fields('Contributor'), getContributorValue(obligation)),
      createField(fields('Tags'), getTagsValue(obligation)),
      createField(fields('Departments'), getDepartmentsValue(obligation)),
      ...(await getCustomAttribute(obligation)),
    ];

    const docDefinition = createDocument(title, [
      createHeading(title),
      createSubHeading(t('details')),
      twoColumns(detailFields),
      optionalTableSection(t('impacts.tab_title'), impactTable),
      optionalTableSection(t('controls.tab_title'), controlsTable),
      optionalTableSection(t('performance'), assessmentsTable),
      optionalTableSection(
        t('obligationsAssessments.complianceMonitoringRatingSubheading'),
        complianceAssessmentsTable
      ),
      optionalTableSection(
        t('obligationsAssessments.internalAuditRatingSubheading'),
        internalAuditsTable
      ),

      optionalTableSection(t('actions.tab_title'), actionTable),
      optionalTableSection(t('issues.tab_title'), issueTable),
    ]);
    download(docDefinition);
  };

  return [exportFunc, { loading }];
};

export default useExporter;
