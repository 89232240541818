import type { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { CustomisableFormContext } from '@/components/Form/Form/CustomisableFormContext';
import type { FormContextProps } from '@/components/Form/Form/types';
import { Parent_Type_Enum } from '@/generated/graphql';

import type { AssessmentTypeEnum } from '../../../assessments/useAssessmentTypeConfig';
import TestResultFormFields from './TestResultFormFields';
import type { TestResultFormFieldsData } from './testResultSchema';
import { TestResultFormSchema } from './testResultSchema';

export type Props = Omit<
  FormContextProps<TestResultFormFieldsData>,
  'formId' | 'i18n' | 'parentType' | 'schema'
> & {
  disableControlSelect?: boolean;
  assessmentMode: AssessmentTypeEnum;
  beforeFieldsSlot?: ReactNode;
};

const getParentType = (assessmentMode?: AssessmentTypeEnum) => {
  switch (assessmentMode) {
    case 'rating':
      return Parent_Type_Enum.TestResult;
    case 'internal_audit_report':
      return Parent_Type_Enum.InternalAuditReportTestResult;
    case 'compliance_monitoring_assessment':
      return Parent_Type_Enum.SecondLineAssessmentTestResult;
  }

  return Parent_Type_Enum.TestResult;
};

const TestResultForm: FC<Props> = ({ beforeFieldsSlot, ...props }) => {
  const { t } = useTranslation('common');

  return (
    <CustomisableFormContext
      {...props}
      schema={TestResultFormSchema}
      i18n={t('testResults')}
      formId={'test-result-form'}
      parentType={getParentType(props.assessmentMode)}
    >
      {beforeFieldsSlot}
      <TestResultFormFields
        readOnly={props.readOnly}
        disableControlSelect={props.disableControlSelect}
      />
    </CustomisableFormContext>
  );
};

export default TestResultForm;
