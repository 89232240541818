import type { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import ControlledSelect from '@/components/Form/ControlledSelect';

import type { UserDetailsFormFields } from './UserDetailsSchema';

interface Props {
  roleOptions: {
    id: string;
    value: string;
    description?: string;
  }[];
  readOnly?: boolean;
}

const UserDetailsForm: FC<Props> = ({ roleOptions, readOnly }) => {
  const { control } = useFormContext<UserDetailsFormFields>();
  const { t } = useTranslation(['common'], {
    keyPrefix: 'userSettings',
  });

  return (
    <>
      <ControlledSelect
        forceRequired={true}
        key={'type'}
        filteringType={'auto'}
        label={t('fields.role')}
        name={'Role'}
        description={t('fields.role_help')}
        placeholder={'Select'}
        control={control}
        options={roleOptions}
        disabled={readOnly}
      />
    </>
  );
};

export default UserDetailsForm;
