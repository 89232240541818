import type { TabsProps } from '@cloudscape-design/components/tabs';
import i18n from '@risksmart-app/i18n/i18n';
import { useTranslation } from 'react-i18next';

import { filterEmptyTabs } from '@/utils/tabUtils';
import {
  enterpriseRiskDetailsUrl,
  enterpriseRiskRiskRegisterUrl,
} from '@/utils/urls';

import RiskCreateTab from '../create/tabs/CreateTab';
import type { EnterpriseRiskFields } from '../types';
import DetailsTab from './tabs/DetailsTab';
import RiskRegisterTab from './tabs/RiskRegisterTab';

export const useTabs = (
  enterpriseRisk: EnterpriseRiskFields | undefined = undefined
) => {
  const { t } = useTranslation(['common']);
  const detailsOnly = !enterpriseRisk;

  const tabs: TabsProps.Tab[] = [
    {
      label: t('details'),
      id: 'details',
      content: detailsOnly ? <RiskCreateTab /> : <DetailsTab />,
      href: detailsOnly
        ? undefined
        : enterpriseRiskDetailsUrl(enterpriseRisk.Id),
    },
    {
      label: i18n.format(t('entity_other'), 'capitalize'),
      disabled: detailsOnly,
      id: 'risks',
      content: <RiskRegisterTab />,
      href: detailsOnly
        ? undefined
        : enterpriseRiskRiskRegisterUrl(enterpriseRisk.Id),
    },
  ];

  return filterEmptyTabs(tabs);
};
