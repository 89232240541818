import type { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import ControlledDatePicker from '@/components/Form/ControlledDatePicker';
import { ControlledFileUpload } from '@/components/Form/ControlledFileUpload/ControlledFileUpload';
import ControlledInput from '@/components/Form/ControlledInput';
import ControlledTextarea from '@/components/Form/ControlledTextarea';
import ConditionalField from '@/components/Form/Form/CustomisableForm/ConditionalField';
import CustomisableForm from '@/components/Form/Form/CustomisableForm/CustomisableForm';
import FieldGroup from '@/components/Form/Form/CustomisableForm/FieldGroup';
import { Indicator_Type_Enum } from '@/generated/graphql';

import type { IndicatorResultFormFields } from './indicatorResultSchema';

interface Props {
  readOnly?: boolean;
  indicatorType: Indicator_Type_Enum | undefined;
}

const enum TestIds {
  Files = 'files',
}

const IndicatorResultsForm: FC<Props> = ({ readOnly, indicatorType }) => {
  const { control } = useFormContext<IndicatorResultFormFields>();
  const { t: st } = useTranslation(['common'], {
    keyPrefix: 'indicator_results.fields',
  });
  const { t } = useTranslation(['common'], {
    keyPrefix: 'fields',
  });

  return (
    <CustomisableForm readOnly={readOnly}>
      <FieldGroup key={'resultsValuesGroup'}>
        <ConditionalField
          condition={indicatorType === Indicator_Type_Enum.Text}
          key={'targetValueTxt'}
        >
          <ControlledInput
            forceRequired={true}
            key={'targetValueTxt'}
            name={'TargetValueTxt'}
            label={st('text_result')}
            control={control}
            placeholder={st('result_placeholder')}
            disabled={readOnly}
          />
        </ConditionalField>
        <ConditionalField
          condition={indicatorType === Indicator_Type_Enum.Number}
          key={'targetValueNum'}
        >
          <ControlledInput
            forceRequired={true}
            key={'targetValueNum'}
            name={'TargetValueNum'}
            label={st('num_result')}
            control={control}
            placeholder={st('result_placeholder')}
            disabled={readOnly}
            type={'number'}
          />
        </ConditionalField>
      </FieldGroup>
      <ControlledDatePicker
        forceRequired={true}
        key={'resultDate'}
        name={'ResultDate'}
        label={st('date')}
        control={control}
        disabled={readOnly}
      />
      <ControlledTextarea
        key={'description'}
        name={'Description'}
        label={st('description')}
        placeholder={st('description_placeholder')}
        control={control}
        disabled={readOnly}
      />

      <ControlledFileUpload
        key={'attachFiles'}
        label={t('newFiles')}
        control={control}
        name={'newFiles'}
        saveFilesName={'files'}
        testId={TestIds.Files}
        disabled={readOnly}
      />
    </CustomisableForm>
  );
};

export default IndicatorResultsForm;
