import type { AggregateType } from '@risksmart-app/shared/reporting/schema';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import type { TypedCustomDatasource } from 'src/pages/reporting/types';
import {
  getChartYFields,
  getCustomDatasourceFields,
  getFieldDefinition,
  getFieldFromValue,
} from 'src/pages/reporting/update/utils';

import ControlledSelect from '@/components/Form/ControlledSelect';
import { useGetCustomDatasourcesQuery } from '@/generated/graphql';

import { useCustomAttributeLookup } from '../useCustomAttributeLookup';
import { getAggregateTypeOptions } from './aggregationTypeOptions';
import { getChartTypeConfig, getChartTypeOptions } from './chartTypesOptions';
import type { ChartType } from './customDataSourceWidgetSettingsSchema';
import { type CustomDataSourceWidgetSettings } from './customDataSourceWidgetSettingsSchema';
import { datePrecisionOptions } from './datePrecisionOptions';

export const CustomDataSourceWidgetSettingsFormFields = () => {
  const { t } = useTranslation('common', {
    keyPrefix: 'dashboard.widgetSettings.fields',
  });
  const { customAttributeSchemaLookup } = useCustomAttributeLookup();
  const { control, watch, setValue } =
    useFormContext<CustomDataSourceWidgetSettings>();
  const chartType = watch('chartType');
  const x1FieldId = watch('x1FieldId');
  const yFieldId = watch('yFieldId');
  const chartTypeConfig = getChartTypeConfig();
  const customDataSourceId = watch('customDataSourceId');
  const aggregationType = watch('aggregationType');
  const { data: customDatasourceResponse, loading } =
    useGetCustomDatasourcesQuery({
      fetchPolicy: 'no-cache',
    });
  const customDatasources: TypedCustomDatasource[] =
    customDatasourceResponse?.custom_datasource ?? [];
  const selectedCustomDatasource = customDatasources.find(
    (cd) => cd.Id === customDataSourceId
  );

  if (!customAttributeSchemaLookup) {
    return <div></div>;
  }

  const x1FieldDefinition =
    selectedCustomDatasource && x1FieldId
      ? getFieldDefinition(
          selectedCustomDatasource?.Datasources,
          getFieldFromValue(x1FieldId),
          customAttributeSchemaLookup
        )
      : null;

  return (
    <div>
      <ControlledSelect
        placeholder={t('dataSourcePlaceholder')}
        addEmptyOption={true}
        options={customDatasources.map((cd) => ({
          value: cd.Id,
          label: cd.Title,
        }))}
        onChange={() => {
          setValue('x1FieldId', null);
          setValue('x2FieldId', null);
          setValue('yFieldId', null);
          setValue('aggregationType', null);
          setValue('yFieldId', null);
        }}
        statusType={loading ? 'loading' : 'finished'}
        testId={'dataSource'}
        name={'customDataSourceId'}
        label={t('dataSource')}
        control={control}
      />
      <ControlledSelect
        onChange={(value) => {
          const chartConfig = value && chartTypeConfig[value as ChartType];
          if (!chartConfig || !chartConfig.category) {
            setValue('x1FieldId', null);
          }
          if (!chartConfig || !chartConfig.aggregation) {
            setValue('aggregationType', null);
            setValue('yFieldId', null);
          }
        }}
        testId={'chartType'}
        addEmptyOption={true}
        placeholder={t('chartTypePlaceholder')}
        options={getChartTypeOptions()}
        name={'chartType'}
        label={t('chartType')}
        control={control}
      />
      {selectedCustomDatasource && chartType && (
        <>
          {chartTypeConfig[chartType].category && (
            <>
              <ControlledSelect
                placeholder={t('categoryPlaceholder')}
                addEmptyOption={true}
                onChange={(value) => {
                  const x1FieldDefinition =
                    selectedCustomDatasource && value
                      ? getFieldDefinition(
                          selectedCustomDatasource?.Datasources,
                          getFieldFromValue(value as string),
                          customAttributeSchemaLookup
                        )
                      : null;
                  if (x1FieldDefinition?.dataType !== 'date') {
                    setValue('x1FieldDatePrecision', null);
                  }
                }}
                testId={'category'}
                options={getCustomDatasourceFields(
                  selectedCustomDatasource,
                  customAttributeSchemaLookup
                )}
                name={'x1FieldId'}
                label={t('category')}
                control={control}
              />
              {x1FieldDefinition && x1FieldDefinition.dataType === 'date' && (
                <ControlledSelect
                  placeholder={t('datePrecisionPlaceholder')}
                  addEmptyOption={true}
                  testId={'datePrecision'}
                  options={datePrecisionOptions}
                  name={'x1FieldDatePrecision'}
                  label={t('datePrecision')}
                  control={control}
                />
              )}

              <>
                <ControlledSelect
                  placeholder={t('subCategoryPlaceholder')}
                  addEmptyOption={true}
                  testId={'subCategory'}
                  options={getCustomDatasourceFields(
                    selectedCustomDatasource,
                    customAttributeSchemaLookup
                  )}
                  name={'x2FieldId'}
                  label={t('subCategory')}
                  control={control}
                />
              </>
            </>
          )}

          {chartTypeConfig[chartType].aggregation && (
            <ControlledSelect
              addEmptyOption={true}
              testId={'aggregationType'}
              placeholder={t('aggregateFunctionPlaceholder')}
              options={getAggregateTypeOptions()}
              name={'aggregationType'}
              label={t('aggregateFunction')}
              control={control}
              onChange={(aggregationType) => {
                if (aggregationType) {
                  const newYFields = getChartYFields(
                    selectedCustomDatasource,
                    aggregationType as AggregateType,
                    customAttributeSchemaLookup
                  );
                  const yFieldAllowed = newYFields.find(
                    (y) => y.value === yFieldId
                  );
                  if (!yFieldAllowed) {
                    setValue('yFieldId', null);
                  }
                } else {
                  setValue('yFieldId', null);
                }
              }}
            />
          )}
          {aggregationType && (
            <ControlledSelect
              placeholder={t('aggregateFieldPlaceholder')}
              addEmptyOption={true}
              options={getChartYFields(
                selectedCustomDatasource,
                aggregationType,
                customAttributeSchemaLookup
              )}
              testId={'aggregateField'}
              name={'yFieldId'}
              label={t('aggregateField')}
              control={control}
            />
          )}
        </>
      )}
    </div>
  );
};
