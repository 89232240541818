import type React from 'react';
import { useCallback } from 'react';

import type { Category, CategoryType } from '../../../types';

export const useBarClickthrough = <T, K extends CategoryType>(
  ref: React.RefObject<HTMLDivElement>,
  categories: Category<T, K>[],
  cb: (category: Category<T, K>) => Promise<void> | void
) => {
  // On click, detect which bar the user is hovering over.
  return useCallback(() => {
    if (categories.length < 1) {
      return;
    }
    const groups = ref.current?.querySelectorAll(`svg g g[role=group]`);
    if (!groups) {
      return;
    }

    const chartGroup = Array.from(groups).findLast(() => true);

    if (!chartGroup) {
      return;
    }

    const selectedXValues = Array.from(chartGroup?.children).map((child) => {
      return child.ariaLabel?.split(',')?.[0];
    });

    if (Array.from(new Set(selectedXValues)).length !== 1) {
      return;
    }

    const selectedXValue = selectedXValues[0];

    const selectedCategory = categories.find((c) => c.label === selectedXValue);

    if (!selectedCategory) {
      return;
    }
    cb(selectedCategory);
  }, [categories, ref, cb]);
};
