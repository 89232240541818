import type { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { CustomisableFormContext } from '@/components/Form/Form/CustomisableFormContext';
import type { FormContextProps } from '@/components/Form/Form/types';
import { Parent_Type_Enum } from '@/generated/graphql';

import type { AssessmentTypeEnum } from '../useAssessmentTypeConfig';
import ObligationAssessmentResultFormFields from './ObligationAssessmentResultFormFields';
import type { ObligationAssessmentResultFormDataFields } from './obligationAssessmentResultSchema';
import { ObligationAssessmentResultSchema } from './obligationAssessmentResultSchema';

// TODO: possible change for Pick or just create new props
type Props = Omit<
  FormContextProps<ObligationAssessmentResultFormDataFields>,
  'formId' | 'i18n' | 'parentType' | 'schema'
> & {
  beforeFieldsSlot?: ReactNode;
  showSelector?: AssessmentTypeEnum;
  assessmentMode: AssessmentTypeEnum;
  disableObligationSelector: boolean;
};

const getParentType = (assessmentMode?: AssessmentTypeEnum) => {
  switch (assessmentMode) {
    case 'rating':
      return Parent_Type_Enum.ObligationAssessmentResult;
    case 'internal_audit_report':
      return Parent_Type_Enum.InternalAuditReportObligationAssessmentResult;
    case 'compliance_monitoring_assessment':
      return Parent_Type_Enum.SecondLineAssessmentObligationAssessmentResult;
  }

  return Parent_Type_Enum.ObligationAssessmentResult;
};

const ObligationAssessmentResultForm: FC<Props> = (props) => {
  const { t } = useTranslation('common');

  return (
    <CustomisableFormContext
      {...props}
      schema={ObligationAssessmentResultSchema}
      i18n={t('assessmentResults')}
      formId={'assessment-result-form'}
      renderTemplate={props.renderTemplate}
      parentType={getParentType(props.assessmentMode)}
    >
      {' '}
      {props.beforeFieldsSlot}
      <ObligationAssessmentResultFormFields
        readOnly={props.readOnly}
        disableObligationSelector={props.disableObligationSelector}
        showSelector={props.showSelector}
      />
    </CustomisableFormContext>
  );
};

export default ObligationAssessmentResultForm;
