import { useTranslation } from 'react-i18next';

import { CustomisableFormContext } from '@/components/Form/Form/CustomisableFormContext';
import { PageWrapper } from '@/components/Form/Form/PageWrapper';
import type { FormContextProps } from '@/components/Form/Form/types';
import { Parent_Type_Enum } from '@/generated/graphql';

import { ThirdPartyFormFields } from './ThirdPartyFormFields';
import type { ThirdPartyFormData } from './thirdPartySchema';
import { defaultValues, thirdPartyFormSchema } from './thirdPartySchema';

export type Props = Omit<
  FormContextProps<ThirdPartyFormData>,
  | 'defaultValues'
  | 'formId'
  | 'i18n'
  | 'mapPreviewedChanges'
  | 'parentType'
  | 'renderTemplate'
  | 'schema'
>;

export const ThirdPartyForm = ({ ...props }: Props) => {
  const { t } = useTranslation();

  return (
    <CustomisableFormContext
      {...props}
      values={props.values}
      formId={'third-party-form'}
      defaultValues={defaultValues}
      i18n={t('third_party')}
      header={t('details')}
      renderTemplate={(renderProps) => <PageWrapper {...renderProps} />}
      parentType={Parent_Type_Enum.ThirdParty}
      schema={thirdPartyFormSchema}
    >
      <ThirdPartyFormFields />
    </CustomisableFormContext>
  );
};
