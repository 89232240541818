import { useOrgScopedLocalStorage } from '@/hooks/useOrgScopedLocalStorage';

import type { TablePreferences, TablePropsWithActions } from '../types';
import { useFiltersFromDBAndUrlHash } from './useFiltersFromDBAndUrlHash';
import type { UseGetTablePropsOptions } from './useGetStatelessTableProps';
import { useGetStatelessTableProps } from './useGetStatelessTableProps';

export function useGetTableProps<T extends Record<string, unknown>>(
  options: UseGetTablePropsOptions<T>
): TablePropsWithActions<T> {
  const {
    sortingState,
    setSortingState,
    propertyFilter,
    setPropertyFilter,
    loading,
  } = useFiltersFromDBAndUrlHash<T>({
    fields: options.fields,
    tableId: options.tableId,
  });

  const [preferences, setPreferences] = useOrgScopedLocalStorage<
    TablePreferences<T> | undefined
  >(undefined, {
    localStorageKey: options.preferencesStorageKey,
  });

  return useGetStatelessTableProps({
    ...options,
    sortingState,
    setSortingState,
    propertyFilter,
    setPropertyFilter,
    preferences,
    setPreferences,
    loadingFilters: loading,
  });
}
