import { useRating } from '@risksmart-app/components/hooks/useRating';
import { useTranslation } from 'react-i18next';
import { MAX_COL_WIDTH } from 'src/App.config';

import type { CustomAttributeSchema } from '@/components/Form/CustomAttributes/CustomAttributeSchema';
import Link from '@/components/Link';
import SimpleRatingBadge from '@/components/SimpleRatingBadge';
import { useGetOwnersFieldConfig } from '@/utils/table/hooks/useGetOwnersFieldConfig';
import { useGetTableProps } from '@/utils/table/hooks/useGetTableProps';
import type { TableFields, TablePropsWithActions } from '@/utils/table/types';
import { dateColumn } from '@/utils/table/utils/dateColumn';
import { impactDetailsUrl } from '@/utils/urls';

import { getPerformanceRatingFromPerformanceScore } from './ratings/performanceCalculation';
import type { Impact, ImpactTableFields } from './types';
import { useLabelledFields } from './useLabelledFields';

const useGetFieldConfig = (): TableFields<ImpactTableFields> => {
  const allOwners = useGetOwnersFieldConfig<ImpactTableFields>();
  const { t: stc } = useTranslation(['common'], { keyPrefix: 'columns' });
  const { t: st } = useTranslation(['common'], {
    keyPrefix: 'impacts.columns',
  });
  const { getByValue: getImpactPerformanceByValue } =
    useRating('impact_performance');

  return {
    SequentialIdLabel: { header: stc('id'), sortingField: 'SequentialId' },
    Name: {
      header: st('Name'),
      cell: (item) => (
        <Link variant={'secondary'} href={impactDetailsUrl(item.Id)}>
          {item.Name}
        </Link>
      ),
      maxWidth: MAX_COL_WIDTH,
      isRowHeader: true,
    },

    Rationale: {
      header: st('Rationale'),
      cell: (item) => item.Rationale ?? '-',
      maxWidth: MAX_COL_WIDTH,
    },
    allOwners,
    PerformanceScore: {
      header: st('PerformanceScore'),
      cell: (item) => {
        const rating = getImpactPerformanceByValue(
          getPerformanceRatingFromPerformanceScore(item.PerformanceScore)
        );

        return (
          <SimpleRatingBadge
            rating={{
              ...rating,
              label: item.PerformanceScore?.toString() ?? '',
              tooltip: rating?.label,
            }}
          />
        );
      },
    },
    RatedItems: {
      header: st('RatedItems'),
    },
    CreatedAtTimestamp: dateColumn(stc('created_on'), 'CreatedAtTimestamp'),
    Id: {
      header: stc('guid'),
    },
    ModifiedAtTimestamp: dateColumn(stc('updated_on'), 'ModifiedAtTimestamp'),
    CreatedByUser: {
      header: stc('created_by_id'),
    },
    CreatedByUserName: {
      header: stc('created_by_username'),
    },
  };
};

export const useGetCollectionTableProps = (
  records: Impact[] | undefined,
  customAttributeSchema?: CustomAttributeSchema | null
): TablePropsWithActions<ImpactTableFields> => {
  const { t: st } = useTranslation(['common'], { keyPrefix: 'impacts' });
  const fields = useGetFieldConfig();

  const labelledFields = useLabelledFields(records);

  return useGetTableProps({
    tableId: 'impactRegister',
    data: labelledFields,
    customAttributeSchema,
    entityLabel: st('entity_name'),
    emptyCollectionAction: <></>,
    preferencesStorageKey: 'ImpactRegisterTable-Preferences',
    enableFiltering: true,
    initialColumns: ['Name', 'Rationale', 'allOwners', 'RatedItems'],
    fields,
  });
};
