import Table from '@risksmart-app/components/Table';
import type { FC } from 'react';
import { useGetCollectionStatelessTableProps } from 'src/pages/actions/config';
import type { ActionFields } from 'src/pages/actions/types';

import type { CustomAttributeSchema } from '@/components/Form/CustomAttributes/CustomAttributeSchema';

interface Props {
  loading: boolean;
  records: ActionFields[] | undefined;
  customAttributeSchema?: CustomAttributeSchema | null;
}

const AssessmentActionRegister: FC<Props> = ({
  loading,
  records,
  customAttributeSchema,
}) => {
  const tableProps = useGetCollectionStatelessTableProps(
    records,
    customAttributeSchema
  );

  return <Table variant={'embedded'} {...tableProps} loading={loading} />;
};

export default AssessmentActionRegister;
