import type { JsonSchema7, UISchemaElement } from '@jsonforms/core';

import type { FieldRendererProps } from './Renderers/CollectionLayouts/TableCollectionRenderers';
import TableCollectionRenderers, {
  jsonFormsDispatchRenderer,
} from './Renderers/CollectionLayouts/TableCollectionRenderers';

/**
 * Retrieve a list of field configs for a give custom attribute schema
 * @param schema
 * @param uiSchema
 * @returns
 */
export const getCustomAttributeRenderProps = (
  schema: JsonSchema7,
  uiSchema: UISchemaElement
): FieldRendererProps[] => {
  const renderers = TableCollectionRenderers;

  return jsonFormsDispatchRenderer({
    renderers,
    rootSchema: schema,
    schema,
    uischema: uiSchema,
  });
};
