import { InternalAuditUpdateBreadcrumb, InternalAuditUpdatePage } from '@pages';
import i18n from '@risksmart-app/i18n/i18n';
import type { RouteObject } from 'react-router';

import { IssueTypeMapping } from '@/utils/issueVariantUtils';

import OrgFeatureFlaggedRoute from '../rbac/OrgFeatureFlaggedRoute';
import ProtectedRoute from '../rbac/ProtectedRoute';
import { actionRoute } from './actionRoutes.config';
import { internalAuditReportsRoute } from './internalAuditReportRoutes.config';
import { issueRoute } from './issueRoutes.config';

export const internalAuditRoute: RouteObject = {
  path: ':internalAuditId',
  handle: {
    breadcrumb: InternalAuditUpdateBreadcrumb,
  },
  children: [
    {
      path: '',
      element: (
        <ProtectedRoute
          permission={'read:internal_audit_entity'}
          canHaveAccessAsContributor={true}
        >
          <InternalAuditUpdatePage activeTabId={'details'} />
        </ProtectedRoute>
      ),
    },
    {
      path: 'reports',
      handle: {
        title: () =>
          i18n.format(i18n.t('internal_audit_report_other'), 'capitalize'),
      },
      children: [
        {
          path: '',
          element: (
            <ProtectedRoute
              permission={'read:internal_audit_entity'}
              canHaveAccessAsContributor={true}
            >
              <InternalAuditUpdatePage activeTabId={'reports'} />
            </ProtectedRoute>
          ),
        },
        internalAuditReportsRoute,
      ],
    },
    ...Object.entries(IssueTypeMapping).map(([_, itm]) => ({
      path: itm.uriPath,
      handle: {
        title: () => i18n.format(i18n.t(itm.entityLabelOther), 'capitalize'),
      },
      children: [
        {
          index: true,
          element: (
            <OrgFeatureFlaggedRoute featureFlag={'policy'}>
              <ProtectedRoute
                permission={'read:document'}
                canHaveAccessAsContributor={true}
              >
                <InternalAuditUpdatePage activeTabId={itm.taxonomy} />
              </ProtectedRoute>
            </OrgFeatureFlaggedRoute>
          ),
        },
        issueRoute(itm.type),
      ],
    })),
    {
      path: 'actions',
      handle: {
        title: () => i18n.format(i18n.t('action_other'), 'capitalize'),
      },
      children: [
        {
          path: '',
          element: (
            <ProtectedRoute
              permission={'read:internal_audit_entity'}
              canHaveAccessAsContributor={true}
            >
              <InternalAuditUpdatePage activeTabId={'actions'} />
            </ProtectedRoute>
          ),
        },
        actionRoute,
      ],
    },
    {
      path: 'risks',
      handle: {
        title: () => i18n.format(i18n.t('risk_other'), 'capitalize'),
      },
      children: [
        {
          path: '',
          element: (
            <ProtectedRoute
              permission={'read:internal_audit_entity'}
              canHaveAccessAsContributor={true}
            >
              <InternalAuditUpdatePage activeTabId={'risks'} />
            </ProtectedRoute>
          ),
        },
        internalAuditReportsRoute,
      ],
    },
  ],
};
