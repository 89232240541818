import { useRating } from '@risksmart-app/components/hooks/useRating';
import { useTranslation } from 'react-i18next';
import { useHasPermission } from 'src/rbac/useHasPermission';

import type { CustomAttributeSchema } from '@/components/Form/CustomAttributes/CustomAttributeSchema';
import Link from '@/components/Link';
import SimpleRatingBadge from '@/components/SimpleRatingBadge';
import { Parent_Type_Enum } from '@/generated/graphql';
import { toLocalDate } from '@/utils/dateUtils';
import { useIsFeatureVisibleToOrg } from '@/utils/featureFlags';
import { getFriendlyId } from '@/utils/friendlyId';
import { useGetTablePropsWithoutUrlHash } from '@/utils/table/hooks/useGetTablePropsWithoutUrlHash';
import type {
  DefaultSortingState,
  TableFields,
  TablePropsWithActions,
} from '@/utils/table/types';
import { impactDetailsUrl } from '@/utils/urls';

import type { AppetiteFields, AppetiteTableFields } from './types';
import { useLabelledFields } from './useLabelledFields';

const useGetFieldConfig = (): TableFields<AppetiteTableFields> => {
  const { t } = useTranslation(['common'], { keyPrefix: 'columns' });
  const { t: st } = useTranslation(['common'], {
    keyPrefix: 'appetites.columns',
  });
  const posture = useIsFeatureVisibleToOrg('posture');
  const impactsEnabled = useIsFeatureVisibleToOrg('impacts');
  const canUpdateImpact = useHasPermission('update:impact');
  const { getByValue } = useRating('risk_appetite');
  const { getByValue: getByImpactAppetiteValue } = useRating('impact_appetite');
  const { getByValue: getAppetiteStatusByValue } = useRating('appetite_status');
  const { getByValue: getLikelihoodAppetiteByValue } = useRating(
    'likelihood_appetite'
  );

  return {
    SequentialId: {
      header: t('id'),
      cell: (item) => (
        <Link href={item.Id}>
          {item.SequentialId
            ? getFriendlyId(Parent_Type_Enum.Appetite, item.SequentialId)
            : '-'}
        </Link>
      ),
      exportVal: (item) =>
        item.SequentialId
          ? getFriendlyId(Parent_Type_Enum.Appetite, item.SequentialId)
          : '-',
    },
    AppetiteType: {
      header: st('appetiteType'),
      isRowHeader: true,
    },
    EffectiveDate: {
      header: st('effectiveDate'),
      cell: (item) => toLocalDate(item.EffectiveDate),
    },
    StatusLabelled: {
      header: st('status'),
      cell: (item) => (
        <SimpleRatingBadge rating={getAppetiteStatusByValue(item.Status)} />
      ),
    },
    ...(impactsEnabled
      ? {
          ImpactName: {
            header: st('impact'),
            cell: (item) =>
              item.ImpactId ? (
                canUpdateImpact ? (
                  <Link
                    href={impactDetailsUrl(item.ImpactId ?? '#')}
                    variant={'secondary'}
                  >
                    {item.ImpactName}
                  </Link>
                ) : (
                  item.ImpactName
                )
              ) : (
                '-'
              ),
            isRowHeader: true,
          },
          ImpactAppetiteLabelled: {
            header: st('impactAppetite'),
            cell: (item) => (
              <SimpleRatingBadge
                rating={getByImpactAppetiteValue(item.ImpactAppetite)}
              />
            ),
          },
          LikelihoodAppetiteLabelled: {
            header: st('likelihoodAppetite'),
            cell: (item) => (
              <SimpleRatingBadge
                rating={getLikelihoodAppetiteByValue(item.LikelihoodAppetite)}
              />
            ),
          },
        }
      : {
          LowerAppetiteLabelled: {
            header: st('lowerAppetite'),
            cell: (item) => (
              <SimpleRatingBadge rating={getByValue(item.LowerAppetite)} />
            ),
          },
          UpperAppetiteLabelled: {
            header: posture ? st('posture') : st('upperAppetite'),
            cell: (item) => (
              <SimpleRatingBadge rating={getByValue(item.UpperAppetite)} />
            ),
          },
        }),
  };
};

export const useGetCollectionStatelessTableProps = (
  records: AppetiteFields[] | undefined,
  customAttributeSchema?: CustomAttributeSchema | null,
  defaultSortingState?: DefaultSortingState<AppetiteTableFields> | undefined
): TablePropsWithActions<AppetiteTableFields> => {
  const labelledFields = useLabelledFields(records);
  const { t: st } = useTranslation(['common'], { keyPrefix: 'appetites' });
  const fields = useGetFieldConfig();
  const posture = useIsFeatureVisibleToOrg('posture');
  const initialColumns: (keyof AppetiteTableFields)[] = [
    'SequentialId',
    'AppetiteType',
    'EffectiveDate',
    'StatusLabelled',
    'ImpactName',
    'UpperAppetiteLabelled',
    'ImpactAppetiteLabelled',
    'LikelihoodAppetiteLabelled',
  ];
  if (!posture) {
    initialColumns.push('LowerAppetiteLabelled');
  }

  return useGetTablePropsWithoutUrlHash({
    data: labelledFields,
    customAttributeSchema: customAttributeSchema,
    entityLabel: st('entity_name'),
    emptyCollectionAction: <></>,
    enableFiltering: true,
    initialColumns,
    fields,
    defaultSortingState,
  });
};
