import type React from 'react';

export const getClickedLabel = (
  ref: React.RefObject<HTMLDivElement>
): string | undefined => {
  // On click, detect which bar the user is hovering over.

  const groups = ref.current?.querySelectorAll(`svg g g[role=group]`);
  if (!groups) {
    return undefined;
  }

  const chartGroup = Array.from(groups).findLast(() => true);

  if (!chartGroup) {
    return undefined;
  }

  const selectedXValues = Array.from(chartGroup?.children).map((child) => {
    return child.ariaLabel?.split(',')?.[0];
  });

  if (Array.from(new Set(selectedXValues)).length !== 1) {
    return undefined;
  }

  const selectedXValue = selectedXValues[0];

  return selectedXValue;
};
