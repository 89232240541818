import LineChart from '@cloudscape-design/components/line-chart';
import dayjs from 'dayjs';
import lodash, { isInteger } from 'lodash';
import type { FC } from 'react';

import { NoWidgetData } from '../NoWidgetData';
import styles from './style.module.scss';

type Props = {
  loading?: boolean;
  xTitle: string;
  yTitle: string;
  values?: { x: Date; y: number }[];
};

const LineChartWidget: FC<Props> = ({ values, loading, xTitle, yTitle }) => {
  return (
    <div className={styles.chart}>
      <LineChart
        statusType={loading ? 'loading' : 'finished'}
        series={
          values
            ? [
                {
                  title: yTitle,
                  type: 'line',
                  data: values,
                  color: '#79B14F',
                },
              ]
            : []
        }
        fitHeight={true}
        height={0}
        xDomain={values?.map((v) => v.x)}
        yDomain={[
          lodash.min(values?.map((v) => v.y)) || 0,
          lodash.max(values?.map((v) => v.y)) || 0,
        ]}
        i18nStrings={{
          xTickFormatter: (e) => dayjs(e).format('D/M'),
        }}
        ariaLabel={xTitle}
        hideFilter={true}
        hideLegend={true}
        xScaleType={'categorical'}
        xTitle={xTitle}
        yTitle={yTitle}
        yTickFormatter={onlyShowIntegers}
        noMatch={<NoWidgetData />}
        empty={<NoWidgetData />}
      />
    </div>
  );
};

const onlyShowIntegers = (value: number) =>
  isInteger(value) ? value.toString() : '';

export default LineChartWidget;
