import SpaceBetween from '@cloudscape-design/components/space-between';
import Button from '@risksmart-app/components/Button';
import Table from '@risksmart-app/components/Table';
import { type FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InstantiateEnterpriseRiskModal from 'src/components/InstantiateEnterpriseRiskModal/InstantiateEnterpriseRiskModal';
import { PageLayout } from 'src/layouts';
import { Permission } from 'src/rbac/Permission';

import CustomisableRibbon from '@/components/CustomisableRibbon/CustomisableRibbon';
import {
  Parent_Type_Enum,
  useGetEnterpriseRisksQuery,
} from '@/generated/graphql';
import { getCounter } from '@/utils/collectionUtils';
import { addEnterpriseRiskUrl } from '@/utils/urls';

import { useGetCollectionTableProps } from './config';
import { defaultRibbonFilters } from './defaultRibbonFilters';
import type { EnterpriseRiskRegisterFields } from './types';

const EnterpriseRiskPage: FC = () => {
  const { t } = useTranslation(['common'], { keyPrefix: 'enterpriseRisks' });

  const { data, loading } = useGetEnterpriseRisksQuery();

  const tableProps = useGetCollectionTableProps(
    data?.enterprise_risk,
    data?.form_configuration?.[0]?.customAttributeSchema
  );

  const [selectedItems, setSelectedItems] = useState<
    Array<EnterpriseRiskRegisterFields>
  >([]);

  const [showInstantiateModal, setShowInstantiateModal] = useState(false);

  const counter = getCounter(tableProps.totalItemsCount, loading);

  return (
    <PageLayout
      title={t('registerTitle')}
      helpTranslationKey={'enterpriseRisks.registerHelp'}
      counter={counter}
      actions={
        <SpaceBetween direction={'horizontal'} size={'xs'}>
          <Permission permission={'insert:enterprise_risk'}>
            <Button variant={'primary'} href={addEnterpriseRiskUrl()}>
              {t('createNewButton')}
            </Button>
          </Permission>
          <Permission permission={'insert:enterprise_risk'}>
            <Button
              variant={selectedItems.length === 0 ? undefined : 'primary'}
              disabled={!selectedItems.length}
              onClick={() => setShowInstantiateModal(true)}
            >
              {t('instantiateButton')}
            </Button>
          </Permission>
        </SpaceBetween>
      }
    >
      <CustomisableRibbon
        items={tableProps.allItems}
        propertyFilterQuery={tableProps.propertyFilterQuery}
        onFilterQueryChanged={tableProps.actions.setPropertyFiltering}
        filteringProperties={tableProps.filteringProperties}
        filteringOptions={tableProps.propertyFilterProps.filteringOptions}
        parentType={Parent_Type_Enum.EnterpriseRisk}
        defaultFilters={defaultRibbonFilters}
      />
      <Table
        {...tableProps}
        data-testid={'enterprise-risk-table'}
        trackBy={'Id'}
        loading={loading}
        selectedItems={selectedItems}
        selectionType={'multi'}
        onSelectionChange={({ detail }) => {
          setSelectedItems(detail.selectedItems);
        }}
      />
      {showInstantiateModal && (
        <InstantiateEnterpriseRiskModal
          isVisible={showInstantiateModal}
          onDismiss={() => {
            setShowInstantiateModal(false);
          }}
          enterpriseRiskIds={selectedItems.map((si) => si.Id)}
        />
      )}
    </PageLayout>
  );
};

export default EnterpriseRiskPage;
