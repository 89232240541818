import { useRating } from '@risksmart-app/components/hooks/useRating';
import i18n from '@risksmart-app/i18n/i18n';
import _ from 'lodash';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  getAllContributorsCellValue,
  getAllOwnersCellValue,
} from 'src/rbac/contributorHelper';

import type { GetAppetitesGroupedByImpactQuery } from '@/generated/graphql';
import {
  Parent_Type_Enum,
  Risk_Assessment_Result_Control_Type_Enum,
} from '@/generated/graphql';
import { useCommonLookupLazy } from '@/hooks/useCommonLookupLazy';
import type { RiskScore } from '@/hooks/useRiskScore';
import { useRiskScoreFormatters } from '@/hooks/useRiskScore';
import { useIsFeatureVisibleToOrg } from '@/utils/featureFlags';
import { getFriendlyId } from '@/utils/friendlyId';

import { getAppetitePerformance } from '../appetites/calculateAppetitePerformance';
import { UNRATED } from '../controls/lookupData';
import { getImpactPerformanceScore } from '../impacts/ratings/performanceCalculation';
import type { RiskFields, RiskRegisterFields } from './types';

export const useGetLabelledFields = (
  records: RiskFields[] | undefined,
  riskScores: RiskScore[] | undefined,
  impactAppetites: GetAppetitesGroupedByImpactQuery['impact'] | undefined
) => {
  const { getByValue: getCommonLookupByValue } = useCommonLookupLazy();
  const { t: tt } = useTranslation('ratings');
  const riskScoreFormatters = useRiskScoreFormatters();
  const { getLabel: getLikelihoodLabel } = useRating('likelihood');
  const { getLabel: getImpactLabel } = useRating('impact');
  const { getLabel: getAppetiteLabel } = useRating('risk_appetite');
  const { getByValue: getAppetitePerformanceByValue } = useRating(
    'appetite_performance'
  );
  const posture = useIsFeatureVisibleToOrg('posture');

  return useMemo<RiskRegisterFields[] | undefined>(() => {
    return records?.map((d) => {
      const riskScore = riskScores?.find((r) => r.id === d.Id);
      const latestAppetite = d.appetites?.[0]?.appetite;

      const appetitePerformance = getAppetitePerformance({
        UpperAppetite: latestAppetite?.UpperAppetite,
        LowerAppetite: latestAppetite?.LowerAppetite,
        controlledRating: riskScore?.residualRating,
        posture,
      });

      return {
        ...d,
        TierLabelled: getCommonLookupByValue('tiers', d.Tier)?.label ?? '',
        TreatmentLabelled:
          getCommonLookupByValue('treatments', d.Treatment)?.label ?? null,
        StatusLabelled:
          getCommonLookupByValue('statuses', d.Status)?.label ?? null,
        UncontrolledRatingLabelled:
          riskScoreFormatters(riskScore).getInherentLabel() ?? UNRATED.label,
        ControlledRatingLabelled:
          riskScoreFormatters(riskScore).getResidualLabel() ?? UNRATED.label,
        UpperAppetiteLabelled: getAppetiteLabel(latestAppetite?.UpperAppetite),
        LowerAppetiteLabelled: getAppetiteLabel(latestAppetite?.LowerAppetite),
        AppetitePerformance: appetitePerformance,
        AppetitePerformanceLabelled:
          getAppetitePerformanceByValue(appetitePerformance)?.label ??
          'Undefined',
        LinkedControlCount: d.controls_aggregate.aggregate?.count || 0,
        LinkedIndicatorCount: d.indicators_aggregate.aggregate?.count || 0,
        ParentTitle:
          d.parent?.Title ??
          (d.parentNode
            ? getFriendlyId(Parent_Type_Enum.Risk, d.parentNode?.SequentialId)
            : null) ??
          null,
        UncontrolledRating: riskScore?.inherentRating ?? null,
        ControlledRating: riskScore?.residualRating ?? null,
        UncontrolledScore: riskScore?.inherentScore ?? null,
        ControlledScore: riskScore?.residualScore ?? null,
        UserName: d.createdByUser?.FriendlyName ?? null,
        ControlledLikelihoodValue: riskScore?.residualLikelihood ?? null,
        ControlledImpactValue: riskScore?.residualImpact ?? null,
        UncontrolledImpactValue: riskScore?.inherentImpact ?? null,
        UncontrolledLikelihoodValue: riskScore?.inherentLikelihood ?? null,
        UncontrolledLikelihood: getLikelihoodLabel(
          riskScore?.inherentLikelihood ?? null
        ),
        ControlledLikelihood: getLikelihoodLabel(
          riskScore?.residualLikelihood ?? null
        ),
        ControlledImpact: getImpactLabel(riskScore?.residualImpact ?? null),
        UncontrolledImpact: getImpactLabel(riskScore?.inherentImpact ?? null),
        CustomAttributeData: {
          ...(d.CustomAttributeData || {}),
          // @TODO: support custom attributes for assessment results
        },
        Owner: d.owners,
        OwnerName: d.owners,
        SequentialIdLabel: d.SequentialId
          ? getFriendlyId(Parent_Type_Enum.Risk, d.SequentialId)
          : '',
        allOwners: getAllOwnersCellValue(d),
        allContributors: getAllContributorsCellValue(d),
        ImpactPerformanceScore: getImpactPerformanceScoreWithAppetiteArray(
          d.Id,
          d.impactRatings,
          impactAppetites
        ),
        LatestRatingDate: d.scheduleState?.LatestDate ?? '-',
        NextTestDate: d.scheduleState?.DueDate ?? '-',
        NextTestOverdueDate: d.scheduleState?.OverdueDate ?? '-',
        TestFrequency:
          getCommonLookupByValue('frequency', d.schedule?.Frequency)?.label ??
          null,

        UncontrolledRatingHistory: getRatingHistory(
          d.assessmentResults ?? [],
          Risk_Assessment_Result_Control_Type_Enum.Uncontrolled
        ),
        ControlledRatingHistory: getRatingHistory(
          d.assessmentResults ?? [],
          Risk_Assessment_Result_Control_Type_Enum.Controlled
        ),
        Entity: d.enterpriseRiskInstance?.entity?.Name ?? '-',
        EnterpriseRiskLabelled: d.enterpriseRiskInstance?.enterpriseRisk
          ? i18n.format(tt('enterprise_one'), 'capitalize')
          : i18n.format(tt('legal_entity_one'), 'capitalize'),
      };
    });
  }, [
    records,
    riskScores,
    posture,
    riskScoreFormatters,
    getAppetiteLabel,
    getAppetitePerformanceByValue,
    getLikelihoodLabel,
    getImpactLabel,
    getCommonLookupByValue,
    impactAppetites,
    tt,
  ]);
};

const getImpactPerformanceScoreWithAppetiteArray = (
  Id: string,
  impactRatings: RiskFields['impactRatings'],
  impactAppetites: GetAppetitesGroupedByImpactQuery['impact'] | undefined
) =>
  impactRatings.reduce((previous, impactRating) => {
    const impactAppetite = impactAppetites?.find(
      (ia) => ia.Id === impactRating.ImpactId
    );
    const impactAppetiteForRisk = impactAppetite?.appetites.find((a) =>
      a?.parents.find((p) => p.risk?.Id === Id)
    );
    if (_.isNil(impactAppetiteForRisk?.ImpactAppetite)) {
      return previous;
    }

    const score = getImpactPerformanceScore(
      impactRating.Rating,
      impactAppetiteForRisk?.ImpactAppetite
    );

    return previous + (score || 0);
  }, 0);

const getRatingHistory = (
  assessmentResults: RiskFields['assessmentResults'],
  controlType: Risk_Assessment_Result_Control_Type_Enum
) =>
  (assessmentResults ?? [])
    .filter(
      (c) =>
        c.riskAssessmentResult &&
        c.riskAssessmentResult.ControlType === controlType
    )
    .slice(1, 7)
    .map((c) => {
      return {
        rating: c.riskAssessmentResult!.Rating ?? 0,
        id: c.riskAssessmentResult!.Id ?? '-',
        testDate: c.riskAssessmentResult!.TestDate ?? '-',
      };
    });
