import SpaceBetween from '@cloudscape-design/components/space-between';
import { type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { PageLayout } from 'src/layouts';

import type { ActionItem } from '@/components/ActionsButton/ActionsButton';
import ActionsButton from '@/components/ActionsButton/ActionsButton';
import RegisterDashboard from '@/components/RegisterDashboard/RegisterDashboard';

import type { DashboardProps } from '../types';
import MyItemsRibbon from './Ribbon';

const Dashboard: FC<DashboardProps> = ({ dashboardViewToggle }) => {
  const { t } = useTranslation(['common'], { keyPrefix: 'dashboard' });

  const actionsButtonItems: ActionItem[] = [
    {
      text: t('actions.addWidget'),
      id: 'add_widget',
      onItemClick: () => {},
    },
    {
      text: t('actions.clear'),
      id: 'clear',
      onItemClick: () => {},
    },
  ];

  return (
    <PageLayout
      title={t('my_items_page_title')}
      actions={
        <SpaceBetween size={'s'} direction={'horizontal'}>
          <div className={'flex gap-3 items-center'}>
            {dashboardViewToggle}
            <ActionsButton
              testId={'dashboardMenu'}
              items={actionsButtonItems}
              buttonText={t('actions_button')}
              variant={'normal'}
            />
          </div>
        </SpaceBetween>
      }
    >
      <RegisterDashboard>
        <MyItemsRibbon />
      </RegisterDashboard>
    </PageLayout>
  );
};

export default Dashboard;
