import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { CustomisableFormContext } from '@/components/Form/Form/CustomisableFormContext';
import type { FormContextProps } from '@/components/Form/Form/types';
import { Parent_Type_Enum } from '@/generated/graphql';

import AssessmentActivityFormFields from './AssessmentActivityFormFields';
import type { AssessmentActivityFormDataFields } from './assessmentActivitySchema';
import {
  BaseAssessmentActivitySchema,
  defaultValues,
} from './assessmentActivitySchema';

type Props = Omit<
  FormContextProps<AssessmentActivityFormDataFields>,
  'defaultValues' | 'formId' | 'i18n' | 'parentType' | 'schema'
>;

const AssessmentActivityForm: FC<Props> = (props) => {
  const { t } = useTranslation(['common']);

  return (
    <CustomisableFormContext
      {...props}
      header={t('details')}
      schema={BaseAssessmentActivitySchema}
      defaultValues={defaultValues}
      i18n={t('assessmentActivities')}
      formId={'assessment-activity-form'}
      parentType={Parent_Type_Enum.AssessmentActivity}
      renderTemplate={props.renderTemplate}
    >
      <AssessmentActivityFormFields readOnly={props.readOnly} />
    </CustomisableFormContext>
  );
};

export default AssessmentActivityForm;
