import SpaceBetween from '@cloudscape-design/components/space-between';
import Button from '@risksmart-app/components/Button';
import { PageNotFound } from '@risksmart-app/components/errors/errors';
import {
  useGetGuidParam,
  useGetOptionalGuidParam,
} from '@risksmart-app/components/routes/routes.utils';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import ControlledTabs from '@/components/ControlledTabs';
import DeleteModal from '@/components/DeleteModal';
import {
  Parent_Type_Enum,
  useDeleteAcceptancesMutation,
  useGetAcceptanceByIdQuery,
} from '@/generated/graphql';
import { useDeleteResultNotification } from '@/hooks/useMutationResultNotification';
import { getFriendlyId } from '@/utils/friendlyId';
import { evictField } from '@/utils/graphqlUtils';
import { acceptanceRegisterUrl, riskDetailsUrl } from '@/utils/urls';

import PageLayout from '../../../../layouts/PageLayout';
import { Permission } from '../../../../rbac/Permission';
import { useTabs } from '../useTabs';

const Page = () => {
  const parentId = useGetOptionalGuidParam('riskId');
  const acceptanceId = useGetGuidParam('acceptanceId');
  const navigate = useNavigate();
  const [deleteAcceptances, deleteResult] = useDeleteAcceptancesMutation({
    update: (cache) => {
      evictField(cache, 'acceptance');
      evictField(cache, 'acceptance_aggregate');
    },
  });
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

  const tabs = useTabs({ acceptanceId, parentId });
  const { t: st } = useTranslation(['common'], { keyPrefix: 'acceptances' });
  const { data } = useGetAcceptanceByIdQuery({
    variables: {
      _eq: acceptanceId,
    },
  });
  const onDelete = useDeleteResultNotification({
    entityName: st('entity_name'),
    asyncAction: async () => {
      if (!acceptanceId) {
        return false;
      }
      await deleteAcceptances({
        variables: { Ids: [acceptanceId] },
      });
      if (parentId) {
        navigate(riskDetailsUrl(parentId));
      } else {
        navigate(acceptanceRegisterUrl());
      }

      return true;
    },
  });
  const fallbackTitle = st('fallback_title');

  if (deleteResult.data?.deleteAcceptancesById?.affected_rows === 1) {
    return <></>;
  }
  if (data?.acceptance.length === 0) {
    throw new PageNotFound(`Acceptance with id ${acceptanceId} not found`);
  }
  const acceptance = data?.acceptance[0];
  const counter =
    acceptance &&
    `(${getFriendlyId(Parent_Type_Enum.Acceptance, acceptance?.SequentialId)})`;

  return (
    <PageLayout
      helpTranslationKey={'acceptances.help'}
      title={acceptance?.Title}
      meta={{ title: fallbackTitle }}
      counter={counter}
      actions={
        <SpaceBetween direction={'horizontal'} size={'xs'}>
          <Permission
            permission={'delete:acceptance'}
            parentObject={acceptance}
          >
            <Button
              variant={'normal'}
              formAction={'none'}
              onClick={() => {
                setIsDeleteModalVisible(true);
              }}
            >
              {st('delete_button')}
            </Button>
          </Permission>
        </SpaceBetween>
      }
    >
      <ControlledTabs tabs={tabs} variant={'container'} />
      <DeleteModal
        loading={deleteResult.loading}
        isVisible={isDeleteModalVisible}
        header={st('delete_modal_title')}
        onDelete={onDelete}
        onDismiss={() => setIsDeleteModalVisible(false)}
      >
        {st('confirm_single_delete_message')}
      </DeleteModal>
    </PageLayout>
  );
};

export default Page;
