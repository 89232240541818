import { useRating } from '@risksmart-app/components/hooks/useRating';
import type { ContentTable } from 'pdfmake/interfaces';
import { useTranslation } from 'react-i18next';

import { useGetAppetitesByRiskIdLazyQuery } from '@/generated/graphql';
import { toLocalDate } from '@/utils/dateUtils';
import { createTable, tableHeaders } from '@/utils/pdf/table';

const useAppetitesExportTable = (
  riskId: string
): [() => Promise<ContentTable>, boolean] => {
  const { getLabel: getRatingLabel } = useRating('risk_appetite');
  const [getAppetites, getAppetitesResult] = useGetAppetitesByRiskIdLazyQuery({
    variables: {
      riskId,
    },
  });

  const { t: appetitesColumns } = useTranslation(['common'], {
    keyPrefix: 'appetites.columns',
  });
  const createExportTable = async () => {
    const { data: appetiteData } = await getAppetites();
    const appetiteTableData = (appetiteData?.appetite_parent ?? []).map((i) => [
      toLocalDate(i.appetite?.CreatedAtTimestamp),
      getRatingLabel(i.appetite?.LowerAppetite),
      getRatingLabel(i.appetite?.UpperAppetite),
    ]);

    return createTable({
      widths: [80, 80, 80],
      body: [
        tableHeaders([
          appetitesColumns('dateSet'),
          appetitesColumns('lowerAppetite'),
          appetitesColumns('upperAppetite'),
        ]),
        ...appetiteTableData,
      ],
    });
  };

  return [createExportTable, getAppetitesResult.loading];
};

export default useAppetitesExportTable;
