import { useCollection } from '@cloudscape-design/collection-hooks';
import SpaceBetween from '@cloudscape-design/components/space-between';
import Button from '@risksmart-app/components/Button';
import { useFileUpdate } from '@risksmart-app/components/File/useFileUpdate';
import { useNotifications } from '@risksmart-app/components/Notifications/useNotifications';
import Table from '@risksmart-app/components/Table';
import type { FC } from 'react';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ownerAndContributorIds } from 'src/components/Form';
import IssueModal from 'src/pages/issues/IssueModal';
import type { IssueFormDataFields } from 'src/pages/issues/update/forms/issueSchema';
import type { ObjectWithContributors } from 'src/rbac/Permission';
import { Permission } from 'src/rbac/Permission';
import { useHasPermission } from 'src/rbac/useHasPermission';

import DeleteModal from '@/components/DeleteModal/DeleteModal';
import EmptyEntityCollection from '@/components/EmptyCollection/EmptyEntityCollection';
import { useI18NSummaryHelpContent } from '@/components/HelpPanel/useSummaryHelpContent';
import TabHeader from '@/components/TabHeader';
import {
  namedOperations,
  Parent_Type_Enum,
  useDeleteIssuesMutation,
  useGetIssuesByParentIdQuery,
  useInsertChildIssueMutation,
} from '@/generated/graphql';
import { useDeleteResultNotification } from '@/hooks/useMutationResultNotification';
import { evictField } from '@/utils/graphqlUtils';
import type { ParentIssueTypes } from '@/utils/issueVariantUtils';
import { IssueTypeMapping } from '@/utils/issueVariantUtils';

import type { IssueTableFields } from './issueTabConfig';
import { useGetIssueColumnDefinitions } from './issueTabConfig';

type Props = {
  parent: ObjectWithContributors;
  type: ParentIssueTypes;
};

const IssuesTab: FC<Props> = ({ parent, type }) => {
  const issueMapping = IssueTypeMapping[type];
  useI18NSummaryHelpContent(`${issueMapping.taxonomy}.tabHelp`);
  const { updateFiles } = useFileUpdate();
  const { addNotification } = useNotifications();
  const { t } = useTranslation(['common']);
  const { t: st } = useTranslation(['common'], {
    keyPrefix: issueMapping.taxonomy,
  });
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const issueColumnDefinitions = useGetIssueColumnDefinitions(type);
  const [selectedIssues, setSelectedIssues] = useState<IssueTableFields[]>([]);
  const canDeleteIssues = useHasPermission('delete:issue', parent);

  const [insertIssue] = useInsertChildIssueMutation({
    update: (cache) => {
      evictField(cache, 'issue');
      evictField(cache, 'issue_aggregate');
      evictField(cache, 'internal_audit_entity');
    },
    refetchQueries: [namedOperations.Query.getIssues],
  });

  const onSave = async (data: IssueFormDataFields) => {
    const { newFiles, files, ...issueData } = data;
    const result = await insertIssue({
      variables: {
        ...issueData,
        ParentId: parent.Id,
        CustomAttributeData: data.CustomAttributeData || undefined,
        ...ownerAndContributorIds(data),
        Type: type,
      },
    });
    const issueId = result.data?.insertChildIssue?.Id;
    if (!issueId) {
      throw new Error('Issue id not returned');
    }
    await updateFiles({
      parentType: Parent_Type_Enum.Issue,
      parentId: issueId,
      newFiles,
      originalFiles: [], // This is always an insert so no original files
      selectedFiles: files,
    });
  };

  const { data, loading } = useGetIssuesByParentIdQuery({
    variables: {
      ParentId: parent.Id,
      Type: type,
    },
    onError: (error) => {
      addNotification({
        type: 'error',
        content: <>{error.message}</>,
      });
    },
  });

  const [deleteIssues, deleteResult] = useDeleteIssuesMutation({
    update: (cache) => {
      evictField(cache, 'issue');
      evictField(cache, 'issue_aggregate');
    },
  });

  const handleIssueModalOpen = () => {
    setIsCreateOpen(true);
  };

  const handleIssueModalClose = () => {
    setIsCreateOpen(false);
  };

  const onDelete = useDeleteResultNotification({
    entityName: st('entity_name'),
    failureAction: () => {
      setIsDeleteModalVisible(false);
    },
    asyncAction: async () => {
      await deleteIssues({
        variables: {
          Ids: selectedIssues.map((s) => s.Id),
        },
      });
      setSelectedIssues([]);
      setIsDeleteModalVisible(false);

      return true;
    },
  });

  const labelledFields = useMemo<IssueTableFields[]>(() => {
    return (
      data?.issue.map((i) => ({
        ...i,
        TargetCloseDate: i.assessment?.TargetCloseDate ?? null,
        Severity: i.assessment?.Severity ?? null,
        Status: i.assessment?.Status ?? null,
      })) || []
    );
  }, [data?.issue]);

  const { items, collectionProps } = useCollection(labelledFields, {
    propertyFiltering: {
      filteringProperties: [],
      empty: (
        <EmptyEntityCollection
          entityLabel={st('entity_name')}
          action={
            <Permission permission={'insert:issue'} parentObject={parent}>
              <Button formAction={'none'} onClick={handleIssueModalOpen}>
                {st('add_button')}
              </Button>
            </Permission>
          }
        />
      ),
    },
    sorting: {
      defaultState: {
        sortingColumn: issueColumnDefinitions[4],
        isDescending: true,
      },
    },
  });

  return (
    <>
      <Table
        {...collectionProps}
        selectionType={canDeleteIssues ? 'multi' : undefined}
        selectedItems={selectedIssues}
        trackBy={'Id'}
        onSelectionChange={({ detail }) => {
          setSelectedIssues(detail.selectedItems);
        }}
        resizableColumns={true}
        header={
          <SpaceBetween size={'m'}>
            <TabHeader
              actions={
                <SpaceBetween direction={'horizontal'} size={'xs'}>
                  <Permission permission={'delete:issue'} parentObject={parent}>
                    <Button
                      formAction={'none'}
                      variant={'normal'}
                      disabled={!selectedIssues.length}
                      onClick={() => setIsDeleteModalVisible(true)}
                    >
                      {t('delete')}
                    </Button>
                  </Permission>
                  <Permission permission={'insert:issue'} parentObject={parent}>
                    <Button
                      variant={'primary'}
                      formAction={'none'}
                      onClick={handleIssueModalOpen}
                    >
                      {st('add_button')}
                    </Button>
                  </Permission>
                </SpaceBetween>
              }
            >
              {st('tab_title')}
            </TabHeader>
          </SpaceBetween>
        }
        variant={'embedded'}
        loading={loading}
        columnDefinitions={issueColumnDefinitions}
        items={items}
        loadingText={st('loading_message') ?? ''}
        sortingDisabled={false}
      />
      {isCreateOpen && (
        <IssueModal
          onDismiss={handleIssueModalClose}
          onSaving={onSave}
          issueType={type}
        />
      )}
      <DeleteModal
        loading={deleteResult.loading}
        isVisible={isDeleteModalVisible}
        header={t('delete')}
        onDelete={onDelete}
        onDismiss={() => {
          setSelectedIssues([]);
          setIsDeleteModalVisible(false);
        }}
      >
        {st('confirm_delete_message')}
      </DeleteModal>
    </>
  );
};

export default IssuesTab;
