import i18n from '@risksmart-app/i18n/i18n';
import _ from 'lodash';
import type { ControlTableFields } from 'src/pages/controls/types';

import { Parent_Type_Enum } from '@/generated/graphql';
import { GetControlsDocument } from '@/generated/graphql.typed';
import { controlRegisterUrl } from '@/utils/urls';

import { useGetControlSmartWidgetTableProps } from '../../../controls/config';
import { UNRATED } from '../../Gigawidget/types';
import {
  dateRangeFilter,
  departmentsFilter,
  tagsFilter,
} from '../../Gigawidget/util/filterHelpers';
import { createDataSource } from '../createDataSource';
import {
  dashboardDateClickthroughFilter,
  dateClickthroughFilter,
  defaultClickthroughFilter,
  defaultClickthroughFilterWithUnrated,
  tagAndDepartmentCategoryGetters,
} from '../dataSourceHelpers';

export default createDataSource({
  hasAccess: () => true,
  parentTypes: [Parent_Type_Enum.Control],
  documentNode: GetControlsDocument,
  useDefaultVariables: () => ({ where: {} }),
  useTablePropsHook: (data, options) =>
    useGetControlSmartWidgetTableProps(
      data?.control,
      data?.form_configuration[0]?.customAttributeSchema ?? null,
      options
    ),
  entityNamePlural: 'control_other',
  entityNameSingular: 'control_one',
  fields: 'controls.fields',
  dashboardFilterConfig: {
    tagsFilter: (tags) => ({ where: { tags: tagsFilter(tags) } }),
    departmentsFilter: (departments) => ({
      where: { departments: departmentsFilter(departments) },
    }),
    dateFilter: (dateRange, precision) => ({
      where: { CreatedAtTimestamp: dateRangeFilter(dateRange, precision) },
    }),
    dateClickthroughFilter:
      dashboardDateClickthroughFilter('CreatedAtTimestamp'),
  },
  clickThroughUrl: (filters) => controlRegisterUrl(filters),
  categoryGetters: [
    ...tagAndDepartmentCategoryGetters<ControlTableFields>(),
    {
      id: 'effectiveness',
      name: () => i18n.t('controls.columns.effectiveness'),
      categoryGetter: (item) => ({
        key: item.OverallEffectiveness ?? UNRATED,
        label: item.OverallEffectiveness
          ? item.OverallEffectivenessLabelled
          : UNRATED,
      }),
      clickthroughFilter: defaultClickthroughFilter(
        'OverallEffectivenessLabelled'
      ),
      ratingColourKey: 'effectiveness',
    },
    {
      id: 'type',
      name: () => i18n.t('controls.columns.type'),
      categoryGetter: (item) => ({
        key: item.Type ?? UNRATED,
        label: item.Type ? item.ControlTypeLabelled : 'No Type',
      }),
      clickthroughFilter: defaultClickthroughFilterWithUnrated(
        'ControlTypeLabelled'
      ),
    },
    {
      id: 'testFrequency',
      name: () => i18n.t('controls.columns.test_frequency'),
      categoryGetter: (item) => item.TestFrequency ?? UNRATED,
      clickthroughFilter: defaultClickthroughFilterWithUnrated('TestFrequency'),
    },
    {
      id: 'owner',
      name: () => i18n.t('controls.columns.owner'),
      categoryGetter: (item) =>
        item.allOwners.map((owner) => ({ key: owner.id, label: owner.label })),
      clickthroughFilter: (category) => [
        {
          propertyKey: 'allOwners',
          operator: '=',
          value: category.key,
        },
      ],
    },
    {
      id: 'createdDate',
      name: () => i18n.t('columns.created_on'),
      categoryGetter: (data) => new Date(data.CreatedAtTimestamp),
      date: true,
      dashboardDateFilterOverride: (dateRange, precision) => ({
        where: { CreatedAtTimestamp: dateRangeFilter(dateRange, precision) },
      }),
      clickthroughFilter: dateClickthroughFilter('CreatedAtTimestamp'),
    },
    {
      id: 'nextTestDate',
      name: () => i18n.t('controls.columns.next_test_date'),
      categoryGetter: (data) =>
        data.NextTestDate ? new Date(data.NextTestDate) : null,
      date: true,
      dashboardDateFilterOverride: (dateRange, precision) => ({
        where: { CreatedAtTimestamp: dateRangeFilter(dateRange, precision) },
      }),
      clickthroughFilter: dateClickthroughFilter('NextTestDate'),
    },
  ],
});
