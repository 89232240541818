import { withAuthenticationRequired } from '@auth0/auth0-react';
import useRisksmartUser from '@risksmart-app/components/hooks/useRisksmartUser';
import { isUserInOrganization } from '@risksmart-app/components/utils/authUtils';
import { Suspense } from 'react';
import { Navigate, Outlet, ScrollRestoration } from 'react-router';
import { TaxonomyProvider } from 'src/providers/TaxonomyProvider';
import { FeaturesProvider } from 'src/rbac/FeatureProvider';
import { PermissionsProvider } from 'src/rbac/PermissionProvider';

import { MessagesRequester } from '@/components/Messages/MessageRequester';
import { MessagesProvider } from '@/components/Messages/MessagesProvider';
import { logoutUrl } from '@/utils/urls';

import PageLayout from './PageLayout';

function ProtectedLayout() {
  const { user } = useRisksmartUser();

  if (!isUserInOrganization(user)) {
    console.warn('User is not part of an organisation, logging out');

    return <Navigate to={logoutUrl()} />;
  }

  return (
    <FeaturesProvider>
      <PermissionsProvider>
        <TaxonomyProvider>
          <MessagesProvider>
            <MessagesRequester>
              <>
                <ScrollRestoration />
                <Suspense fallback={<PageLayout></PageLayout>}>
                  <Outlet />
                </Suspense>
              </>
            </MessagesRequester>
          </MessagesProvider>
        </TaxonomyProvider>
      </PermissionsProvider>
    </FeaturesProvider>
  );
}

const ProtectedLayoutAuthRequired = withAuthenticationRequired(ProtectedLayout);

export default ProtectedLayoutAuthRequired;
