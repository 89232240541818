import {
  QuestionnaireTemplateUpdateBreadcrumb,
  QuestionnaireTemplateUpdatePage,
  QuestionnaireTemplateVersionCreatePage,
  QuestionnaireTemplateVersionUpdateBreadcrumb,
  QuestionnaireTemplateVersionUpdatePage,
} from '@pages';
import i18n from '@risksmart-app/i18n/i18n';
import type { RouteObject } from 'react-router';
import OrgFeatureFlaggedRoute from 'src/rbac/OrgFeatureFlaggedRoute';

export const questionnaireTemplateRoute: RouteObject = {
  path: ':questionnaireTemplateId',
  handle: {
    breadcrumb: QuestionnaireTemplateUpdateBreadcrumb,
  },
  children: [
    {
      index: true,
      element: (
        <OrgFeatureFlaggedRoute featureFlag={'questionnaire_builder'}>
          <QuestionnaireTemplateUpdatePage activeTabId={'details'} />
        </OrgFeatureFlaggedRoute>
      ),
    },
    {
      path: 'versions',
      handle: {
        title: () => i18n.format(i18n.t('versions'), 'capitalize'),
      },
      children: [
        {
          index: true,
          element: (
            <OrgFeatureFlaggedRoute featureFlag={'questionnaire_builder'}>
              <QuestionnaireTemplateUpdatePage activeTabId={'versions'} />
            </OrgFeatureFlaggedRoute>
          ),
        },
        {
          path: 'update/:questionnaireTemplateVersionId',
          handle: {
            breadcrumb: QuestionnaireTemplateVersionUpdateBreadcrumb,
          },
          children: [
            {
              index: true,
              element: (
                <OrgFeatureFlaggedRoute featureFlag={'questionnaire_builder'}>
                  <QuestionnaireTemplateVersionUpdatePage
                    activeTabId={'details'}
                  />
                </OrgFeatureFlaggedRoute>
              ),
            },
          ],
        },
        {
          path: 'create',
          handle: {
            title: () => i18n.format(i18n.t('create_version'), 'capitalize'),
          },
          element: (
            <OrgFeatureFlaggedRoute featureFlag={'questionnaire_builder'}>
              <QuestionnaireTemplateVersionCreatePage />
            </OrgFeatureFlaggedRoute>
          ),
        },
      ],
    },
  ],
};
