import { useFileUpdate } from '@risksmart-app/components/File/useFileUpdate';
import type { FC } from 'react';
import { useHasPermission } from 'src/rbac/useHasPermission';

import { ModalWrapper } from '@/components/Form/Form/ModalWrapper';
import {
  Parent_Type_Enum,
  useGetControlByIdQuery,
  useGetTestResultByIdQuery,
  useInsertControlTestResultMutation,
  useUpdateTestResultMutation,
} from '@/generated/graphql';
import { evictField } from '@/utils/graphqlUtils';

import type { AssessmentTypeEnum } from '../../../assessments/useAssessmentTypeConfig';
import TestResultForm from '../forms/TestResultForm';
import type { TestResultFormFieldsData } from '../forms/testResultSchema';
import { defaultValues } from '../forms/testResultSchema';

type Props = {
  onDismiss: (saved?: boolean) => void;
  parentControlId: string;
  Id?: string;
  assessmentMode: AssessmentTypeEnum;
};

const TestResultModal: FC<Props> = ({
  onDismiss,
  parentControlId,
  Id,
  assessmentMode,
}) => {
  const { updateFiles } = useFileUpdate();
  const { data: controlData, error } = useGetControlByIdQuery({
    variables: {
      _eq: parentControlId,
    },
  });
  if (error) {
    throw error;
  }
  const control = controlData?.control[0];
  const [insert] = useInsertControlTestResultMutation({
    update: (cache) => {
      evictField(cache, 'test_result');
      evictField(cache, 'test_result_aggregate');
      // latest test result (overall effectiveness) shown in controls
      evictField(cache, 'control');
      evictField(cache, 'risk_score');
    },
  });
  const [update] = useUpdateTestResultMutation({
    update: (cache) => {
      evictField(cache, 'test_result');
      // latest test result (overall effectiveness) shown in controls
      evictField(cache, 'control');
      evictField(cache, 'risk_score');
    },
  });
  const { data, loading } = useGetTestResultByIdQuery({
    variables: {
      Id: Id,
    },
    skip: !Id,
    fetchPolicy: 'no-cache',
  });

  const testResult = data?.test_result[0];
  const canEditTestResult = useHasPermission('update:test_result', control);
  const canCreateTestResult = useHasPermission('insert:test_result', control);

  const canModify = testResult ? canEditTestResult : canCreateTestResult;

  const onSave = async (data: TestResultFormFieldsData) => {
    const { newFiles, files, ParentControlIds: _, ...rest } = data;
    if (testResult) {
      const result = await update({
        variables: {
          object: {
            ...rest,
            OriginalTimestamp: testResult.ModifiedAtTimestamp,
            Id: testResult.Id,
            ParentControlId: testResult.ParentControlId,
            CustomAttributeData: rest.CustomAttributeData || undefined,
            Submitter: data.Submitter.value,
          },
        },
      });
      if (!result.data?.updateTestResultApi?.Id) {
        throw new Error(
          'Records not updated. Record may have been updated by another user'
        );
      }
    } else {
      const result = await insert({
        variables: {
          ...rest,
          CustomAttributeData: rest.CustomAttributeData || undefined,
          ControlIds: [parentControlId],
          Submitter: data.Submitter.value,
        },
      });
      const ids = result.data?.insertControlTestResult?.Ids;
      if (ids && ids.length > 0) {
        Id = ids[0];
      }
    }
    if (!Id) {
      throw new Error('Id not found');
    }

    await updateFiles({
      parentType: Parent_Type_Enum.TestResult,
      parentId: Id,
      newFiles,
      originalFiles: testResult?.files,
      selectedFiles: files,
    });
  };

  if (loading) {
    return null;
  }

  return (
    <TestResultForm
      values={
        testResult
          ? {
              ...testResult,
              ParentControlIds: [{ value: parentControlId }],
              Submitter: { value: testResult.Submitter, type: 'user' },
            }
          : undefined
      }
      defaultValues={{
        ...defaultValues,
        ParentControlIds: [{ value: parentControlId }],
      }}
      onSave={onSave}
      onDismiss={onDismiss}
      readOnly={!canModify}
      disableControlSelect={Id !== undefined}
      renderTemplate={(renderProps) => (
        <ModalWrapper
          testId={'testResultModal'}
          {...renderProps}
          visible={true}
        />
      )}
      assessmentMode={assessmentMode}
    />
  );
};

export default TestResultModal;
