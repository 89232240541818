import {
  AssessmentUpdateBreadcrumb,
  AssessmentUpdatePage,
  CreateAssessmentResultPage,
  UpdateAssessmentResultPage,
} from '@pages';
import i18n from '@risksmart-app/i18n/i18n';
import type { RouteObject } from 'react-router';

export const assessmentsRoute: RouteObject = {
  path: ':assessmentId',
  handle: {
    breadcrumb: AssessmentUpdateBreadcrumb,
  },
  children: [
    {
      path: '',
      element: <AssessmentUpdatePage activeTabId={'details'} />,
    },
    {
      path: 'activities',
      handle: {
        title: () => i18n.format(i18n.t('activity_other'), 'capitalizeAll'),
      },

      children: [
        {
          path: '',
          element: (
            <AssessmentUpdatePage
              activeTabId={'activities'}
              activityMode={'list'}
            />
          ),
        },
        {
          path: 'add',
          handle: {
            title: () => i18n.format(i18n.t('activity_one'), 'capitalizeAll'),
          },
          element: (
            <AssessmentUpdatePage
              activeTabId={'activities'}
              activityMode={'add'}
            />
          ),
        },
        {
          path: ':activityId',
          handle: {
            title: () => 'Activity',
          },
          element: (
            <AssessmentUpdatePage
              activeTabId={'activities'}
              activityMode={'update'}
            />
          ),
        },
      ],
    },

    {
      path: 'findings',
      handle: {
        title: () => i18n.format(i18n.t('finding_other'), 'capitalizeAll'),
      },
      children: [
        {
          path: '',
          element: <AssessmentUpdatePage activeTabId={'findings'} />,
          index: true,
        },
        {
          path: ':findingId',
          element: <UpdateAssessmentResultPage />,

          handle: {
            title: () => i18n.format(i18n.t('finding_one'), 'capitalizeAll'),
          },
        },
        {
          path: 'add',
          element: <CreateAssessmentResultPage />,
          handle: {
            title: () => i18n.t('assessmentResults.create_title'),
          },
        },
      ],
    },

    {
      path: 'linked-items',
      children: [
        {
          path: '',
          element: <AssessmentUpdatePage activeTabId={'linkedItems'} />,
        },
      ],
    },
  ],
};
