import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { getOwners } from 'src/rbac/contributorHelper';
import { UserOrGroupsSchema } from 'src/schemas/global';
import { z } from 'zod';

import { ModalForm } from '@/components/Form/Form/ModalForm';
import {
  useGetEntityByIdQuery,
  useInsertEntityMutation,
  useUpdateEntityMutation,
} from '@/generated/graphql';
import { evictField } from '@/utils/graphqlUtils';

import EntityFormFields from './EntityFormFields';

interface Props {
  Id?: string;
  isVisible: boolean;
  onDismiss: () => void;
}

const schema = z.object({
  Name: z.string(),
  ParentId: z.string().nullish(),
  Description: z.string().nullish(),
  Owners: UserOrGroupsSchema.min(1, { message: 'Required' }),
});

type SchemaFields = z.infer<typeof schema>;

const defaultValues = {
  Name: '',
  Description: '',
  ParentId: null,
  Owners: [],
};

const EntityDetailsModal: FC<Props> = ({ onDismiss, Id, isVisible }) => {
  const { t } = useTranslation(['common']);

  const { data } = useGetEntityByIdQuery({
    variables: { Id: Id! },
    skip: !Id,
  });

  const [insert] = useInsertEntityMutation({
    update: (cache) => {
      evictField(cache, 'entity');
      evictField(cache, 'entity_by_pk');
    },
  });
  const [update] = useUpdateEntityMutation({
    update: (cache) => {
      evictField(cache, 'entity');
      evictField(cache, 'entity_by_pk');
    },
  });

  const onSave = async (data: SchemaFields) => {
    if (Id) {
      await update({
        variables: {
          Id,
          ...data,
          owners: data.Owners.filter((c) => c.type === 'user').map(
            (c) => c.value
          ),
          ownerGroups: data.Owners.filter((c) => c.type === 'userGroup').map(
            (c) => c.value
          ),
        },
      });
    } else {
      await insert({
        variables: {
          ...data,
          owners: data.Owners.filter((c) => c.type === 'user').map(
            (c) => c.value
          ),
          ownerGroups: data.Owners.filter((c) => c.type === 'userGroup').map(
            (c) => c.value
          ),
        },
      });
    }
    onDismiss();
  };

  return (
    <ModalForm<SchemaFields>
      onDismiss={onDismiss}
      visible={isVisible}
      defaultValues={defaultValues}
      values={
        data?.entity_by_pk
          ? {
              ...data.entity_by_pk,
              Owners: getOwners(data.entity_by_pk),
            }
          : defaultValues
      }
      formId={'entityDetailsForm'}
      schema={schema}
      onSave={onSave}
      i18n={t('entity')}
    >
      <EntityFormFields Id={Id} />
    </ModalForm>
  );
};

export default EntityDetailsModal;
