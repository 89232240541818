import type { OptionDefinition } from '@cloudscape-design/components/internal/components/option/interfaces';
import type { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import ControlledAutosuggest from '@/components/Form/ControlledAutosuggest';
import ControlledRating from '@/components/Form/ControlledRating';
import ControlledTextarea from '@/components/Form/ControlledTextarea';
import CustomisableForm from '@/components/Form/Form/CustomisableForm/CustomisableForm';
import type { InsertCauseMutationVariables } from '@/generated/graphql';
import { useLibrary } from '@/hooks/use-library';

type SuggestedTitle = {
  value: string;
  label: string;
  type: 'library';
};

type FormFields = InsertCauseMutationVariables;

type Props = {
  readOnly?: boolean;
};

const CauseForm: FC<Props> = ({ readOnly }) => {
  const { control, setValue } = useFormContext<FormFields>();
  const { t } = useTranslation(['common']);
  const { t: st } = useTranslation(['common'], {
    keyPrefix: 'causes',
  });

  const causesLibrary = useLibrary('causes');
  const autoPopulateDescription = (selectedTitleOption?: OptionDefinition) => {
    if (!selectedTitleOption) {
      return;
    }
    const selectedOption = selectedTitleOption as SuggestedTitle;
    const description = causesLibrary?.find(
      (control) => control.title === selectedOption?.value
    )?.description;
    if (description) {
      setValue('Description', description);
    }
  };

  const causesLibraryOptions: SuggestedTitle[] = causesLibrary?.map((c) => ({
    value: c.title,
    label: c.title,
    type: 'library',
  }));

  const options =
    causesLibraryOptions.length > 0
      ? [
          {
            value: 'Library',
            label: 'Library',
            options: causesLibraryOptions,
          },
        ]
      : [];

  return (
    <CustomisableForm readOnly={readOnly}>
      <ControlledAutosuggest
        key={'title'}
        forceRequired={true}
        name={'Title'}
        label={st('fields.Title')}
        description={st('fields.Title_help')}
        control={control}
        placeholder={st('fields.Title_placeholder') ?? ''}
        onSelect={(e) => {
          autoPopulateDescription(e.detail.selectedOption);
        }}
        options={options}
        disabled={readOnly}
      />
      <ControlledRating
        key={'significance'}
        addEmptyOption={true}
        defaultRequired={true}
        control={control}
        name={'Significance'}
        type={'significance'}
        label={st('fields.Significance')}
        description={st('fields.Significance_help')}
        disabled={readOnly}
      />

      <ControlledTextarea
        key={'description'}
        defaultRequired={true}
        name={'Description'}
        label={t('fields.Description')}
        placeholder={st('fields.Description_placeholder') ?? ''}
        description={st('fields.Description_help')}
        control={control}
        disabled={readOnly}
      />
    </CustomisableForm>
  );
};

export default CauseForm;
