import type { FC } from 'react';
import { useFormContext } from 'react-hook-form';

import { ControlledFileUpload } from '@/components/Form/ControlledFileUpload/ControlledFileUpload';

import type { DataImportDataFields } from './dataImportSchema';

const DataImportFormFields: FC = () => {
  const { control } = useFormContext<DataImportDataFields>();

  return (
    <ControlledFileUpload
      multiple={true}
      name={'newFiles'}
      label={'Files'}
      saveFilesName={'files'}
      control={control}
    />
  );
};

export default DataImportFormFields;
