import type { HeaderProps } from '@cloudscape-design/components/header';
import Header from '@cloudscape-design/components/header';
import { InfoCircle } from '@untitled-ui/icons-react';
import type { FC } from 'react';

interface Props extends HeaderProps {
  showHelp: boolean;
  onShowHelpClick: () => void;
}

const PageHeader: FC<Props> = ({
  children,
  counter,
  actions,
  showHelp,
  onShowHelpClick,
  ...rest
}) => {
  const variant = rest.variant || 'h1';

  return (
    <div className={'rs-header'}>
      <Header
        variant={variant}
        actions={
          <div className={'flex '}>
            {actions}
            {showHelp && (
              <button
                data-testid={'help-button'}
                className={
                  'ml-3 bg-navy rounded-[19px] h-[38px] w-[38px] border-0 text-white flex cursor-pointer p-[0px] content-baseline justify-center items-center'
                }
                onClick={onShowHelpClick}
              >
                <InfoCircle
                  viewBox={'0 0 24 24'}
                  className={'w-[24px] h-[24px] relative'}
                />
              </button>
            )}
          </div>
        }
        {...rest}
      >
        <div
          className={
            'flex flex-row items-baseline font-extrabold text-grey800 text-[32px]/[38px]'
          }
        >
          <div data-testid={'heading-text'}>{children}</div>
          {counter && (
            <span
              data-testid={'heading-count'}
              className={'ml-3 text-grey font-normal'}
            >
              {counter}
            </span>
          )}
        </div>
      </Header>
    </div>
  );
};

export default PageHeader;
