import Select from '@cloudscape-design/components/select';
import { LayoutLeft } from '@untitled-ui/icons-react';
import clsx from 'clsx';
import { type FC } from 'react';
import { useTranslation } from 'react-i18next';

import Logo from './Logo';
import NavigationCollapsed from './NavigationCollapsed';
import { NavItems } from './NavItems';
import styles from './styles.module.scss';
import type { Props } from './types';
import UserMenu from './UserMenu';

const Navigation: FC<Props> = ({
  navigationOpen,
  setNavigationOpen,
  logoutUrl,
  navItems,
  renderCount,
  showUserMenu,
  customLogoUrl,
  altTheme,
  authContext,
  showEntitySelector,
  entityFilter,
  onEntityChange,
  entityOptions,
}) => {
  const { t } = useTranslation('common');

  return navigationOpen ? (
    <div
      data-testid={'navigation'}
      className={clsx('h-screen relative print:hidden', {
        'bg-navy_mid': !altTheme,
        'bg-dark_green': altTheme,
      })}
    >
      <div
        className={clsx(
          'min-h-screen text-white font-sans text-[14px] font-semibold flex flex-col relative',
          {
            'bg-navy_mid': !altTheme,
            'bg-dark_green': altTheme,
          }
        )}
      >
        <div
          className={clsx(
            'py-4 px-6 border-0 border-b border-solid  border-navy_light flex sticky grow-0 z-20 justify-between items-center',
            {
              'bg-navy_mid': !altTheme,
              'bg-dark_green': altTheme,
            }
          )}
          style={{
            top: 0,
          }}
        >
          <Logo small={!navigationOpen} />
          <button
            className={
              'bg-transparent border-0 hover:bg-navy_light text-grey500 flex items-center justify-center text-center p-1 rounded-md cursor-pointer w-[40px] h-[40px] rs-nav-button -right-3 relative'
            }
          >
            <LayoutLeft onClick={() => setNavigationOpen(!navigationOpen)} />
          </button>
        </div>

        {showEntitySelector && entityOptions && entityOptions.length > 0 && (
          <div className={styles.select}>
            <Select
              selectedOption={
                entityOptions.find(
                  (option) => option.value === entityFilter?.[0]
                ) ?? { value: undefined, label: t('entity.global') }
              }
              options={entityOptions}
              onChange={({ detail }) => {
                onEntityChange?.(detail?.selectedOption?.value);
              }}
            />
          </div>
        )}

        <div className={'grow overflow-y-auto no-scrollbar'}>
          <ul className={'list-none p-0 my-3 ps-[0]'}>
            <NavItems items={navItems} renderCount={renderCount} />
          </ul>
        </div>

        {showUserMenu && (
          <UserMenu
            logoutUrl={logoutUrl}
            customLogoUrl={customLogoUrl}
            authContext={authContext}
          />
        )}
      </div>
    </div>
  ) : (
    <NavigationCollapsed
      altTheme={altTheme}
      showUserMenu={showUserMenu}
      renderCount={renderCount}
      navItems={navItems}
      logoutUrl={logoutUrl}
      setNavigationOpen={setNavigationOpen}
      navigationOpen={navigationOpen}
      customLogoUrl={customLogoUrl}
      authContext={authContext}
    />
  );
};

export default Navigation;
