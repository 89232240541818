import type {
  PropertyFilterProperty,
  PropertyFilterQuery,
} from '@cloudscape-design/collection-hooks';
import { useRating } from '@risksmart-app/components/hooks/useRating';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { FilterPropertyDashboardItem } from '@/components/RegisterDashboard/FilterPropertyDashboardItem';
import RegisterDashboard from '@/components/RegisterDashboard/RegisterDashboard';
import { emptyFilterQuery } from '@/utils/collectionUtils';

import type { ControlTestFields } from './types';

type TestSummaryProps = {
  items: readonly ControlTestFields[] | undefined;
  propertyFilterQuery: PropertyFilterQuery;
  onFilterQueryChanged: (query: PropertyFilterQuery) => void;
  filteringProperties: readonly PropertyFilterProperty<unknown>[];
};

export const TestSummary: FC<TestSummaryProps> = ({
  items,
  onFilterQueryChanged,
  propertyFilterQuery,
  filteringProperties,
}) => {
  const { t } = useTranslation(['common'], {
    keyPrefix: 'testResults.summary_category_titles',
  });
  const { getByValue } = useRating('effectiveness');

  return (
    <RegisterDashboard>
      <FilterPropertyDashboardItem
        onClick={onFilterQueryChanged}
        tableFilterQuery={propertyFilterQuery}
        itemFilterQuery={{
          tokens: [],
          tokenGroups: [
            {
              propertyKey: 'OverallEffectivenessLabelled',
              value: getByValue(0)?.label || '',
              operator: '=',
            },
          ],
          operation: 'and',
        }}
        title={t('not_effective')}
        items={items ?? []}
        filteringProperties={filteringProperties}
      />
      <FilterPropertyDashboardItem
        onClick={onFilterQueryChanged}
        itemFilterQuery={emptyFilterQuery}
        tableFilterQuery={propertyFilterQuery}
        title={t('all')}
        items={items ?? []}
        filteringProperties={filteringProperties}
      />
    </RegisterDashboard>
  );
};
