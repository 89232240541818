import type { TabsProps } from '@cloudscape-design/components/tabs';
import { useGetGuidParam } from '@risksmart-app/components/routes/routes.utils';
import i18n from '@risksmart-app/i18n/i18n';
import { useTranslation } from 'react-i18next';

import { useIsFeatureVisibleToOrg } from '@/utils/featureFlags';
import { filterEmptyTabs } from '@/utils/tabUtils';

import { useHasPermission } from '../../../../../../rbac/useHasPermission';
import { useGetDetailPath } from '../../../../../../routes/useGetDetailParentPath';
import AttestationsTab from './tabs/attestations/Tab';
import DetailsTab from './tabs/details/Tab';

export const useTabs = () => {
  const parentDocumentId = useGetGuidParam('documentId');
  const documentFileId = useGetGuidParam('id');

  const parentUrl = useGetDetailPath(documentFileId);
  const attestationsEnabled = useIsFeatureVisibleToOrg('attestations');
  const canViewAttestations = useHasPermission('read:attestation_record');
  const { t } = useTranslation(['common', 'taxonomy']);

  const tabs: TabsProps['tabs'][number][] = [
    {
      id: 'details',
      label: t('details'),
      content: (
        <DetailsTab
          parentDocumentId={parentDocumentId}
          documentFileId={documentFileId}
        />
      ),
      href: parentUrl,
    },
  ];

  if (attestationsEnabled && canViewAttestations) {
    tabs.push({
      id: 'attestations',
      label: i18n.format(t('taxonomy:attestation_other'), 'capitalizeAll'),
      content: <AttestationsTab documentFileId={documentFileId} />,
      href: `${parentUrl}/attestations`,
    });
  }

  return filterEmptyTabs(tabs);
};
