import i18n from '@risksmart-app/i18n/i18n';
import { t } from 'i18next';

import { ParentTypeEnum } from '../../../generated/graphql';
import { DataSourceType } from '../schema';
import {
  getAuditColumns,
  getContributorsColumns,
  getOwnersColumns,
  getTagAndDepartmentsColumns,
} from './shared';
import { SharedDataset } from './types';

export const getIndicators = () =>
  ({
    label: i18n.format(t('indicator_other'), 'capitalize'),
    parentTypes: new Set<DataSourceType>(['risks', 'controls']),
    customAttributeFormConfigurationParentTypes: [ParentTypeEnum.Indicator],
    fields: {
      ...getAuditColumns(),
      ...getTagAndDepartmentsColumns(),
      ...getOwnersColumns(),
      ...getContributorsColumns(),
      ratingFrequency: {
        dataType: 'text',
        label: t('indicators.columns.test_frequency'),
        displayType: 'commonLookup',
        i18nKey: 'frequency',
      },
      latestRatingDate: {
        dataType: 'date',
        label: t('indicators.columns.latest_result_date'),
        displayType: 'date',
      },
      nextRatingDueDate: {
        dataType: 'date',
        label: t('indicators.columns.nextTestDate'),
        displayType: 'date',
      },
      nextRatingOverdueDate: {
        dataType: 'date',
        label: t('indicators.columns.nextTestOverdue'),
        displayType: 'date',
      },
      name: {
        dataType: 'text',
        displayType: 'text',
        label: t('indicators.columns.title'),
      },
      details: {
        dataType: 'text',
        displayType: 'text',
        label: t('indicators.fields.description'),
      },
      id: { dataType: 'guid', displayType: 'text', label: t('columns.guid') },
      sequentialId: {
        dataType: 'number',
        displayType: 'number',
        label: t('columns.id'),
        prefix: 'IN-',
      },
      lowerTolerance: {
        dataType: 'number',
        displayType: 'number',
        label: t('indicators.columns.lower_tolerance_num'),
      },
      lowerAppetite: {
        dataType: 'number',
        displayType: 'number',
        label: t('indicators.columns.lower_appetite_num'),
      },
      upperAppetite: {
        dataType: 'number',
        displayType: 'number',
        label: t('indicators.columns.upper_appetite_num'),
      },
      upperTolerance: {
        dataType: 'number',
        displayType: 'number',
        label: t('indicators.columns.upper_tolerance_num'),
      },
      type: {
        dataType: 'text',
        label: t('indicators.fields.type'),
        displayType: 'rating',
        ratingKey: 'indicator_type',
      },
      unit: {
        dataType: 'text',
        label: t('indicators.columns.unit'),
        displayType: 'text',
      },
      expectedTextValue: {
        dataType: 'text',
        label: t('indicators.columns.target_text_value'),
        displayType: 'text',
      },
      detailsLink: {
        dataType: 'guid',
        label: t('indicators.columns.details_link'),
        displayType: 'detailsLink',
        entityInfoParentType: ParentTypeEnum.Indicator,
      },
    },
  }) satisfies SharedDataset;
