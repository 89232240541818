import SpaceBetween from '@cloudscape-design/components/space-between';
import Button from '@risksmart-app/components/Button';
import { PageNotFound } from '@risksmart-app/components/errors/errors';
import { useGetGuidParam } from '@risksmart-app/components/routes/routes.utils';
import type { FC } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import {
  namedOperations,
  Parent_Type_Enum,
  useDeleteRiskMutation,
  useGetRiskByIdQuery,
} from 'src/generated/graphql';
import { PageLayout } from 'src/layouts';
import { useHasPermission } from 'src/rbac/useHasPermission';
import { useGetDetailParentPath } from 'src/routes/useGetDetailParentPath';

import ActionsButton from '@/components/ActionsButton';
import AddToEnterpriseRiskModal from '@/components/AddToEnterpriseRiskModal/AddToEnterpriseRiskModal';
import ControlledTabs from '@/components/ControlledTabs';
import DeleteModal from '@/components/DeleteModal/DeleteModal';
import { useInitiateWizard } from '@/components/Wizard/hooks/useInitiateWizard';
import { WizardButton } from '@/components/Wizard/WizardButton';
import { useDeleteResultNotification } from '@/hooks/useMutationResultNotification';
import { useIsFeatureVisibleToOrg } from '@/utils/featureFlags';
import { getFriendlyId } from '@/utils/friendlyId';
import { evictField } from '@/utils/graphqlUtils';

import useExporter from './useExporter';
import { useTabs } from './useTabs';

type Props = {
  selectedTabId:
    | 'acceptances'
    | 'actions'
    | 'appetites'
    | 'approvals'
    | 'controls'
    | 'details'
    | 'impacts'
    | 'indicators'
    | 'linkedItems'
    | 'ratings';
  showDeleteButton?: boolean;
};

const Page: FC<Props> = ({ selectedTabId, showDeleteButton }) => {
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [
    isAddToEnterpriseRiskModalVisible,
    setIsAddToEnterpriseRiskModalVisible,
  ] = useState(false);
  const navigate = useNavigate();
  const riskId = useGetGuidParam('riskId');
  const params = useParams();
  const parentUrl = useGetDetailParentPath(riskId);
  const [mutate, deleteResult] = useDeleteRiskMutation({
    update: (cache) => {
      evictField(cache, 'risk');
      evictField(cache, 'risk_aggregate');
      evictField(cache, 'acceptance');
      evictField(cache, 'appetite');
      evictField(cache, 'appetite_aggregate');
      evictField(cache, 'appetite_aggregate');
      evictField(cache, 'impact_rating');
    },
    refetchQueries: [
      namedOperations.Query.getRiskById,
      namedOperations.Query.getRisksByTier,
    ],
  });
  const { t } = useTranslation(['common']);
  const { t: st } = useTranslation(['common'], { keyPrefix: 'risks' });
  const { data, error } = useGetRiskByIdQuery({
    variables: {
      _eq: riskId,
    },
    fetchPolicy: 'no-cache',
  });
  if (error) {
    throw error;
  }

  const risk = data?.risk[0];
  const complianceMonitoringEnabled = useIsFeatureVisibleToOrg(
    'compliance_monitoring'
  );
  const internalAuditEnabled = useIsFeatureVisibleToOrg('internal_audit');
  const canViewCompliance = useHasPermission(
    'read:compliance_monitoring_assessment',
    risk
  );
  const canViewInternalAudit = useHasPermission(
    'read:internal_audit_report',
    risk
  );
  const [exportItem, { loading: exporting }] = useExporter(
    riskId,
    internalAuditEnabled && canViewInternalAudit,
    complianceMonitoringEnabled && canViewCompliance
  );
  const tabs = useTabs(risk);
  const onDelete = useDeleteResultNotification({
    entityName: st('entity_name'),
    asyncAction: async () => {
      if (!risk) {
        return false;
      }
      await mutate({
        variables: {
          id: risk.Id,
        },
      });
      await navigate(parentUrl);

      return true;
    },
  });

  if (
    !deleteResult.data?.deleteRiskById.affected_rows &&
    data?.risk.length === 0
  ) {
    throw new PageNotFound(`Risk with id ${riskId} not found`);
  }

  const counter =
    risk && `(${getFriendlyId(Parent_Type_Enum.Risk, risk.SequentialId)})`;
  const fallbackTitle = st('fallback_title');

  useInitiateWizard(riskId);

  const wizardFeatureEnabled = useIsFeatureVisibleToOrg('wizard');

  const canViewWizard = useHasPermission('update:risk', risk, true);

  const wizardButtonVisible = wizardFeatureEnabled && canViewWizard;

  const isEnterpriseRiskEnabled = useIsFeatureVisibleToOrg('enterprise_risk');
  const canLinkToEnterpriseRisk = useHasPermission('insert:enterprise_risk');

  const buttons = [
    <Button
      iconName={'download'}
      disabled={exporting}
      onClick={exportItem}
      key={'export'}
    >
      {t('export')}
    </Button>,
  ];

  if (isEnterpriseRiskEnabled && canLinkToEnterpriseRisk) {
    buttons.push(
      <Button
        variant={'normal'}
        formAction={'none'}
        key={'addToEnterpriseRisk'}
        onClick={() => {
          setIsAddToEnterpriseRiskModalVisible(true);
        }}
      >
        {'Add to Enterprise Risk'}
      </Button>
    );
  }

  const hasPermissionToDelete = useHasPermission('delete:risk', risk);
  // Always last so it shows on the right for consistency
  if (showDeleteButton && hasPermissionToDelete) {
    buttons.push(
      <Button
        variant={'normal'}
        formAction={'none'}
        key={'delete'}
        onClick={() => {
          setIsDeleteModalVisible(true);
        }}
      >
        {st('delete_button')}
      </Button>
    );
  }

  let actions = buttons;

  // More than 2 buttons, show actions dropdown
  if (
    (wizardButtonVisible && buttons.length > 1) ||
    (!wizardButtonVisible && buttons.length > 2)
  ) {
    actions = [
      <ActionsButton
        key={'actions'}
        buttonText={'Actions'}
        items={buttons.map((b) => {
          return {
            text: b.props.children,
            id: b.props.children,
            onItemClick: b.props.onClick,
            key: b.props.key,
          };
        })}
      />,
    ];
  }

  return (
    <PageLayout
      actions={
        <SpaceBetween direction={'horizontal'} size={'xs'}>
          {wizardButtonVisible && (
            <WizardButton
              riskId={riskId}
              basePath={`${parentUrl}/${params.riskId}`}
              key={'wizard'}
            />
          )}
          {actions}
        </SpaceBetween>
      }
      meta={{
        title: fallbackTitle,
      }}
      title={risk?.Title}
      counter={counter}
    >
      <ControlledTabs
        tabs={tabs}
        activeTabId={selectedTabId}
        variant={'container'}
      />

      <DeleteModal
        loading={deleteResult.loading}
        isVisible={isDeleteModalVisible}
        header={st('delete_modal_title')}
        onDelete={onDelete}
        onDismiss={() => setIsDeleteModalVisible(false)}
      >
        {st('confirm_delete_message')}
      </DeleteModal>

      <AddToEnterpriseRiskModal
        riskId={riskId}
        enterpriseRiskId={risk?.enterpriseRiskInstance?.EnterpriseRiskId}
        entityId={risk?.enterpriseRiskInstance?.EntityId}
        riskTier={risk?.Tier ?? 1}
        isVisible={isAddToEnterpriseRiskModalVisible}
        onDismiss={() => setIsAddToEnterpriseRiskModalVisible(false)}
      />
    </PageLayout>
  );
};

export default Page;
