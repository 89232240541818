import _ from 'lodash';

type AppetitePerformance = 'inside' | 'outside';

export const getAppetitePerformance = ({
  controlledRating,
  UpperAppetite,
  LowerAppetite,
  posture,
}: {
  controlledRating: null | number | undefined;
  UpperAppetite?: null | number | undefined;
  LowerAppetite?: null | number | undefined;
  posture: boolean | null;
}): AppetitePerformance | null => {
  if (_.isNil(controlledRating)) {
    return null;
  }

  if (posture && (_.isNil(UpperAppetite) || UpperAppetite === 0)) {
    return null;
  }

  if (
    !posture &&
    (_.isNil(UpperAppetite) ||
      _.isNil(LowerAppetite) ||
      UpperAppetite === 0 ||
      LowerAppetite === 0)
  ) {
    return null;
  }

  if (posture) {
    if (UpperAppetite == null) {
      throw new Error('upperThreshold is null');
    }

    return controlledRating <= UpperAppetite ? 'inside' : 'outside';
  }
  if (UpperAppetite == null) {
    throw new Error('upperThreshold is null');
  }
  if (LowerAppetite == null) {
    throw new Error('lowerThreshold is null');
  }
  if (
    controlledRating >= LowerAppetite &&
    Math.floor(controlledRating) <= UpperAppetite
  ) {
    return 'inside';
  } else {
    return 'outside';
  }
};
