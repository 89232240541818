import type { FilteringProperty } from '@cloudscape-design/components/property-filter/interfaces';

import SelectFilter from '../filters/SelectFilter';
import type { AllowedField } from '../utils';
import type { ReportFieldType } from './types';

export const options: ReportFieldType = {
  getFilteringOptions: function (field: AllowedField) {
    const fieldDef = field.fieldDef;
    if (fieldDef.displayType !== 'options') {
      throw new Error('options filed type used out of context');
    }

    return fieldDef
      .getOptions()
      .map((o) => ({ ...o, propertyKey: field.value }));
  },

  cell: ({ field, fieldData }) => {
    const fieldDef = field.fieldDef;
    const value = fieldData.value;
    if (fieldDef.displayType !== 'options') {
      throw new Error('options filed type used out of context');
    }

    return fieldDef.getOptions().find((o) => o.value === String(value))?.label;
  },
  propertyConfig: function (field: AllowedField): FilteringProperty {
    const fieldDef = field.fieldDef;
    if (fieldDef.displayType !== 'options') {
      throw new Error('options filed type used out of context');
    }

    return {
      key: field.value,
      groupValuesLabel: '',
      propertyLabel: field.label,
      operators: [
        {
          operator: '=',
          format: (value) => {
            return (
              fieldDef.getOptions().find((o) => o.value === value)?.label ?? ''
            );
          },
          form: (props) => {
            return <SelectFilter {...props} options={fieldDef.getOptions()} />;
          },
        },
      ],
    };
  },
};
