import SpaceBetween from '@cloudscape-design/components/space-between';
import Button from '@risksmart-app/components/Button';
import { useNotifications } from '@risksmart-app/components/Notifications/useNotifications';
import Table from '@risksmart-app/components/Table';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { PageLayout } from 'src/layouts';
import { Permission } from 'src/rbac/Permission';

import { useGetCustomDatasourcesQuery } from '@/generated/graphql';
import { getCounter } from '@/utils/collectionUtils';
import { handleError } from '@/utils/errorUtils';
import { addCustomDatasourceUrl } from '@/utils/urls';

import { useGetCollectionTableProps } from './config';

const Page: FC = () => {
  const { t: st } = useTranslation(['common'], {
    keyPrefix: 'customDatasources',
  });
  const { t } = useTranslation(['common']);

  const { addNotification } = useNotifications();
  const { data, loading } = useGetCustomDatasourcesQuery({
    fetchPolicy: 'no-cache',
    onError: (error) => {
      handleError(error);
      addNotification({
        type: 'error',
        content: <>{error.message}</>,
      });
    },
  });

  const tableProps = useGetCollectionTableProps(data?.custom_datasource);

  return (
    <PageLayout
      helpTranslationKey={'customDatasources.registerHelp'}
      title={st('register_title')}
      counter={getCounter(tableProps.totalItemsCount, loading)}
      actions={
        <SpaceBetween direction={'horizontal'} size={'xs'}>
          <Button iconName={'download'} onClick={tableProps.exportToCsv}>
            {t('export')}
          </Button>
          <Permission permission={'insert:custom_datasource'}>
            <Button variant={'primary'} href={addCustomDatasourceUrl()}>
              {st('create_new_button')}
            </Button>
          </Permission>
        </SpaceBetween>
      }
      meta={{
        title: st('register_title'),
      }}
    >
      <Table {...tableProps} loading={loading} />
    </PageLayout>
  );
};

export default Page;
