import { useRating } from '@risksmart-app/components/hooks/useRating';
import type { FC } from 'react';
import { useCalculateRiskRating } from 'src/ratings/useCalculateRiskRating';

import { Risk_Assessment_Result_Control_Type_Enum } from '@/generated/graphql';
import { Risk_Scoring_Model_Enum } from '@/generated/graphql';
import { getRiskScoreBadge } from '@/hooks/useRiskScore';

export type Props = {
  controlType: Risk_Assessment_Result_Control_Type_Enum;
  riskScoreModel: Risk_Scoring_Model_Enum;
  impact: null | number;
  likelihood: null | number;
  rating: null | number;
  score: null | number;
};

export const RiskScoreBadge: FC<Props> = ({
  controlType,
  impact,
  likelihood,
  rating,
  riskScoreModel,
  score,
}) => {
  const { getByValue } = useRating(
    controlType === Risk_Assessment_Result_Control_Type_Enum.Controlled
      ? 'risk_controlled'
      : 'risk_uncontrolled'
  );

  const calculateRating = useCalculateRiskRating(controlType);
  // Currently the only way to get a score rating category is to use the impact likelihood values.
  // This is because when using the default scoring model,
  // a) the rating can be overriden so it has no reflection on impact likelihood, whilst the score is always likelihod * impact
  // b) a single score value could potentially map to multiple rating categories e.g. a score of 12 = likelihood 3, impact 4, or likelihood 4, impact 3 (which could have different labels, colours)
  // Note we could simplify this in the future by storing a score-rating value on the risk_score table
  const isDefaultScoringModel =
    riskScoreModel === Risk_Scoring_Model_Enum.Default;
  const scoreRating = isDefaultScoringModel
    ? calculateRating({ impact: impact ?? 0, likelihood: likelihood ?? 0 })
        .value
    : rating;

  return getRiskScoreBadge(scoreRating, getByValue, score);
};
