import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { create } from 'zustand/index';

export type ToolsContent =
  | 'help'
  | 'notifications'
  | 'page-content'
  | 'wizard'
  | `change-request:${string}`
  | undefined;

type ToolsState = {
  toolsContent: ToolsContent;
  setToolsContent: (toolsContent: ToolsContent) => void;
};

export const useToolsStore = create<ToolsState>((set) => ({
  toolsContent: undefined,
  setToolsContent: (toolsContent) => set({ toolsContent }),
}));

export const useTools = () => {
  const location = useLocation();

  const { toolsContent, setToolsContent } = useToolsStore();

  // Close the right panel when user navigates to a new route, except when:
  // - the current content is notifications
  // - the current path includes risk and the content is for the RCSA wizard
  useEffect(() => {
    if (
      toolsContent !== 'notifications' &&
      !(toolsContent === 'wizard' && location.pathname.includes('risks/'))
    ) {
      setToolsContent(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setToolsContent, location.pathname]);

  return [toolsContent, setToolsContent] as const;
};
