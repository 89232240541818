import type { FilterModal } from '@/components/CustomisableRibbon/customisableRibbonModalSchema';

export const defaultRibbonFilters: FilterModal[] = [
  {
    id: crypto.randomUUID(),
    title: 'Tier 1',
    itemFilterQuery: {
      tokens: [],
      tokenGroups: [
        {
          operator: '=',
          propertyKey: 'TierLabelled',
          value: 'Tier 1',
        },
      ],
      operation: 'and',
    },
  },
  {
    id: crypto.randomUUID(),
    title: 'Tier 2',
    itemFilterQuery: {
      tokens: [],
      tokenGroups: [
        {
          operator: '=',
          propertyKey: 'TierLabelled',
          value: 'Tier 2',
        },
      ],
      operation: 'and',
    },
  },
  {
    id: crypto.randomUUID(),
    title: 'Tier 3',
    itemFilterQuery: {
      tokens: [],
      tokenGroups: [
        {
          operator: '=',
          propertyKey: 'TierLabelled',
          value: 'Tier 3',
        },
      ],
      operation: 'and',
    },
  },
  {
    id: crypto.randomUUID(),
    title: 'All risks',
    itemFilterQuery: {
      tokens: [],
      tokenGroups: [],
      operation: 'and',
    },
  },
];
