import { useNotifications } from '@risksmart-app/components/Notifications/useNotifications';
import _ from 'lodash';
import type { FieldValues } from 'react-hook-form';
import { useFormContext } from 'react-hook-form';

import { useGetRiskListQuery } from '@/generated/graphql';

import ControlledSelect from '../ControlledSelect';
import type { ControlledBaseProps } from '../types';
import { getOptions } from './selectUtils';

interface Props<T extends FieldValues> extends ControlledBaseProps<T> {
  addEmptyOption?: boolean;
  disabled?: boolean;
  single?: boolean;
  testId?: string;
}

export const ControlledRiskSelect = <T extends FieldValues>({
  ...props
}: Props<T>) => {
  const { addNotification } = useNotifications();
  const { watch } = useFormContext();
  const value = watch(props.name);
  const { data, loading } = useGetRiskListQuery({
    fetchPolicy: 'no-cache',
    onError: (error) => {
      addNotification({
        type: 'error',
        content: <>{error.message}</>,
      });
    },
  });

  return (
    <ControlledSelect
      statusType={loading ? 'loading' : 'finished'}
      {...props}
      options={getOptions(data, value)}
      filteringType={'auto'}
    />
  );
};
