import { useNotifications } from '@risksmart-app/components/Notifications/useNotifications';
import type { FieldValues } from 'react-hook-form';

import { useGetImpactListQuery } from '@/generated/graphql';

import ControlledSelect from '../ControlledSelect';
import type { ControlledBaseProps } from '../types';

interface Props<T extends FieldValues> extends ControlledBaseProps<T> {
  addEmptyOption?: boolean;
  disabled?: boolean;
  single?: boolean;
  testId?: string;
}

export const ControlledImpactSelect = <T extends FieldValues>({
  ...props
}: Props<T>) => {
  const { addNotification } = useNotifications();

  const { data, loading } = useGetImpactListQuery({
    fetchPolicy: 'no-cache',
    onError: (error) => {
      addNotification({
        type: 'error',
        content: <>{error.message}</>,
      });
    },
  });

  const options =
    data?.impact.map((r) => ({
      value: r.Id,
      label: r.Name,
    })) ?? [];

  return (
    <ControlledSelect
      statusType={loading ? 'loading' : 'finished'}
      {...props}
      options={options}
      filteringType={'auto'}
    />
  );
};
