import { useRating } from '@risksmart-app/components/hooks/useRating';
import type { Dictionary } from 'lodash';
import _ from 'lodash';
import type { RiskRegisterFields } from 'src/pages/risks/types';
import { useCalculateRiskRating } from 'src/ratings/useCalculateRiskRating';

import { Risk_Assessment_Result_Control_Type_Enum } from '@/generated/graphql';
import { handleError } from '@/utils/errorUtils';

import { initializeHeatmapCells } from '../HeatmapWidget/heatmapUtils';
import type { HeatmapCellData } from '../HeatmapWidget/types';

const hasImpactAndLikelihood = (
  rawData: OptionalImpactLikelihood
): rawData is ImpactLikelihood => !!rawData.Impact && !!rawData.Likelihood;

type ImpactLikelihood = { Impact: number; Likelihood: number };
type OptionalImpactLikelihood = {
  Impact: null | number | undefined;
  Likelihood: null | number | undefined;
};

const populateCellDataValues = (
  cellData: HeatmapCellData[][],
  rawData: ImpactLikelihood[] | undefined
) => {
  const counts: Dictionary<number> = _.countBy(
    rawData,
    (item) => `${item.Impact},${item.Likelihood}`
  );
  Object.keys(counts).forEach((k) => {
    const fields = k.split(',');
    const impact = Number(fields[0]);
    const likelihood = Number(fields[1]);
    if (likelihood > cellData.length) {
      handleError(
        `Cannot render likelihood value ${likelihood} on grid of size ${cellData.length}`
      );

      return;
    }
    if (impact > cellData[likelihood - 1].length) {
      handleError(
        `Cannot render impact value ${impact} on grid of size ${
          cellData[likelihood - 1].length
        }`
      );

      return;
    }
    cellData[likelihood - 1][impact - 1].value = counts[k];
  });
};

const useCreateCellDataWithBackgroundColor = (
  controlType: Risk_Assessment_Result_Control_Type_Enum
) => {
  const { options: impactOptions } = useRating('impact');
  const { options: likelihoodOptions } = useRating('likelihood');
  const getComputedRating = useCalculateRiskRating(controlType);

  return initializeHeatmapCells(
    likelihoodOptions.length,
    impactOptions.length,
    (r, c) => {
      const rating = getComputedRating({ likelihood: r + 1, impact: c + 1 });

      return { label: rating.label, background: rating.color! };
    }
  );
};

export const useGetRiskAssessmentRatingsData = (
  controlType: Risk_Assessment_Result_Control_Type_Enum,
  risks: readonly RiskRegisterFields[] | undefined
): HeatmapCellData[][] => {
  const cellData = useCreateCellDataWithBackgroundColor(controlType);

  const rawData = risks
    ?.map((a) => {
      return {
        Impact:
          controlType === Risk_Assessment_Result_Control_Type_Enum.Controlled
            ? a?.ControlledImpactValue
            : a?.UncontrolledImpactValue,
        Likelihood:
          controlType === Risk_Assessment_Result_Control_Type_Enum.Controlled
            ? a?.ControlledLikelihoodValue
            : a?.UncontrolledLikelihoodValue,
      };
    })
    .filter(hasImpactAndLikelihood);

  populateCellDataValues(cellData, rawData);

  return cellData;
};
