import type { FileUploadProps } from '@cloudscape-design/components/file-upload';
import FileUpload from '@cloudscape-design/components/file-upload';
import { allowedFileExtensions } from '@risksmart-app/shared/allowedFileExtensions';
import type { FieldPath, FieldValues } from 'react-hook-form';
import { useFormContext } from 'react-hook-form';

import { FormField } from '@/components/Form/Form/FormField';
import HelpLink from '@/components/HelpPanel/HelpLink';

import ControlledFileList from '../ControlledFileList';
import { Controller } from '../FieldController/Controller';
import { useIsFieldReadOnly } from '../Form/CustomisableForm/hooks/useIsFieldReadOnly';
import type { ControlledBaseProps } from '../types';
import styles from './style.module.scss';

interface Props<
  T extends FieldValues,
  SFName extends FieldPath<T> = FieldPath<T>,
> extends ControlledBaseProps<T>,
    Partial<FileUploadProps> {
  saveFilesName?: SFName;
  disabled?: boolean;
  testId?: string;
}

export const ControlledFileUpload = <T extends FieldValues>({
  name,
  control,
  label,
  saveFilesName,
  disabled,
  forceRequired,
  defaultRequired,
  testId,
  description,
  allowDefaultValue,
  ...props
}: Props<T>) => {
  const { setValue } = useFormContext();
  const { error } = control.getFieldState(name);
  const multiple = props.multiple ?? true;
  const readOnly = useIsFieldReadOnly(name);

  return (
    <Controller
      defaultRequired={defaultRequired}
      forceRequired={forceRequired}
      allowDefaultValue={allowDefaultValue}
      name={name}
      control={control}
      render={({ field: { ref, onChange, value } }) => {
        return (
          <FormField
            label={label}
            errorText={error?.message}
            stretch
            testId={testId}
            info={
              description && (
                <HelpLink title={label} content={description} id={label} />
              )
            }
          >
            {!disabled && !readOnly && (
              <FileUpload
                {...{ className: styles.hideFiles }}
                accept={allowedFileExtensions.join(',')}
                ref={ref}
                value={value || []}
                onChange={(e) => {
                  onChange(e.detail.value);
                  // remove existing file if not allowing multiple
                  if (saveFilesName && !multiple) {
                    setValue(saveFilesName, [] as T[typeof saveFilesName]);
                  }
                }}
                showFileLastModified={true}
                showFileSize={true}
                multiple={multiple}
                i18nStrings={{
                  uploadButtonText: (e) => (e ? 'Choose files' : 'Choose file'),
                  dropzoneText: (e) =>
                    e ? 'Drop files to upload' : 'Drop file to upload',
                  removeFileAriaLabel: (e) => `Remove file ${e + 1}`,
                  limitShowFewer: 'Show fewer files',
                  limitShowMore: 'Show more files',
                  errorIconAriaLabel: 'Error',
                }}
                {...props}
              />
            )}

            {saveFilesName && (
              <ControlledFileList
                disabled={disabled || readOnly}
                name={saveFilesName}
                control={control}
              />
            )}
            <div className={'newFiles'}>
              <ControlledFileList
                disabled={disabled || readOnly}
                name={name}
                control={control}
              />
            </div>
          </FormField>
        );
      }}
    />
  );
};
