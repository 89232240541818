import type { SideNavigationProps } from '@cloudscape-design/components/side-navigation';

import type { CountRenderer } from './Count';
import { NavItems } from './NavItems';

export const SubItems = ({
  item,
  renderCount,
  altTheme,
}: {
  item: SideNavigationProps.Section;
  renderCount?: CountRenderer;
  altTheme?: boolean;
}) => {
  return (
    <ul className={'pt-2 pl-4 text-sm list-none'}>
      <NavItems
        items={item.items}
        isChild={true}
        renderCount={renderCount}
        altTheme={altTheme}
      />
    </ul>
  );
};
