import { useRating } from '@risksmart-app/components/hooks/useRating';
import type { ContentTable } from 'pdfmake/interfaces';
import { useTranslation } from 'react-i18next';

import { useGetObligationImpactsByParentIdLazyQuery } from '@/generated/graphql';
import { createTable, tableHeaders } from '@/utils/pdf/table';

const useImpactsExportTable = (
  obligationId: string
): [() => Promise<ContentTable>, boolean] => {
  const { getLabel: getImpactLabel } = useRating('impact');
  const [getImpacts, getImpactsResult] =
    useGetObligationImpactsByParentIdLazyQuery({
      variables: {
        _eq: obligationId,
      },
    });

  const { t: impactsColumns } = useTranslation(['common'], {
    keyPrefix: 'impacts.columns',
  });
  const createExportTable = async () => {
    const { data: impactData } = await getImpacts();
    const issuesTableData = (impactData?.obligation_impact ?? []).map((i) => [
      i.Description,
      getImpactLabel(i.ImpactRating),
    ]);

    return createTable({
      widths: '*',
      body: [
        tableHeaders([impactsColumns('Title'), impactsColumns('Impact')]),
        ...issuesTableData,
      ],
    });
  };

  return [createExportTable, getImpactsResult.loading];
};

export default useImpactsExportTable;
