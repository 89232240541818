import SpaceBetween from '@cloudscape-design/components/space-between';
import Button from '@risksmart-app/components/Button';
import { PageNotFound } from '@risksmart-app/components/errors/errors';
import { useGetGuidParam } from '@risksmart-app/components/routes/routes.utils';
import type { FC } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { PageLayout } from 'src/layouts';
import { Permission } from 'src/rbac/Permission';
import { useHasPermission } from 'src/rbac/useHasPermission';
import {
  useGetDetailParentPath,
  useGetDetailPath,
} from 'src/routes/useGetDetailParentPath';

import ControlledTabs from '@/components/ControlledTabs';
import {
  Parent_Type_Enum,
  useDeleteControlsMutation,
  useGetControlByIdQuery,
} from '@/generated/graphql';
import { useDeleteResultNotification } from '@/hooks/useMutationResultNotification';
import { useIsFeatureVisibleToOrg } from '@/utils/featureFlags';
import { getFriendlyId } from '@/utils/friendlyId';
import { evictField } from '@/utils/graphqlUtils';
import type { IssueTaxonomyKeys } from '@/utils/issueVariantUtils';

import DeleteControlModal from '../tab/DeleteModal/DeleteControlModal';
import useExporter from './useExporter';
import { useTabs } from './useTabs';

type Props = {
  activeTabId:
    | 'actions'
    | 'approvals'
    | 'details'
    | 'indicators'
    | 'issues'
    | 'linkedItems'
    | 'performance'
    | IssueTaxonomyKeys;
  showDeleteButton?: boolean;
};

const Page: FC<Props> = ({ activeTabId, showDeleteButton }) => {
  const controlId = useGetGuidParam('controlId');

  const navigate = useNavigate();

  const detailPath = useGetDetailPath(controlId);
  const parentPath = useGetDetailParentPath(controlId);

  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const { t } = useTranslation(['common']);
  const { t: st } = useTranslation(['common'], { keyPrefix: 'controls' });
  const defaultTitle = st('fallback_title');

  const { data, error } = useGetControlByIdQuery({
    variables: {
      _eq: controlId,
    },
    fetchPolicy: 'no-cache',
  });
  if (error) {
    throw error;
  }
  if (data?.control.length === 0) {
    throw new PageNotFound(`Control with id ${controlId} not found`);
  }
  const control = data?.control?.[0];
  const complianceMonitoringEnabled = useIsFeatureVisibleToOrg(
    'compliance_monitoring'
  );
  const internalAuditEnabled = useIsFeatureVisibleToOrg('internal_audit');
  const canViewCompliance = useHasPermission(
    'read:compliance_monitoring_assessment',
    control
  );
  const canViewInternalAudit = useHasPermission(
    'read:internal_audit_report',
    control
  );
  const [exportItem, { loading: exporting }] = useExporter(
    controlId,
    internalAuditEnabled && canViewInternalAudit,
    complianceMonitoringEnabled && canViewCompliance
  );
  const tabs = useTabs(detailPath, control);
  const [deleteControls, deleteResult] = useDeleteControlsMutation({
    update: (cache) => evictField(cache, 'control'),
  });

  const onDelete = useDeleteResultNotification({
    asyncAction: async () => {
      if (!control) {
        throw new Error('Control not selected');
      }
      await deleteControls({
        variables: {
          Ids: [control.Id],
        },
      });
      navigate(parentPath);
      setIsDeleteModalVisible(false);

      return true;
    },
    entityName: st('entity_name'),
    failureAction: () => {
      setIsDeleteModalVisible(false);
    },
  });
  const counter =
    control &&
    `(${getFriendlyId(Parent_Type_Enum.Control, control.SequentialId)})`;

  return (
    <PageLayout
      title={control?.Title}
      meta={{
        title: defaultTitle,
      }}
      counter={counter}
      actions={
        <SpaceBetween direction={'horizontal'} size={'xs'}>
          <Button
            iconName={'download'}
            disabled={exporting}
            onClick={exportItem}
          >
            {t('export')}
          </Button>
          {showDeleteButton && (
            <Permission permission={'delete:control'} parentObject={control}>
              <Button
                variant={'normal'}
                formAction={'none'}
                onClick={() => {
                  setIsDeleteModalVisible(true);
                }}
              >
                {t('delete')}
              </Button>
            </Permission>
          )}
        </SpaceBetween>
      }
    >
      <ControlledTabs
        activeTabId={activeTabId}
        tabs={tabs}
        variant={'container'}
      />
      <DeleteControlModal
        loading={deleteResult.loading}
        isVisible={isDeleteModalVisible}
        onDelete={onDelete}
        onDismiss={() => setIsDeleteModalVisible(false)}
        showUnlink={false}
      />
    </PageLayout>
  );
};

export default Page;
