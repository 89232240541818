import i18n from '@risksmart-app/i18n/i18n';
import _ from 'lodash';
import { useMemo } from 'react';
import type { PolicyRegisterFields } from 'src/pages/policy/types';

import { Parent_Type_Enum } from '@/generated/graphql';
import { GetDocumentsDocument } from '@/generated/graphql.typed';
import { policyRegisterUrl } from '@/utils/urls';

import { useGetPolicySmartWidgetTableProps } from '../../../policy/config';
import { UNRATED } from '../../Gigawidget/types';
import {
  dateRangeFilter,
  departmentsFilter,
  tagsFilter,
} from '../../Gigawidget/util/filterHelpers';
import { createDataSource } from '../createDataSource';
import {
  dashboardDateRangeClickthroughFilter,
  dateRangeClickthroughFilter,
  defaultClickthroughFilterWithUnrated,
  tagAndDepartmentCategoryGetters,
} from '../dataSourceHelpers';

export default createDataSource({
  useRelativeCustomAttributeDates: true,
  parentTypes: [Parent_Type_Enum.Document],
  hasAccess: () => true,
  documentNode: GetDocumentsDocument,
  useDefaultVariables: () => ({ where: {}, filesWhere: {} }),
  useTablePropsHook: (data, options) => {
    const assessmentData = useMemo(
      () =>
        data?.assessment_result_parent.map((ar) => ar.documentAssessmentResult),
      [data?.assessment_result_parent]
    );

    return useGetPolicySmartWidgetTableProps(
      data?.document,
      assessmentData,
      data?.form_configuration[0]?.customAttributeSchema ?? null,
      options
    );
  },
  entityNamePlural: 'document_other',
  entityNameSingular: 'document_one',
  fields: 'policy.fields',
  dashboardFilterConfig: {
    tagsFilter: (tags) => ({ where: { tags: tagsFilter(tags) } }),
    departmentsFilter: (departments) => ({
      where: { departments: departmentsFilter(departments) },
    }),
    dateFilter: (dateRange, precision) => ({
      where: { CreatedAtTimestamp: dateRangeFilter(dateRange, precision) },
    }),
    dateClickthroughFilter:
      dashboardDateRangeClickthroughFilter('CreatedAtTimestamp'),
  },
  clickThroughUrl: (filters) => policyRegisterUrl(filters),
  categoryGetters: [
    ...tagAndDepartmentCategoryGetters<PolicyRegisterFields>(),
    {
      id: 'owner',
      name: () => i18n.t('policy.columns.owner'),
      categoryGetter: (item) =>
        item.allOwners.map((owner) => ({ key: owner.id, label: owner.label })),
      clickthroughFilter: (category) => [
        {
          propertyKey: 'allOwners',
          operator: '=',
          value: category.key,
        },
      ],
    },
    {
      id: 'rating',
      name: () => i18n.t('policy.columns.rating'),
      categoryGetter: (data) => ({
        sortKey: `${data.PerformanceResultValue ?? -1}`,
        key: data.PerformanceResultValue ?? UNRATED,
        label: data.PerformanceResultValue
          ? (data.PerformanceResult ?? UNRATED)
          : UNRATED,
      }),
      ratingColourKey: 'performance_result',
      clickthroughFilter:
        defaultClickthroughFilterWithUnrated('PerformanceResult'),
    },
    {
      id: 'status',
      name: () => i18n.t('policy.columns.status'),
      categoryGetter: (data) => ({
        key: data.StatusValue ?? UNRATED,
        label: data.StatusValue ? (data.Status ?? 'No Status') : 'No Status',
      }),
      ratingColourKey: 'document_file_status',
      clickthroughFilter: defaultClickthroughFilterWithUnrated('Status'),
    },
    {
      id: 'lastReviewedDate',
      name: () => i18n.t('policy.columns.review_date'),
      categoryGetter: (data) =>
        data.ReviewDate ? new Date(data.ReviewDate) : null,
      date: true,
      dashboardDateFilterOverride: (dateRange, precision) => ({
        filesWhere: { ReviewDate: dateRangeFilter(dateRange, precision) },
      }),
      clickthroughFilter: dateRangeClickthroughFilter('ReviewDate'),
    },
    {
      id: 'nextReviewDue',
      name: () => i18n.t('policy.columns.review_due'),
      categoryGetter: (data) =>
        data.NextReviewDate ? new Date(data.NextReviewDate) : null,
      date: true,
      dashboardDateFilterOverride: (dateRange, precision) => ({
        filesWhere: { NextReviewDate: dateRangeFilter(dateRange, precision) },
      }),
      clickthroughFilter: dateRangeClickthroughFilter('NextReviewDate'),
    },
    {
      id: 'createdDate',
      name: () => i18n.t('columns.created_on'),
      categoryGetter: (data) => new Date(data.CreatedAtTimestamp),
      date: true,
      dashboardDateFilterOverride: (dateRange, precision) => ({
        where: { CreatedAtTimestamp: dateRangeFilter(dateRange, precision) },
      }),
      clickthroughFilter: dateRangeClickthroughFilter('CreatedAtTimestamp'),
    },
  ],
});
