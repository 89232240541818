import Alert from '@cloudscape-design/components/alert';
import SpaceBetween from '@cloudscape-design/components/space-between';
import Loading from '@risksmart-app/components/Loading';
import type { FC } from 'react';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  useGetEnterpriseRisksByTierQuery,
  useGetEntitiesQuery,
} from '@/generated/graphql';

import ControlledSelect from '../Form/ControlledSelect';

type Props = {
  entityId: null | string | undefined;
  enterpriseRiskId: null | string | undefined;
  riskTier: number;
};

const InstantiateEnterpriseRiskForm: FC<Props> = ({ riskTier }) => {
  const { control } = useFormContext();
  const { data: entities, loading } = useGetEntitiesQuery();
  const { data: enterpriseRisks, loading: enterpriseRisksLoading } =
    useGetEnterpriseRisksByTierQuery({
      variables: {
        Tier: riskTier,
      },
    });
  const { t } = useTranslation(['common'], { keyPrefix: 'enterpriseRisks' });

  const enterpriseRiskOptions = useMemo(() => {
    return enterpriseRisks?.enterprise_risk.map((risk) => ({
      label: risk.Title,
      value: risk.Id,
    }));
  }, [enterpriseRisks]);

  const entityOptions = useMemo(
    () => [
      ...(entities?.entity
        .filter((entity) => !entity.children || !entity.children.length)
        .map((entity) => ({
          label: entity.Name,
          value: entity.Id,
        })) ?? []),
    ],
    [entities]
  );

  if (loading || enterpriseRisksLoading) {
    return <Loading />;
  }

  return (
    <>
      <Alert type={'warning'}>{t('addRiskToEnterpriseRisk.alert')}</Alert>
      <SpaceBetween direction={'vertical'} size={'xs'}>
        <ControlledSelect
          key={'enterpriseRisk'}
          control={control}
          options={enterpriseRiskOptions}
          name={'EnterpriseRiskId'}
          label={'Enterprise Risk'}
        />
        <ControlledSelect
          key={'entity'}
          control={control}
          options={entityOptions}
          name={'EntityId'}
          label={'Entity'}
        />
      </SpaceBetween>
    </>
  );
};

export default InstantiateEnterpriseRiskForm;
