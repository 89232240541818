import Button from '@risksmart-app/components/Button';
import { useRating } from '@risksmart-app/components/hooks/useRating';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useControlTypeLabel } from 'src/pages/assessments/forms/useControlTypeLabel';
import { UNRATED } from 'src/pages/controls/lookupData';

import EmptyEntityCollection from '@/components/EmptyCollection/EmptyEntityCollection';
import type { CustomAttributeSchema } from '@/components/Form/CustomAttributes/CustomAttributeSchema';
import Link from '@/components/Link';
import SimpleRatingBadge from '@/components/SimpleRatingBadge';
import { Risk_Assessment_Result_Control_Type_Enum } from '@/generated/graphql';
import { useRiskScore } from '@/hooks/useRiskScore';
import type { UseGetTablePropsOptions } from '@/utils/table/hooks/useGetStatelessTableProps';
import { useGetTableProps } from '@/utils/table/hooks/useGetTableProps';
import type { TableFields, TablePropsWithActions } from '@/utils/table/types';
import { dateColumn } from '@/utils/table/utils/dateColumn';

import type { ObjectWithContributors } from '../../../../../rbac/Permission';
import { Permission } from '../../../../../rbac/Permission';
import type {
  RiskAssessmentResultFlatFields,
  RiskAssessmentResultRegisterFields,
} from './types';
import { useRiskRatingLabelledFields } from './useRiskRatingLabelledFields';

const useGetFieldConfig = (
  onOpenResult: (id: string) => void,
  riskId: string
): TableFields<RiskAssessmentResultRegisterFields> => {
  const { t: at } = useTranslation(['common'], {
    keyPrefix: 'assessments',
  });
  const { t: ar } = useTranslation(['common'], {
    keyPrefix: 'assessmentResults',
  });
  const { t: rt } = useTranslation(['common'], {
    keyPrefix: 'ratings',
  });
  const getControlTypeLabel = useControlTypeLabel();
  const riskScores = useRiskScore(riskId);
  const { getByValue: getUncontrolledByValue } = useRating('risk_uncontrolled');
  const { getByValue: getControlledByValue } = useRating('risk_controlled');
  const { getByValue: getLikelihoodByValue } = useRating('likelihood');
  const { getByValue: getImpactByValue } = useRating('impact');
  const { getByValue: statusGetByValue } = useRating('assessment_status');

  return useMemo(
    () => ({
      TestDate: dateColumn(rt('columns.TestDate'), 'TestDate', (item) =>
        onOpenResult(item.Id)
      ),
      ParentTitle: {
        header: rt('columns.AssessmentTitle'),
        cell: (item) =>
          item.parents.find((p) => p.assessment)?.assessment ? (
            <Link
              isRelativeUrl={true}
              variant={'secondary'}
              href={item.parents.find((p) => p.assessment)?.assessment?.Id}
            >
              {item.parents.find((p) => p.assessment)?.assessment?.Title}
            </Link>
          ) : (
            '-'
          ),
        isRowHeader: true,
      },
      ControlTypeLabelled: {
        header: ar('fields.ControlType'),
        cell: (item) => getControlTypeLabel(item.ControlType),
        sortingField: 'ControlType',
      },
      RatingLabelled: {
        header: rt('columns.Rating'),
        sortingField: 'Rating',
        cell: (item) => {
          if (!riskScores.showScore) {
            const result =
              item.ControlType ===
              Risk_Assessment_Result_Control_Type_Enum.Controlled
                ? getControlledByValue(item.Rating)
                : getUncontrolledByValue(item.Rating);

            return <SimpleRatingBadge rating={result ?? UNRATED} />;
          }

          return '-';
        },
      },
      ImpactLabelled: {
        header: rt('columns.Impact'),
        cell: (item) =>
          item.Impact ? (
            <SimpleRatingBadge rating={getImpactByValue(item.Impact)} />
          ) : (
            '-'
          ),
      },
      LikelihoodLabelled: {
        header: rt('columns.Likelihood'),
        cell: (item) =>
          item.Likelihood ? (
            <SimpleRatingBadge rating={getLikelihoodByValue(item.Likelihood)} />
          ) : (
            '-'
          ),
      },
      StatusLabelled: {
        header: rt('columns.AssessmentStatus'),
        cell: (item) => {
          const status = item.parents.find((p) => p.assessment)?.assessment
            ?.Status;

          return <SimpleRatingBadge rating={statusGetByValue(status)} />;
        },
      },
      CompletionDate: dateColumn(
        at('columns.CompletionDate'),
        'ActualCompletionDate'
      ),
    }),
    [
      ar,
      at,
      getControlTypeLabel,
      getControlledByValue,
      getImpactByValue,
      getLikelihoodByValue,
      getUncontrolledByValue,
      onOpenResult,
      riskScores.showScore,
      rt,
      statusGetByValue,
    ]
  );
};

const useGetRiskRatingTableProps = (
  risk: ObjectWithContributors,
  onOpenResult: (id: string) => void,
  records: RiskAssessmentResultFlatFields[] | undefined,
  customAttributeSchema?: CustomAttributeSchema[] | null
): UseGetTablePropsOptions<RiskAssessmentResultRegisterFields> => {
  const labelledFields = useRiskRatingLabelledFields(risk.Id, records);
  const fields = useGetFieldConfig(onOpenResult, risk.Id);

  return {
    customAttributeSchema: customAttributeSchema,
    data: labelledFields,
    enableFiltering: true,
    entityLabel: 'rating',
    defaultSortingState: {
      sortingColumn: 'TestDate',
      sortingDirection: 'desc',
    },
    initialColumns: [
      'TestDate',
      'ParentTitle',
      'ControlTypeLabelled',
      'RatingLabelled',
      'ImpactLabelled',
      'LikelihoodLabelled',
      'StatusLabelled',
    ],
    preferencesStorageKey: 'RiskRatingRegisterTable-Preferences',
    fields,
  };
};

export const useGetCollectionTableProps = (
  risk: ObjectWithContributors,
  onOpenResult: (id: string) => void,
  onAddRating: () => void,
  records: RiskAssessmentResultFlatFields[] | undefined,
  customAttributeSchema?: CustomAttributeSchema[] | null
): TablePropsWithActions<RiskAssessmentResultRegisterFields> => {
  const { t } = useTranslation(['common']);
  const props = useGetRiskRatingTableProps(
    risk,
    onOpenResult,
    records,
    customAttributeSchema
  );

  return {
    ...useGetTableProps(props),
    empty: (
      <EmptyEntityCollection
        entityLabel={t('rating')}
        action={
          <Permission
            permission={'insert:risk_assessment_result'}
            parentObject={risk}
          >
            <Button formAction={'none'} onClick={onAddRating}>
              {t('assessments.add_rating_button')}
            </Button>
          </Permission>
        }
      />
    ),
  };
};
