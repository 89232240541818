import type {
  PropertyFilterProperty,
  PropertyFilterQuery,
} from '@cloudscape-design/collection-hooks';
import { useRating } from '@risksmart-app/components/hooks/useRating';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { FilterPropertyDashboardItem } from '@/components/RegisterDashboard/FilterPropertyDashboardItem';
import RegisterDashboard from '@/components/RegisterDashboard/RegisterDashboard';
import { emptyFilterQuery } from '@/utils/collectionUtils';

import { UNRATED } from './lookupData';
import type { ControlTableFields } from './types';

type ControlDashboardProps = {
  items: readonly ControlTableFields[] | undefined;
  propertyFilterQuery: PropertyFilterQuery;
  onFilterQueryChanged: (query: PropertyFilterQuery) => void;
  filteringProperties: readonly PropertyFilterProperty<unknown>[];
};

export const ControlDashboard: FC<ControlDashboardProps> = ({
  items,
  onFilterQueryChanged,
  propertyFilterQuery,
  filteringProperties,
}) => {
  const { t } = useTranslation(['common'], {
    keyPrefix: 'controls.dashboard',
  });

  const { options } = useRating('effectiveness');

  // TODO This `map` feels inconsistent with the rest of the codebase
  const effectivenessValues = [
    ...options,
    {
      value: UNRATED.label,
      label: UNRATED.label,
    },
  ].map(({ value, label }) => ({
    value: String(value),
    label,
  }));

  return (
    <RegisterDashboard>
      {effectivenessValues.map(({ value, label }) => {
        return (
          <FilterPropertyDashboardItem
            key={value}
            onClick={onFilterQueryChanged}
            itemFilterQuery={{
              tokens: [],
              tokenGroups: [
                {
                  propertyKey: 'OverallEffectivenessLabelled',
                  value: label,
                  operator: '=',
                },
              ],
              operation: 'and',
            }}
            tableFilterQuery={propertyFilterQuery}
            title={label}
            items={items ?? []}
            filteringProperties={filteringProperties}
          />
        );
      })}
      <FilterPropertyDashboardItem
        onClick={onFilterQueryChanged}
        itemFilterQuery={emptyFilterQuery}
        tableFilterQuery={propertyFilterQuery}
        title={t('all')}
        items={items ?? []}
        filteringProperties={filteringProperties}
      />
    </RegisterDashboard>
  );
};
