import { useRating } from '@risksmart-app/components/hooks/useRating';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import type { GetChangeRequestsQuery } from '@/generated/graphql';
import { Approval_Status_Enum, Parent_Type_Enum } from '@/generated/graphql';
import { useChangeRequests } from '@/hooks/useChangeRequests';
import { getFriendlyId } from '@/utils/friendlyId';

import type { ChangeRequestRegisterFields } from './types';

export const useLabelledFields = (
  data?: GetChangeRequestsQuery
): ChangeRequestRegisterFields[] => {
  const { t } = useTranslation(['taxonomy', 'common']);
  const objectTypeMapping = t('common:objectTypes', {
    returnObjects: true,
  });
  const workflowMap = t('common:approvals.workflows', {
    returnObjects: true,
  });
  const {
    isActiveApprover,
    getCurrentLevel,
    getMaxLevel,
    getCurrentApprovers,
    getNextApprovers,
  } = useChangeRequests();
  const { getByValue } = useRating('approval_status');

  const getParentName = (item: GetChangeRequestsQuery['change_request'][0]) => {
    switch (item.parent?.ObjectType) {
      case 'document_file':
        return `${item.parent?.documentFile?.parent?.Title} (${item.parent?.documentFile?.Version})`;
      case 'acceptance':
        return item.parent?.acceptance?.Title;
      case 'risk':
        return item.parent?.risk?.Title;
      case 'control':
        return item.parent?.control?.Title;
      case 'action':
        return item.parent?.action?.Title;
      case 'issue_assessment':
        return item.parent?.issue_assessment?.parent?.Title;
      default:
        return item.parent
          ? getFriendlyId(item.parent.ObjectType, item.parent.SequentialId)
          : 'Deleted Item'; // TODO: Translation
    }
  };

  return useMemo((): ChangeRequestRegisterFields[] => {
    if (!data) {
      return [];
    }

    return data.change_request.map((cr) => ({
      ...cr,
      ParentType: cr.parent?.ObjectType
        ? objectTypeMapping[
            cr.parent?.ObjectType as keyof typeof objectTypeMapping
          ]
        : '',
      Workflow:
        workflowMap[
          cr.responses[0]?.approver?.level?.approval
            ?.Workflow as keyof typeof workflowMap
        ],
      StatusLabelled: getByValue(cr.ChangeRequestStatus)?.label ?? '-',
      ParentSequentialId: cr.parent?.ObjectType
        ? getFriendlyId(
            cr.parent.ObjectType === Parent_Type_Enum.DocumentFile
              ? Parent_Type_Enum.Document
              : cr.parent.ObjectType,
            cr.parent.ObjectType === Parent_Type_Enum.DocumentFile
              ? cr.parent?.documentFile?.parent?.SequentialId
              : cr.parent?.SequentialId
          )
        : null,
      RequiresAction: isActiveApprover(
        cr,
        cr.currentUserOwnerList?.map((u) => u.UserId ?? '')
      ),
      ParentName: getParentName(cr),
      allApprovers:
        cr.responses?.map((response, i) => ({
          id: response.approver.OwnerApprover
            ? String(i)
            : (response.approver.user?.Id ?? ''),
          label: response.approver.OwnerApprover
            ? 'Owner' // TODO: Translation
            : (response.approver.user?.FriendlyName ??
              response.approver.group?.Name ??
              ''),
        })) ?? [],
      allRequesters: [
        {
          id: cr.createdBy?.Id ?? '',
          label: cr.createdBy?.FriendlyName ?? '',
        },
        ...cr.contributors.map((c) => ({
          id: c.user?.Id ?? '',
          label: c.user?.FriendlyName ?? '',
        })),
      ],
      approvalConfig: Array.from(
        new Set(cr.responses.map((r) => r.approver.level?.approval?.Id ?? ''))
      ),
      DateLastActioned: cr.responses
        .filter((r) => r.Approved !== null)
        .map((r) => r.ModifiedAtTimestamp)
        .sort((a, b) => b.localeCompare(a))
        .pop(),
      DateClosed:
        cr.ChangeRequestStatus !== Approval_Status_Enum.Pending
          ? cr.ModifiedAtTimestamp
          : null,
      CurrentStage: t(
        'common:approvals.requestsRegister.columns.currentStageValue',
        {
          current: getCurrentLevel(cr.responses) + 1,
          max: getMaxLevel(cr.responses) + 1,
        }
      ),
      currentApprovers: getCurrentApprovers(cr.responses),
      nextApprovers: getNextApprovers(cr.responses),
    }));
  }, [
    data,
    workflowMap,
    objectTypeMapping,
    getByValue,
    isActiveApprover,
    getCurrentLevel,
    getMaxLevel,
    getCurrentApprovers,
    getNextApprovers,
    t,
  ]);
};
