import Box from '@cloudscape-design/components/box';
import ErrorContent from '@risksmart-app/components/ErrorPages/ErrorContent';
import type { FC, ReactNode } from 'react';

import Loading from '@/components/Loading';
import { useGetRoleAccessQuery } from '@/generated/graphql';

import { PermissionsContext } from './PermissionsContext';

type Props = { children: ReactNode };

export const PermissionsProvider: FC<Props> = ({ children }) => {
  const { data, loading, error } = useGetRoleAccessQuery({});
  const roleAccess = data?.role_access;

  return (
    <PermissionsContext.Provider value={roleAccess ? roleAccess : null}>
      {loading && <Loading />}
      {error && (
        <ErrorContent
          title={'Sorry, we couldn’t make that happen'}
          imgSrc={'/errors/rubiks-cube.png'}
          imgAlt={'binoculars'}
        >
          <Box variant={'p'}>{'Please try again later'}</Box>
        </ErrorContent>
      )}
      {!error && !loading && children}
    </PermissionsContext.Provider>
  );
};
