import type { FC } from 'react';
import { useFormContext } from 'react-hook-form';

import { ControlledGroupAndUserContributorMultiSelect } from '@/components/Form/ControlledGroupAndUserContributorMultiSelect/ControlledGroupAndUserContributorMultiSelect';
import ControlledInput from '@/components/Form/ControlledInput';
import ControlledSelect from '@/components/Form/ControlledSelect';
import {
  Contributor_Type_Enum,
  useGetEntitiesQuery,
} from '@/generated/graphql';

const EntityFormFields: FC<{ Id?: string }> = ({ Id }) => {
  const { control } = useFormContext();

  const { data } = useGetEntitiesQuery();

  const parentOptions = data?.entity
    .filter((entity) => entity.Id !== Id)
    .map((entity) => ({
      value: entity.Id,
      label: entity.Name,
    }));

  return (
    <>
      <ControlledInput name={'Name'} control={control} label={'Name'} />

      <ControlledInput
        name={'Description'}
        control={control}
        label={'Description'}
        testId={'description'}
      />

      <ControlledSelect
        name={'ParentId'}
        control={control}
        label={'Parent Entity'}
        options={parentOptions}
        testId={'parent-entity'}
      />

      <ControlledGroupAndUserContributorMultiSelect
        name={'Owners'}
        control={control}
        label={'Default Owners'}
        includeGroups
        inheritedContributorsName={'ancestorContributors'}
        contributorType={Contributor_Type_Enum.Owner}
        testId={'default-owners'}
      />
    </>
  );
};

export default EntityFormFields;
