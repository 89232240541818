import Table from '@risksmart-app/components/Table';
import type { FC } from 'react';
import { useNavigate } from 'react-router';
import { useGetCollectionStatelessTableProps } from 'src/pages/impacts/ratings/config';

import type { CustomAttributeSchema } from '@/components/Form/CustomAttributes/CustomAttributeSchema';
import type {
  GetAppetitesGroupedByImpactQuery,
  GetImpactRatingsQuery,
} from '@/generated/graphql';
import type { CollectionData } from '@/utils/collectionUtils';
import { assessmentResultsEditUrl } from '@/utils/urls';

type ImpactRating = CollectionData<
  GetImpactRatingsQuery['impact_rating'][number]
>;
type ImpactAppetites = CollectionData<
  GetAppetitesGroupedByImpactQuery['impact']
>;

interface Props {
  loading: boolean;
  assessmentId: string;
  records: ImpactRating[] | undefined;
  impactAppetites?: ImpactAppetites;
  customAttributeSchema?: CustomAttributeSchema | null;
}

const AssessmentImpactRatingRegister: FC<Props> = ({
  loading,
  assessmentId,
  records,
  impactAppetites,
  customAttributeSchema,
}) => {
  const navigate = useNavigate();
  const tableProps = useGetCollectionStatelessTableProps(
    records,
    impactAppetites,
    customAttributeSchema ?? null,
    (item) => navigate(assessmentResultsEditUrl(assessmentId, item.Id))
  );

  return <Table variant={'embedded'} {...tableProps} loading={loading} />;
};

export default AssessmentImpactRatingRegister;
