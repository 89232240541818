import i18n from '@risksmart-app/i18n/i18n';

import { Parent_Type_Enum } from '@/generated/graphql';
import { getFriendlyId } from '@/utils/friendlyId';
import { issueDetailsUrl } from '@/utils/urls';

import type { GetItem } from './types';

export const getItem: GetItem = (item, lookupData) => {
  const issueId = item.data?.objectId ?? item.data?.issueId;
  const issue = lookupData.issues?.[issueId];

  return {
    message: i18n.t('notifications.messages.issueDue', {
      title:
        issue?.SequentialId && issue?.Title
          ? `${issue?.Title}`
          : i18n.t('notifications.unknown'),
    }),

    url: issue ? issueDetailsUrl(issue.Id) : null,
    id: `${getFriendlyId(Parent_Type_Enum.Issue, issue?.SequentialId)}`,
  };
};
