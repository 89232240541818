import i18n from '@risksmart-app/i18n/i18n';
import { t } from 'i18next';

import { ParentTypeEnum } from '../../../generated/graphql';
import { DataSourceType } from '../schema';
import { getAuditColumns } from './shared';
import { SharedDataset } from './types';

export const getConsequences = () =>
  ({
    label: i18n.format(t('consequence_other'), 'capitalize'),
    parentTypes: new Set<DataSourceType>(['issues']),
    customAttributeFormConfigurationParentTypes: [ParentTypeEnum.Consequence],
    fields: {
      ...getAuditColumns(),
      title: {
        dataType: 'text',
        displayType: 'text',
        label: t('consequences.columns.title'),
      },
      id: { dataType: 'guid', displayType: 'text', label: t('columns.guid') },
      description: {
        dataType: 'text',
        displayType: 'text',
        label: t('consequences.columns.description'),
      },
      criticality: {
        dataType: 'number',
        displayType: 'rating',
        ratingKey: 'criticality',
        label: t('consequences.columns.criticality'),
      },
      costType: {
        dataType: 'text',
        displayType: 'commonLookup',
        i18nKey: 'consequences.costType',
        label: t('consequences.columns.costType'),
      },
      costValue: {
        dataType: 'number',
        displayType: 'number',
        label: t('consequences.columns.costValue'),
      },
      type: {
        dataType: 'text',
        displayType: 'commonLookup',
        i18nKey: 'consequences.types',
        label: t('consequences.columns.type'),
      },
    },
  }) satisfies SharedDataset;
