import {
  ThirdPartyResponseEnumAction,
  ThirdPartyResponseStatusEnum,
} from '../../../generated/graphql';

export const getAllowedStatusesByAction = (
  action: ThirdPartyResponseEnumAction | undefined
): ThirdPartyResponseStatusEnum[] => {
  if (!action) {
    return [];
  }

  if (action === ThirdPartyResponseEnumAction.Recall) {
    return [
      ThirdPartyResponseStatusEnum.AwaitingReview,
      ThirdPartyResponseStatusEnum.InProgress,
      ThirdPartyResponseStatusEnum.Expired,
      ThirdPartyResponseStatusEnum.NotStarted,
    ];
  }

  if (
    action === ThirdPartyResponseEnumAction.Approve ||
    action === ThirdPartyResponseEnumAction.Reject ||
    action === ThirdPartyResponseEnumAction.RequestMoreInformation
  ) {
    return [ThirdPartyResponseStatusEnum.AwaitingReview];
  }

  return [];
};

export const getIsActionAllowed = (
  action: ThirdPartyResponseEnumAction | undefined,
  status: ThirdPartyResponseStatusEnum | undefined
) => {
  if (!action || !status) {
    return false;
  }

  return getAllowedStatusesByAction(action).includes(status);
};

export const getNewStatus = (
  action: ThirdPartyResponseEnumAction
): ThirdPartyResponseStatusEnum => {
  return {
    [ThirdPartyResponseEnumAction.Approve]:
      ThirdPartyResponseStatusEnum.Completed,
    [ThirdPartyResponseEnumAction.RequestMoreInformation]:
      ThirdPartyResponseStatusEnum.InProgress,
    [ThirdPartyResponseEnumAction.Recall]:
      ThirdPartyResponseStatusEnum.Recalled,
    [ThirdPartyResponseEnumAction.Reject]:
      ThirdPartyResponseStatusEnum.Rejected,
  }[action];
};
