import { useRating } from '@risksmart-app/components/hooks/useRating';
import { useTranslation } from 'react-i18next';
import { MAX_COL_WIDTH } from 'src/App.config';

import type { CustomAttributeSchema } from '@/components/Form/CustomAttributes/CustomAttributeSchema';
import IndicatorsPopover from '@/components/IndicatorsPopover/IndicatorsPopover';
import Link from '@/components/Link';
import ResponsiveRatingBadges from '@/components/ResponsiveRatingBadges';
import SimpleRatingBadge from '@/components/SimpleRatingBadge';
import { toLocalDate } from '@/utils/dateUtils';
import { useGetContributorsFieldConfig } from '@/utils/table/hooks/useGetContributorsFieldConfig';
import { useGetDepartmentFieldConfig } from '@/utils/table/hooks/useGetDepartmentFieldConfig';
import { useGetOwnersFieldConfig } from '@/utils/table/hooks/useGetOwnersFieldConfig';
import type {
  StatefulTableOptions,
  UseGetTablePropsOptions,
} from '@/utils/table/hooks/useGetStatelessTableProps';
import { useGetStatelessTableProps } from '@/utils/table/hooks/useGetStatelessTableProps';
import { useGetTableProps } from '@/utils/table/hooks/useGetTableProps';
import { useGetTablePropsWithoutUrlHash } from '@/utils/table/hooks/useGetTablePropsWithoutUrlHash';
import { useGetTagFieldConfig } from '@/utils/table/hooks/useGetTagFieldConfig';
import { useLinkArrayField } from '@/utils/table/hooks/useLinkArrayField';
import type { TableFields, TablePropsWithActions } from '@/utils/table/types';
import { dateColumn } from '@/utils/table/utils/dateColumn';
import { controlDetailsUrl } from '@/utils/urls';

import type { ControlFlatFields, ControlTableFields } from './types';
import { useGetControlGroupFieldConfig } from './useGetControlGroupFieldConfig';
import { useLabelledFields } from './useLabelledFields';

const useGetFieldConfig = (
  onEditRating?:
    | ((item: { controlId: string; ratingId: string }) => void)
    | null,
  useAbsoluteUrls: boolean = false
): TableFields<ControlTableFields> => {
  const allOwners = useGetOwnersFieldConfig<ControlTableFields>();
  const allContributors = useGetContributorsFieldConfig<ControlTableFields>();
  const tagField = useGetTagFieldConfig<ControlTableFields>();
  const controlGroups = useGetControlGroupFieldConfig<ControlTableFields>();
  const { getByValue: getByValueOverallEffectiveness, options } =
    useRating('effectiveness');
  const { getByValue: getByValueDesignEffectiveness } = useRating(
    'design_effectiveness'
  );
  const { getByValue: getByValuePerformanceEffectiveness } = useRating(
    'performance_effectiveness'
  );
  let maxRating = Number(
    options.sort(
      (a, b) =>
        (!isNaN(Number(b.value)) ? Number(b.value) : 0) -
        (!isNaN(Number(a.value)) ? Number(a.value) : 0)
    )[0].value
  );
  if (isNaN(maxRating)) {
    maxRating = 0;
  }
  const departmentField = useGetDepartmentFieldConfig<ControlTableFields>(
    (r) => r.departments
  );
  const { t: stc } = useTranslation(['common'], { keyPrefix: 'columns' });
  const { t: st } = useTranslation(['common'], {
    keyPrefix: 'controls.columns',
  });
  const { t } = useTranslation(['common'], { keyPrefix: 'columns' });
  const associations = useLinkArrayField<ControlTableFields>(
    t('associations'),
    (r) => r.ParentTitle
  );

  return {
    SequentialIdLabel: { header: stc('id'), sortingField: 'SequentialId' },
    Title: {
      header: st('title'),
      cell: (item) => (
        <Link
          variant={'secondary'}
          href={useAbsoluteUrls ? controlDetailsUrl(item.Id) : item.Id}
          isRelativeUrl={!useAbsoluteUrls}
        >
          {item.Title}
        </Link>
      ),
      maxWidth: MAX_COL_WIDTH,
      isRowHeader: true,
    },
    ControlTypeLabelled: {
      header: st('type'),
      cell: (item) => item.ControlTypeLabelled,
    },
    ParentTitle: associations,
    allOwners,
    allContributors,
    DesignEffectivenessLabelled: {
      header: st('design_effectiveness'),
      cell: (item) => {
        return (
          <SimpleRatingBadge
            rating={getByValueDesignEffectiveness(item.DesignEffectiveness)}
          >
            {item.DesignEffectivenessLabelled}
          </SimpleRatingBadge>
        );
      },
      exportVal: (item) =>
        getByValueDesignEffectiveness(item.DesignEffectiveness)?.label ?? '',
    },
    PerformanceEffectivenessLabelled: {
      header: st('performance_effectiveness'),
      cell: (item) => {
        return (
          <SimpleRatingBadge
            rating={getByValuePerformanceEffectiveness(
              item.PerformanceEffectiveness
            )}
          >
            {item.PerformanceEffectivenessLabelled}
          </SimpleRatingBadge>
        );
      },
      exportVal: (item) =>
        getByValuePerformanceEffectiveness(item.PerformanceEffectiveness)
          ?.label ?? '',
    },
    OverallEffectivenessLabelled: {
      header: st('effectiveness'),
      cell: (item) => {
        return (
          <SimpleRatingBadge
            rating={getByValueOverallEffectiveness(item.OverallEffectiveness)}
          >
            {item.OverallEffectivenessLabelled}
          </SimpleRatingBadge>
        );
      },
      exportVal: (item) =>
        getByValueOverallEffectiveness(item.OverallEffectiveness)?.label ?? '',
    },
    OverallEffectivenessHistory: {
      // TODO: translations
      header: 'Overall Effectiveness History',
      filterOptions: {
        filteringProperties: {
          operators: [],
        },
      },
      cell: (item) => {
        return (
          <ResponsiveRatingBadges
            maxRating={maxRating}
            invertRating={false}
            ratings={item.OverallEffectivenessHistory?.filter(
              (c) => c?.rating !== undefined
            ).map((c) => ({
              label: '-',
              ...getByValueOverallEffectiveness(c.rating!),
              id: c.id,
              rating: c.rating,
              testDate: c.testDate,
              onClick: onEditRating
                ? () => onEditRating({ controlId: item.Id, ratingId: c.id })
                : undefined,
            }))}
          ></ResponsiveRatingBadges>
        );
      },
      minWidth: 160,
      exportVal: (item) =>
        item.OverallEffectivenessHistory?.map(
          (c) =>
            `${toLocalDate(c.testDate)} ${getByValueOverallEffectiveness(c.rating)?.label}`
        ).join(',') ?? '',
    },
    OpenIssues: {
      header: st('open_issues'),
      cell: (item) => item.OpenIssues ?? '-',
      filterOptions: {
        filteringProperties: {
          operators: ['!=', '>', '<', '>=', '<='],
        },
      },
    },
    LinkedIndicatorCount: {
      header: st('linked_indicators'),
      cell: (item) => (
        <IndicatorsPopover id={item.Id} count={item.LinkedIndicatorCount} />
      ),
    },
    IssueCount: {
      header: st('issues'),
      cell: (item) => item.IssueCount ?? '-',
      filterOptions: {
        filteringProperties: {
          operators: ['!=', '>', '<', '>=', '<='],
        },
      },
    },

    OpenActions: {
      header: st('open_actions'),
      cell: (item) => item.OpenActions ?? '-',
      filterOptions: {
        filteringProperties: {
          operators: ['!=', '>', '<', '>=', '<='],
        },
      },
    },
    tags: tagField,
    //------------------
    departments: departmentField,
    CreatedAtTimestamp: dateColumn(stc('created_on'), 'CreatedAtTimestamp'),
    Description: {
      header: st('description'),
      cell: (item) => item.Description || '-',
      maxWidth: MAX_COL_WIDTH,
    },
    Id: {
      header: stc('guid'),
    },
    TestFrequency: {
      header: st('test_frequency'),
      cell: (item) => {
        return item.TestFrequency ?? '-';
      },
    },
    ModifiedAtTimestamp: dateColumn(stc('updated_on'), 'ModifiedAtTimestamp'),
    CreatedByUser: {
      header: stc('created_by_id'),
    },
    CreatedByUserName: {
      header: stc('created_by_username'),
    },
    LatestRatingDate: dateColumn(st('latest_rating_date'), 'LatestRatingDate'),
    NextTestDate: dateColumn(st('next_test_date'), 'NextTestDate'),
    NextTestOverdueDate: dateColumn(
      st('nextTestOverdue'),
      'NextTestOverdueDate'
    ),
    ControlGroups: controlGroups,
  };
};

const useGetControlTableProps = (
  records: ControlFlatFields[] | undefined,
  customAttributeSchema?: CustomAttributeSchema | null,
  onEditRating?:
    | ((item: { controlId: string; ratingId: string }) => void)
    | null,
  emptyCollectionAction: JSX.Element = <></>,
  useAbsoluteUrls: boolean = false
): UseGetTablePropsOptions<ControlTableFields> => {
  const { t: st } = useTranslation(['common'], { keyPrefix: 'controls' });
  const fields = useGetFieldConfig(onEditRating, useAbsoluteUrls);

  const labelledFields = useLabelledFields(records);

  return {
    tableId: 'controlRegister',
    data: labelledFields,
    customAttributeSchema,
    entityLabel: st('entity_name'),
    emptyCollectionAction,
    preferencesStorageKey: 'ControlRegisterTable-PreferencesV2',
    enableFiltering: true,

    initialColumns: [
      'Title',
      'ControlTypeLabelled',
      'ParentTitle',
      'allOwners',
      'OverallEffectivenessLabelled',
      'OpenIssues',
      'OpenActions',
      'tags',
    ],
    fields,
  };
};

export const useGetCollectionTableProps = (
  onEditRating: (item: { controlId: string; ratingId: string }) => void,
  records: ControlFlatFields[] | undefined,
  customAttributeSchema?: CustomAttributeSchema | null,
  emptyCollectionAction: JSX.Element = <></>
): TablePropsWithActions<ControlTableFields> => {
  const props = useGetControlTableProps(
    records,
    customAttributeSchema,
    onEditRating,
    emptyCollectionAction
  );

  return useGetTableProps(props);
};

export const useGetCollectionStatelessTableProps = (
  onEditRating: (item: { controlId: string; ratingId: string }) => void,
  records: ControlFlatFields[] | undefined,
  customAttributeSchema?: CustomAttributeSchema | null,
  emptyCollectionAction: JSX.Element = <></>
): TablePropsWithActions<ControlTableFields> => {
  const props = useGetControlTableProps(
    records,
    customAttributeSchema,
    onEditRating,
    emptyCollectionAction
  );

  return useGetTablePropsWithoutUrlHash(props);
};

export const useGetControlSmartWidgetTableProps = (
  records: ControlFlatFields[] | undefined,
  customAttributeSchema: CustomAttributeSchema | null,
  statefulTableOptions: StatefulTableOptions<ControlTableFields>
): TablePropsWithActions<ControlTableFields> => {
  const props = useGetControlTableProps(
    records,
    customAttributeSchema,
    undefined,
    <></>,
    true
  );

  return useGetStatelessTableProps<ControlTableFields>({
    ...props,
    ...statefulTableOptions,
    enableFiltering: false,
  });
};
