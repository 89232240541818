import SpaceBetween from '@cloudscape-design/components/space-between';
import Button from '@risksmart-app/components/Button';
import { useNotifications } from '@risksmart-app/components/Notifications/useNotifications';
import Table from '@risksmart-app/components/Table';
import type { FC } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PageLayout } from 'src/layouts';

import { useGetControlsQuery } from '@/generated/graphql';
import { getCounter } from '@/utils/collectionUtils';

import { useGetCollectionTableProps } from './config';
import { ControlDashboard } from './ControlDashboard';
import TestResultModal from './update/tabs/TestResultModal';

const Page: FC = () => {
  const { t } = useTranslation(['common'], {});
  const { t: st } = useTranslation(['common'], { keyPrefix: 'controls' });
  const { addNotification } = useNotifications();
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [openTestResult, setOpenTestResult] = useState<
    { controlId: string; ratingId: string } | undefined
  >();
  const { data, loading, refetch } = useGetControlsQuery({
    fetchPolicy: 'no-cache',
    onError: (error) => {
      addNotification({
        type: 'error',
        content: <>{error.message}</>,
      });
    },
  });
  const handleTestResultModalClose = () => {
    setOpenTestResult(undefined);
    setIsEditOpen(false);
    refetch();
  };

  const tableProps = useGetCollectionTableProps(
    (testResult) => {
      setOpenTestResult(testResult);
      setIsEditOpen(true);
    },
    data?.control,
    data?.form_configuration?.[0]?.customAttributeSchema
  );

  const counter = getCounter(tableProps.totalItemsCount, loading);
  const title = st('register_title');

  return (
    <PageLayout
      helpTranslationKey={'controls.registerHelp'}
      title={title}
      counter={counter}
      actions={
        <SpaceBetween direction={'horizontal'} size={'xs'}>
          <Button iconName={'download'} onClick={tableProps.exportToCsv}>
            {t('export')}
          </Button>
        </SpaceBetween>
      }
    >
      <ControlDashboard
        propertyFilterQuery={tableProps.propertyFilterQuery}
        items={tableProps.allItems}
        onFilterQueryChanged={tableProps.actions.setPropertyFiltering}
        filteringProperties={tableProps.filteringProperties}
      />
      <Table {...tableProps} loading={loading} />
      {isEditOpen && openTestResult && (
        <TestResultModal
          parentControlId={openTestResult.controlId}
          Id={openTestResult.ratingId}
          onDismiss={handleTestResultModalClose}
          assessmentMode={'rating'}
        />
      )}
    </PageLayout>
  );
};

export default Page;
