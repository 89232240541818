import type { FilteringProperty } from '@cloudscape-design/components/property-filter/interfaces';

import SimpleRatingBadge from '@/components/SimpleRatingBadge';

import SelectFilter from '../filters/SelectFilter';
import type { AllowedField } from '../utils';
import type { Helpers, ReportFieldType } from './types';

export const rating: ReportFieldType = {
  getChartColor({ fieldData, fieldDef, helpers }) {
    if (fieldDef.displayType !== 'rating') {
      throw new Error('rating filed type used out of context');
    }

    return helpers.getRatingByValue(
      fieldDef.ratingKey,
      fieldData.value as number | string
    )?.color;
  },
  getChartLabel({ fieldData, fieldDef, helpers }) {
    if (fieldDef.displayType !== 'rating') {
      throw new Error('rating filed type used out of context');
    }

    return (
      helpers.getRatingByValue(
        fieldDef.ratingKey,
        fieldData.value as number | string
      )?.label ?? ''
    );
  },
  cell: function ({ field, fieldData, helpers }) {
    const fieldDef = field.fieldDef;
    const value = fieldData.value;
    if (fieldDef.displayType !== 'rating') {
      throw new Error('rating filed type used out of context');
    }

    return (
      <SimpleRatingBadge
        rating={helpers.getRatingByValue(
          fieldDef.ratingKey,
          value as number | string
        )}
      />
    );
  },
  propertyConfig: function (
    field: AllowedField,
    { getRatingByValue, getRatingOptions }: Helpers
  ): FilteringProperty {
    const fieldDef = field.fieldDef;
    if (fieldDef.displayType !== 'rating') {
      throw new Error('rating filed type used out of context');
    }

    return {
      key: field.value,
      groupValuesLabel: '',
      propertyLabel: field.label,
      operators: [
        {
          operator: '=',
          format: (value) => {
            const rating = getRatingByValue(fieldDef.ratingKey, value);

            return rating?.label ?? '';
          },
          form: (props) => {
            return (
              <SelectFilter
                {...props}
                options={getRatingOptions(fieldDef.ratingKey).map((o) => ({
                  value: (o.value as unknown as string) ?? undefined,
                  label: o.label,
                }))}
              />
            );
          },
        },
      ],
    };
  },
};
