import { useRating } from '@risksmart-app/components/hooks/useRating';
import _ from 'lodash';
import { useCallback, useMemo } from 'react';
import { UNRATED } from 'src/pages/controls/lookupData';
import { useAggregation } from 'src/providers/useAggregation';
import type { RatingOption } from 'src/ratings/ratings';

import SimpleRatingBadge from '@/components/SimpleRatingBadge';
import {
  Risk_Scoring_Model_Enum,
  useGetLatestRiskScoresByRiskIdSubscription,
  useGetRiskScoresByRiskIdQuery,
  useGetRiskScoresSubscription,
} from '@/generated/graphql';

export type RiskScore = {
  id: string;
  inherentRating: null | number;
  inherentScore: null | number;
  inherentLikelihood?: null | number;
  inherentImpact?: null | number;
  inherentRatingId: string | undefined;
  residualRating: null | number;
  residualScore: null | number;
  residualLikelihood?: null | number;
  residualImpact?: null | number;
  inherentCompletionDate: null | string | undefined;
  residualCompletionDate: null | string | undefined;
  residualRatingId: string | undefined;
};

type RiskScoreMeta = {
  loading: boolean;
  showScore?: boolean;
};

export const getRiskScoreBadge = (
  lookupValue: null | number | undefined,
  getLabelByFn: (
    r: null | number | string | undefined
  ) => RatingOption | undefined,
  displayValue?: null | number | undefined
) => {
  if (!lookupValue) {
    return <SimpleRatingBadge rating={UNRATED} />;
  }

  let ratingOption = getLabelByFn(lookupValue) ?? UNRATED;
  if (!_.isNil(displayValue) && ratingOption.label !== 'Unrated') {
    ratingOption = {
      ...ratingOption,
      label: displayValue?.toFixed(1),
    };
  }

  return <SimpleRatingBadge rating={ratingOption} />;
};

export const useRiskScoreFormatters = () => {
  const { getByValue: getByValueResidual } = useRating('risk_controlled');
  const { getByValue: getByValueInherent } = useRating('risk_uncontrolled');

  return useCallback(
    (score: Partial<RiskScore> | undefined) => ({
      getInherentOption: () => getByValueInherent(score?.inherentRating),
      getResidualOption: () => getByValueResidual(score?.residualRating),

      getInherentLabel: () =>
        getByValueInherent(score?.inherentRating)?.label ?? UNRATED.label,

      getResidualLabel: () =>
        getByValueResidual(score?.residualRating)?.label ?? UNRATED.label,

      getInherentRatingBadge: () =>
        getRiskScoreBadge(score?.inherentRating, getByValueInherent),
      getResidualRatingBadge: () =>
        getRiskScoreBadge(score?.residualRating, getByValueResidual),
    }),
    [getByValueInherent, getByValueResidual]
  );
};

export type UseRiskScoreFormattersResponse = ReturnType<
  typeof useRiskScoreFormatters
>;

export const useRiskScore = (riskId: string): RiskScore & RiskScoreMeta => {
  const { riskModel } = useAggregation();
  const { data: riskScores } = useGetLatestRiskScoresByRiskIdSubscription({
    variables: {
      RiskId: riskId!,
    },
  });
  const { data, loading } = useGetRiskScoresByRiskIdQuery({
    variables: {
      RiskId: riskId!,
    },
  });
  const showScore = riskModel !== 'default';

  const latestInherent = data?.inherent[0];
  const latestResidual = data?.residual[0];
  const riskScore = riskScores?.risk_score[0];
  const scores = mapToRiskScore({
    showScore,
    id: riskId,
    latestInherent,
    latestResidual,
    riskScore,
  });

  return {
    loading,
    showScore,
    ...scores,
  };
};

type RiskAssessmentResult =
  | {
      Id: string;
      Rating?: null | number | undefined;
      Impact?: null | number | undefined;
      Likelihood?: null | number | undefined;
      TestDate?: null | string | undefined;
    }
  | null
  | undefined;

const mapToRiskScore = ({
  showScore,
  id,
  latestInherent,
  latestResidual,
  riskScore,
}: {
  showScore: boolean;
  id: string;
  latestInherent: RiskAssessmentResult;
  latestResidual: RiskAssessmentResult;
  riskScore:
    | {
        ResidualScore?: null | number | undefined;
        InherentScore?: null | number | undefined;
        ResidualRating?: null | number | undefined;
        InherentRating?: null | number | undefined;
        ModifiedAtTimestamp: string;
      }
    | null
    | undefined;
}): RiskScore => {
  const score: RiskScore = {
    id,
    inherentRating: riskScore?.InherentRating ?? null,
    inherentScore: riskScore?.InherentScore ?? null,
    inherentImpact: latestInherent?.Impact,
    inherentLikelihood: latestInherent?.Likelihood,
    residualRating: riskScore?.ResidualRating ?? null,
    residualScore: riskScore?.ResidualScore ?? null,
    residualImpact: latestResidual?.Impact,
    residualLikelihood: latestResidual?.Likelihood,
    inherentRatingId: !showScore ? latestInherent?.Id : undefined,
    inherentCompletionDate: !showScore
      ? latestInherent?.TestDate
      : riskScore?.ModifiedAtTimestamp,
    residualCompletionDate: !showScore
      ? latestResidual?.TestDate
      : riskScore?.ModifiedAtTimestamp,
    residualRatingId: !showScore ? latestResidual?.Id : undefined,
  };

  return score;
};

export const useRiskScores = (): {
  loading: boolean;
  showScore: boolean;
  scores: RiskScore[] | undefined;
} => {
  const { data, loading } = useGetRiskScoresSubscription();
  const { riskModel } = useAggregation();

  const showScore = riskModel !== Risk_Scoring_Model_Enum.Default;

  return useMemo(
    () => ({
      loading,
      showScore,
      scores: data?.risk.map((r) => {
        const latestInherent = r?.inherent[0]?.riskAssessmentResult;
        const latestResidual = r?.residual[0]?.riskAssessmentResult;

        return mapToRiskScore({
          showScore,
          id: r.Id,
          latestInherent,
          latestResidual,
          riskScore: r.riskScore,
        });
      }),
    }),
    [data?.risk, loading, showScore]
  );
};
