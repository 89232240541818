import i18n from '@risksmart-app/i18n/i18n';
import { useGetConsequenceSmartWidgetTableProps } from 'src/pages/consequences/config';
import type { ConsequenceRegisterFields } from 'src/pages/consequences/types';

import { Parent_Type_Enum } from '@/generated/graphql';
import { GetConsequencesDocument } from '@/generated/graphql.typed';
import { getFriendlyId } from '@/utils/friendlyId';
import type { StatefulTableOptions } from '@/utils/table/hooks/useGetStatelessTableProps';
import { consequencesRegisterUrl } from '@/utils/urls';

import { UNRATED } from '../../Gigawidget/types';
import { departmentGetter } from '../../Gigawidget/util/categoryGetters';
import {
  dateRangeFilter,
  departmentsFilter,
  tagsFilter,
} from '../../Gigawidget/util/filterHelpers';
import { createDataSource } from '../createDataSource';
import {
  dashboardDateClickthroughFilter,
  dateClickthroughFilter,
  defaultClickthroughFilterWithUnrated,
  tagAndDepartmentCategoryGetters,
} from '../dataSourceHelpers';

export default createDataSource({
  hasAccess: (_, isFeatureVisibleToOrg) =>
    !isFeatureVisibleToOrg('disable-consequences'),
  parentTypes: [Parent_Type_Enum.Consequence],
  documentNode: GetConsequencesDocument,
  useDefaultVariables: () => ({ where: {} }),
  useTablePropsHook: (
    data,
    options: StatefulTableOptions<ConsequenceRegisterFields>
  ) =>
    useGetConsequenceSmartWidgetTableProps(
      data?.consequence,
      data?.form_configuration[0]?.customAttributeSchema ?? null,
      options
    ),
  entityNamePlural: 'consequence_other',
  entityNameSingular: 'consequence_one',
  fields: 'consequences.fields',
  dashboardFilterConfig: {
    dateFilter: (dateRange, precision) => ({
      where: { CreatedAtTimestamp: dateRangeFilter(dateRange, precision) },
    }),
    dateClickthroughFilter:
      dashboardDateClickthroughFilter('CreatedAtTimestamp'),
    tagsFilter: (tags) => ({ where: { issue: { tags: tagsFilter(tags) } } }),
    departmentsFilter: (departments) => ({
      where: { issue: { departments: departmentsFilter(departments) } },
    }),
  },
  clickThroughUrl: (filters) => consequencesRegisterUrl(filters),
  categoryGetters: [
    ...tagAndDepartmentCategoryGetters<ConsequenceRegisterFields>(),
    {
      id: 'issueId',
      name: () => i18n.t('consequences.columns.issueId'),
      categoryGetter: (item) => ({
        key: item.IssueSequentialId ?? UNRATED,
        label: item.IssueSequentialId
          ? getFriendlyId(Parent_Type_Enum.Issue, item.IssueSequentialId)
          : UNRATED,
      }),
      clickthroughFilter: defaultClickthroughFilterWithUnrated(
        'IssueSequentialId',
        {
          categoryValue: 'key',
        }
      ),
    },
    {
      id: 'issueRaisedDate',
      name: () => i18n.t('consequences.columns.issueRaisedDate'),
      categoryGetter: (item) =>
        item.IssueRaisedDate ? new Date(item.IssueRaisedDate) : null,
      clickthroughFilter: dateClickthroughFilter('IssueRaisedDate'),
      date: true,
      dashboardDateFilterOverride: (dateRange, precision) => ({
        where: {
          issue: { CreatedAtTimestamp: dateRangeFilter(dateRange, precision) },
        },
      }),
    },
    {
      id: 'issueClosedDate',
      name: () => i18n.t('consequences.columns.issueClosedDate'),
      categoryGetter: (item) =>
        item.IssueClosedDate ? new Date(item.IssueClosedDate) : null,
      clickthroughFilter: dateClickthroughFilter('IssueClosedDate'),
      date: true,
      dashboardDateFilterOverride: (dateRange, precision) => ({
        where: {
          issue: {
            assessment: {
              ActualCloseDate: dateRangeFilter(dateRange, precision),
            },
          },
        },
      }),
    },
    {
      id: 'issueStatus',
      name: () => i18n.t('consequences.columns.issueStatus'),
      categoryGetter: (item) => ({
        key: item.IssueStatus ?? UNRATED,
        label: item.IssueStatusLabelled ?? UNRATED,
      }),
      ratingColourKey: 'issue_assessment_status',
      clickthroughFilter: defaultClickthroughFilterWithUnrated(
        'IssueStatusLabelled'
      ),
    },
    {
      id: 'issueType',
      name: () => i18n.t('consequences.columns.issueType'),
      categoryGetter: (item) => ({
        key: item.IssueType ?? UNRATED,
        label: item.IssueType ? item.IssueTypeLabelled : UNRATED,
      }),
      clickthroughFilter:
        defaultClickthroughFilterWithUnrated('IssueTypeLabelled'),
    },
    {
      id: 'issueSeverity',
      name: () => i18n.t('consequences.columns.issueSeverity'),
      categoryGetter: (item) => ({
        key: item.IssueSeverity ?? UNRATED,
        label: item.IssueSeverityLabelled ?? UNRATED,
      }),
      ratingColourKey: 'severity',
      clickthroughFilter: defaultClickthroughFilterWithUnrated(
        'IssueSeverityLabelled'
      ),
    },
    {
      id: 'issueTitle',
      name: () => i18n.t('consequences.columns.issue'),
      categoryGetter: (item) => ({
        key: item.IssueTitle,
        label: item.IssueTitle,
      }),
      clickthroughFilter: defaultClickthroughFilterWithUnrated('IssueTitle'),
    },
    {
      id: 'owner',
      name: () => i18n.t('columns.owner'),
      categoryGetter: (item) =>
        item.allOwners.map((owner) => ({ key: owner.id, label: owner.label })),
      clickthroughFilter: (category) => [
        {
          propertyKey: 'allOwners',
          operator: '=',
          value: category.key,
        },
      ],
    },
    {
      id: 'title',
      name: () => i18n.t('consequences.columns.title'),
      categoryGetter: (item) => ({
        key: item.Title,
        label: item.Title,
      }),
      clickthroughFilter: defaultClickthroughFilterWithUnrated('Title'),
    },
    {
      id: 'criticality',
      name: () => i18n.t('consequences.columns.criticality'),
      categoryGetter: (item) => ({
        key: item.Criticality,
        label: item.CriticalityLabelled ?? UNRATED,
        sortKey: `${item.Criticality}`,
      }),
      ratingColourKey: 'criticality',
      clickthroughFilter: defaultClickthroughFilterWithUnrated(
        'CriticalityLabelled'
      ),
    },
    {
      id: 'type',
      name: () => i18n.t('consequences.columns.type'),
      categoryGetter: (item) => ({
        key: item.TypeLabelled,
        label: item.TypeLabelled,
      }),
      clickthroughFilter: defaultClickthroughFilterWithUnrated('TypeLabelled'),
    },
    {
      id: 'costType',
      name: () => i18n.t('consequences.columns.costType'),
      categoryGetter: (item) => ({
        key: item.CostTypeLabelled,
        label: item.CostTypeLabelled,
      }),
      clickthroughFilter:
        defaultClickthroughFilterWithUnrated('CostTypeLabelled'),
    },
    {
      id: 'createdDate',
      name: () => i18n.t('columns.created_on'),
      categoryGetter: (data) => new Date(data.CreatedAtTimestamp),
      date: true,
      dashboardDateFilterOverride: (dateRange, precision) => ({
        where: { CreatedAtTimestamp: dateRangeFilter(dateRange, precision) },
      }),
      clickthroughFilter: dateClickthroughFilter('CreatedAtTimestamp'),
    },
    {
      id: 'assessmentDepartments',
      name: () => i18n.t('consequences.columns.assessmentDepartments'),
      categoryGetter: (item) =>
        item.issue?.assessment
          ? departmentGetter({ includeNoDepartments: true })(
              item.issue.assessment
            )
          : null,
      clickthroughFilter: (category) => [
        {
          propertyKey: 'AssessmentDepartments',
          operator: category.key === UNRATED ? '<' : '=',
          value: category.key === UNRATED ? 1 : category.key,
        },
      ],
    },
  ],
});
