import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { CustomisableFormContext } from '@/components/Form/Form/CustomisableFormContext';
import { ModalWrapper } from '@/components/Form/Form/ModalWrapper';
import type { FormContextProps } from '@/components/Form/Form/types';

import { DashboardFormFields } from './DashboardFormFields';
import type { DashboardFormFieldData } from './dashboardSchema';
import { DashboardSchema, defaultValues } from './dashboardSchema';

export type Props = Omit<
  FormContextProps<DashboardFormFieldData>,
  | 'defaultValues'
  | 'formId'
  | 'i18n'
  | 'parentType'
  | 'renderTemplate'
  | 'schema'
>;

const DashboardForm: FC<Props> = (props) => {
  const { t } = useTranslation(['common']);

  return (
    <CustomisableFormContext
      {...props}
      schema={DashboardSchema}
      defaultValues={defaultValues}
      i18n={t('dashboard')}
      formId={'save-dashboard-form'}
      renderTemplate={(renderProps) => (
        <ModalWrapper {...renderProps} visible={true} />
      )}
    >
      <DashboardFormFields />
    </CustomisableFormContext>
  );
};

export default DashboardForm;
