import { IssuesUpdateBreadcrumb, IssuesUpdatePage } from '@pages';
import i18n from '@risksmart-app/i18n/i18n';
import type { RouteObject } from 'react-router';

import type { ParentIssueTypes } from '@/utils/issueVariantUtils';

import { actionRoute } from './actionRoutes.config';

export const issueRoute = (issueType: ParentIssueTypes): RouteObject => ({
  path: ':issueId',
  handle: {
    breadcrumb: IssuesUpdateBreadcrumb,
  },
  children: [
    {
      path: '',
      element: (
        <IssuesUpdatePage
          activeTabId={'details'}
          showDeleteButton={true}
          issueType={issueType}
        />
      ),
    },
    {
      path: 'updates',
      handle: {
        title: () => i18n.format(i18n.t('update_other'), 'capitalize'),
      },
      element: (
        <IssuesUpdatePage activeTabId={'updates'} issueType={issueType} />
      ),
    },
    {
      path: 'actions',
      handle: {
        title: () => i18n.format(i18n.t('action_other'), 'capitalize'),
      },
      children: [
        {
          path: '',
          element: (
            <IssuesUpdatePage activeTabId={'actions'} issueType={issueType} />
          ),
        },
        actionRoute,
      ],
    },
    {
      path: 'causes',
      handle: {
        // TODO: translation
        title: 'Causes',
      },
      element: (
        <IssuesUpdatePage activeTabId={'causes'} issueType={issueType} />
      ),
    },
    {
      path: 'consequences',
      handle: {
        // TODO: translation
        title: 'Consequences',
      },
      element: (
        <IssuesUpdatePage activeTabId={'consequences'} issueType={issueType} />
      ),
    },
    {
      path: 'assessment',
      handle: {
        // TODO: translation
        title: 'Assessment',
      },
      element: (
        <IssuesUpdatePage activeTabId={'assessment'} issueType={issueType} />
      ),
    },
    {
      path: 'linked-items',
      handle: {
        title: () => i18n.t('linkedItems.tab_title'),
      },
      element: (
        <IssuesUpdatePage activeTabId={'linkedItems'} issueType={issueType} />
      ),
    },
  ],
});
