import SpaceBetween from '@cloudscape-design/components/space-between';
import Button from '@risksmart-app/components/Button';
import { PageNotFound } from '@risksmart-app/components/errors/errors';
import { useGetGuidParam } from '@risksmart-app/components/routes/routes.utils';
import type { FC } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import {
  namedOperations,
  Parent_Type_Enum,
  useDeleteEnterpriseRiskMutation,
  useGetEnterpriseRiskByIdQuery,
} from 'src/generated/graphql';
import { PageLayout } from 'src/layouts';
import { Permission } from 'src/rbac/Permission';
import { useGetDetailParentPath } from 'src/routes/useGetDetailParentPath';

import ControlledTabs from '@/components/ControlledTabs';
import DeleteModal from '@/components/DeleteModal/DeleteModal';
import InstantiateEnterpriseRiskModal from '@/components/InstantiateEnterpriseRiskModal/InstantiateEnterpriseRiskModal';
import { useDeleteResultNotification } from '@/hooks/useMutationResultNotification';
import { getFriendlyId } from '@/utils/friendlyId';
import { evictField } from '@/utils/graphqlUtils';

import { useTabs } from './useTabs';

type Props = {
  selectedTabId: 'details' | 'risks';
  showDeleteButton?: boolean;
};

const Page: FC<Props> = ({ selectedTabId, showDeleteButton }) => {
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [showInstantiateModal, setShowInstantiateModal] = useState(false);
  const navigate = useNavigate();
  const enterpriseRiskId = useGetGuidParam('id');
  const parentUrl = useGetDetailParentPath(enterpriseRiskId);
  const [mutate, deleteResult] = useDeleteEnterpriseRiskMutation({
    update: (cache) => {
      evictField(cache, 'enterprise_risk');
    },
    refetchQueries: [
      namedOperations.Query.getEnterpriseRiskById,
      namedOperations.Query.getEnterpriseRisks,
    ],
  });
  const { t: st } = useTranslation(['common'], {
    keyPrefix: 'enterpriseRisks',
  });
  const { data, error } = useGetEnterpriseRiskByIdQuery({
    variables: {
      Id: enterpriseRiskId,
    },
    fetchPolicy: 'no-cache',
  });
  if (error) {
    throw error;
  }

  const enterpriseRisk = data?.enterprise_risk[0];
  const tabs = useTabs(enterpriseRisk);

  const onDelete = useDeleteResultNotification({
    entityName: st('entity_name'),
    asyncAction: async () => {
      if (!enterpriseRisk) {
        return false;
      }
      await mutate({
        variables: {
          Id: enterpriseRisk.Id,
        },
      });
      await navigate(parentUrl);

      return true;
    },
  });

  if (deleteResult.data?.deleteChildEnterpriseRisk?.affected_rows === 1) {
    return <></>;
  }

  if (data?.enterprise_risk.length === 0) {
    throw new PageNotFound(
      `Enterprise risk with id ${enterpriseRiskId} not found`
    );
  }

  const counter =
    enterpriseRisk &&
    `(${getFriendlyId(Parent_Type_Enum.EnterpriseRisk, enterpriseRisk.SequentialId)})`;

  const fallbackTitle = st('fallback_title');

  return (
    <PageLayout
      actions={
        <SpaceBetween direction={'horizontal'} size={'xs'}>
          {showDeleteButton && (
            <Permission permission={'delete:enterprise_risk'}>
              <Button
                variant={'normal'}
                formAction={'none'}
                onClick={() => {
                  setIsDeleteModalVisible(true);
                }}
              >
                {st('delete_button')}
              </Button>
            </Permission>
          )}
          <Permission permission={'update:enterprise_risk'}>
            <Button
              variant={'normal'}
              formAction={'none'}
              onClick={() => {
                setShowInstantiateModal(true);
              }}
            >
              {st('instantiateButton')}
            </Button>
          </Permission>
        </SpaceBetween>
      }
      meta={{
        title: fallbackTitle,
      }}
      title={enterpriseRisk?.Title}
      counter={counter}
    >
      <ControlledTabs
        tabs={tabs}
        activeTabId={selectedTabId}
        variant={'container'}
      />

      <DeleteModal
        loading={deleteResult.loading}
        isVisible={isDeleteModalVisible}
        header={st('delete_modal_title')}
        onDelete={onDelete}
        onDismiss={() => setIsDeleteModalVisible(false)}
      >
        {st('confirm_delete_message')}
      </DeleteModal>

      <InstantiateEnterpriseRiskModal
        isVisible={showInstantiateModal}
        onDismiss={() => {
          setShowInstantiateModal(false);
        }}
        enterpriseRiskIds={[enterpriseRiskId]}
      />
    </PageLayout>
  );
};

export default Page;
