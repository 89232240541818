import SpaceBetween from '@cloudscape-design/components/space-between';
import Button from '@risksmart-app/components/Button';
import { useNotifications } from '@risksmart-app/components/Notifications/useNotifications';
import Table from '@risksmart-app/components/Table';
import type { FC } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import AddControlModal from 'src/pages/control-groups/AddControlModal';
import { useGetCollectionTableProps } from 'src/pages/controls/config';
import type { ControlTableFields } from 'src/pages/controls/types';
import type { ObjectWithContributors } from 'src/rbac/Permission';
import { Permission } from 'src/rbac/Permission';

import { useI18NSummaryHelpContent } from '@/components/HelpPanel/useSummaryHelpContent';
import RemovalModal from '@/components/RemoveModal';
import TabHeader from '@/components/TabHeader';
import {
  useGetControlsQuery,
  useRemoveParentControlsMutation,
} from '@/generated/graphql';
import { useRemoveResultNotification } from '@/hooks/useMutationResultNotification';
import { evictField } from '@/utils/graphqlUtils';

const translationKeyPrefix = 'controls';

interface Props {
  parent: ObjectWithContributors;
}

const Tab: FC<Props> = ({ parent }) => {
  useI18NSummaryHelpContent('controls.tabHelp');
  const { t } = useTranslation(['common']);
  const { t: st } = useTranslation(['common'], {
    keyPrefix: translationKeyPrefix,
  });
  const { t: sst } = useTranslation(['common'], {
    keyPrefix: 'addControl',
  });
  const [selectedControls, setSelectedControls] = useState<
    ControlTableFields[]
  >([]);
  const { addNotification } = useNotifications();
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [removeControls] = useRemoveParentControlsMutation({
    update: (cache) => {
      evictField(cache, 'control');
      evictField(cache, 'control_group');
    },
  });
  const { data, loading, refetch } = useGetControlsQuery({
    variables: {
      where: {
        parents: {
          ParentId: { _eq: parent.Id },
        },
      },
    },
    onError: (error) => {
      addNotification({
        type: 'error',
        content: <>{error.message}</>,
      });
    },
  });

  const openAddModal = () => setIsAddModalVisible(true);

  const tableProps = useGetCollectionTableProps(
    openAddModal,
    data?.control,
    data?.form_configuration?.[0]?.customAttributeSchema,
    <Permission permission={'insert:control'} parentObject={parent}>
      <Button formAction={'none'} onClick={openAddModal}>
        {st('create_button')}
      </Button>
    </Permission>
  );

  const onRemove = useRemoveResultNotification({
    asyncAction: async () => {
      await removeControls({
        variables: {
          ParentId: parent.Id,
          ControlIds: selectedControls.map((s) => s.Id),
        },
      });
      setSelectedControls([]);
      refetch();
      setIsDeleteModalVisible(false);

      return true;
    },
    failureAction: () => {
      setIsDeleteModalVisible(false);
    },
    entityName: st('entity_name'),
  });

  return (
    <>
      <Table
        {...tableProps}
        header={
          <SpaceBetween size={'m'}>
            <TabHeader
              actions={
                <SpaceBetween direction={'horizontal'} size={'xs'}>
                  <Permission
                    permission={'delete:control'}
                    parentObject={parent}
                  >
                    <Button
                      formAction={'none'}
                      variant={'normal'}
                      disabled={!selectedControls.length}
                      onClick={() => setIsDeleteModalVisible(true)}
                    >
                      {t('remove')}
                    </Button>
                  </Permission>
                  <Permission
                    permission={'insert:control'}
                    parentObject={parent}
                  >
                    <Button
                      variant={'primary'}
                      formAction={'none'}
                      onClick={() => setIsAddModalVisible(true)}
                    >
                      {st('add_button')}
                    </Button>
                  </Permission>
                </SpaceBetween>
              }
            >
              {st('tab_title')}
            </TabHeader>
          </SpaceBetween>
        }
        trackBy={'Id'}
        selectionType={'multi'}
        onSelectionChange={({ detail }) => {
          setSelectedControls(detail.selectedItems);
        }}
        selectedItems={selectedControls}
        variant={'embedded'}
        loading={loading}
        loadingText={st('loading_message') ?? ''}
        sortingDisabled={false}
        resizableColumns={true}
      />
      <RemovalModal
        isVisible={isDeleteModalVisible}
        header={t('remove')}
        onRemove={onRemove}
        onDismiss={() => {
          setIsDeleteModalVisible(false);
        }}
      >
        {sst('confirm_remove_message')}
      </RemovalModal>
      {isAddModalVisible && (
        <AddControlModal
          excludedControlIds={data?.control.map((c) => c.Id)}
          onDismiss={() => {
            setIsAddModalVisible(false);
            refetch();
          }}
          controlGroupId={parent.Id}
        />
      )}
    </>
  );
};

export default Tab;
