import { useFileUpdate } from '@risksmart-app/components/File/useFileUpdate';
import type { FC } from 'react';
import { useMemo } from 'react';
import { useNavigate } from 'react-router';
import { useAggregation } from 'src/providers/useAggregation';
import { useHasPermission } from 'src/rbac/useHasPermission';

import Loading from '@/components/Loading';
import {
  Parent_Type_Enum,
  useGetAppetiteByIdQuery,
  useGetRiskByIdQuery,
  useInsertAppetiteMutation,
  useUpdateAppetiteMutation,
} from '@/generated/graphql';
import { evictField } from '@/utils/graphqlUtils';

import AppetiteForm from '../forms/AppetiteForm';
import type { AppetiteFormFieldsData } from '../forms/appetiteSchema';

type Props = {
  Id?: string;
  ParentId?: string;
};

const Tab: FC<Props> = ({ Id, ParentId }) => {
  const { updateFiles } = useFileUpdate();
  const { appetiteAggregation, loading: aggregationLoading } = useAggregation();
  const { data: riskData, loading: risksLoading } = useGetRiskByIdQuery({
    variables: { _eq: ParentId },
    skip: !ParentId,
  });
  const navigate = useNavigate();
  const onDismiss = () => navigate(-1);
  const parentRisk = riskData?.risk[0];

  const [insert] = useInsertAppetiteMutation({
    update: (cache) => {
      evictField(cache, 'appetite');
      evictField(cache, 'appetite_aggregate');
      evictField(cache, 'risk_aggregate');
    },
  });
  const [update] = useUpdateAppetiteMutation({
    update: (cache) => {
      evictField(cache, 'appetite');
    },
  });

  const {
    data: appetiteData,
    loading,
    error,
  } = useGetAppetiteByIdQuery({
    variables: {
      _eq: Id,
    },
    skip: !Id,
    fetchPolicy: 'no-cache',
  });
  if (error) {
    throw error;
  }
  const appetite = appetiteData?.appetite[0];

  const onSave = async (data: AppetiteFormFieldsData) => {
    const { newFiles, files, ...rest } = data;
    if (appetite) {
      const result = await update({
        variables: {
          ...rest,
          Id: appetite.Id,
          OriginalTimestamp: appetite.ModifiedAtTimestamp,
          CustomAttributeData: data.CustomAttributeData || undefined,
        },
      });
      if (result.data?.update_appetite?.affected_rows !== 1) {
        throw new Error(
          'Records not updated. Record may have been updated by another user'
        );
      }
    } else {
      if (!parentRisk) {
        throw new Error('Cannot insert acceptance without a parent risk');
      }

      const result = await insert({
        variables: {
          ...rest,
          ParentIds: [parentRisk.Id],
          CustomAttributeData: data.CustomAttributeData || undefined,
        },
      });
      Id = result.data?.insertChildAppetite?.Id;
    }
    if (!Id) {
      throw new Error('Missing id');
    }
    await updateFiles({
      parentType: Parent_Type_Enum.Appetite,
      parentId: Id,
      newFiles,
      originalFiles: appetite?.files,
      selectedFiles: files,
    });
  };

  const canEditAppetite = useHasPermission('update:appetite', appetite);
  const canCreateAppetite = useHasPermission('insert:appetite', parentRisk);
  const { enableTierTwoCascading } = useAggregation();

  const canModify = appetite ? canEditAppetite : canCreateAppetite;

  const values: AppetiteFormFieldsData | undefined = useMemo<
    AppetiteFormFieldsData | undefined
  >(() => {
    if (!appetite) {
      return undefined;
    }

    const fields: AppetiteFormFieldsData = {
      ...appetite,
      ImpactId: appetite.impact?.Id,
      AppetiteType: appetite.AppetiteType,
    };

    return fields;
  }, [appetite]);

  if (loading || aggregationLoading || risksLoading) {
    return <Loading />;
  }

  const isTierThree = parentRisk?.Tier === 3;
  const isTierTwo = parentRisk?.Tier === 2;

  return (
    <>
      <AppetiteForm
        appetiteAggregation={appetiteAggregation}
        onSave={onSave}
        values={values}
        onDismiss={onDismiss}
        readOnly={
          !canModify ||
          (appetiteAggregation !== 'default' &&
            (isTierThree || (isTierTwo && !enableTierTwoCascading)))
        }
      />
    </>
  );
};

export default Tab;
