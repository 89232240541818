import { FormBuilder } from '@risksmart-app/components/FormBuilder/FormBuilder';
import type { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import ControlledInput from '@/components/Form/ControlledInput';
import { Questionnaire_Template_Version_Status_Enum } from '@/generated/graphql';

import type { QuestionnaireTemplateVersionFormFieldData } from './questionnaireTemplateVersionSchema';

type QuestionnaireTemplateVersionFormFieldsProps = {
  readonly?: boolean;
  isCreatingNewEntity: boolean;
  savedStatus: Questionnaire_Template_Version_Status_Enum;
  parentId: string;
  disableStatus: boolean;
};

const QuestionnaireTemplateVersionFormFields: FC<
  QuestionnaireTemplateVersionFormFieldsProps
> = ({ readonly, savedStatus }) => {
  const { control } =
    useFormContext<QuestionnaireTemplateVersionFormFieldData>();
  const { t: st } = useTranslation(['common'], {
    keyPrefix: 'questionnaire_template_versions.fields',
  });

  const isArchived =
    savedStatus === Questionnaire_Template_Version_Status_Enum.Archived;
  const isPublished =
    savedStatus === Questionnaire_Template_Version_Status_Enum.Published;
  const versionContentIsDisabled = readonly || isArchived || isPublished;

  return (
    <>
      <ControlledInput
        key={'version'}
        name={'Version'}
        label={st('version')}
        forceRequired={true}
        description={st('version_help')}
        control={control}
        placeholder={st('version_placeholder')}
        testId={'version'}
        disabled={versionContentIsDisabled}
        stretch={false}
      />
      <FormBuilder key={'form-builder-wrapper'} />
    </>
  );
};

export default QuestionnaireTemplateVersionFormFields;
