import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { PageLayout } from 'src/layouts';

import ControlledTabs from '@/components/ControlledTabs';

import { useTabs } from '../update/useTabs';
import type { AssessmentTypeEnum } from '../useAssessmentTypeConfig';
import { useAssessmentTypeConfig } from '../useAssessmentTypeConfig';

interface Props {
  assessmentMode: AssessmentTypeEnum;
}
const Page: FC<Props> = ({ assessmentMode }) => {
  const { taxonomyKey } = useAssessmentTypeConfig(assessmentMode);
  const { t } = useTranslation(['common'], { keyPrefix: taxonomyKey });
  const title = t('create_title');
  const tabs = useTabs(undefined, assessmentMode);

  return (
    <PageLayout title={title}>
      <ControlledTabs tabs={tabs} variant={'container'} />
    </PageLayout>
  );
};

export default Page;
