import type { HandleOptions } from '@risksmart-app/components/hooks/use-breadcrumbs';
import i18n from '@risksmart-app/i18n/i18n';
import type { RouteObject } from 'react-router';
import EnterpriseRiskCreatePage from 'src/pages/enterprise-risk/create/Page';
import EnterpriseRiskPage from 'src/pages/enterprise-risk/Page';
import EnterpriseRiskBreadcrumb from 'src/pages/enterprise-risk/update/Breadcrumb';
import EnterpriseRiskUpdatePage from 'src/pages/enterprise-risk/update/Page';
import OrgFeatureFlaggedRoute from 'src/rbac/OrgFeatureFlaggedRoute';
import ProtectedRoute from 'src/rbac/ProtectedRoute';

import { enterpriseRiskDashboardUrl, enterpriseRiskUrl } from '@/utils/urls';

export const enterpriseRiskRoute: RouteObject = {
  path: enterpriseRiskUrl,
  handle: {
    title: () => i18n.format(i18n.t('enterprise_risk_other'), 'capitalizeAll'),
    breadcrumbUrl: (options: HandleOptions) => {
      if (options.location.state?.from === 'enterprise-risk-dashboard') {
        return enterpriseRiskDashboardUrl();
      }

      return enterpriseRiskUrl;
    },
  },
  children: [
    {
      path: enterpriseRiskUrl,
      index: true,
      element: (
        <OrgFeatureFlaggedRoute featureFlag={'enterprise_risk'}>
          <ProtectedRoute permission={'read:enterprise_risk'}>
            <EnterpriseRiskPage />
          </ProtectedRoute>
        </OrgFeatureFlaggedRoute>
      ),
    },
    {
      path: 'add',
      element: (
        <OrgFeatureFlaggedRoute featureFlag={'enterprise_risk'}>
          <ProtectedRoute permission={'insert:enterprise_risk'}>
            <EnterpriseRiskCreatePage />
          </ProtectedRoute>
        </OrgFeatureFlaggedRoute>
      ),
    },
    {
      path: ':id',
      handle: {
        breadcrumb: EnterpriseRiskBreadcrumb,
      },
      children: [
        {
          path: '',
          element: (
            <OrgFeatureFlaggedRoute featureFlag={'enterprise_risk'}>
              <ProtectedRoute permission={'read:enterprise_risk'}>
                <EnterpriseRiskUpdatePage
                  selectedTabId={'details'}
                  showDeleteButton={true}
                />
              </ProtectedRoute>
            </OrgFeatureFlaggedRoute>
          ),
        },
        {
          path: 'risks',
          element: (
            <OrgFeatureFlaggedRoute featureFlag={'enterprise_risk'}>
              <ProtectedRoute permission={'read:enterprise_risk'}>
                <EnterpriseRiskUpdatePage
                  selectedTabId={'risks'}
                  showDeleteButton={true}
                />
              </ProtectedRoute>
            </OrgFeatureFlaggedRoute>
          ),
        },
      ],
    },
  ],
};
