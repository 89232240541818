import i18n from '@risksmart-app/i18n/i18n';

import {
  GetInternalAuditsDocument,
  Parent_Type_Enum,
} from '@/generated/graphql.typed';
import { internalAuditRegisterUrl } from '@/utils/urls';

import { useGetInternalAuditsSmartWidgetTableProps } from '../../../internal-audit/config';
import type { InternalAuditRegisterFields } from '../../../internal-audit/types';
import { UNRATED } from '../../Gigawidget/types';
import {
  dateRangeFilter,
  departmentsFilter,
  tagsFilter,
} from '../../Gigawidget/util/filterHelpers';
import { createDataSource } from '../createDataSource';
import {
  dashboardDateClickthroughFilter,
  dateClickthroughFilter,
  defaultClickthroughFilterWithUnrated,
  tagAndDepartmentCategoryGetters,
} from '../dataSourceHelpers';

const data = createDataSource({
  parentTypes: [Parent_Type_Enum.InternalAuditEntity],
  hasAccess: (_, isFeatureVisibleToOrg) =>
    !!isFeatureVisibleToOrg('internal_audit'),
  documentNode: GetInternalAuditsDocument,
  useDefaultVariables: () => ({ where: {} }),
  useTablePropsHook: (data, options) =>
    useGetInternalAuditsSmartWidgetTableProps(
      data?.internal_audit_entity,
      data?.form_configuration?.[0]?.customAttributeSchema ?? null,
      options
    ),
  dashboardFilterConfig: {
    tagsFilter: (tags) => ({ where: { tags: tagsFilter(tags) } }),
    departmentsFilter: (departments) => ({
      where: { departments: departmentsFilter(departments) },
    }),
    dateFilter: (dateRange, precision) => ({
      where: { CreatedAtTimestamp: dateRangeFilter(dateRange, precision) },
    }),
    dateClickthroughFilter:
      dashboardDateClickthroughFilter('CreatedAtTimestamp'),
  },
  entityNamePlural: 'internal_audit_other',
  entityNameSingular: 'internal_audit_one',
  fields: 'internalAudits.fields',
  clickThroughUrl: (filter) => internalAuditRegisterUrl(filter),
  categoryGetters: [
    ...tagAndDepartmentCategoryGetters<InternalAuditRegisterFields>(),
    {
      id: 'businessArea',
      name: () => i18n.t('internalAudits.columns.BusinessArea'),
      categoryGetter: (item) => ({
        key: item.BusinessArea,
        label: item.BusinessArea,
      }),
      clickthroughFilter: defaultClickthroughFilterWithUnrated('BusinessArea'),
    },
    {
      id: 'reportStatus',
      name: () => i18n.t('internalAudits.columns.ReportStatusLabelled'),
      categoryGetter: (item) => ({
        key: item.ReportStatus,
        label: item.ReportStatusLabelled ?? UNRATED,
      }),
      clickthroughFilter: defaultClickthroughFilterWithUnrated(
        'ReportStatusLabelled'
      ),
    },
    {
      id: 'auditRating',
      name: () => i18n.t('internalAudits.columns.AuditRatingLabelled'),
      categoryGetter: (item) => ({
        key: item.AuditRating ?? UNRATED,
        label:
          item.AuditRatingLabelled === '-' ? UNRATED : item.AuditRatingLabelled,
      }),
      clickthroughFilter: defaultClickthroughFilterWithUnrated(
        'AuditRatingLabelled'
      ),
    },
    {
      id: 'latestReportDate',
      name: () => i18n.t('internalAudits.columns.LatestReportDate'),
      categoryGetter: (data) =>
        !data.LatestReportDate || data.LatestReportDate === '-'
          ? null
          : new Date(data.LatestReportDate),
      date: true,
      dashboardDateFilterOverride: (dateRange, precision) => ({
        where: { LatestReportDate: dateRangeFilter(dateRange, precision) },
      }),
      clickthroughFilter: dateClickthroughFilter('LatestReportDate'),
    },
    {
      id: 'owner',
      name: () => i18n.t('internalAudits.columns.Owner'),
      categoryGetter: (item) =>
        item.allOwners.map((owner) => ({ key: owner.id, label: owner.label })),
      clickthroughFilter: (category) => [
        {
          propertyKey: 'allOwners',
          operator: '=',
          value: category.key,
        },
      ],
    },
    {
      id: 'contributors',
      name: () => i18n.t('columns.contributors'),
      categoryGetter: (item) =>
        item.allContributors.map((contributor) => ({
          key: contributor.id,
          label: contributor.label,
        })),
      clickthroughFilter: (category) => [
        {
          propertyKey: 'allContributors',
          operator: '=',
          value: category.key,
        },
      ],
    },
  ],
});
export default data;
