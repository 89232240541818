export const scrollStepIntoView = (stepNumber: number) => {
  const stepsContainer = document.getElementById('steps-container');
  const stepElement = document.getElementById(`step-${stepNumber}`);

  if (stepsContainer) {
    stepElement?.scrollIntoView({
      behavior: 'instant',
      block: 'center',
      inline: 'start',
    });
  }
};
