import SpaceBetween from '@cloudscape-design/components/space-between';
import Button from '@risksmart-app/components/Button';
import { PageNotFound } from '@risksmart-app/components/errors/errors';
import { useGetGuidParam } from '@risksmart-app/components/routes/routes.utils';
import type { FC } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { PageLayout } from 'src/layouts';
import { Permission } from 'src/rbac/Permission';
import { useHasPermission } from 'src/rbac/useHasPermission';
import {
  useGetDetailParentPath,
  useGetDetailPath,
} from 'src/routes/useGetDetailParentPath';

import ControlledTabs from '@/components/ControlledTabs';
import DeleteModal from '@/components/DeleteModal/DeleteModal';
import {
  namedOperations,
  useDeleteObligationMutation,
  useGetObligationByIdQuery,
} from '@/generated/graphql';
import { useDeleteResultNotification } from '@/hooks/useMutationResultNotification';
import { useIsFeatureVisibleToOrg } from '@/utils/featureFlags';
import { evictField } from '@/utils/graphqlUtils';
import type { IssueTaxonomyKeys } from '@/utils/issueVariantUtils';

import useExporter from './useExporter';
import { useTabs } from './useTabs';

type Props = {
  selectedTabId:
    | 'actions'
    | 'controls'
    | 'details'
    | 'impact'
    | 'linkedItems'
    | 'ratings'
    | IssueTaxonomyKeys;
};

const Page: FC<Props> = ({ selectedTabId }) => {
  const obligationId = useGetGuidParam('obligationId');

  const navigate = useNavigate();

  const detailsPath = useGetDetailPath(obligationId);
  const parentPath = useGetDetailParentPath(obligationId);

  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const { t } = useTranslation(['common']);
  const defaultTitle = t('obligations.fallback_title');

  const { data, error } = useGetObligationByIdQuery({
    variables: {
      _eq: obligationId,
    },
  });
  if (error) {
    throw error;
  }
  const [obligation] = data?.obligation || [];
  const tabs = useTabs(detailsPath, obligation);
  const [mutate, deleteResult] = useDeleteObligationMutation({
    update: (cache) => {
      evictField(cache, 'obligation');
      evictField(cache, 'obligation_impact');
    },
    refetchQueries: [
      namedOperations.Query.getObligationById,
      namedOperations.Query.getObligationsByType,
    ],
  });
  const complianceMonitoringEnabled = useIsFeatureVisibleToOrg(
    'compliance_monitoring'
  );
  const internalAuditEnabled = useIsFeatureVisibleToOrg('internal_audit');
  const canViewCompliance = useHasPermission(
    'read:compliance_monitoring_assessment',
    obligation
  );
  const canViewInternalAudit = useHasPermission(
    'read:internal_audit_report',
    obligation
  );
  const [exportItem, { loading: exporting }] = useExporter(
    obligationId,
    internalAuditEnabled && canViewInternalAudit,
    complianceMonitoringEnabled && canViewCompliance
  );

  const onDelete = useDeleteResultNotification({
    entityName: t('obligations.entity_name'),
    asyncAction: async () => {
      // do nothing if obligation isn't present yet.
      if (!obligation) {
        return false;
      }
      await mutate({
        variables: { id: obligation.Id },
      });
      navigate(parentPath);

      return true;
    },
  });
  if (deleteResult.data?.delete_obligation?.affected_rows === 1) {
    return <></>;
  }

  if (data?.obligation.length === 0) {
    throw new PageNotFound(`Obligation with id ${obligationId} not found`);
  }
  const title = obligation?.Title;
  const counter = obligation && `(O-${obligation.SequentialId})`;

  return (
    <PageLayout
      title={title}
      meta={{
        title: defaultTitle,
      }}
      counter={counter}
      actions={
        <SpaceBetween direction={'horizontal'} size={'xs'}>
          <Button
            iconName={'download'}
            disabled={exporting}
            onClick={exportItem}
          >
            {t('export')}
          </Button>
          <Permission
            permission={'delete:obligation'}
            parentObject={obligation}
          >
            <Button
              variant={'normal'}
              formAction={'none'}
              onClick={() => {
                setIsDeleteModalVisible(true);
              }}
            >
              {t('obligations.delete_button')}
            </Button>
          </Permission>
        </SpaceBetween>
      }
    >
      <ControlledTabs
        activeTabId={selectedTabId}
        tabs={tabs}
        variant={'container'}
      />
      <DeleteModal
        loading={deleteResult.loading}
        isVisible={isDeleteModalVisible}
        header={t('delete')}
        onDelete={onDelete}
        onDismiss={() => setIsDeleteModalVisible(false)}
      >
        {t('obligations.confirm_delete_message')}
      </DeleteModal>
    </PageLayout>
  );
};

export default Page;
