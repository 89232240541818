import { useRating } from '@risksmart-app/components/hooks/useRating';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { MAX_COL_WIDTH } from 'src/App.config';

import type { CustomAttributeSchema } from '@/components/Form/CustomAttributes/CustomAttributeSchema';
import Link from '@/components/Link';
import SimpleRatingBadge from '@/components/SimpleRatingBadge';
import { useGetContributorsFieldConfig } from '@/utils/table/hooks/useGetContributorsFieldConfig';
import { useGetDepartmentFieldConfig } from '@/utils/table/hooks/useGetDepartmentFieldConfig';
import { useGetOwnersFieldConfig } from '@/utils/table/hooks/useGetOwnersFieldConfig';
import type { UseGetTablePropsOptions } from '@/utils/table/hooks/useGetStatelessTableProps';
import { useGetTableProps } from '@/utils/table/hooks/useGetTableProps';
import { useGetTablePropsWithoutUrlHash } from '@/utils/table/hooks/useGetTablePropsWithoutUrlHash';
import { useGetTagFieldConfig } from '@/utils/table/hooks/useGetTagFieldConfig';
import type {
  DefaultSortingState,
  TableFields,
  TablePropsWithActions,
} from '@/utils/table/types';
import { dateColumn } from '@/utils/table/utils/dateColumn';
import { acceptanceDetailUrl } from '@/utils/urls';

import type { AcceptanceFlatFields, AcceptanceTableFields } from './types';
import { useLabelledFields } from './useLabelledFields';

const useGetFieldConfig = ({
  useAbsoluteUrls,
}: {
  useAbsoluteUrls?: boolean;
}): TableFields<AcceptanceTableFields> => {
  const allOwners = useGetOwnersFieldConfig<AcceptanceTableFields>();
  const tagField = useGetTagFieldConfig<AcceptanceTableFields>();
  const departmentField = useGetDepartmentFieldConfig<AcceptanceTableFields>(
    (r) => r.departments
  );
  const allContributors =
    useGetContributorsFieldConfig<AcceptanceTableFields>();
  const status = useRating('acceptance_status');
  const { t: st } = useTranslation(['common'], {
    keyPrefix: 'acceptances.columns',
  });
  const { t } = useTranslation(['common'], {
    keyPrefix: 'columns',
  });

  return useMemo(
    () => ({
      Title: {
        header: st('title'),
        cell: (acceptance) => (
          <Link
            variant={'secondary'}
            href={
              useAbsoluteUrls
                ? acceptanceDetailUrl(acceptance.Id)
                : acceptance.Id
            }
            isRelativeUrl={!useAbsoluteUrls}
          >
            {acceptance.Title}
          </Link>
        ),
        isRowHeader: true,
        maxWidth: MAX_COL_WIDTH,
      },
      Details: { header: st('details'), maxWidth: MAX_COL_WIDTH },
      ParentTitle: { header: st('associations'), maxWidth: MAX_COL_WIDTH },
      Tier: { header: st('tier') },
      allOwners,
      allContributors,
      DateAcceptedFrom: dateColumn(st('accepted_from'), 'DateAcceptedFrom'),
      DateAcceptedTo: dateColumn(st('accepted_to'), 'DateAcceptedTo'),
      StatusLabelled: {
        header: st('status'),
        cell: (item) => {
          const rating = status.getByValue(item.Status);

          return (
            <SimpleRatingBadge rating={rating}>
              {item.StatusLabelled}
            </SimpleRatingBadge>
          );
        },
      },
      Id: { header: t('guid') },
      SequentialIdLabel: { header: t('id'), sortingField: 'SequentialId' },
      ModifiedAtTimestamp: dateColumn(t('updated_on'), 'ModifiedAtTimestamp'),
      ModifiedByUser: { header: t('updated_by_id') },
      ModifiedByUserName: { header: t('updated_by_username') },
      requestedBy: { header: t('requested_by') },
      approvedBy: { header: t('approved_by') },
      tags: tagField,
      departments: departmentField,
    }),
    [
      allContributors,
      allOwners,
      departmentField,
      st,
      status,
      t,
      tagField,
      useAbsoluteUrls,
    ]
  );
};

const useGetAcceptancesTableProps = (
  records: AcceptanceFlatFields[] | undefined,
  customAttributeSchema?: CustomAttributeSchema | null,
  defaultSortingState?: DefaultSortingState<AcceptanceTableFields> | undefined,
  useAbsoluteUrls?: boolean
): UseGetTablePropsOptions<AcceptanceTableFields> => {
  const { t: st } = useTranslation(['common'], { keyPrefix: 'acceptances' });

  const labelledFields = useLabelledFields(records);
  const fields = useGetFieldConfig({ useAbsoluteUrls });

  return useMemo(
    () => ({
      tableId: 'acceptanceRegister',
      data: labelledFields,
      customAttributeSchema: customAttributeSchema,
      entityLabel: st('entity_name'),
      emptyCollectionAction: <></>,
      preferencesStorageKey: 'AcceptanceRegisterTable-PreferencesV1',
      enableFiltering: true,
      initialColumns: [
        'Title',
        'Details',
        'ParentTitle',
        'Tier',
        'allOwners',
        'DateAcceptedFrom',
        'DateAcceptedTo',
        'StatusLabelled',
      ],
      fields,
      defaultSortingState,
    }),
    [labelledFields, customAttributeSchema, st, fields, defaultSortingState]
  );
};

export const useGetCollectionTableProps = (
  records: AcceptanceFlatFields[] | undefined,
  customAttributeSchema?: CustomAttributeSchema | null,
  defaultSortingState?: DefaultSortingState<AcceptanceTableFields> | undefined
): TablePropsWithActions<AcceptanceTableFields> => {
  const tableProps = useGetAcceptancesTableProps(
    records,
    customAttributeSchema,
    defaultSortingState,
    true
  );

  return useGetTableProps(tableProps);
};

export const useGetCollectionStatelessTableProps = (
  records: AcceptanceFlatFields[] | undefined,
  customAttributeSchema?: CustomAttributeSchema | null,
  defaultSortingState?: DefaultSortingState<AcceptanceTableFields> | undefined
): TablePropsWithActions<AcceptanceTableFields> => {
  const tableProps = useGetAcceptancesTableProps(
    records,
    customAttributeSchema,
    defaultSortingState
  );

  return useGetTablePropsWithoutUrlHash(tableProps);
};
