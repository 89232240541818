import Alert from '@cloudscape-design/components/alert';
import Container from '@cloudscape-design/components/container';
import SpaceBetween from '@cloudscape-design/components/space-between';
import Button from '@risksmart-app/components/Button';
import { PageNotFound } from '@risksmart-app/components/errors/errors';
import { useGetOptionalGuidParam } from '@risksmart-app/components/routes/routes.utils';
import _ from 'lodash';
import { type FC, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import PageLayout from 'src/layouts/PageLayout';
import { useCustomAttributeLookup } from 'src/pages/dashboards/CustomDataSourceWidget/useCustomAttributeLookup';
import { Permission } from 'src/rbac/Permission';
import { useHasPermission } from 'src/rbac/useHasPermission';

import DeleteModal from '@/components/DeleteModal';
import {
  useDeleteCustomDatasourceMutation,
  useGetCustomDatasourceByIdQuery,
  useGetReportingDataLazyQuery,
  useInsertCustomDataSourceMutation,
  useUpdateCustomDatasourceMutation,
} from '@/generated/graphql';
import { useDeleteResultNotification } from '@/hooks/useMutationResultNotification';
import { evictField } from '@/utils/graphqlUtils';

import type { TypedCustomDatasource } from '../types';
import { CustomDatasourceForm } from './CustomDatasourceForm';
import type { ReportDefinitionFormData } from './definitionSchema';
import type { CustomDataSource } from './formDataMapping';
import {
  mapFromDataToServerVariables,
  mapServerDataToFormData,
} from './formDataMapping';
import ReportTable from './ReportTable';

const Page: FC = () => {
  const navigate = useNavigate();
  const canUpdateDatasource = useHasPermission('update:custom_datasource');
  const customDataSourceId = useGetOptionalGuidParam('customDatasourceId');

  const { data: customDatasource, loading } = useGetCustomDatasourceByIdQuery({
    variables: { Id: customDataSourceId! },
    skip: !customDataSourceId,
    fetchPolicy: 'no-cache',
  });
  const savedCustomDatasource: null | TypedCustomDatasource | undefined =
    customDatasource?.custom_datasource_by_pk;

  if (!loading && !savedCustomDatasource && customDataSourceId) {
    throw new PageNotFound(
      `Custom datasource with id ${customDataSourceId} not found`
    );
  }

  const { t } = useTranslation(['common'], { keyPrefix: 'customDatasources' });
  const [definitionFormData, setDefinitionFormData] =
    useState<ReportDefinitionFormData>();
  const { customAttributeSchemaLookup } = useCustomAttributeLookup();
  const [getReportData, getReportDataResult] = useGetReportingDataLazyQuery({
    fetchPolicy: 'no-cache',
  });
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [insertCustomDatasource] = useInsertCustomDataSourceMutation({});
  const [updateCustomDatasource] = useUpdateCustomDatasourceMutation({});
  const [deleteCustomDatasource, deleteResult] =
    useDeleteCustomDatasourceMutation({
      update: (cache) => {
        evictField(cache, 'custom_datasource');
        evictField(cache, 'custom_datasource_by_pk');
      },
    });

  const pageIndex = useRef(0);
  const pageSize = 20;

  const fetchData = (formData: ReportDefinitionFormData) =>
    getReportData({
      notifyOnNetworkStatusChange: true,
      variables: {
        Input: mapFromDataToServerVariables(formData, {
          offset: pageIndex.current * pageSize,
          limit: pageSize,
        }),
      },
    });

  const onDelete = useDeleteResultNotification({
    entityName: t('entity_name'),
    asyncAction: async () => {
      if (!savedCustomDatasource) {
        return false;
      }
      await deleteCustomDatasource({
        variables: {
          Id: savedCustomDatasource.Id,
        },
      });
      await navigate('..');

      return true;
    },
  });

  const values = useMemo<ReportDefinitionFormData | undefined>(() => {
    return (
      definitionFormData ??
      (savedCustomDatasource
        ? mapServerDataToFormData(savedCustomDatasource as CustomDataSource)
        : undefined)
    );
  }, [savedCustomDatasource, definitionFormData]);

  return (
    <PageLayout
      title={savedCustomDatasource?.Title ?? t('create_title')}
      actions={
        savedCustomDatasource && (
          <SpaceBetween direction={'horizontal'} size={'xxs'}>
            {customDataSourceId && (
              <Permission permission={'delete:custom_datasource'}>
                <Button
                  variant={'normal'}
                  formAction={'none'}
                  onClick={async () => {
                    setIsDeleteModalVisible(true);
                  }}
                >
                  {t('delete_button')}
                </Button>
              </Permission>
            )}
          </SpaceBetween>
        )
      }
    >
      <Container>
        {customAttributeSchemaLookup && (
          <CustomDatasourceForm
            mode={customDataSourceId ? 'update' : 'create'}
            readOnly={!canUpdateDatasource}
            customAttributeSchemaLookup={customAttributeSchemaLookup}
            values={values}
            onPreview={async (formData) => {
              pageIndex.current = 0;
              setDefinitionFormData(formData);
              fetchData(formData);
            }}
            onDismiss={async (saved) => {
              if (!saved) {
                navigate('..');
              }
            }}
            onSave={async (formData) => {
              pageIndex.current = 0;
              setDefinitionFormData(formData);
              const mappedFromData = mapFromDataToServerVariables(formData, {
                offset: pageIndex.current * pageSize,
                limit: pageSize,
              });
              if (savedCustomDatasource) {
                await updateCustomDatasource({
                  variables: {
                    Id: savedCustomDatasource.Id,
                    Data: {
                      Title: formData.title,
                      Fields: mappedFromData.fields,
                      Datasources: mappedFromData.dataSources,
                      Filters: mappedFromData.filters,
                    },
                  },
                });
              } else {
                await insertCustomDatasource({
                  variables: {
                    customDatasource: {
                      Title: formData.title,
                      Fields: mappedFromData.fields,
                      Datasources: mappedFromData.dataSources,
                      Filters: mappedFromData.filters,
                    },
                  },
                });
              }
              navigate('..');
            }}
          />
        )}
      </Container>

      {getReportDataResult.error && !getReportDataResult.loading && (
        <Alert type={'error'}>{t('data_request_failure_message')}</Alert>
      )}
      {customAttributeSchemaLookup && (
        <ReportTable
          customAttributeSchemaLookup={customAttributeSchemaLookup}
          loading={getReportDataResult.loading}
          pageSize={pageSize}
          currentPageIndex={pageIndex.current}
          onPageChangeClick={({ requestedPageIndex }) => {
            pageIndex.current = requestedPageIndex;
            if (!definitionFormData) {
              throw new Error('Missing form data');
            }
            fetchData(definitionFormData);
          }}
          definition={
            definitionFormData
              ? mapFromDataToServerVariables(definitionFormData, {
                  offset: pageIndex.current * pageSize,
                  limit: pageSize,
                })
              : undefined
          }
          items={getReportDataResult.data?.reportingData ?? []}
        />
      )}
      <DeleteModal
        loading={deleteResult.loading}
        isVisible={isDeleteModalVisible}
        header={t('delete_modal_title')}
        onDelete={onDelete}
        onDismiss={() => setIsDeleteModalVisible(false)}
      >
        {t('confirm_delete_message')}
      </DeleteModal>
    </PageLayout>
  );
};

export default Page;
