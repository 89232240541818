import { downloadBlob } from '@risksmart-app/components/File/fileUtils';
import { useAxios } from '@risksmart-app/components/hooks/useAxios';
import { useNotifications } from '@risksmart-app/components/Notifications/useNotifications';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Link from '@/components/Link';

type Props = {
  csvFile: string;
};

const TemplateLink: FC<Props> = ({ csvFile }) => {
  const axios = useAxios();
  const csvType = csvFile.replace('.csv', '');
  const { addNotification } = useNotifications();
  const { t } = useTranslation(['common'], { keyPrefix: 'dataImport' });

  return (
    <div data-testid={csvType}>
      <Link
        onFollow={async () => {
          try {
            const result = await axios.get('/data-import/template', {
              params: { type: csvFile },
              responseType: 'blob',
            });
            downloadBlob(csvFile, result.data);
          } catch {
            addNotification({
              type: 'error',
              content: t('downloadTemplateFailedMessage'),
            });
          }
        }}
        href={'#'}
      >
        {t('downloadTemplate', { item: csvFile })}
      </Link>
    </div>
  );
};

export default TemplateLink;
