import type { SelectProps } from '@cloudscape-design/components/select';
import { PromptId } from '@risksmart-app/shared/ai/PromptId';
import { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import TestScheduleFields from 'src/pages/controls/update/forms/TestScheduleFields';
import type { RiskFormDataFields } from 'src/pages/risks/forms/riskSchema';
import { useHasPermission } from 'src/rbac/useHasPermission';

import ControlledAutosuggest from '@/components/Form/ControlledAutosuggest';
import ControlledGroupAndUserContributorMultiSelect from '@/components/Form/ControlledGroupAndUserContributorMultiSelect';
import ControlledRadioGroup from '@/components/Form/ControlledRadioGroup';
import ControlledSelect from '@/components/Form/ControlledSelect';
import ControlledTextarea from '@/components/Form/ControlledTextarea';
import DepartmentSelector from '@/components/Form/DepartmentSelector';
import ConditionalField from '@/components/Form/Form/CustomisableForm/ConditionalField';
import CustomisableForm from '@/components/Form/Form/CustomisableForm/CustomisableForm';
import FieldGroup from '@/components/Form/Form/CustomisableForm/FieldGroup';
import FormRow from '@/components/Form/Form/FormRow';
import TagSelector from '@/components/Form/TagSelector';
import {
  Contributor_Type_Enum,
  Parent_Type_Enum,
  Risk_Status_Type_Enum,
  Risk_Treatment_Type_Enum,
  useGetEnterpriseRisksByTierQuery,
  useGetRisksByTierQuery,
} from '@/generated/graphql';
import { useLibrary } from '@/hooks/use-library';
import { getFriendlyId } from '@/utils/friendlyId';

import { TestIds } from './RiskFormFieldsTestIds';

interface Props {
  riskId?: string;
  readOnly?: boolean;
  parentRiskNode?: {
    Id: string;
    SequentialId?: null | number | undefined;
    ObjectType: Parent_Type_Enum;
  } | null;
  latestTestDate?: string;
  enterpriseRisk?: boolean;
}

const RiskFormFields = ({
  riskId,
  readOnly,
  parentRiskNode,
  latestTestDate,
  enterpriseRisk,
}: Props) => {
  const { control, watch, setValue, formState } =
    useFormContext<RiskFormDataFields>();

  const { t } = useTranslation(['common']);
  const { t: st } = useTranslation(['common'], { keyPrefix: 'risks.fields' });
  const canInsertTier1Risks = useHasPermission('insert:risk_tier_1');

  const riskTierAndParentEnabled = useHasPermission('update:risk') || !riskId;
  const tiers = t('tiers', { returnObjects: true });
  const tierKeys = Object.keys(tiers) as (keyof typeof tiers)[];
  const tierOptions = tierKeys
    .map((key) => ({
      value: key,
      label: tiers[key],
    }))
    .filter((t) => t.value !== '1' || canInsertTier1Risks || riskId);
  const treatments = t('treatments', { returnObjects: true });

  const treatmentOptions = useMemo(
    () =>
      Object.values(Risk_Treatment_Type_Enum).map((treatment) => ({
        value: treatment,
        label: treatments[treatment as keyof typeof treatments],
      })) ?? [],
    [treatments]
  );

  const statuses = t('statuses', { returnObjects: true });

  const statusOptions = useMemo(
    () =>
      Object.values(Risk_Status_Type_Enum).map((status) => ({
        value: status,
        label: statuses[status as keyof typeof statuses],
      })) ?? [],
    [statuses]
  );

  const riskLibrary = useLibrary('risks');

  const titleOptions = riskLibrary?.map((risk) => ({ value: risk.title }));

  const tier: number = watch('Tier') || 1;
  const title = watch('Title');
  useEffect(() => {
    if (formState.defaultValues?.Description) {
      return;
    }
    const description = riskLibrary.find(
      (risk) => risk.title === title
    )?.description;

    if (description) {
      setValue('Description', description);
    }
  }, [title, riskLibrary, setValue, formState.defaultValues?.Description]);

  const { data: risks, loading: loadingRisks } = useGetRisksByTierQuery({
    variables: {
      where: { Tier: { _eq: tier - 1 } },
    },
    skip: enterpriseRisk || tier === 1,
  });

  const { data: enterpriseRisks, loading: loadingEnterpriseRisks } =
    useGetEnterpriseRisksByTierQuery({
      variables: {
        Tier: tier - 1,
      },
      skip: !enterpriseRisk || tier === 1,
    });

  const showParentRisk = tier > 1;

  const parentRisks = useMemo<SelectProps.Options | undefined>(() => {
    const parents = risks?.risk.filter((r) => r.Id !== riskId);
    if (parentRiskNode) {
      if (!parents?.find((p) => p.Id === parentRiskNode.Id)) {
        parents?.push({
          Title: getFriendlyId(
            Parent_Type_Enum.Risk,
            parentRiskNode.SequentialId
          ),
          SequentialId: parentRiskNode.SequentialId || 0,
          Id: parentRiskNode?.Id || '',
        });
      }
    }

    return parents?.map((risk) => ({
      value: risk.Id,
      label: risk.Title,
      tags: [getFriendlyId(Parent_Type_Enum.Risk, risk.SequentialId)],
    }));
  }, [risks?.risk, parentRiskNode, riskId]);

  const parentEnterpriseRisks = useMemo<SelectProps.Options | undefined>(() => {
    const parents = enterpriseRisks?.enterprise_risk.filter(
      (r) => r.Id !== riskId
    );
    if (parentRiskNode) {
      if (!parents?.find((p) => p.Id === parentRiskNode.Id)) {
        parents?.push({
          Title: getFriendlyId(
            Parent_Type_Enum.EnterpriseRisk,
            parentRiskNode.SequentialId
          ),
          SequentialId: parentRiskNode.SequentialId || 0,
          Id: parentRiskNode?.Id || '',
        });
      }
    }

    return parents?.map((risk) => ({
      value: risk.Id,
      label: risk.Title,
      tags: [getFriendlyId(Parent_Type_Enum.EnterpriseRisk, risk.SequentialId)],
    }));
  }, [enterpriseRisks?.enterprise_risk, parentRiskNode, riskId]);

  return (
    <CustomisableForm readOnly={readOnly}>
      <ControlledAutosuggest
        key={'title'}
        forceRequired={true}
        allowDefaultValue={true}
        testId={TestIds.Name}
        name={'Title'}
        disabled={readOnly}
        label={st('title')}
        description={st('title_help')}
        placeholder={st('title_placeholder')}
        control={control}
        options={titleOptions}
      />
      <ControlledTextarea
        testId={TestIds.Description}
        key={'description'}
        disabled={readOnly}
        defaultRequired={true}
        name={'Description'}
        label={st('description')}
        placeholder={st('description_placeholder')}
        control={control}
        description={st('description_help')}
        additionalPrompts={[
          {
            id: PromptId.GenerateARiskDescription,
            text: t('textInference.general.generateARiskDescription'),
            altPromptText: title,
          },
        ]}
      />

      <FieldGroup key={'tierAndParent'}>
        <ControlledRadioGroup<RiskFormDataFields, number>
          forceRequired={true}
          key={'tier'}
          description={st('tier_help')}
          testId={TestIds.Tier}
          disabled={readOnly || !riskTierAndParentEnabled}
          control={control}
          label={st('tier')}
          name={'Tier'}
          onChange={() => {
            setValue('ParentRiskId', null);
          }}
          transform={{
            input: (value) =>
              isNaN(value) || value === 0 ? '' : value.toString(),
            output: (e) => {
              const output = parseInt(e, 10);

              return isNaN(output) ? 0 : output;
            },
          }}
          items={tierOptions}
        />

        <ConditionalField condition={showParentRisk} key={'parentRiskId'}>
          <FormRow>
            <ControlledSelect
              forceRequired={true}
              filteringType={'auto'}
              statusType={
                loadingRisks || loadingEnterpriseRisks ? 'loading' : 'finished'
              }
              disabled={readOnly || !riskTierAndParentEnabled}
              name={'ParentRiskId'}
              label={st('parent')}
              description={st('parent_help')}
              placeholder={'Select'}
              testId={TestIds.ParentRiskId}
              control={control}
              options={enterpriseRisk ? parentEnterpriseRisks : parentRisks}
            />
          </FormRow>
        </ConditionalField>
      </FieldGroup>

      <ControlledSelect<RiskFormDataFields>
        key={'treatment'}
        testId={TestIds.Treatment}
        statusType={'finished'}
        disabled={readOnly}
        control={control}
        addEmptyOption={true}
        allowDefaultValue={true}
        label={st('treatment')}
        placeholder={st('treatment_placeholder')}
        description={st('treatment_help')}
        name={'Treatment'}
        options={treatmentOptions}
      />

      {!enterpriseRisk && (
        <ControlledSelect<RiskFormDataFields>
          key={'status'}
          testId={TestIds.Status}
          statusType={'finished'}
          disabled={readOnly}
          control={control}
          label={st('status')}
          description={st('status_help')}
          placeholder={st('status_placeholder')}
          name={'Status'}
          options={statusOptions}
        />
      )}

      {!enterpriseRisk && (
        <FormRow key={'owners'}>
          <ControlledGroupAndUserContributorMultiSelect
            forceRequired={true}
            control={control}
            includeGroups={true}
            inheritedContributorsName={'ancestorContributors'}
            label={t('fields.Owner')}
            name={'Owners'}
            description={st('owner_help')}
            testId={TestIds.Owners}
            placeholder={t('fields.Owner_placeholder')}
            disabled={readOnly}
            contributorType={Contributor_Type_Enum.Owner}
          />
        </FormRow>
      )}

      {!enterpriseRisk && (
        <FormRow key={'contributors'}>
          <ControlledGroupAndUserContributorMultiSelect
            key={'contributors'}
            control={control}
            includeGroups={true}
            inheritedContributorsName={'ancestorContributors'}
            label={t('fields.Contributor')}
            name={'Contributors'}
            description={st('Contributor_help')}
            testId={TestIds.Contributors}
            placeholder={t('fields.Contributor_placeholder')}
            disabled={readOnly}
            contributorType={Contributor_Type_Enum.Contributor}
          />
        </FormRow>
      )}

      {!enterpriseRisk && (
        <FormRow size={'xl'} key={'tags'}>
          <TagSelector
            testId={TestIds.Tags}
            disabled={readOnly}
            description={st('tagsHelp')}
            name={'tags'}
            control={control}
          />
        </FormRow>
      )}

      {!enterpriseRisk && (
        <DepartmentSelector
          testId={TestIds.Departments}
          key={'departments'}
          description={st('departmentsHelp')}
          disabled={readOnly}
          name={'departments'}
          control={control}
        />
      )}

      {!enterpriseRisk && (
        <TestScheduleFields
          key={'testConfigFields'}
          control={control}
          readOnly={false}
          latestTestDate={latestTestDate ?? null}
          manualNextTestDueName={'schedule.ManualDueDate'}
          testFrequencyName={'schedule.Frequency'}
          testTimeToCompleteValueName={'schedule.TimeToCompleteValue'}
          testScheduleStartDateName={'schedule.StartDate'}
          testTimeToCompleteUnitName={'schedule.TimeToCompleteUnit'}
        />
      )}
    </CustomisableForm>
  );
};

export default RiskFormFields;
