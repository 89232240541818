import i18n from '@risksmart-app/i18n/i18n';
import { t } from 'i18next';

import { ParentTypeEnum } from '../../../generated/graphql';
import { DataSourceType } from '../../reporting/schema';
import { getAuditColumns } from './shared';
import { SharedDataset } from './types';

export const getCauses = () =>
  ({
    label: i18n.format(t('cause_other'), 'capitalize'),
    parentTypes: new Set<DataSourceType>(['issues']),
    customAttributeFormConfigurationParentTypes: [ParentTypeEnum.Cause],
    fields: {
      ...getAuditColumns(),
      title: {
        dataType: 'text',
        displayType: 'text',
        label: t('causes.columns.title'),
      },
      id: { dataType: 'guid', displayType: 'text', label: t('columns.guid') },
      description: {
        dataType: 'text',
        displayType: 'text',
        label: t('causes.columns.description'),
      },
      significance: {
        dataType: 'number',
        displayType: 'rating',
        ratingKey: 'significance',
        label: t('causes.columns.significance'),
      },
    },
  }) satisfies SharedDataset;
