import { useMultiParentFileUpdate } from '@risksmart-app/components/File/useFileUpdate';
import type { FC, ReactNode } from 'react';
import { useNavigate } from 'react-router';
import TestResultForm from 'src/pages/controls/update/forms/TestResultForm';
import type { TestResultFormFieldsData } from 'src/pages/controls/update/forms/testResultSchema';
import { defaultValues } from 'src/pages/controls/update/forms/testResultSchema';
import type { ObjectWithContributors } from 'src/rbac/Permission';
import type { UserOption } from 'src/schemas/global';

import { PageWrapper } from '@/components/Form/Form/PageWrapper';
import {
  Parent_Type_Enum,
  useGetTestResultByIdQuery,
  useInsertControlTestResultMutation,
  useUpdateTestResultMutation,
} from '@/generated/graphql';
import { evictField } from '@/utils/graphqlUtils';

import type { AssessmentTypeEnum } from '../useAssessmentTypeConfig';
import { useAssessmentTypeConfig } from '../useAssessmentTypeConfig';
type Props = {
  assessmentMode: AssessmentTypeEnum;
  readonly: boolean;
  navigateToResults: boolean;
  assessmentId: string;
  controlTestId?: string;
  assessedItem?: ObjectWithContributors;
  id?: string;
  onDismiss?: (saved: boolean) => void;
  beforeFieldsSlot?: ReactNode;
  controlIds?: string[];
  header?: string;
};

const ConnectedControlTestResultForm: FC<Props> = (props) => {
  const navigate = useNavigate();
  const Id = props.id;
  let Ids: string[] = [];
  if (Id) {
    Ids.push(Id);
  }
  const {
    routing: { resultsRegisterUrl },
  } = useAssessmentTypeConfig(props.assessmentMode);
  const { data } = useGetTestResultByIdQuery({
    variables: {
      Id: Id,
    },
    skip: !Id,
    fetchPolicy: 'no-cache',
  });
  const testResult = data?.test_result[0];
  const { updateFiles } = useMultiParentFileUpdate();

  const [insert] = useInsertControlTestResultMutation({
    update: (cache) => {
      evictField(cache, 'test_result');
      // latest test result (overall effectiveness) shown in controls
      evictField(cache, 'control');
      evictField(cache, 'internal_audit_report');
      evictField(cache, 'compliance_monitoring_assessment');
      evictField(cache, 'risk_score');
    },
  });
  const [update] = useUpdateTestResultMutation({
    update: (cache) => {
      evictField(cache, 'test_result');
      // latest test result (overall effectiveness) shown in controls
      evictField(cache, 'control');
      evictField(cache, 'internal_audit_report');
      evictField(cache, 'compliance_monitoring_assessment');
      evictField(cache, 'risk_score');
    },
  });

  const onSave = async (data: TestResultFormFieldsData) => {
    const { newFiles, files, ...rest } = data;
    if (testResult) {
      const { data } = await update({
        variables: {
          object: {
            ...rest,
            OriginalTimestamp: testResult.ModifiedAtTimestamp,
            Id: testResult.Id,
            ParentControlId: testResult.ParentControlId,
            CustomAttributeData: rest.CustomAttributeData || undefined,
            Submitter: rest.Submitter.value,
          },
        },
      });
      if (!data?.updateTestResultApi?.Id) {
        throw new Error(
          'Records not updated. Record may have been updated by another user'
        );
      }
    } else {
      const result = await insert({
        variables: {
          ...rest,
          ControlIds: rest.ParentControlIds.map((p) => p.value),
          AssessmentId:
            props.assessmentMode == 'rating' ? props.assessmentId : undefined,
          ComplianceMonitoringAssessmentId:
            props.assessmentMode == 'compliance_monitoring_assessment'
              ? props.assessmentId
              : undefined,
          InternalAuditReportId:
            props.assessmentMode == 'internal_audit_report'
              ? props.assessmentId
              : undefined,
          CustomAttributeData: rest.CustomAttributeData || undefined,
          Submitter: rest.Submitter.value,
        },
      });
      if (!result.data?.insertControlTestResult?.Ids) {
        throw new Error('Id not found');
      }
      Ids = result.data?.insertControlTestResult?.Ids;
    }
    if (!Ids) {
      throw new Error('Id not found');
    }

    await updateFiles({
      parentType: Parent_Type_Enum.TestResult,
      parentIds: Ids,
      newFiles,
      originalFiles: testResult?.files ?? [],
      selectedFiles: files,
    });
    if (props.navigateToResults) {
      navigate(resultsRegisterUrl(props.assessmentId));
    }
  };

  return (
    <div className={'pb-5'}>
      <TestResultForm
        {...props}
        renderTemplate={(renderProps) => <PageWrapper {...renderProps} />}
        defaultValues={{
          ...defaultValues,
        }}
        assessmentMode={props.assessmentMode}
        values={{
          ...defaultValues,
          ...testResult,
          Submitter: testResult?.Submitter
            ? { value: testResult.Submitter, type: 'user' }
            : (undefined as unknown as UserOption),
          ParentControlIds: testResult
            ? [{ value: testResult.ParentControlId! }]
            : props.controlIds
              ? props.controlIds.map((c) => ({
                  value: c,
                }))
              : [],
        }}
        onSave={onSave}
        onDismiss={props.onDismiss}
        readOnly={props.readonly}
        disableControlSelect={props.id !== undefined}
      />
    </div>
  );
};

export default ConnectedControlTestResultForm;
