import i18n from '@risksmart-app/i18n/i18n';
import { t } from 'i18next';

import { ParentTypeEnum } from '../../../generated/graphql';
import { DataSourceType } from '../schema';
import {
  getAuditColumns,
  getContributorsColumns,
  getOwnersColumns,
  getTagAndDepartmentsColumns,
} from './shared';
import { SharedDataset } from './types';

export const getAssessments = () =>
  ({
    label: i18n.format(t('assessment_other'), 'capitalize'),
    parentTypes: new Set<DataSourceType>(),
    customAttributeFormConfigurationParentTypes: [ParentTypeEnum.Assessment],
    fields: {
      ...getAuditColumns(),
      ...getTagAndDepartmentsColumns(),
      ...getOwnersColumns(),
      ...getContributorsColumns(),
      sequentialId: {
        dataType: 'number',
        displayType: 'number',
        label: t('columns.id'),
        prefix: 'ASMT-',
      },
      id: { dataType: 'guid', displayType: 'text', label: t('columns.guid') },
      detailsLink: {
        dataType: 'guid',
        label: t('assessments.columns.details_link'),
        displayType: 'detailsLink',
        entityInfoParentType: ParentTypeEnum.Assessment,
      },
      title: {
        dataType: 'text',
        displayType: 'text',
        label: t('assessments.columns.Title'),
      },
      summary: {
        dataType: 'text',
        displayType: 'text',
        label: t('assessments.columns.Summary'),
      },
      status: {
        dataType: 'text',
        displayType: 'rating',
        ratingKey: 'assessment_status',
        label: t('assessments.columns.Status'),
      },
      outcome: {
        dataType: 'text',
        displayType: 'rating',
        ratingKey: 'assessment_outcome',
        label: t('assessments.columns.Outcome'),
      },
      startDate: {
        dataType: 'date',
        displayType: 'date',
        label: t('assessments.columns.StartDate'),
      },
      targetCompletionDate: {
        dataType: 'date',
        displayType: 'date',
        label: t('assessments.columns.TargetCompletionDate'),
      },
      actualCompletionDate: {
        dataType: 'date',
        displayType: 'date',
        label: t('assessments.columns.CompletionDate'),
      },
      nextAssessmentDate: {
        dataType: 'date',
        displayType: 'date',
        label: t('assessments.columns.NextTestDate'),
      },
      completedById: {
        dataType: 'text',
        displayType: 'text',
        label: t('assessments.columns.CompletionById'),
      },
      completedByFriendlyName: {
        dataType: 'text',
        displayType: 'text',
        label: t('assessments.columns.CompletionBy'),
      },
    },
  }) satisfies SharedDataset;
