import { ControlGroupsUpdateBreadcrumb, ControlGroupUpdatePage } from '@pages';
import i18n from '@risksmart-app/i18n/i18n';
import type { RouteObject } from 'react-router';
import ProtectedRoute from 'src/rbac/ProtectedRoute';

import { controlRoute } from './controlRoute.routes.config';

export const controlGroupRoute: RouteObject = {
  path: ':controlGroupId',
  handle: {
    // TODO: translation
    title: 'Control Group',
    breadcrumb: ControlGroupsUpdateBreadcrumb,
  },
  children: [
    {
      path: '',
      element: (
        <ProtectedRoute permission={'read:control_group'}>
          <ControlGroupUpdatePage activeTabId={'details'} />
        </ProtectedRoute>
      ),
    },
    {
      path: 'controls',
      handle: {
        title: () => i18n.format(i18n.t('control_other'), 'capitalize'),
      },
      children: [
        {
          path: '',
          element: (
            <ProtectedRoute permission={'read:control'}>
              <ControlGroupUpdatePage activeTabId={'controls'} />
            </ProtectedRoute>
          ),
        },
        controlRoute,
      ],
    },
    {
      path: 'linked-items',
      handle: {
        title: 'Linked items',
      },
      children: [
        {
          path: '',
          element: <ControlGroupUpdatePage activeTabId={'linkedItems'} />,
        },
      ],
    },
  ],
};
