import { useRating } from '@risksmart-app/components/hooks/useRating';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Attestation_Record_Status_Enum } from '@/generated/graphql';
import { calculateAttestationsCompletePercentage } from '@/utils/attestationUtils';

import type {
  AttestationFlatField,
  AttestationRegisterFields,
  GroupedRecords,
} from './types';

export const useLabelledFields = (
  records: AttestationFlatField[] | undefined
): AttestationRegisterFields[] | undefined => {
  const { getByValue } = useRating('attestation_record_status');
  const { t } = useTranslation(['common'], {
    keyPrefix: 'attestations.is_attestation_active',
  });

  return useMemo<AttestationRegisterFields[] | undefined>(() => {
    const groupedRecords: GroupedRecords = {};

    records?.forEach((record) => {
      const attestationStatus = getCorrectedAttestationStatus(record);
      const groupedRecord = {
        ...record,
        AttestationStatusLabel: getByValue(attestationStatus)?.label ?? '-',
        ActiveLabel: record.Active ? t('yes') : t('no'),
        User: record.user?.FriendlyName ?? record.UserId,
        Document: `${record.node.documentFile?.parent?.Title} (${record.node.documentFile?.Version})`,
        AttestationStatus: attestationStatus,
      };

      if (!(record.UserId in groupedRecords)) {
        groupedRecords[record.UserId] = {
          UserId: record.UserId,
          User: record.user.FriendlyName ?? '-',
          Email: record.user.Email ?? '-',
          records: [groupedRecord],
        };

        return;
      }

      groupedRecords[record.UserId].records.push(groupedRecord);
    });

    calculateAttestationsCompletePercentage(groupedRecords);

    return Object.values(groupedRecords).map((record) => {
      return {
        ...record,
      };
    });
  }, [records, getByValue, t]);
};

/**
 * Return the attestation status with the expiry date taken into account
 * @param record
 * @returns
 */
export const getCorrectedAttestationStatus = (record: {
  AttestationStatus: Attestation_Record_Status_Enum;
  ExpiresAt?: null | string | undefined;
}): Attestation_Record_Status_Enum =>
  dayjs(record.ExpiresAt).isBefore() &&
  record.AttestationStatus !== Attestation_Record_Status_Enum.Attested &&
  record.AttestationStatus !== Attestation_Record_Status_Enum.NotRequired
    ? Attestation_Record_Status_Enum.Expired
    : record.AttestationStatus;
