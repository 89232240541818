import { useRating } from '@risksmart-app/components/hooks/useRating';
import type { ContentTable } from 'pdfmake/interfaces';
import { useTranslation } from 'react-i18next';

import { useGetDocumentFilesByDocumentIdLazyQuery } from '@/generated/graphql';
import { toLocalDate } from '@/utils/dateUtils';
import { createTable, tableHeaders } from '@/utils/pdf/table';

const useVersionExportTable = (
  documentId: string
): [() => Promise<ContentTable>, boolean] => {
  const { getLabel: getVersionTypeLabel } = useRating('document_file_type');
  const { getLabel: geVersionStatusLabel } = useRating('document_file_status');
  const [getDocumentVersions, getDocumentVersionResult] =
    useGetDocumentFilesByDocumentIdLazyQuery({
      variables: {
        documentId,
      },
      fetchPolicy: 'no-cache',
    });

  const { t: columns } = useTranslation(['common'], {
    keyPrefix: 'columns',
  });
  const { t: documentFilesColumns } = useTranslation(['common'], {
    keyPrefix: 'documentFiles.columns',
  });
  const createExportTable = async () => {
    const { data: versionsData } = await getDocumentVersions();
    const versionsTableData = (versionsData?.document_file ?? []).map((au) => [
      au.Version,
      getVersionTypeLabel(au.Type),
      geVersionStatusLabel(au.Status),
      toLocalDate(au.ReviewDate),
      au.reviewedBy?.FriendlyName ?? '-',
      toLocalDate(au.NextReviewDate),
      toLocalDate(au.CreatedAtTimestamp),
    ]);

    return createTable({
      widths: '*',
      body: [
        tableHeaders([
          documentFilesColumns('version'),
          documentFilesColumns('type'),
          documentFilesColumns('status'),
          documentFilesColumns('reviewDate'),
          documentFilesColumns('reviewedBy'),
          documentFilesColumns('reviewDate'),
          columns('created_on'),
        ]),
        ...versionsTableData,
      ],
    });
  };

  return [createExportTable, getDocumentVersionResult.loading];
};

export default useVersionExportTable;
