import Alert from '@cloudscape-design/components/alert';
import Spinner from '@cloudscape-design/components/spinner';
import {
  EMPTY_PREFERENCES_SET,
  ENABLED_CHANNELS,
  preferencesSetSchema,
} from '@risksmart-app/shared/knock/schemas';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ModalForm } from '@/components/Form/Form/ModalForm';
import NotificationSettingsForm from '@/components/NotificationSettingsModal/NotificationSettingsForm';
import { useWorkflows } from '@/components/NotificationSettingsModal/util';
import type { NotificationPreferencesOutput } from '@/generated/graphql';
import {
  GetNotificationPreferencesDocument,
  useDisconnectSlackMutation,
  useGetNotificationPreferencesQuery,
  useUpdateNotificationPreferencesMutation,
} from '@/generated/graphql';
import { useSlack } from '@/hooks/useSlack';

type Props = {
  onClose: () => void;
};

const NotificationSettingsModal = ({ onClose }: Props) => {
  const { loginWithSlack } = useSlack();
  const [disconnectSlack, { loading: slackLoading }] =
    useDisconnectSlackMutation();
  const { data, loading, error, refetch } =
    useGetNotificationPreferencesQuery();
  const { t } = useTranslation(['common'], {
    keyPrefix: 'notification_settings',
  });
  const { t: tt } = useTranslation(['common']);
  const [update] = useUpdateNotificationPreferencesMutation();
  const onSave = async (data: NotificationPreferencesOutput) => {
    const { __typename, ...preferenceSet } = data;
    await update({
      variables: { preferenceSet },
      refetchQueries: [GetNotificationPreferencesDocument],
    });
  };
  const workflows = useWorkflows();

  const isSlackConnected = useMemo(() => {
    return !!data?.slackNotificationConnection.connected;
  }, [data]);

  return (
    <ModalForm
      formId={'notification-settings'}
      values={data?.notificationPreferences}
      defaultValues={EMPTY_PREFERENCES_SET}
      onSave={onSave}
      schema={preferencesSetSchema}
      i18n={tt('notification_settings')}
      visible={true}
      onDismiss={onClose}
      secondaryActions={
        loading || !data?.notificationPreferences
          ? []
          : [
              {
                label: isSlackConnected
                  ? t('slackButton.disconnect')
                  : t('slackButton.connect'),
                action: async () => {
                  if (isSlackConnected) {
                    disconnectSlack().then(() => refetch());
                  } else {
                    loginWithSlack()
                      .then(() => refetch())
                      .catch();
                  }
                },
                loading: slackLoading,
              },
            ]
      }
    >
      {loading ? (
        <div className={'grid place-items-center p-4'}>
          <Spinner size={'big'} />
        </div>
      ) : error ? (
        <Alert
          statusIconAriaLabel={'Error'}
          type={'error'}
          header={`Failed to load ${t('entity_name')}`}
        />
      ) : (
        <NotificationSettingsForm
          workflows={workflows}
          enabledChannels={ENABLED_CHANNELS}
          defaultDisabledChannels={isSlackConnected ? [] : ['chat']}
        />
      )}
    </ModalForm>
  );
};

export default NotificationSettingsModal;
