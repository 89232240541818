import i18n from '@risksmart-app/i18n/i18n';
import { t } from 'i18next';

import { ParentTypeEnum } from '../../../generated/graphql';
import { DataSourceType } from '../../reporting/schema';
import {
  getAuditColumns,
  getContributorsColumns,
  getOwnersColumns,
  getTagAndDepartmentsColumns,
} from './shared';
import { SharedDataset } from './types';

export const getActions = () =>
  ({
    label: i18n.format(t('action_other'), 'capitalize'),
    parentTypes: new Set<DataSourceType>(['risks', 'issues', 'controls']),
    customAttributeFormConfigurationParentTypes: [ParentTypeEnum.Action],
    fields: {
      ...getAuditColumns(),
      ...getTagAndDepartmentsColumns(),
      ...getOwnersColumns(),
      ...getContributorsColumns(),
      title: {
        dataType: 'text',
        displayType: 'text',
        label: t('actions.columns.title'),
      },
      id: { dataType: 'guid', displayType: 'text', label: t('columns.guid') },
      dateDue: {
        dataType: 'date',
        displayType: 'date',
        label: t('actions.columns.due_date'),
      },
      priority: {
        dataType: 'number',
        displayType: 'rating',
        ratingKey: 'priority',
        label: t('actions.columns.priority'),
      },
      detailsLink: {
        dataType: 'guid',
        label: t('actions.columns.details_link'),
        displayType: 'detailsLink',
        entityInfoParentType: ParentTypeEnum.Action,
      },
      description: {
        dataType: 'text',
        displayType: 'text',
        label: t('actions.columns.description'),
      },
    },
  }) satisfies SharedDataset;
