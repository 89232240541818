import useRisksmartUser from '@risksmart-app/components/hooks/useRisksmartUser';
import { isUserInOrganization } from '@risksmart-app/components/utils/authUtils';
import type { FC } from 'react';
import { Navigate } from 'react-router';
import { FeaturesProvider } from 'src/rbac/FeatureProvider';
import { PermissionsProvider } from 'src/rbac/PermissionProvider';

import { logoutUrl } from '@/utils/urls';

interface Props {
  children?: React.ReactNode | React.ReactNode[];
  title?: string;
}

const FullscreenLayout: FC<Props> = ({ children }) => {
  const { user } = useRisksmartUser();

  if (!isUserInOrganization(user)) {
    console.warn('User is not part of an organisation, logging out');

    return <Navigate to={logoutUrl()} />;
  }

  return (
    <FeaturesProvider>
      <PermissionsProvider>
        <div className={'w-full h-full'}>{children}</div>
      </PermissionsProvider>
    </FeaturesProvider>
  );
};

export default FullscreenLayout;
