import Button from '@risksmart-app/components/Button';
import { useRating } from '@risksmart-app/components/hooks/useRating';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Permission } from 'src/rbac/Permission';

import Link from '@/components/Link';
import SimpleRatingBadge from '@/components/SimpleRatingBadge';
import type { UseGetTablePropsOptions } from '@/utils/table/hooks/useGetStatelessTableProps';
import { useGetTableProps } from '@/utils/table/hooks/useGetTableProps';
import type { TableFields, TablePropsWithActions } from '@/utils/table/types';
import { dateColumn } from '@/utils/table/utils/dateColumn';
import {
  createQuestionnaireInviteUrl,
  questionnaireResponseDetailsUrl,
} from '@/utils/urls';

import type {
  ThirdPartyResponseFields,
  ThirdPartyResponseRegisterFields,
} from './types';
import { useLabelledFields } from './useLabelledFields';

const useGetFieldConfig = (): TableFields<ThirdPartyResponseRegisterFields> => {
  const { t } = useTranslation(['common'], { keyPrefix: 'columns' });
  const { t: ct } = useTranslation(['common'], {
    keyPrefix: 'third_party_responses.columns',
  });
  const { getByValue } = useRating('third_party_response_status');

  return {
    Questionnaire: {
      header: ct('questionnaire'),
      cell: (item) => (
        <Link
          variant={'secondary'}
          href={questionnaireResponseDetailsUrl(item.Id, item.ParentId)}
        >
          {item.Questionnaire}
        </Link>
      ),
    },
    QuestionnaireVersion: {
      header: ct('version'),
      cell: (item) => item.QuestionnaireVersion,
    },
    UserEmail: {
      header: ct('userEmail'),
    },
    StartDate: dateColumn(ct('startDate'), 'StartDate'),
    Status: {
      header: ct('status'),
      cell: (item) => <SimpleRatingBadge rating={getByValue(item.Status)} />,
    },
    ExpiresAt: dateColumn(ct('expireBy'), 'ExpiresAt'),
    CreatedAtTimestamp: dateColumn(t('created_on'), 'CreatedAtTimestamp'),
    ModifiedAtTimestamp: dateColumn(t('updated_on'), 'ModifiedAtTimestamp'),
    CreatedByFriendlyName: {
      header: t('created_by_username'),
    },
    ModifiedByFriendlyName: {
      header: t('updated_by_username'),
    },
  };
};

export const useGetQuestionnaireTemplatesTableProps = (
  records: ThirdPartyResponseFields[] | undefined,
  thirdPartyId?: string
): UseGetTablePropsOptions<ThirdPartyResponseRegisterFields> => {
  const { t: st } = useTranslation(['common'], {
    keyPrefix: 'questionnaire_invite',
  });
  const data = useLabelledFields(records);
  const fields = useGetFieldConfig();

  return useMemo(() => {
    return {
      data,
      fields,
      customAttributeSchema: null,
      entityLabel: st('entity_name'),
      emptyCollectionAction: (
        <Permission permission={'update:third_party'}>
          <Button href={createQuestionnaireInviteUrl(thirdPartyId ?? '')}>
            {st('create_new_button')}
          </Button>
        </Permission>
      ),
      enableFiltering: true,
      initialColumns: [
        'Questionnaire',
        'QuestionnaireVersion',
        'Status',
        'UserEmail',
      ],
      preferencesStorageKey: 'QuestionnaireInvitesTable-PreferencesV1',
    };
  }, [st, data, fields, thirdPartyId]);
};

export const useGetCollectionTableProps = (
  records: ThirdPartyResponseFields[] | undefined,
  thirdPartyId?: string
): TablePropsWithActions<ThirdPartyResponseRegisterFields> => {
  const props = useGetQuestionnaireTemplatesTableProps(records, thirdPartyId);

  return useGetTableProps(props);
};
