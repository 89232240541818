import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ModalForm } from '@/components/Form/Form/ModalForm';
import { useLinkItemsMutation } from '@/generated/graphql';
import { evictField } from '@/utils/graphqlUtils';

import type { LinkedItemFields } from '../../../schemas/linkedItemSchema';
import { defaultValues, LinkedItem } from '../../../schemas/linkedItemSchema';
import LinkedItemForm from '../forms/LinkedItemForm';

interface Props {
  onDismiss: (saved?: boolean) => void;
  sourceId: string;
  excludeIds: string[];
  includeAssessments?: boolean;
}

const LinkItemModal: FC<Props> = ({
  sourceId,
  excludeIds,
  onDismiss,
  includeAssessments,
}) => {
  const { t } = useTranslation('common');
  const [insert] = useLinkItemsMutation({
    update: (cache) => {
      evictField(cache, 'linked_item');
      evictField(cache, 'control');
      evictField(cache, 'action');
      evictField(cache, 'issue');
      evictField(cache, 'appetite');
      evictField(cache, 'risk');
    },
  });

  const onSave = async (data: LinkedItemFields) => {
    await insert({
      variables: {
        Source: sourceId,
        Targets: data.Target.map((c) => c.value),
      },
    });
  };

  return (
    <ModalForm
      formId={'link-item'}
      header={'Link item'} // translation
      onSave={onSave}
      schema={LinkedItem}
      i18n={t('linkedItems')}
      defaultValues={defaultValues}
      visible={true}
      onDismiss={onDismiss}
    >
      <LinkedItemForm
        excludeIds={excludeIds}
        includeAssessments={includeAssessments}
      />
    </ModalForm>
  );
};

export default LinkItemModal;
