import type { Auth0ContextInterface } from '@auth0/auth0-react';
import type { Context, FC } from 'react';

import useRisksmartUser from '../hooks/useRisksmartUser';

interface Props {
  loginUrl: () => string | string;
  authContext?: Context<Auth0ContextInterface>;
}

const Page: FC<Props> = ({ loginUrl, authContext }) => {
  const { logout, isLoading } = useRisksmartUser(authContext);

  // clear data from current session
  window.sessionStorage.clear();

  if (isLoading) {
    return <>{'Logging you out, please wait...'}</>;
  }

  logout({
    logoutParams: {
      returnTo:
        window.location.origin +
        (typeof loginUrl === 'function' ? loginUrl() : loginUrl),
    },
  });

  return <></>;
};

export default Page;
