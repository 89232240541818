import type { PropertyFilterQuery } from '@cloudscape-design/collection-hooks';
import type { SortingState } from '@cloudscape-design/collection-hooks/cjs/interfaces';
import { useCallback, useEffect } from 'react';

import {
  useGetUserTablePreferencesQuery,
  useUpsertUserTablePreferencesMutation,
} from '@/generated/graphql';

import type { DefaultSortingState, TableFields, TableRecord } from '../types';
import { useFiltersFromUrlHash } from './useFiltersFromUrlHash';

type StorageType<T> = {
  sortingState: SortingState<T> | undefined;
  propertyFilter: PropertyFilterQuery | undefined;
};

/**
 * Store and retrieve filters and sorting in the url after the hash.
 * If the hash does not contain this info, then require from db instead
 *
 * @returns
 */
export const useFiltersFromDBAndUrlHash = <T extends TableRecord>({
  fields,
  defaultSortingState,
  tableId,
}: {
  fields: TableFields<T>;
  defaultSortingState?: DefaultSortingState<T> | undefined;
  tableId?: string;
}) => {
  const { data, loading } = useGetUserTablePreferencesQuery({
    variables: { TableId: tableId! },
    skip: !tableId,
    fetchPolicy: 'no-cache',
  });
  const preferences = data?.user_table_preferences[0]?.Preferences;
  const [updateTablePreferences] = useUpsertUserTablePreferencesMutation({});
  const result = useFiltersFromUrlHash(fields, defaultSortingState, true);
  const { setPropertyFilter, setSortingState, sortingState, propertyFilter } =
    result;

  const saveFilterAndSortState = useCallback(
    ({
      sortingState,
      propertyFilter,
    }: {
      sortingState: SortingState<T> | undefined;
      propertyFilter: PropertyFilterQuery | undefined;
    }) => {
      if (tableId) {
        const state: StorageType<T> = { sortingState, propertyFilter };
        updateTablePreferences({
          variables: {
            Preferences: state,
            TableId: tableId,
          },
        });
      }
    },
    [tableId, updateTablePreferences]
  );

  const setSortingStateInStorage = useCallback(
    (sortingState: SortingState<T>) => {
      setSortingState(sortingState);
      saveFilterAndSortState({ sortingState, propertyFilter });
    },
    [propertyFilter, saveFilterAndSortState, setSortingState]
  );

  const setPropertyFilterInStorage = useCallback(
    (propertyFilter: PropertyFilterQuery) => {
      setPropertyFilter(propertyFilter);
      saveFilterAndSortState({ propertyFilter, sortingState });
    },
    [saveFilterAndSortState, setPropertyFilter, sortingState]
  );

  useEffect(() => {
    if (!preferences || result.sortingState || result.propertyFilter) {
      return;
    }

    if (preferences.propertyFilter || preferences.sortingState) {
      result.setPropertyFilterAndSortingState({
        propertyFilter: preferences.propertyFilter,
        sortingState: preferences.sortingState,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  return {
    ...result,
    loading,
    setPropertyFilter: setPropertyFilterInStorage,
    setSortingState: setSortingStateInStorage,
  };
};
