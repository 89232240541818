import { useNotifications } from '@risksmart-app/components/Notifications/useNotifications';
import type { FieldValues } from 'react-hook-form';

import ControlledMultiselect from '@/components/Form/ControlledMultiselect';
import Tokens from '@/components/Tokens';
import { Parent_Type_Enum, useGetRisksQuery } from '@/generated/graphql';
import { getFriendlyId } from '@/utils/friendlyId';
import { riskDetailsUrl } from '@/utils/urls';

import type { ControlledBaseProps } from '../types';

interface Props<T extends FieldValues> extends ControlledBaseProps<T> {
  addEmptyOption?: boolean;
  disabled?: boolean;
  single?: boolean;
  testId?: string;
  excludedIds?: string[];
}

export const ControlledRiskMultiSelect = <T extends FieldValues>({
  ...props
}: Props<T>) => {
  const { addNotification } = useNotifications();

  const { data, loading } = useGetRisksQuery({
    fetchPolicy: 'no-cache',
    onError: (error) => {
      addNotification({
        type: 'error',
        content: <>{error.message}</>,
      });
    },
  });

  const options =
    data?.risk
      .filter((c) => !props.excludedIds?.includes(c.Id))
      .map((r) => ({
        value: r.Id,
        tags: r ? [getFriendlyId(Parent_Type_Enum.Risk, r.SequentialId)] : [],
        label: r?.Title ?? getFriendlyId(Parent_Type_Enum.Risk, r.SequentialId),
      })) ?? [];

  return (
    <ControlledMultiselect
      statusType={loading ? 'loading' : 'finished'}
      {...props}
      hideTokens={true}
      options={options}
      renderTokens={true}
      filteringType={'auto'}
      customTokenRender={(options, actions) => (
        <Tokens
          onRemove={actions.removeToken}
          disabled={props.disabled}
          tokens={options.map((o) => ({
            value: o.value!,
            url: riskDetailsUrl(o.value!),
            label: o.label!,
          }))}
        />
      )}
    />
  );
};
