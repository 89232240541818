import useRisksmartUser from '@risksmart-app/components/hooks/useRisksmartUser';
import { useNotifications } from '@risksmart-app/components/Notifications/useNotifications';
import dayjs from 'dayjs';
import { type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { DashboardItem } from '@/components/RegisterDashboard/DashboardItem';
import { useGetMyItemsDashboardQuery } from '@/generated/graphql';
import { useChangeRequests } from '@/hooks/useChangeRequests';
import {
  actionRegisterUrl,
  assessmentRegisterUrl,
  controlRegisterUrl,
  indicatorRegisterUrl,
  policyRegisterUrl,
  publicPoliciesUrl,
  requestsRegisterUrl,
  riskRegisterUrl,
} from '@/utils/urls';

import { useGetRibbonFilteringTokens } from './hooks/useGetRibbonFilteringTokens';

const Ribbon: FC = () => {
  const { t } = useTranslation(['common'], {
    keyPrefix: 'dashboard.myItemsDashboard.ribbonTitles',
  });
  const { addNotification } = useNotifications();
  const { user } = useRisksmartUser();
  const navigate = useNavigate();
  const { isActiveApprover } = useChangeRequests();
  const { getRibbonFilteringTokens } = useGetRibbonFilteringTokens({
    owners: true,
    contributors: true,
    ownerGroups: true,
    contributorGroups: true,
  });

  const today = dayjs().endOf('day').toISOString();

  const { data } = useGetMyItemsDashboardQuery({
    variables: {
      userId: user!.userId,
      today,
    },
    fetchPolicy: 'no-cache',
    onError: (error) => {
      addNotification({
        type: 'error',
        content: <>{error.message}</>,
      });
    },
  });

  return (
    <>
      <DashboardItem
        title={t('approvals')}
        value={
          data?.change_request.filter((cr) =>
            isActiveApprover(
              cr,
              cr.currentUserOwnerList?.map((u) => u.UserId ?? '')
            )
          ).length ?? 0
        }
        onClick={() =>
          navigate(
            requestsRegisterUrl({
              tokens: [
                {
                  propertyKey: 'RequiresAction',
                  value: 'true',
                  operator: '=',
                },
              ],
              operation: 'and',
            })
          )
        }
      />
      <DashboardItem
        title={t('actions')}
        value={data?.action.length ?? 0}
        onClick={() =>
          navigate(
            actionRegisterUrl({
              tokens: data ? getRibbonFilteringTokens(data.action) : [],
              operation: 'or',
            })
          )
        }
      />
      <DashboardItem
        title={t('risks')}
        value={data?.risk.length ?? 0}
        onClick={() =>
          navigate(
            riskRegisterUrl({
              tokens: data ? getRibbonFilteringTokens(data.risk) : [],
              operation: 'or',
            })
          )
        }
      />
      <DashboardItem
        title={t('indicators')}
        value={data?.indicator.length ?? 0}
        onClick={() =>
          navigate(
            indicatorRegisterUrl({
              filtering: {
                tokens: data ? getRibbonFilteringTokens(data.indicator) : [],
                operation: 'or',
              },
            })
          )
        }
      />
      <DashboardItem
        title={t('documents')}
        value={data?.document.length ?? 0}
        onClick={() =>
          navigate(
            policyRegisterUrl({
              tokens: data ? getRibbonFilteringTokens(data.document) : [],
              operation: 'or',
            })
          )
        }
      />
      <DashboardItem
        title={t('assessments')}
        value={data?.assessment.length ?? 0}
        onClick={() =>
          navigate(
            assessmentRegisterUrl({
              tokens: data ? getRibbonFilteringTokens(data.assessment) : [],
              operation: 'or',
            })
          )
        }
      />
      <DashboardItem
        title={t('controlTests')}
        value={data?.control.length ?? 0}
        onClick={() =>
          navigate(
            controlRegisterUrl({
              tokenGroups: [
                {
                  tokens: [
                    {
                      propertyKey: 'NextTestOverdueDate',
                      value: today,
                      operator: '<=',
                    },
                  ],
                  operation: 'or',
                },
                {
                  tokens: data ? getRibbonFilteringTokens(data.control) : [],
                  operation: 'or',
                },
              ],
              tokens: [],
              operation: 'and',
            })
          )
        }
      />
      <DashboardItem
        title={t('attestations')}
        value={data?.attestation_record_aggregate.aggregate?.count ?? 0}
        onClick={() =>
          navigate(
            publicPoliciesUrl({
              filtering: {
                tokens: [
                  {
                    propertyKey: 'AttestationStatusLabel',
                    value: 'Pending',
                    operator: '=',
                  },
                ],
                operation: 'and',
              },
            })
          )
        }
      />
    </>
  );
};

export default Ribbon;
