import { useRating } from '@risksmart-app/components/hooks/useRating';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import type { CustomAttributeSchema } from '@/components/Form/CustomAttributes/CustomAttributeSchema';
import Link from '@/components/Link';
import SimpleRatingBadge from '@/components/SimpleRatingBadge';
import { Parent_Type_Enum } from '@/generated/graphql';
import { getFriendlyId } from '@/utils/friendlyId';
import type { UseGetTablePropsOptions } from '@/utils/table/hooks/useGetStatelessTableProps';
import { useGetTablePropsWithoutUrlHash } from '@/utils/table/hooks/useGetTablePropsWithoutUrlHash';
import type { TableFields, TablePropsWithActions } from '@/utils/table/types';
import { dateColumn } from '@/utils/table/utils/dateColumn';

import type {
  InternalAuditPerformanceFlatFields,
  InternalAuditPerformanceRegisterFields,
} from './types';
import { useInternalAuditPerformanceLabelledFields } from './useInternalAuditPerformanceLabelledFields';

const useGetFieldConfig = (
  onEdit: (item: InternalAuditPerformanceFlatFields) => void
): TableFields<InternalAuditPerformanceRegisterFields> => {
  const { t } = useTranslation(['common']);
  const { t: st } = useTranslation(['common'], {
    keyPrefix: 'testResults.columns',
  });
  const { getByValue } = useRating('effectiveness');

  return useMemo(
    () => ({
      FriendlyID: {
        header: t('columns.id'),
        cell: (item) => (
          <Link variant={'secondary'} href={'#'} onFollow={() => onEdit(item)}>
            {getFriendlyId(Parent_Type_Enum.TestResult, item.SequentialId)}
          </Link>
        ),
      },
      Title: {
        header: st('title'),
        cell: (item) => item.Title,
        sortingField: 'Title',
      },
      InternalAuditReportTitle: {
        header: st('internalAuditReportTitle'),
        cell: (item) =>
          item.assessmentParents.find((p) => p.internalAuditReport)
            ?.internalAuditReport ? (
            <Link
              isRelativeUrl={true}
              variant={'secondary'}
              href={
                item.assessmentParents.find((p) => p.internalAuditReport)
                  ?.internalAuditReport?.Id
              }
            >
              {
                item.assessmentParents.find((p) => p.internalAuditReport)
                  ?.internalAuditReport?.Title
              }
            </Link>
          ) : (
            '-'
          ),
        isRowHeader: true,
      },
      TestDate: dateColumn(st('date'), 'TestDate'),
      TestTypeLabelled: {
        header: st('test_type'),
        cell: (item) => {
          const testTypeLookup = t('testTypes');
          if (item.TestType) {
            const testTypeLabel =
              testTypeLookup[item.TestType as keyof typeof testTypeLookup];
            if (testTypeLabel) {
              return testTypeLabel;
            }

            return '-';
          }
        },
      },
      OverallEffectivenessLabelled: {
        header: st('overall_effectiveness'),
        cell: (item) => {
          return (
            <SimpleRatingBadge rating={getByValue(item.OverallEffectiveness)} />
          );
        },
      },
      SubmitterName: {
        header: st('submitter'),
        cell: (item) => item.SubmitterName || '-',
        sortingField: 'SubmitterName',
      },
    }),
    [getByValue, onEdit, st, t]
  );
};

const useGetPerformanceTableProps = (
  onEdit: (item: InternalAuditPerformanceFlatFields) => void,
  records: InternalAuditPerformanceFlatFields[] | undefined,
  customAttributeSchema?: CustomAttributeSchema | null
): UseGetTablePropsOptions<InternalAuditPerformanceRegisterFields> => {
  const fields = useGetFieldConfig(onEdit);
  const labelledFields = useInternalAuditPerformanceLabelledFields(records);

  return {
    customAttributeSchema: customAttributeSchema,
    data: labelledFields,
    enableFiltering: true,
    entityLabel: 'rating',
    defaultSortingState: {
      sortingColumn: 'TestDate',
      sortingDirection: 'desc',
    },
    initialColumns: [
      'FriendlyID',
      'Title',
      'TestDate',
      'TestTypeLabelled',
      'OverallEffectivenessLabelled',
      'SubmitterName',
    ],
    preferencesStorageKey:
      'ControlInternalAuditPerformanceRegisterTable-Preferences',
    fields,
  };
};

export const useGetCollectionTableProps = (
  onEdit: (item: InternalAuditPerformanceFlatFields) => void,
  records: InternalAuditPerformanceFlatFields[] | undefined,
  customAttributeSchema?: CustomAttributeSchema | null
): TablePropsWithActions<InternalAuditPerformanceRegisterFields> => {
  const props = useGetPerformanceTableProps(
    onEdit,
    records,
    customAttributeSchema
  );

  return useGetTablePropsWithoutUrlHash(props);
};
