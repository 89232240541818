import type { FC } from 'react';

import { DatePickerInputWithFormField } from '@/components/Form/ControlledDatePicker/ControlledDatePicker';
import HelpLink from '@/components/HelpPanel/HelpLink';

import type { CustomAttributeProps } from './CustomAttributeProps';

export const CustomAttributeDateInput: FC<CustomAttributeProps> = ({
  value,
  onChange,
  label,
  error,
  disabled,
  description,
}) => (
  <DatePickerInputWithFormField
    info={
      description && <HelpLink id={label} title={label} content={description} />
    }
    label={label}
    value={value}
    onChange={onChange}
    disabled={disabled}
    errorMessage={error}
  />
);
