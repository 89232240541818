import type { PropertyFilterOption } from '@cloudscape-design/collection-hooks';
import type { PropertyFilterQuery } from '@cloudscape-design/collection-hooks/mjs/interfaces';
import type { NonCancelableCustomEvent } from '@cloudscape-design/components/interfaces';
import type { PropertyFilterProps } from '@cloudscape-design/components/property-filter';
import { useRating } from '@risksmart-app/components/hooks/useRating';
import { defaultPropertyFilterI18nStrings } from '@risksmart-app/components/Table/propertyFilterI18nStrings';
import type { DataSourceType } from '@risksmart-app/shared/reporting/schema';
import { type FC, useRef, useState } from 'react';

import type { StatusType } from '@/components/Form/ControlledSelect/SelectUtils';
import PropertyFilterPanel from '@/components/PropertyFilterPanel';
import { useGetReportingFilterOptionsLazyQuery } from '@/generated/graphql';
import useEntityInfo from '@/hooks/getEntityInfo';
import { useCommonLookupLazy } from '@/hooks/useCommonLookupLazy';

import { fieldTypes } from './fieldTypes';
import type { RelatedDataSource } from './utils';
import { type AllowedField, getFieldFromValue } from './utils';

const limit = 50;

export type Props = {
  query: PropertyFilterQuery;
  onChange: (query: PropertyFilterQuery) => void;
  allFields: AllowedField[];
  datasources: RelatedDataSource[];
};

const CustomDatasourcePropertyFilter: FC<Props> = ({
  allFields,
  datasources,
  query,
  onChange,
}) => {
  const pageNumber = useRef<number>(0);
  const [filteringOptions, setFilteringOptions] = useState<
    PropertyFilterOption[]
  >([]);
  const request = useRef<{
    filteringProperty: PropertyFilterProps.FilteringProperty | undefined;
    filteringText: string;
  }>();

  const [getFilterOptions] = useGetReportingFilterOptionsLazyQuery();
  const [status, setStatus] = useState<StatusType>('pending');
  const getEntityInfo = useEntityInfo();

  const {
    getByValueAndRatingKey: getRatingByValue,
    getOptionsByRatingKey: getRatingOptions,
  } = useRating();

  const {
    getByValue: getCommonLookupByValue,
    getOptions: getCommonLookupOptions,
  } = useCommonLookupLazy();

  const fetchData = async ({
    filteringText,
    filteringProperty,
    fieldId,
    dataSourceType,
  }: {
    filteringText: string;
    filteringProperty: PropertyFilterProps.FilteringProperty;
    fieldId: string;
    dataSourceType: DataSourceType;
  }) => {
    try {
      const { data } = await getFilterOptions({
        variables: {
          Input: {
            dataSourceType,
            fieldId,
            filteringText,
            limit,
            offset: (pageNumber.current - 1) * limit,
          },
        },
      });
      const items: PropertyFilterOption[] =
        data?.reportingFilterOptions.map(({ value }) => ({
          value,
          label: value,
          propertyKey: filteringProperty.key,
        })) ?? [];

      const hasNext = items.length === limit;
      if (
        !request.current ||
        request.current.filteringText !== filteringText ||
        request.current.filteringProperty !== filteringProperty
      ) {
        // there is another request in progress, discard the result of this one
        return;
      }
      setFilteringOptions(
        pageNumber.current === 1 ? items : filteringOptions.concat(items)
      );
      setStatus(hasNext ? 'pending' : 'finished');
    } catch {
      setStatus('error');
    }
  };

  const handleLoadItems = ({
    detail: { filteringProperty, filteringText, firstPage, samePage },
  }: NonCancelableCustomEvent<PropertyFilterProps.LoadItemsDetail>) => {
    if (firstPage) {
      pageNumber.current = 0;
      setFilteringOptions([]);
    }
    if (!filteringProperty) {
      return;
    }
    const fieldKeys = getFieldFromValue(filteringProperty.key);

    const field = allFields.find((f) => f.fieldId === fieldKeys.fieldId);
    if (!field) {
      return;
    }
    const fieldType = fieldTypes[field.fieldDef.displayType];
    if (!fieldType.asyncOptionSuggestions) {
      return;
    }
    const dataSourceType = datasources[field.dataSourceIndex]?.type;

    if (!dataSourceType) {
      throw new Error('Data source not found');
    }

    setStatus('loading');
    if (!samePage) {
      pageNumber.current++;
    }
    request.current = {
      filteringProperty,
      filteringText,
    };

    fetchData({
      filteringText,
      filteringProperty,
      fieldId: fieldKeys.fieldId,
      dataSourceType,
    });
  };

  return (
    <PropertyFilterPanel
      filteringStatusType={status}
      onLoadItems={handleLoadItems}
      disableFreeTextFiltering={true}
      virtualScroll={true}
      expandToViewport={true}
      enableTokenGroups={true}
      i18nStrings={defaultPropertyFilterI18nStrings}
      query={query}
      filteringOptions={filteringOptions}
      filteringProperties={
        allFields
          .filter((f) => {
            const fieldDef = f.fieldDef;

            return !!fieldTypes[fieldDef.displayType].propertyConfig;
          })
          .map((f) => {
            const fieldDef = f.fieldDef;

            return fieldTypes[fieldDef.displayType].propertyConfig!(f, {
              getRatingByValue,
              getRatingOptions,
              getCommonLookupByValue,
              getCommonLookupOptions,
              getEntityInfo,
            });
          }) ?? []
      }
      onChange={(e) => onChange(e.detail)}
    />
  );
};

export default CustomDatasourcePropertyFilter;
