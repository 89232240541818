import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

const Breadcrumb: FC = () => {
  const { t } = useTranslation(['common'], {
    keyPrefix: 'attestations',
  });

  return <>{t('user_attestations_breadcrumb')}</>;
};

export default Breadcrumb;
