import { useRating } from '@risksmart-app/components/hooks/useRating';
import type { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ControlledFileUpload } from '@/components/Form/ControlledFileUpload/ControlledFileUpload';
import ControlledGroupAndUserContributorMultiSelect from '@/components/Form/ControlledGroupAndUserContributorMultiSelect';
import ControlledInput from '@/components/Form/ControlledInput';
import ControlledRadioGroup from '@/components/Form/ControlledRadioGroup';
import { noTransform } from '@/components/Form/ControlledRadioGroup/radioGroupUtils';
import ControlledSelect from '@/components/Form/ControlledSelect';
import ControlledTextarea from '@/components/Form/ControlledTextarea';
import DepartmentSelector from '@/components/Form/DepartmentSelector';
import CustomisableForm from '@/components/Form/Form/CustomisableForm/CustomisableForm';
import TagSelector from '@/components/Form/TagSelector';
import { Contributor_Type_Enum } from '@/generated/graphql';

import type { ThirdPartyFormData } from './thirdPartySchema';

type Props = {
  readOnly?: boolean;
};

export const ThirdPartyFormFields: FC<Props> = ({ readOnly }) => {
  const { control } = useFormContext<ThirdPartyFormData>();
  const { t: st } = useTranslation(['common'], {
    keyPrefix: 'third_party.fields',
  });

  const { options: typeRatingOptions } = useRating('third_party_type');
  const typeOptions = typeRatingOptions.map((option) => ({
    ...option,
    value: String(option.value),
  }));

  const { options: statusRatingOptions } = useRating('third_party_status');
  const statusOptions = statusRatingOptions.map((option) => ({
    ...option,
    value: String(option.value),
  }));

  const { options: criticalityRatingOptions } = useRating(
    'third_party_criticality'
  );
  const criticalityOptions = criticalityRatingOptions.map((option) => ({
    ...option,
    value: String(option.value),
  }));

  return (
    <CustomisableForm>
      <ControlledInput
        key={'title'}
        name={'title'}
        label={st('title')}
        description={st('title_help')}
        placeholder={st('title_placeholder')}
        control={control}
        readOnly={readOnly}
        forceRequired
      />
      <ControlledTextarea
        key={'description'}
        name={'description'}
        label={st('description')}
        description={st('description_help')}
        placeholder={st('description_placeholder')}
        control={control}
        readOnly={readOnly}
      />
      <ControlledInput
        key={'companyName'}
        name={'companyName'}
        label={st('companyName')}
        description={st('companyName_help')}
        placeholder={st('companyName_placeholder')}
        control={control}
        readOnly={readOnly}
        forceRequired
      />
      <ControlledInput
        key={'companiesHouseNumber'}
        name={'companiesHouseNumber'}
        label={st('companiesHouseNumber')}
        description={st('companiesHouseNumber_help')}
        placeholder={st('companiesHouseNumber_placeholder')}
        control={control}
        readOnly={readOnly}
      />
      <ControlledInput
        key={'address'}
        name={'address'}
        label={st('address')}
        description={st('address_help')}
        placeholder={st('address_placeholder')}
        control={control}
        readOnly={readOnly}
      />
      <ControlledInput
        key={'cityTown'}
        name={'cityTown'}
        label={st('cityTown')}
        description={st('cityTown_help')}
        placeholder={st('cityTown_placeholder')}
        control={control}
        readOnly={readOnly}
      />
      <ControlledInput
        key={'postcode'}
        name={'postcode'}
        label={st('postcode')}
        description={st('postcode_help')}
        placeholder={st('postcode_placeholder')}
        control={control}
        readOnly={readOnly}
      />
      <ControlledInput
        key={'country'}
        name={'country'}
        label={st('country')}
        description={st('country_help')}
        placeholder={st('country_placeholder')}
        control={control}
        readOnly={readOnly}
      />
      <ControlledInput
        key={'primaryContactName'}
        name={'primaryContactName'}
        label={st('primaryContactName')}
        description={st('primaryContactName_help')}
        placeholder={st('primaryContactName_placeholder')}
        control={control}
        readOnly={readOnly}
      />
      <ControlledInput
        key={'contactName'}
        name={'contactName'}
        label={st('contactName')}
        description={st('contactName_help')}
        placeholder={st('contactName_placeholder')}
        control={control}
        readOnly={readOnly}
      />
      <ControlledInput
        key={'contactEmail'}
        name={'contactEmail'}
        label={st('contactEmail')}
        description={st('contactEmail_help')}
        placeholder={st('contactEmail_placeholder')}
        control={control}
        readOnly={readOnly}
      />
      <ControlledInput
        key={'companyDomain'}
        name={'companyDomain'}
        label={st('companyDomain')}
        description={st('companyDomain_help')}
        placeholder={st('companyDomain_placeholder')}
        control={control}
        readOnly={readOnly}
      />
      <ControlledRadioGroup
        key={'type'}
        name={'type'}
        label={st('type')}
        description={st('type_help')}
        placeholder={st('type_placeholder')}
        items={typeOptions}
        transform={noTransform}
        control={control}
        readOnly={readOnly}
        forceRequired
      />
      <ControlledSelect
        key={'status'}
        name={'status'}
        label={st('status')}
        description={st('status_help')}
        placeholder={st('status_placeholder')}
        control={control}
        options={statusOptions}
        readOnly={readOnly}
        forceRequired
      />
      <ControlledSelect
        key={'criticality'}
        name={'criticality'}
        label={st('criticality')}
        description={st('criticality_help')}
        placeholder={st('criticality_placeholder')}
        control={control}
        options={criticalityOptions}
        readOnly={readOnly}
        forceRequired
      />

      <ControlledGroupAndUserContributorMultiSelect
        key={'owners'}
        forceRequired={true}
        control={control}
        includeGroups={true}
        inheritedContributorsName={'ancestorContributors'}
        label={st('owners')}
        name={'Owners'}
        description={st('owners_help')}
        placeholder={st('owners_placeholder')}
        contributorType={Contributor_Type_Enum.Owner}
        readOnly={readOnly}
      />

      <ControlledGroupAndUserContributorMultiSelect
        key={'contributors'}
        control={control}
        includeGroups={true}
        inheritedContributorsName={'ancestorContributors'}
        label={st('contributors')}
        name={'Contributors'}
        description={st('contributors_help')}
        placeholder={st('contributors_placeholder')}
        contributorType={Contributor_Type_Enum.Contributor}
        readOnly={readOnly}
      />

      <TagSelector
        key={'tags'}
        name={'tags'}
        control={control}
        readOnly={readOnly}
      />

      <DepartmentSelector
        key={'departments'}
        name={'departments'}
        control={control}
        readOnly={readOnly}
      />

      <ControlledFileUpload
        key={'newFiles'}
        label={st('newFiles')}
        description={st('newFiles_help')}
        control={control}
        name={'newFiles'}
        saveFilesName={'files'}
        disabled={readOnly}
      />
    </CustomisableForm>
  );
};
