import type { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { CustomisableFormContext } from '@/components/Form/Form/CustomisableFormContext';
import type { FormContextProps } from '@/components/Form/Form/types';
import type { ParentIssueTypes } from '@/utils/issueVariantUtils';
import { IssueTypeMapping } from '@/utils/issueVariantUtils';

import IssueFormFields from './IssueFormFields';
import type { IssueFormDataFields } from './issueSchema';
import { defaultValues, IssueFormSchema } from './issueSchema';

type Props = Omit<
  FormContextProps<IssueFormDataFields>,
  'defaultValues' | 'formId' | 'i18n' | 'parentType' | 'schema'
> & { beforeFieldsSlot?: ReactNode; issueType: ParentIssueTypes };

const IssueForm: FC<Props> = ({ beforeFieldsSlot, issueType, ...props }) => {
  const { t } = useTranslation('common');
  const issueMapping = IssueTypeMapping[issueType];

  return (
    <CustomisableFormContext
      {...props}
      schema={IssueFormSchema}
      defaultValues={defaultValues}
      i18n={t(issueMapping.taxonomy)}
      formId={'issue-form'}
      parentType={issueType}
      approvalConfig={{
        object: props.values?.Id ? { Id: props.values.Id } : undefined,
      }}
    >
      {beforeFieldsSlot}
      <IssueFormFields readOnly={props.readOnly} issueType={issueType} />
    </CustomisableFormContext>
  );
};

export default IssueForm;
