import i18n from '@risksmart-app/i18n/i18n';
import { t } from 'i18next';

import { ParentTypeEnum } from '../../../generated/graphql';
import { DataSourceType } from '../schema';
import { getAuditColumns } from './shared';
import { SharedDataset } from './types';

export const getAppetites = () =>
  ({
    label: i18n.format(t('appetite_other'), 'capitalize'),
    parentTypes: new Set<DataSourceType>(['risks']),
    customAttributeFormConfigurationParentTypes: [ParentTypeEnum.Appetite],
    fields: {
      ...getAuditColumns(),
      statement: {
        dataType: 'text',
        displayType: 'text',
        label: t('appetites.columns.statement'),
      },
      id: { dataType: 'guid', displayType: 'text', label: t('columns.guid') },
      effectiveDate: {
        dataType: 'date',
        displayType: 'date',
        label: t('appetites.columns.effectiveDate'),
      },
      lowerAppetite: {
        dataType: 'number',
        displayType: 'rating',
        ratingKey: 'risk_appetite',
        label: t('appetites.columns.lowerAppetite'),
      },
      upperAppetite: {
        dataType: 'number',
        displayType: 'rating',
        ratingKey: 'risk_appetite',
        label: t('appetites.columns.upperAppetite'),
      },
      sequentialId: {
        dataType: 'number',
        displayType: 'number',
        label: t('columns.id'),
        prefix: 'APT-',
      },
      status: {
        dataType: 'text',
        displayType: 'rating',
        ratingKey: 'appetite_status',
        label: t('appetites.columns.status'),
        onlyShowIfChild: true,
      },
      type: {
        dataType: 'text',
        displayType: 'commonLookup',
        i18nKey: 'appetiteTypes',
        label: t('appetites.columns.appetiteType'),
      },
      detailsLink: {
        dataType: 'guid',
        label: t('appetites.columns.details_link'),
        displayType: 'detailsLink',
        entityInfoParentType: ParentTypeEnum.Appetite,
      },
    },
  }) satisfies SharedDataset;
