import type { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import type { IssueFormDataFields } from 'src/pages/issues/update/forms/issueSchema';

import ControlledDatePicker from '@/components/Form/ControlledDatePicker';
import { ControlledFileUpload } from '@/components/Form/ControlledFileUpload/ControlledFileUpload';
import ControlledGroupAndUserContributorMultiSelect from '@/components/Form/ControlledGroupAndUserContributorMultiSelect';
import ControlledInput from '@/components/Form/ControlledInput';
import { ControlledBooleanRadioGroup } from '@/components/Form/ControlledRadioGroup/ControlledBooleanRadioGroup';
import { yesNoOptions } from '@/components/Form/ControlledRadioGroup/radioGroupUtils';
import ControlledTextarea from '@/components/Form/ControlledTextarea';
import DepartmentSelector from '@/components/Form/DepartmentSelector';
import CustomisableForm from '@/components/Form/Form/CustomisableForm/CustomisableForm';
import TagSelector from '@/components/Form/TagSelector';
import { Contributor_Type_Enum } from '@/generated/graphql';
import { useCommonLookupLazy } from '@/hooks/useCommonLookupLazy';
import type { ParentIssueTypes } from '@/utils/issueVariantUtils';
import { IssueTypeMapping } from '@/utils/issueVariantUtils';

type Props = {
  readOnly?: boolean;
  issueType: ParentIssueTypes;
};
enum TestIds {
  Title = 'title',
  Details = 'details',
  ImpactsCustomer = 'impactsCustomer',
  IsExternalIssue = 'isExternalIssue',
}

const IssueFormFields: FC<Props> = ({ readOnly, issueType }) => {
  const { control } = useFormContext<IssueFormDataFields>();
  const issueMapping = IssueTypeMapping[issueType];
  const { t: st } = useTranslation(['common'], {
    keyPrefix: issueMapping.taxonomy,
  });

  const { getOptions } = useCommonLookupLazy();

  return (
    <CustomisableForm readOnly={readOnly}>
      <ControlledInput
        key={'title'}
        testId={TestIds.Title}
        forceRequired={true}
        name={'Title'}
        label={st('fields.Title')}
        control={control}
        placeholder={st('fields.Title_placeholder') ?? ''}
        disabled={readOnly}
        description={st('fields.Title_help') ?? ''}
      />

      <ControlledTextarea
        key={'details'}
        testId={TestIds.Details}
        defaultRequired={true}
        name={'Details'}
        label={st('fields.Details')}
        placeholder={st('fields.Details_placeholder') ?? ''}
        control={control}
        disabled={readOnly}
        description={st('fields.Details_help')}
      />

      <ControlledBooleanRadioGroup
        key={'impactsCustomer'}
        testId={TestIds.ImpactsCustomer}
        label={st('fields.ImpactsCustomer')}
        name={'ImpactsCustomer'}
        allowDefaultValue={true}
        control={control}
        items={yesNoOptions}
        disabled={readOnly}
        description={st('fields.ImpactsCustomer_help')}
      />

      <ControlledBooleanRadioGroup
        key={'isExternalIssue'}
        testId={TestIds.IsExternalIssue}
        label={st('fields.IsExternalIssue')}
        name={'IsExternalIssue'}
        description={st('fields.IsExternalIssue_help')}
        control={control}
        allowDefaultValue={true}
        items={getOptions(`${issueMapping.taxonomy}.isExternalIssue`)}
        disabled={readOnly}
      />

      <ControlledDatePicker
        key={'dateOccurred'}
        forceRequired={true}
        name={'DateOccurred'}
        label={st('fields.DateOccurred')}
        description={st('fields.DateOccurred_help')}
        control={control}
        disabled={readOnly}
      />

      <ControlledDatePicker
        key={'dateIdentified'}
        forceRequired={true}
        name={'DateIdentified'}
        label={st('fields.DateIdentified')}
        description={st('fields.DateIdentified_help')}
        control={control}
        disabled={readOnly}
      />

      <ControlledGroupAndUserContributorMultiSelect
        key={'owners'}
        control={control}
        label={st('fields.Owner')}
        includeGroups={true}
        name={'Owners'}
        inheritedContributorsName={'ancestorContributors'}
        contributorType={Contributor_Type_Enum.Owner}
        placeholder={st('fields.Owner_placeholder')}
        description={st('fields.Owner_help')}
        disabled={readOnly}
      />

      <ControlledGroupAndUserContributorMultiSelect
        key={'contributors'}
        control={control}
        includeGroups={true}
        inheritedContributorsName={'ancestorContributors'}
        contributorType={Contributor_Type_Enum.Contributor}
        label={st('fields.Contributor')}
        name={'Contributors'}
        placeholder={st('fields.Contributor_placeholder')}
        description={st('fields.Contributor_help')}
        disabled={readOnly}
      />

      <ControlledFileUpload
        key={'newFiles'}
        label={st('fields.newFiles')}
        description={st('fields.newFiles_help')}
        control={control}
        name={'newFiles'}
        saveFilesName={'files'}
        disabled={readOnly}
      />

      <TagSelector
        testId={'tags'}
        name={'tags'}
        key={'tags'}
        control={control}
        label={st('fields.Tags')}
        description={st('fields.Tags_help')}
        disabled={readOnly}
      />
      <DepartmentSelector
        key={'departments'}
        name={'departments'}
        label={st('fields.Departments')}
        description={st('fields.Departments_help')}
        control={control}
        disabled={readOnly}
      />
    </CustomisableForm>
  );
};

export default IssueFormFields;
