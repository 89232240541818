import SpaceBetween from '@cloudscape-design/components/space-between';
import { useGetGuidParam } from '@risksmart-app/components/routes/routes.utils';
import type { FC } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import AssessmentResultModal from 'src/pages/assessments/modals/AssessmentResultModal';
import { getContributors, getOwners } from 'src/rbac/contributorHelper';
import { useHasPermission } from 'src/rbac/useHasPermission';

import DocumentVersionPreview from '@/components/DocumentVersionPreview';
import { ownerAndContributorIds } from '@/components/Form';
import LatestRatingsPreview from '@/components/LatestRatingsPreview';
import type { ResultProps } from '@/components/LatestRatingsPreview/LatestRatingsPreview';
import {
  Parent_Type_Enum,
  useGetDocumentByIdQuery,
  useGetLatestComplianceMonitoringAssessmentDocumentAssessmentResultByDocumentIdQuery,
  useGetLatestDocumentAssessmentResultByDocumentIdQuery,
  useGetLatestInternalAuditReportDocumentAssessmentResultByDocumentIdQuery,
  useUpdateDocumentMutation,
  Version_Status_Enum,
} from '@/generated/graphql';
import { useIsFeatureVisibleToOrg } from '@/utils/featureFlags';

import type { AssessmentTypeEnum } from '../../../../assessments/useAssessmentTypeConfig';
import DocumentForm from '../../forms/DocumentForm';
import type { DocumentFormFieldData } from '../../forms/documentSchema';
import { defaultValues } from '../../forms/documentSchema';

const Tab: FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(['common']);
  const documentId = useGetGuidParam('documentId');
  const { data, error, refetch } = useGetDocumentByIdQuery({
    variables: {
      id: documentId,
    },
    fetchPolicy: 'no-cache',
  });
  if (error) {
    throw error;
  }
  const document = data?.document?.[0];
  const canEditDocument = useHasPermission('update:document', document);
  const latestDraftDocument = document?.latestDraftVersion[0];
  const latestPublishedDocument = document?.latestPublishedVersion[0];
  const documentVersionStatus =
    latestDraftDocument?.Status ||
    latestPublishedDocument?.Status ||
    Version_Status_Enum.Draft;

  const complianceMonitoringEnabled = useIsFeatureVisibleToOrg(
    'compliance_monitoring'
  );
  const internalAuditEnabled = useIsFeatureVisibleToOrg('internal_audit');
  const canViewCompliance = useHasPermission(
    'read:compliance_monitoring_assessment',
    document
  );
  const canViewInternalAudit = useHasPermission(
    'read:internal_audit_report',
    document
  );
  const skipInternalAudit = !internalAuditEnabled || !canViewInternalAudit;
  const skipComplianceMonitoring =
    !complianceMonitoringEnabled || !canViewCompliance;
  const values: DocumentFormFieldData = {
    ...defaultValues,
    ...document,
    linkedDocuments:
      document?.linkedDocuments.map((d) => ({ value: d.LinkedDocumentId })) ??
      [],
    Owners: getOwners(document),
    Contributors: getContributors(document),
    ancestorContributors: document?.ancestorContributors ?? [],
    requireAttestationFromEveryone: document?.attestationConfig
      ?.RequireGlobalAttestation
      ? 'true'
      : 'false',
    attestationPromptText: document?.attestationConfig?.PromptText,
    attestationTimeLimit: document?.attestationConfig?.AttestationTimeLimit,
    attestationGroups:
      document?.attestationConfig?.groups?.map((group) => ({
        type: 'userGroup',
        value: group.GroupId,
      })) ?? [],
    schedule: document?.schedule ?? defaultValues.schedule,
  };

  const { data: assessmentResult } =
    useGetLatestDocumentAssessmentResultByDocumentIdQuery({
      variables: {
        DocumentId: documentId,
      },
    });

  const { data: complianceMonitoringResults } =
    useGetLatestComplianceMonitoringAssessmentDocumentAssessmentResultByDocumentIdQuery(
      {
        variables: {
          DocumentId: documentId,
        },
        skip: skipComplianceMonitoring,
      }
    );

  const { data: internalAuditResults } =
    useGetLatestInternalAuditReportDocumentAssessmentResultByDocumentIdQuery({
      variables: {
        DocumentId: documentId,
      },
      skip: skipInternalAudit,
    });
  const [selectedAssessmentResultId, setSelectedAssessmentResultId] = useState<
    string | undefined
  >();
  const [selectedAssessmentMode, setSelectedAssessmentMode] =
    useState<AssessmentTypeEnum>('rating');
  const [showAssessmentResultModal, setShowAssessmentResultModal] =
    useState<boolean>(false);

  const [mutate] = useUpdateDocumentMutation();

  const onSave = async ({
    ancestorContributors: _1,
    linkedDocuments,
    attestationTimeLimit,
    attestationPromptText,
    attestationGroups,
    requireAttestationFromEveryone,
    Contributors,
    Owners,
    departments: _2,
    tags: _3,
    ...data
  }: DocumentFormFieldData) => {
    if (!document) {
      throw new Error('Missing document');
    }
    await mutate({
      variables: {
        object: {
          ...data,
          OriginalTimestamp: document.ModifiedAtTimestamp,
          Id: document.Id,
          LinkedDocumentIds: linkedDocuments.map(
            (linkedDocument) => linkedDocument.value
          ),
          ...ownerAndContributorIds({ Owners, Contributors }),
          CustomAttributeData: data.CustomAttributeData || undefined,
          RequireGlobalAttestation: requireAttestationFromEveryone === 'true',
          AttestationTimeLimit: attestationTimeLimit,
          AttestationPromptText: attestationPromptText,
          AttestationGroupIds: attestationGroups.map((group) => group.value),
        },
      },
    });
    await refetch();
  };

  const onDismiss = () => navigate(-1);

  return (
    <>
      <DocumentForm
        latestTestDate={document?.scheduleState?.LatestDate}
        values={values}
        onDismiss={onDismiss}
        onSave={onSave}
        readOnly={!canEditDocument}
        aside={
          <SpaceBetween size={'m'}>
            {latestDraftDocument && (
              <DocumentVersionPreview
                document={document}
                documentFileId={latestDraftDocument.Id}
              />
            )}
            {latestPublishedDocument && (
              <DocumentVersionPreview
                document={document}
                documentFileId={latestPublishedDocument.Id}
              />
            )}
            {assessmentResult?.document_assessment_result[0] && (
              <LatestRatingsPreview
                ratingsTitle={t('documentAssessments.documentRatingSubheading')}
                onClick={(id) => {
                  setSelectedAssessmentMode('rating');
                  setSelectedAssessmentResultId(id);
                  setShowAssessmentResultModal(true);
                }}
                assessmentResults={[
                  {
                    id: assessmentResult?.document_assessment_result[0].Id,
                    rating:
                      assessmentResult?.document_assessment_result[0].Rating,
                    completionDate:
                      assessmentResult?.document_assessment_result[0].TestDate,
                    title: t('documentAssessments.latestAssessmentResultTitle'),
                    ratingType: 'performance_result',
                  },
                ]}
              />
            )}

            {complianceMonitoringResults &&
              complianceMonitoringResults.document_assessment_result.length >
                0 && (
                <LatestRatingsPreview
                  ratingsTitle={t('ratings.complianceRatingSubheading')}
                  assessmentResults={complianceMonitoringResults.document_assessment_result.map(
                    (c) =>
                      ({
                        id: c.Id,
                        title:
                          c?.parents?.length > 0
                            ? (c?.parents[0].complianceMonitoringAssessment
                                ?.Title ?? '-')
                            : '-',
                        rating: c.Rating,
                        ratingType: 'performance_result',
                        completionDate: c.TestDate,
                      }) as ResultProps
                  )}
                  onClick={(id) => {
                    setSelectedAssessmentMode(
                      'compliance_monitoring_assessment'
                    );
                    setSelectedAssessmentResultId(id);
                    setShowAssessmentResultModal(true);
                  }}
                />
              )}
            {internalAuditResults &&
              internalAuditResults.document_assessment_result.length > 0 && (
                <LatestRatingsPreview
                  ratingsTitle={t('ratings.internalAuditRatingSubheading')}
                  assessmentResults={internalAuditResults.document_assessment_result.map(
                    (c) =>
                      ({
                        id: c.Id,
                        title:
                          c?.parents?.length > 0
                            ? (c?.parents[0].internalAuditReport?.Title ?? '-')
                            : '-',
                        rating: c.Rating,
                        ratingType: 'performance_result',
                        completionDate: c.TestDate,
                      }) as ResultProps
                  )}
                  onClick={(id) => {
                    setSelectedAssessmentMode('internal_audit_report');
                    setSelectedAssessmentResultId(id);
                    setShowAssessmentResultModal(true);
                  }}
                />
              )}
          </SpaceBetween>
        }
        documentId={documentId}
        docVersionStatus={documentVersionStatus}
      />

      {showAssessmentResultModal && (
        <AssessmentResultModal
          id={selectedAssessmentResultId}
          resultType={Parent_Type_Enum.DocumentAssessmentResult}
          onDismiss={() => setShowAssessmentResultModal(false)}
          i18n={t('assessmentResults')}
          assessmentMode={selectedAssessmentMode}
        />
      )}
    </>
  );
};

export default Tab;
