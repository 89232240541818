import SpaceBetween from '@cloudscape-design/components/space-between';
import Button from '@risksmart-app/components/Button';
import { useNotifications } from '@risksmart-app/components/Notifications/useNotifications';
import Table from '@risksmart-app/components/Table';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetCollectionTableProps } from 'src/pages/data-import/config';

import TabHeader from '@/components/TabHeader';
import { useGetDataImportsQuery } from '@/generated/graphql';

const DataImportTab: FC = () => {
  const { addNotification } = useNotifications();
  const { data, loading } = useGetDataImportsQuery({
    onError: (error) => {
      addNotification({
        type: 'error',
        content: <>{error.message}</>,
      });
    },
  });
  const { t } = useTranslation(['common'], { keyPrefix: 'dataImport' });
  const tableProps = useGetCollectionTableProps(data?.data_import ?? []);

  return (
    <>
      <Table
        {...tableProps}
        header={
          <SpaceBetween size={'m'}>
            <TabHeader
              actions={
                <SpaceBetween direction={'horizontal'} size={'xs'}>
                  <Button variant={'primary'} href={'add'}>
                    {t('create_new_button')}
                  </Button>
                </SpaceBetween>
              }
            >
              {t('tabTitle')}
            </TabHeader>
          </SpaceBetween>
        }
        variant={'embedded'}
        loading={loading}
        trackBy={'Id'}
      />
    </>
  );
};

export default DataImportTab;
