import type { TabsProps } from '@cloudscape-design/components/tabs';
import i18n from '@risksmart-app/i18n/i18n';
import { useTranslation } from 'react-i18next';
import ActionsTab from 'src/pages/actions/ActionsTab';
import LinkedItemsTab from 'src/pages/linked-items/LinkedItemsTab';
import { useHasPermission } from 'src/rbac/useHasPermission';

import type { GetIssueByIdQuery } from '@/generated/graphql';
import { Parent_Type_Enum } from '@/generated/graphql';
import { useIsFeatureVisibleToOrg } from '@/utils/featureFlags';
import type { ParentIssueTypes } from '@/utils/issueVariantUtils';
import { IssueTypeMapping } from '@/utils/issueVariantUtils';
import { filterEmptyTabs } from '@/utils/tabUtils';

import AssessmentsTab from './tabs/assessments/Tab';
import CausesTab from './tabs/causes/Tab';
import ConsequencesTab from './tabs/consequences/Tab';
import DetailsTab from './tabs/details/Tab';
import UpdatesTab from './tabs/updates/Tab';

export const useTabs = (
  detailsPath: string,
  issue: GetIssueByIdQuery['issue'][number] | undefined,
  type: ParentIssueTypes
) => {
  const issueMapping = IssueTypeMapping[type];
  const causesEnabled = !useIsFeatureVisibleToOrg('disable-causes');
  const consequenceEnabled = !useIsFeatureVisibleToOrg('disable-consequences');
  const canViewIssueUpdates = useHasPermission('read:issue_update', issue);
  const canViewActions = useHasPermission('read:action', issue);
  const canViewCauses = useHasPermission('read:cause', issue);
  const canViewConsequences = useHasPermission('read:consequence', issue);
  const canViewIssueAssessments = useHasPermission(
    'read:issue_assessment',
    issue
  );
  const linkedItemsEnabled = useIsFeatureVisibleToOrg('linked-items');
  const canViewLinkedItems = useHasPermission('read:linked_item', issue);
  const { t } = useTranslation('common');

  const tabs: TabsProps.Tab[] = [
    {
      label: i18n.format(t('details'), 'capitalizeAll'),
      id: 'details',
      content: <DetailsTab type={type} />,
      href: detailsPath,
    },
  ];

  if (canViewIssueUpdates) {
    tabs.push({
      label: i18n.format(t('update_other'), 'capitalizeAll'),
      id: 'updates',
      content: issue && <UpdatesTab parent={issue} />,
      href: `${detailsPath}/updates`,
    });
  }
  if (canViewActions) {
    tabs.push({
      label: i18n.format(t('action_other'), 'capitalizeAll'),
      id: 'actions',
      content: issue && <ActionsTab parent={issue} />,
      href: `${detailsPath}/actions`,
    });
  }
  if (canViewCauses && causesEnabled) {
    tabs.push({
      label: i18n.format(t('cause_other'), 'capitalizeAll'),
      id: 'causes',
      href: `${detailsPath}/causes`,
      content: issue && <CausesTab parent={issue} />,
    });
  }

  if (canViewConsequences && consequenceEnabled) {
    tabs.push({
      label: i18n.format(t('consequence_other'), 'capitalizeAll'),
      id: 'consequences',
      content: issue && <ConsequencesTab parent={issue} />,
      href: `${detailsPath}/consequences`,
    });
  }

  if (canViewIssueAssessments) {
    tabs.push({
      label: i18n.format(
        t(`${issueMapping.taxonomy}.assessment`),
        'capitalizeAll'
      ),
      id: 'assessment',
      content: issue && <AssessmentsTab issue={issue} type={type} />,
      href: `${detailsPath}/assessment`,
    });
  }

  if (linkedItemsEnabled && canViewLinkedItems) {
    tabs.push({
      label: t('linkedItems.tab_title'),
      id: 'linkedItems',
      content: issue && (
        <LinkedItemsTab
          parent={issue}
          parentType={Parent_Type_Enum.Issue}
          includeAssessments={true}
        />
      ),
      href: `${detailsPath}/linked-items`,
    });
  }

  return filterEmptyTabs(tabs);
};
