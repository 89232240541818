import SpaceBetween from '@cloudscape-design/components/space-between';
import Button from '@risksmart-app/components/Button';
import { useNotifications } from '@risksmart-app/components/Notifications/useNotifications';
import Table from '@risksmart-app/components/Table';
import type { FC } from 'react';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { PageLayout } from 'src/layouts';
import { useHasPermission } from 'src/rbac/useHasPermission';

import DeleteModal from '@/components/DeleteModal';
import {
  GetAllAssessmentResultsDocument,
  GetAssessmentResultsByParentIdDocument,
  GetDocumentAssessmentResultsByParentIdDocument,
  GetObligationAssessmentResultsByObligationIdDocument,
  GetRiskAssessmentResultsByControlTypeDocument,
  GetRiskAssessmentResultsByRiskIdDocument,
  useDeleteAssessmentResultsMutation,
  useGetAllAssessmentResultsQuery,
} from '@/generated/graphql';
import { useDeleteResultNotification } from '@/hooks/useMutationResultNotification';
import { handleError } from '@/utils/errorUtils';
import { evictField } from '@/utils/graphqlUtils';

import { useGetCollectionTableProps } from './config';
import type { AssessmentResultRegisterFields } from './types';

const AssessmentResultsPage: FC = () => {
  const { t } = useTranslation('common');
  const { t: st } = useTranslation('common', {
    keyPrefix: 'assessmentResults',
  });

  const { addNotification } = useNotifications();
  const { data, loading } = useGetAllAssessmentResultsQuery({
    onError: (error) => {
      handleError(error);
      addNotification({
        type: 'error',
        content: <>{error.message}</>,
      });
    },
  });
  const [selectedAssessmentResults, setSelectedAssessmentResults] = useState<
    AssessmentResultRegisterFields[]
  >([]);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

  const canDeleteDocumentAssessmentResult = useHasPermission(
    'delete:document_assessment_result'
  );
  const canDeleteObligationAssessmentResult = useHasPermission(
    'delete:obligation_assessment_result'
  );
  const canDeleteRiskAssessmentResult = useHasPermission(
    'delete:risk_assessment_result'
  );
  const canDeleteAssessmentResult =
    canDeleteDocumentAssessmentResult ||
    canDeleteObligationAssessmentResult ||
    canDeleteRiskAssessmentResult;

  const onDelete = useDeleteResultNotification({
    entityName: st('entity_name'),
    asyncAction: async () => {
      await deleteAssessmentResults({
        variables: {
          Ids: selectedAssessmentResults?.map((s) => s.originalResult.Id),
        },
      });
      setSelectedAssessmentResults([]);
      setIsDeleteModalVisible(false);

      return true;
    },
    failureAction: () => {
      setIsDeleteModalVisible(false);
    },
  });

  const [deleteAssessmentResults, deleteResult] =
    useDeleteAssessmentResultsMutation({
      update: (cache) => {
        evictField(cache, 'obligation_assessment_result');
        evictField(cache, 'document_assessment_result');
        evictField(cache, 'risk_assessment_result');
        evictField(cache, 'assessment');
        evictField(cache, 'obligation_assessment_result_aggregate');
        evictField(cache, 'document_assessment_result_aggregate');
        evictField(cache, 'risk_assessment_result_aggregate');
      },
      refetchQueries: [
        GetAssessmentResultsByParentIdDocument,
        GetDocumentAssessmentResultsByParentIdDocument,
        GetAllAssessmentResultsDocument,
        GetObligationAssessmentResultsByObligationIdDocument,
        GetRiskAssessmentResultsByControlTypeDocument,
        GetRiskAssessmentResultsByRiskIdDocument,
      ],
    });

  const navigate = useNavigate();

  const handleOpenRating = (result: AssessmentResultRegisterFields) => {
    navigate(result.Id);
  };

  const tableProps = useGetCollectionTableProps(
    [
      ...(data?.document_assessment_result || []),
      ...(data?.obligation_assessment_result || []),
      ...(data?.risk_assessment_result || []),
    ],
    handleOpenRating
  );

  const assessmentResultCount = useMemo(() => {
    if (loading) {
      return '';
    }

    return `(${tableProps.allItems?.length})`;
  }, [loading, tableProps]);

  return (
    <PageLayout
      helpTranslationKey={'assessmentResults.registerHelp'}
      title={st('register_title')}
      counter={assessmentResultCount}
      actions={
        <SpaceBetween direction={'horizontal'} size={'xxs'}>
          {canDeleteAssessmentResult && (
            <Button
              formAction={'none'}
              variant={'normal'}
              disabled={!selectedAssessmentResults.length}
              onClick={() => setIsDeleteModalVisible(true)}
            >
              {st('delete_button')}
            </Button>
          )}
          <Button iconName={'download'} onClick={tableProps.exportToCsv}>
            {t('export')}
          </Button>
        </SpaceBetween>
      }
    >
      <Table
        {...tableProps}
        loading={loading}
        selectionType={canDeleteAssessmentResult ? 'multi' : undefined}
        selectedItems={selectedAssessmentResults}
        trackBy={'Id'}
        onSelectionChange={({ detail }) => {
          setSelectedAssessmentResults(detail.selectedItems);
        }}
      />

      <DeleteModal
        isVisible={isDeleteModalVisible}
        loading={deleteResult.loading}
        header={st('delete_button')}
        onDelete={onDelete}
        onDismiss={() => setIsDeleteModalVisible(false)}
      >
        {st('confirm_delete_message')}
      </DeleteModal>
    </PageLayout>
  );
};

export default AssessmentResultsPage;
