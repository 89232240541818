import type { SelectProps } from '@cloudscape-design/components/select';
import type { FC } from 'react';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useComputedEffectiveness } from 'src/ratings/useComputedEffectiveness';

import ControlledControlMultiSelect from '@/components/Form/ControlledControlMultiSelect';
import ControlledDatePicker from '@/components/Form/ControlledDatePicker';
import { ControlledFileUpload } from '@/components/Form/ControlledFileUpload/ControlledFileUpload';
import ControlledGroupAndUserSelect from '@/components/Form/ControlledGroupAndUserSelect';
import ControlledInput from '@/components/Form/ControlledInput';
import ControlledRating from '@/components/Form/ControlledRating';
import ControlledSelect from '@/components/Form/ControlledSelect';
import ControlledTextarea from '@/components/Form/ControlledTextarea';
import CustomisableForm from '@/components/Form/Form/CustomisableForm/CustomisableForm';
import { useCommonLookupLazy } from '@/hooks/useCommonLookupLazy';
import { useIsFeatureVisibleToOrg } from '@/utils/featureFlags';

import { TestIds } from './TestResultFormFieldsTestIds';
import type { TestResultFormFieldsData } from './testResultSchema';

const isEmptyOrUndefined = (val: unknown) =>
  val === undefined || val === '' || val === null;

type Props = {
  readOnly?: boolean;
  disableControlSelect?: boolean;
};

const TestResultFormFields: FC<Props> = ({
  readOnly,
  disableControlSelect,
}) => {
  const {
    control,
    watch,
    setValue,
    formState: {
      dirtyFields: {
        PerformanceEffectiveness: isPerformanceDirty,
        DesignEffectiveness: isDesignDirty,
      },
    },
  } = useFormContext<TestResultFormFieldsData>();
  const internalAuditEnabled = useIsFeatureVisibleToOrg('internal_audit');
  const complianceMonitoringEnabled = useIsFeatureVisibleToOrg(
    'compliance_monitoring'
  );
  const { t: st } = useTranslation(['common'], {
    keyPrefix: 'testResults.fields',
  });
  const { t } = useTranslation(['common']);

  const { getOptions } = useCommonLookupLazy();

  const testTypes: SelectProps.Option[] = getOptions('testTypes').sort(
    function (a, b) {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }

      return 0;
    }
  );

  const designEffectiveness = watch('DesignEffectiveness');
  const performanceEffectiveness = watch('PerformanceEffectiveness');

  const overallEffectiveness = useComputedEffectiveness({
    design: Number(designEffectiveness),
    performance: Number(performanceEffectiveness),
  });

  useEffect(() => {
    if (
      isEmptyOrUndefined(designEffectiveness) ||
      isEmptyOrUndefined(performanceEffectiveness)
    ) {
      /// only update the overall effectiveness if design and performance are set by the USER
      return;
    }

    setValue('OverallEffectiveness', overallEffectiveness, {
      shouldValidate: true,
    });
  }, [
    designEffectiveness,
    isDesignDirty,
    isPerformanceDirty,
    overallEffectiveness,
    performanceEffectiveness,
    setValue,
  ]);

  return (
    <CustomisableForm readOnly={readOnly}>
      <ControlledControlMultiSelect
        defaultRequired={true}
        key={'ControlIds'}
        control={control}
        label={t('control')}
        disabled={readOnly || disableControlSelect}
        name={'ParentControlIds'}
        placeholder={t('control')}
        description={st('controlHelp')}
      />

      {!internalAuditEnabled && !complianceMonitoringEnabled && (
        <ControlledSelect
          key={'testType'}
          forceRequired={false}
          addEmptyOption={true}
          filteringType={'none'}
          label={t('testType')}
          name={'TestType'}
          description={st('testTypeHelp')}
          // TODO: translation
          placeholder={'Select'}
          control={control}
          options={testTypes}
          disabled={readOnly}
          testId={TestIds.TestType}
        />
      )}

      <ControlledInput
        allowDefaultValue={true}
        key={'title'}
        defaultRequired={true}
        name={'Title'}
        label={t('titleField')}
        description={st('titleFieldHelp')}
        // TODO: translation
        placeholder={'Enter a title'}
        control={control}
        disabled={readOnly}
      />

      <ControlledRating
        allowDefaultValue={true}
        key={'designEffectiveness'}
        filteringType={'none'}
        label={t('designEffectiveness')}
        description={st('designEffectivenessHelp')}
        name={'DesignEffectiveness'}
        // TODO: translation
        placeholder={'Select'}
        control={control}
        type={'design_effectiveness'}
        addEmptyOption={true}
        disabled={readOnly}
      />

      <ControlledRating
        allowDefaultValue={true}
        key={'performanceEffectiveness'}
        filteringType={'none'}
        label={t('performanceEffectiveness')}
        description={st('performanceEffectivenessHelp')}
        name={'PerformanceEffectiveness'}
        // TODO: translation
        placeholder={'Select'}
        control={control}
        type={'performance_effectiveness'}
        addEmptyOption={true}
        disabled={readOnly}
      />

      <ControlledRating
        allowDefaultValue={true}
        key={'overallEffectiveness'}
        filteringType={'none'}
        defaultRequired={true}
        label={t('controlTestResult')}
        description={st('controlTestResultHelp')}
        name={'OverallEffectiveness'}
        placeholder={'Select'}
        control={control}
        type={'effectiveness'}
        addEmptyOption={true}
        disabled={readOnly}
        testId={TestIds.OverallEffectiveness}
      />

      <ControlledTextarea
        key={'description'}
        name={'Description'}
        defaultRequired={true}
        label={t('controlTestDetails')}
        description={st('controlTestDetailsHelp')}
        placeholder={t('enterDetailsAboutTest') ?? ''}
        control={control}
        disabled={readOnly}
      />

      <ControlledGroupAndUserSelect
        key={'submitter'}
        label={t('performedBy')}
        description={st('performedByHelp')}
        forceRequired={true}
        name={'Submitter'}
        placeholder={t('searchForAPerson') ?? ''}
        control={control}
        includeGroups={false}
        disabled={readOnly}
        testId={TestIds.PerformedBy}
      />

      <ControlledDatePicker
        key={'testDate'}
        forceRequired={true}
        control={control}
        name={'TestDate'}
        label={t('testDate')}
        description={st('testDateHelp')}
        disabled={readOnly}
      />

      <ControlledFileUpload
        key={'newFiles'}
        label={t('fields.newFiles')}
        description={t('fields.newFiles_help')}
        control={control}
        name={'newFiles'}
        saveFilesName={'files'}
        disabled={readOnly}
      />
    </CustomisableForm>
  );
};

export default TestResultFormFields;
