import type { Rating } from '@risksmart-app/i18n/ratings';
import { getRatingByRange } from '@risksmart-app/i18n/ratings';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { hasColor } from '../utils/colours';
import type { RatingKey, RatingOption } from './types';

type Value = null | number | string | undefined;

const normalizeLegacyValues = (value: Value) => {
  return value === null || value === '-' ? undefined : value;
};

export type UseRatingResponse = {
  options: RatingOption[];
  getOptionsByRatingKey: (ratingKey: RatingKey) => RatingOption[];
  getByValue: (value: Value) => RatingOption | undefined;
  getByValueAndRatingKey: (
    ratingKey: RatingKey,
    value: number | string
  ) => RatingOption | undefined;
  getByLabel: (label: string) => RatingOption | undefined;
  getLabel: (value: Value) => string;
  getColorClass: (value: Value) => null | string;
  getByRange: (range: null | number | undefined) => RatingOption | undefined;
};

type Option = {
  label: string;
  value: number | string;
  range?: [number, number];
};

export const useRating = (ratingKey?: RatingKey): UseRatingResponse => {
  const { t } = useTranslation('ratings');

  const options: Option[] = useMemo(
    () =>
      ratingKey
        ? (t(ratingKey, {
            returnObjects: true,
          }) as Option[])
        : [],
    [t, ratingKey]
  );

  const getOptionsByRatingKey = useCallback(
    (key: RatingKey): RatingOption[] =>
      key
        ? t(key, {
            returnObjects: true,
          })
        : [],
    [t]
  );

  const supportsPending = options?.some((option) => option.value === 'pending');

  const getByValue = useCallback(
    (value: Value) => {
      const foundValue = options?.find(
        (option) => option.value === normalizeLegacyValues(value)
      );

      if (foundValue) {
        return foundValue;
      }

      return supportsPending
        ? options?.find((option) => option.value === 'pending')
        : undefined;
    },
    [options, supportsPending]
  );

  const getByValueAndRatingKey = useCallback(
    (
      ratingKey: RatingKey,
      value: number | string
    ): RatingOption | undefined => {
      const options = getOptionsByRatingKey(ratingKey);

      return options.find((r) => r.value === value);
    },
    [getOptionsByRatingKey]
  );

  const getByRange = useCallback(
    (value: null | number | undefined) =>
      getRatingByRange(options as Rating[], value || null),
    [options]
  );

  const getByLabel = useCallback(
    (label: string) => {
      return options?.find((option) => option.label === label);
    },
    [options]
  );

  const getLabel = useCallback(
    (value: Value) => getByValue(value)?.label || '',
    [getByValue]
  );

  const getColorClass = useCallback(
    (value: Value): null | string => {
      const item = getByValue(value);

      return hasColor(item) ? item.color : null;
    },
    [getByValue]
  );

  return useMemo(
    () => ({
      options,
      getByValue,
      getOptionsByRatingKey,
      getByValueAndRatingKey,
      getByLabel,
      getLabel,
      getColorClass,
      getByRange,
    }),
    [
      options,
      getByValue,
      getOptionsByRatingKey,
      getByValueAndRatingKey,
      getByLabel,
      getLabel,
      getColorClass,
      getByRange,
    ]
  );
};
