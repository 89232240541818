import 'pdfmake/build/vfs_fonts';

import { useTranslation } from 'react-i18next';
import useActionExportTable from 'src/pages/actions/update/useActionExportTable';

import { useGetIssueByIdLazyQuery } from '@/generated/graphql';
import { toLocalDate } from '@/utils/dateUtils';
import { getFriendlyId } from '@/utils/friendlyId';
import type { ParentIssueTypes } from '@/utils/issueVariantUtils';
import { IssueTypeMapping } from '@/utils/issueVariantUtils';
import { getContributorValue } from '@/utils/pdf/contributorValue';
import { getDepartmentsValue } from '@/utils/pdf/departmentValue';
import { createDocument } from '@/utils/pdf/document';
import { download } from '@/utils/pdf/downloader';
import { createField, createYesNoField } from '@/utils/pdf/field';
import { createHeading, createSubHeading } from '@/utils/pdf/headings';
import { getOwnerValue } from '@/utils/pdf/ownerValue';
import { optionalTableSection } from '@/utils/pdf/tableSection';
import { getTagsValue } from '@/utils/pdf/tagsValue';
import { twoColumns } from '@/utils/pdf/twoColumns';
import useCustomAttributeDataForExport from '@/utils/pdf/useCustomAttributeDataForExport';

import useCausesExportTable from './useCausesExportTable';
import useConsequenceExportTable from './useConsequenceExportTable';
import useExportAssessmentDetails from './useExportAssessmentDetails';
import useUpdatesExportTable from './useUpdatesExportTable';

const useExporter = (
  issueId: string,
  issueType: ParentIssueTypes
): [() => void, { loading: boolean }] => {
  const issueTypeMap = IssueTypeMapping[issueType];
  const [getCustomAttribute, customAttributesLoading] =
    useCustomAttributeDataForExport(issueType);
  const [createActionTable, actionsLoading] = useActionExportTable(issueId);
  const [createUpdatesTable, updatesLoading] = useUpdatesExportTable(issueId);
  const [createCausesTable, causesLoading] = useCausesExportTable(issueId);
  const [createConsequenceTable, consequenceLoading] =
    useConsequenceExportTable(issueId);
  const [createAssessmentDetails, assessmentDetailsLoading] =
    useExportAssessmentDetails(issueId, issueType);

  const { t } = useTranslation(['common']);
  const { t: st } = useTranslation(['common'], {
    keyPrefix: issueTypeMap.taxonomy,
  });
  const { t: issueFields } = useTranslation(['common'], {
    keyPrefix: `${issueTypeMap.taxonomy}.fields`,
  });

  const { t: fields } = useTranslation(['common'], { keyPrefix: 'fields' });

  const [geIssue, getIssueResult] = useGetIssueByIdLazyQuery({
    variables: {
      _eq: issueId,
    },
  });

  const loading =
    getIssueResult.loading ||
    actionsLoading ||
    customAttributesLoading ||
    updatesLoading ||
    causesLoading ||
    consequenceLoading ||
    assessmentDetailsLoading;
  const exportFunc = async () => {
    const getIssuePromise = geIssue();

    const { data: issueData } = await getIssuePromise;
    const createActionTablePromise = await createActionTable();
    const createUpdatesTablePromise = await createUpdatesTable();
    const createCausesTablePromise = await createCausesTable();
    const createConsequenceTablePromise = await createConsequenceTable();
    const createAssessmentDetailsPromise = await createAssessmentDetails();
    const issue = issueData?.issue?.[0];

    const actionTable = await createActionTablePromise;
    const updatesTable = await createUpdatesTablePromise;
    const causesTable = await createCausesTablePromise;
    const consequenceTable = await createConsequenceTablePromise;
    const assessmentDetails = await createAssessmentDetailsPromise;

    if (!issue) {
      return;
    }

    const title = `${issue.Title} (${getFriendlyId(
      issue.Type,
      issue.SequentialId
    )})`;

    const detailFields = [
      createField(fields('Title'), issue.Title),
      createField(fields('Details'), issue.Details),
      createYesNoField(issueFields('ImpactsCustomer'), issue.ImpactsCustomer),
      createField(
        issueFields('IsExternalIssue'),
        issue.IsExternalIssue ? st('external') : st('internal')
      ),
      createField(issueFields('DateOccurred'), toLocalDate(issue.DateOccurred)),
      createField(
        issueFields('DateIdentified'),
        toLocalDate(issue.DateIdentified)
      ),
      createField(fields('Owner'), getOwnerValue(issue)),
      createField(fields('Contributor'), getContributorValue(issue)),
      createField(fields('Tags'), getTagsValue(issue)),
      createField(fields('Departments'), getDepartmentsValue(issue)),
      ...(await getCustomAttribute(issue)),
    ];

    const docDefinition = createDocument(title, [
      createHeading(title),
      createSubHeading(t('details')),
      twoColumns(detailFields),
      optionalTableSection(t('actionUpdates.tab_title'), updatesTable),
      optionalTableSection(t('actions.tab_title'), actionTable),
      optionalTableSection(t('causes.tab_title'), causesTable),
      optionalTableSection(t('consequences.tab_title'), consequenceTable),
      createSubHeading(t(`${issueTypeMap.assessmentTaxonomy}.tab_title`)),
      assessmentDetails,
    ]);

    download(docDefinition);
  };

  return [exportFunc, { loading }];
};

export default useExporter;
