import FormField from '@cloudscape-design/components/form-field';
import { type FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import CustomDatasourcePropertyFilter from './CustomDatasourcePropertyFilter';
import type { TreeDataSource } from './definitionSchema';
import { type AllowedField, getFlattenedDataSources } from './utils';

const ConnectedCustomDatasourcePropertyFilter: FC<{
  allFields: AllowedField[];
  rootDataSource: TreeDataSource;
}> = ({ allFields, rootDataSource }) => {
  const { t } = useTranslation(['common'], {
    keyPrefix: 'customDatasources.fields',
  });
  const { watch, setValue } = useFormContext();
  const filters = watch('filters');

  return (
    <FormField label={t('filters')} data-testid={'form-field-filters'}>
      <CustomDatasourcePropertyFilter
        allFields={allFields}
        datasources={getFlattenedDataSources(rootDataSource)}
        query={filters}
        onChange={(filters) => {
          setValue('filters', filters);
        }}
      />
    </FormField>
  );
};

export default ConnectedCustomDatasourcePropertyFilter;
