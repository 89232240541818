import Alert from '@cloudscape-design/components/alert';
import type { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import ControlledSelect from '@/components/Form/ControlledSelect';
import { ControlledSwitch } from '@/components/Form/ControlledSwitch/ControlledSwitch';
import ControlledTextarea from '@/components/Form/ControlledTextarea';
import ConditionalField from '@/components/Form/Form/CustomisableForm/ConditionalField';
import { Third_Party_Response_Enum_Action } from '@/generated/graphql';

import type { UpdateStatusSchemaFields } from './schema';
import { translationKeyMap } from './schema';
import { RequestTypeOptions } from './schema';

type Props = {
  action: Third_Party_Response_Enum_Action;
};

export const UpdateStatusModalForm: FC<Props> = ({ action }) => {
  const translationKey = translationKeyMap[action];
  const { control, watch } = useFormContext<UpdateStatusSchemaFields>();
  const { t: rt } = useTranslation('common', {
    keyPrefix: 'third_party_responses.updateStatus',
  });

  const shareWithRespondents = watch('ShareWithRespondents');
  const shareWithRespondentsLabel = shareWithRespondents
    ? rt(`${translationKey}.shareWithRespondentsOnInfo`)
    : rt(`${translationKey}.shareWithRespondentsOffInfo`);

  const requestTypeOptions = [
    {
      label: '-',
      value: '',
    },
    ...Object.entries(RequestTypeOptions).map(([value, label]) => ({
      label,
      value,
    })),
  ];

  return (
    <>
      <ConditionalField
        key={'RequestType'}
        condition={
          action === Third_Party_Response_Enum_Action.RequestMoreInformation
        }
      >
        <ControlledSelect
          name={'RequestType'}
          label={'Request type'}
          control={control}
          defaultRequired={true}
          options={requestTypeOptions}
        />
      </ConditionalField>
      <ConditionalField
        key={'Reason'}
        condition={action !== Third_Party_Response_Enum_Action.Approve}
      >
        <ControlledTextarea
          name={'Reason'}
          label={'Reason'}
          control={control}
          defaultRequired={false}
          forceRequired={false}
        />
      </ConditionalField>
      {shareWithRespondentsLabel ? null : <div className={'mt-4'} />}
      <ControlledSwitch
        control={control}
        label={'Share with respondents'}
        name={'ShareWithRespondents'}
        defaultRequired={true}
      />
      {shareWithRespondentsLabel ? (
        <Alert type={'info'}>{shareWithRespondentsLabel}</Alert>
      ) : null}
    </>
  );
};
