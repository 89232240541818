import type {
  JsonFormsCore,
  JsonFormsRendererRegistryEntry,
  JsonSchema,
  Layout,
  OwnPropsOfControl,
  UISchemaElement,
} from '@jsonforms/core';
import {
  mapStateToControlProps,
  rankWith,
  scopeEndsWith,
  uiTypeIs,
} from '@jsonforms/core';

import { FieldType } from '../../EditFields/NewFieldSchema';

export interface FieldRendererProps {
  type: FieldType;
  label: string;
  path: string;
  options?: string[];
}

interface RendererProps {
  uischema: UISchemaElement;
  schema: JsonSchema;
  rootSchema: JsonSchema;
  renderers: JsonFormsRendererRegistryEntry[];
}
export const jsonFormsDispatchRenderer = ({
  renderers,
  rootSchema,
  schema,
  uischema,
}: RendererProps): FieldRendererProps[] => {
  const [renderer] = [...(renderers || [])].sort((a, b) => {
    const bTest = b.tester(uischema, schema, { rootSchema, config: {} });
    const aTest = a.tester(uischema, schema, { rootSchema, config: {} });

    return bTest - aTest;
  });
  if (renderer.tester(uischema, schema, { rootSchema, config: {} }) === -1) {
    // unknown renderer for schema
    console.error(
      'Unknown custom attribute collection renderer',
      uischema,
      schema
    );

    return [];
  }

  return renderer.renderer({
    uischema,
    schema,
    rootSchema,
    renderers,
  });
};

interface VerticalLayoutRendererProps extends RendererProps {
  uischema: Layout;
}

const collectionVerticalLayoutRenderer = ({
  uischema,
  schema,
  rootSchema,
  renderers,
}: VerticalLayoutRendererProps) => {
  if (uischema.elements.length === 0) {
    return [];
  }

  return uischema.elements.map((child) =>
    jsonFormsDispatchRenderer({
      uischema: child,
      schema,
      rootSchema,
      renderers,
    })
  );
};

const textInputCollectionRenderer =
  (type: FieldType) =>
  (data: OwnPropsOfControl): FieldRendererProps => {
    const contextState: JsonFormsCore = {
      data: {},
      schema: data.schema || {},
      uischema: data.uischema || ({} as UISchemaElement),
    };
    const ctx = { core: contextState, renderers: [] };
    const { label, path, schema } = mapStateToControlProps(
      { jsonforms: ctx },
      data
    );

    return { label, path, type, options: (schema.enum as string[]) ?? [] };
  };

const rendererRegistry: JsonFormsRendererRegistryEntry[] = [
  {
    tester: rankWith(3, uiTypeIs('VerticalLayout')),
    renderer: collectionVerticalLayoutRenderer,
  },
  {
    tester: rankWith(3, scopeEndsWith(FieldType.Text)),
    renderer: textInputCollectionRenderer(FieldType.Text),
  },
  {
    tester: rankWith(3, scopeEndsWith(FieldType.Date)),
    renderer: textInputCollectionRenderer(FieldType.Date),
  },
  {
    tester: rankWith(3, scopeEndsWith(FieldType.Textarea)),
    renderer: textInputCollectionRenderer(FieldType.Textarea),
  },
  {
    tester: rankWith(3, scopeEndsWith(FieldType.MultiSelect)),
    renderer: textInputCollectionRenderer(FieldType.MultiSelect),
  },
  {
    tester: rankWith(3, scopeEndsWith(FieldType.Select)),
    renderer: textInputCollectionRenderer(FieldType.Select),
  },
  {
    tester: rankWith(3, scopeEndsWith(FieldType.Link)),
    renderer: textInputCollectionRenderer(FieldType.Link),
  },
];

export default rendererRegistry;
