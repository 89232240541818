import SpaceBetween from '@cloudscape-design/components/space-between';
import Button from '@risksmart-app/components/Button';
import { useNotifications } from '@risksmart-app/components/Notifications/useNotifications';
import Table from '@risksmart-app/components/Table';
import type { FC } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PageLayout } from 'src/layouts';
import { Permission } from 'src/rbac/Permission';

import { useGetControlGroupsFlatQuery } from '@/generated/graphql';
import { getCounter } from '@/utils/collectionUtils';

import { useGetCollectionTableProps } from './config';
import ControlGroupModal from './ControlGroupModal';

const Page: FC = () => {
  const { t } = useTranslation(['common']);
  const { t: st } = useTranslation(['common'], {
    keyPrefix: 'controlGroups',
  });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { addNotification } = useNotifications();
  const { data, loading } = useGetControlGroupsFlatQuery({
    fetchPolicy: 'no-cache',
    onError: (error) => {
      addNotification({
        type: 'error',
        content: <>{error.message}</>,
      });
    },
  });

  const tableProps = useGetCollectionTableProps(
    data?.control_group,
    data?.form_configuration?.[0]?.customAttributeSchema
  );

  const title = st('register_title', 'Register');
  const counter = getCounter(tableProps.totalItemsCount, loading);

  return (
    <PageLayout
      helpTranslationKey={'controlGroups.registerHelp'}
      title={title}
      counter={counter}
      actions={
        <SpaceBetween direction={'horizontal'} size={'xs'}>
          <Button iconName={'download'} onClick={tableProps.exportToCsv}>
            {t('export')}
          </Button>
          <Permission permission={'insert:control_group'}>
            <Button variant={'primary'} onClick={() => setIsModalVisible(true)}>
              {st('create_new_button', 'Add')}
            </Button>
          </Permission>
        </SpaceBetween>
      }
    >
      <Table {...tableProps} loading={loading} />
      {isModalVisible && (
        <ControlGroupModal onDismiss={() => setIsModalVisible(false)} />
      )}
    </PageLayout>
  );
};

export default Page;
