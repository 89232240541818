import ButtonDropdown from '@cloudscape-design/components/button-dropdown';
import type { FC } from 'react';
import { useContext } from 'react';
import { useHasPermission } from 'src/rbac/useHasPermission';

import type { Parent_Type_Enum } from '@/generated/graphql';

import { ControlledFieldContext } from '../FieldController/ControlledFieldContext';
import { useEditableFormContext } from './CustomisableForm/EditableFormContext';
import { useRiskSmartForm } from './CustomisableForm/RiskSmartFormContext';

interface FormMenuProps {
  parentType: Parent_Type_Enum;
}
export const FormMenu: FC<FormMenuProps> = ({ parentType }) => {
  const hasAccess = useHasPermission('update:custom_attribute_schema');
  const controlledField = useContext(ControlledFieldContext);
  const { toggleEditMode } = useRiskSmartForm();
  const { showEditModal } = useEditableFormContext();
  const onAddAction = () => {
    showEditModal({
      isCustomField: true,

      parentType: parentType,
      fieldId: controlledField?.field.name,
      defaultRequired: controlledField?.defaultRequired,
      defaultValueOptions: controlledField?.defaultValueOptions ?? [],
      allowDefaultValue: controlledField?.allowDefaultValue,
      forceRequired: controlledField?.forceRequired,
    });
  };
  const onEditAction = () => {
    toggleEditMode();
  };

  if (!hasAccess) {
    return null;
  }

  return (
    <>
      <ButtonDropdown
        data-testid={'form-settings-button'}
        expandToViewport
        ariaLabel={'Form Settings'}
        items={[
          {
            // TODO: translation
            text: 'Add custom field',
            id: 'add',
            disabled: false,
          },
          {
            // TODO: translation
            text: 'Edit form',
            id: 'edit',
            disabled: false,
          },
        ]}
        variant={'icon'}
        onItemClick={(e) => {
          if (e.detail.id === 'add') {
            onAddAction();
          }
          if (e.detail.id === 'edit') {
            onEditAction();
          }
        }}
      >
        {'...'}
      </ButtonDropdown>
    </>
  );
};
