import i18n from '@risksmart-app/i18n/i18n';
import { t } from 'i18next';

import { ParentTypeEnum } from '../../../generated/graphql';
import { DataSourceType } from '../../reporting/schema';
import {
  getAuditColumns,
  getContributorsColumns,
  getOwnersColumns,
  getTagAndDepartmentsColumns,
} from './shared';
import { SharedDataset } from './types';

export const getRisks = () =>
  ({
    label: i18n.format(t('risk_other'), 'capitalize'),
    parentTypes: new Set<DataSourceType>(['risks']),
    customAttributeFormConfigurationParentTypes: [ParentTypeEnum.Risk],
    fields: {
      ...getAuditColumns(),
      ...getTagAndDepartmentsColumns(),
      ...getOwnersColumns(),
      ...getContributorsColumns(),
      ratingFrequency: {
        dataType: 'text',
        label: t('risks.columns.test_frequency'),
        displayType: 'commonLookup',
        i18nKey: 'frequency',
      },
      latestRatingDate: {
        dataType: 'date',
        label: t('risks.columns.latest_rating_date'),
        displayType: 'date',
      },
      nextRatingDueDate: {
        dataType: 'date',
        label: t('risks.columns.next_test_date'),
        displayType: 'date',
      },
      nextRatingOverdueDate: {
        dataType: 'date',
        label: t('risks.columns.nextTestOverdue'),
        displayType: 'date',
      },
      title: {
        dataType: 'text',
        displayType: 'text',
        label: t('risks.columns.risk_name'),
      },
      id: { dataType: 'guid', displayType: 'text', label: t('columns.guid') },
      sequentialId: {
        dataType: 'number',
        displayType: 'number',
        label: t('columns.id'),
        prefix: 'R-',
      },
      tier: {
        dataType: 'number',
        label: t('risks.columns.risk_tier'),
        displayType: 'commonLookup',
        i18nKey: 'tiers',
      },
      treatment: {
        dataType: 'text',
        label: t('risks.columns.risk_treatment'),
        displayType: 'commonLookup',
        i18nKey: 'treatments',
      },
      status: {
        dataType: 'text',
        label: t('risks.columns.risk_status'),
        displayType: 'commonLookup',
        i18nKey: 'statuses',
      },
      inherentRating: {
        dataType: 'number',
        label: t('risks.columns.uncontrolled_rating'),
        displayType: 'rating',
        ratingKey: 'risk_uncontrolled',
      },
      residualRating: {
        dataType: 'number',
        label: t('risks.columns.controlled_rating'),
        displayType: 'rating',
        ratingKey: 'risk_controlled',
      },
      inherentScore: {
        dataType: 'number',
        label: t('risks.columns.uncontrolled_score'),
        displayType: 'number',
      },
      residualScore: {
        dataType: 'number',
        label: t('risks.columns.controlled_score'),
        displayType: 'number',
      },
      detailsLink: {
        dataType: 'guid',
        label: t('risks.columns.details_link'),
        displayType: 'detailsLink',
        entityInfoParentType: ParentTypeEnum.Risk,
      },
      details: {
        dataType: 'text',
        displayType: 'text',
        label: t('risks.columns.risk_description'),
      },
    },
  }) satisfies SharedDataset;
