import Button from '@risksmart-app/components/Button';
import { useGetUserIdParam } from '@risksmart-app/components/routes/routes.utils';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { PageLayout } from 'src/layouts';

import AttestationCards from '@/components/AttestationsCards';
import { useGetPolicyAttestationRecordsQuery } from '@/generated/graphql';
import { getCounter } from '@/utils/collectionUtils';

import { useGetCollectionCardProps } from './config';

const Page: FC = () => {
  const { t: st } = useTranslation(['common'], {
    keyPrefix: 'attestations',
  });
  const { t } = useTranslation(['common']);

  const userId = useGetUserIdParam('userId');

  const { data, loading } = useGetPolicyAttestationRecordsQuery({
    variables: {
      where: { UserId: { _eq: userId } },
    },
    fetchPolicy: 'no-cache',
  });

  const title =
    data?.attestation_record[0]?.user?.FriendlyName ?? st('register_title');

  const collectionProps = useGetCollectionCardProps(data?.attestation_record);

  return (
    <PageLayout
      title={title}
      helpTranslationKey={'policy.registerHelp'}
      counter={getCounter(collectionProps.totalItemsCount, loading)}
      actions={
        <Button iconName={'download'} onClick={collectionProps.exportToCsv}>
          {t('export')}
        </Button>
      }
    >
      <AttestationCards
        pagination={collectionProps.pagination}
        items={collectionProps.items}
        empty={collectionProps.empty}
        filter={collectionProps.filter}
        preferences={collectionProps.preferenceDetails.preferences}
        setPreferences={collectionProps.preferenceDetails.setPreferences}
        loading={loading}
      />
    </PageLayout>
  );
};

export default Page;
