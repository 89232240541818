import useRisksmartUser from '@risksmart-app/components/hooks/useRisksmartUser';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import BadgeList from '@/components/BadgeList';
import Link from '@/components/Link';
import type { GetAuthUsersQuery } from '@/generated/graphql';
import type { CollectionData } from '@/utils/collectionUtils';
import { useGetTableProps } from '@/utils/table/hooks/useGetTableProps';
import type { TableFields } from '@/utils/table/types';
import { dateColumn } from '@/utils/table/utils/dateColumn';

type AuthUserFields = CollectionData<GetAuthUsersQuery['auth_user'][0]>;

type AuthUserRegisterFields = Omit<AuthUserFields, 'userGroupUsers'> & {
  Name: string;
  UserGroups: string[];
  LastSeen: string;
};

export const useGetUsersTableProps = (
  users: AuthUserFields[],
  onEdit: (user: AuthUserRegisterFields) => void
) => {
  const risksmartUser = useRisksmartUser();
  const { t } = useTranslation(['common'], { keyPrefix: 'userSettings' });
  const { t: st } = useTranslation(['common'], {
    keyPrefix: 'userSettings.columns',
  });

  const fields: TableFields<AuthUserRegisterFields> = {
    Name: {
      id: 'name',
      header: st('displayName'),
      cell: (item) => (
        <Link variant={'secondary'} onFollow={() => onEdit(item)}>
          {item.Name}
        </Link>
      ),
    },
    FirstName: {
      header: st('firstName'),
    },
    LastName: {
      header: st('lastName'),
    },
    FriendlyName: {
      header: st('username'),
    },
    Email: {
      header: st('email'),
    },
    RoleKey: {
      header: st('role'),
    },
    Status: {
      header: st('status'),
    },
    UserGroups: {
      header: st('userGroups'),
      cell: (item) => <BadgeList badges={item.UserGroups} />,
      exportVal: (item) => item.UserGroups.join(', '),
    },
    CreatedOn: dateColumn(st('created_on'), 'CreatedOn'),
    LastSeen: dateColumn(st('lastSeen'), 'LastSeen'),
    Id: {
      header: st('userId'),
    },
    JobTitle: {
      header: st('jobTitle'),
    },
    Department: {
      header: st('department'),
    },
    OfficeLocation: {
      header: st('officeLocation'),
    },
    CreatedByUser: {
      header: st('createdByUser'),
    },
    ModifiedByUser: {
      header: st('modifiedByUser'),
    },
    ModifiedAtTimestamp: dateColumn(
      st('modifiedAtTimestamp'),
      'ModifiedAtTimestamp'
    ),
  };

  const mappedData = useMemo<AuthUserRegisterFields[]>(
    () =>
      users
        .filter(
          (u) => !u.IsCustomerSupport || !!risksmartUser.user?.isCustomerSupport
        )
        .map(
          (user) =>
            ({
              ...user,
              Name: user.FriendlyName,
              UserGroups: user.userGroupUsers.map((u) => u.userGroups.Name),
              RoleKey: user.organisationusers?.[0]?.RoleKey ?? user.RoleKey,
              LastSeen: user.organisationusers?.[0]?.LastSeen ?? user.LastSeen,
            }) as AuthUserRegisterFields
        ),
    [risksmartUser.user?.isCustomerSupport, users]
  );

  return useGetTableProps({
    tableId: 'userRegister',
    data: mappedData,
    entityLabel: t('entity_name'),
    fields,
    initialColumns: [
      'Name',
      'Email',
      'Status',
      'RoleKey',
      'LastSeen',
      'UserGroups',
    ],
    preferencesStorageKey: 'AuthUsersRegisterTable-Preferences',
  });
};
