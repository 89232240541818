import { t } from 'i18next';

import { ParentTypeEnum } from '../../../generated/graphql';
import { DataSourceType } from '../../reporting/schema';
import { getAuditColumns } from './shared';
import { SharedDataset } from './types';

export const getRiskAssessmentResults = () =>
  ({
    label: 'Risk assessment results',
    parentTypes: new Set<DataSourceType>(['risks', 'assessments']),
    customAttributeFormConfigurationParentTypes: [
      ParentTypeEnum.ControlledRiskAssessmentResult,
      ParentTypeEnum.UncontrolledRiskAssessmentResult,
    ],
    fields: {
      ...getAuditColumns(),
      id: { dataType: 'guid', displayType: 'text', label: t('columns.guid') },
      controlType: {
        dataType: 'text',
        displayType: 'commonLookup',
        i18nKey: 'assessmentResults.controlTypesCased',
        label: t('assessmentResults.fields.ControlType'),
      },
      likelihood: {
        dataType: 'number',
        displayType: 'rating',
        ratingKey: 'likelihood',
        label: t('assessmentResults.fields.Likelihood'),
      },
      impact: {
        dataType: 'number',
        displayType: 'rating',
        ratingKey: 'impact',
        label: t('assessmentResults.fields.Impact'),
      },
      rationale: {
        dataType: 'text',
        displayType: 'text',
        label: t('assessmentResults.fields.Rationale'),
      },
      rating: {
        dataType: 'text',
        displayType: 'metaRating',
        label: t('assessmentResults.fields.Rating'),
      },
      testDate: {
        dataType: 'date',
        displayType: 'date',
        label: t('assessmentResults.fields.TestDate'),
      },
    },
  }) satisfies SharedDataset;
