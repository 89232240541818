import { create } from 'zustand';

import type { Step } from '../types';

type WizardState = {
  currentStep: number;
  setCurrentStep: (currentStep: number) => void;
  steps: Step[];
  setSteps: (steps: Step[]) => void;
  basePath: string;
  setBasePath: (basePath: string) => void;
  riskId: string;
  setRiskId: (riskId: string) => void;
  showGuide: boolean;
  toggleShowGuide: () => void;
  activeWizards: Set<string>;
  addActiveWizard: (riskId: string) => void;
  removeActiveWizard: (riskId: string) => void;
};

export const useWizardStore = create<WizardState>((set) => ({
  currentStep: 0,
  setCurrentStep: (currentStep: number) => set({ currentStep }),
  steps: [],
  setSteps: (steps: Step[]) => set({ steps }),
  basePath: '',
  setBasePath: (basePath: string) => set({ basePath }),
  riskId: '',
  setRiskId: (riskId: string) => set({ riskId }),
  showGuide: true,
  toggleShowGuide: () => set((state) => ({ showGuide: !state.showGuide })),
  activeWizards: new Set(),
  addActiveWizard: (riskId: string) =>
    set((state) => {
      const activeWizardsClone = new Set(state.activeWizards);
      activeWizardsClone.add(riskId);

      return {
        activeWizards: activeWizardsClone,
      };
    }),
  removeActiveWizard: (riskId: string) =>
    set((state) => {
      const activeWizardsClone = new Set(state.activeWizards);
      activeWizardsClone.delete(riskId);

      return {
        activeWizards: activeWizardsClone,
      };
    }),
}));
