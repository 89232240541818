import Grid from '@cloudscape-design/components/grid';
import type { OptionDefinition } from '@cloudscape-design/components/internal/components/option/interfaces';
import { useRating } from '@risksmart-app/components/hooks/useRating';
import type { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import TestScheduleFields from 'src/pages/controls/update/forms/TestScheduleFields';
import type { IndicatorFormDataFields } from 'src/pages/indicators/forms/indicatorSchema';

import { ControlledFileUpload } from '@/components/Form/ControlledFileUpload/ControlledFileUpload';
import ControlledGroupAndUserContributorMultiSelect from '@/components/Form/ControlledGroupAndUserContributorMultiSelect';
import ControlledInput from '@/components/Form/ControlledInput';
import ControlledSelect from '@/components/Form/ControlledSelect';
import ControlledTextarea from '@/components/Form/ControlledTextarea';
import DepartmentSelector from '@/components/Form/DepartmentSelector';
import ConditionalField from '@/components/Form/Form/CustomisableForm/ConditionalField';
import CustomisableForm from '@/components/Form/Form/CustomisableForm/CustomisableForm';
import FieldGroup from '@/components/Form/Form/CustomisableForm/FieldGroup';
import { FormField } from '@/components/Form/Form/FormField';
import TagSelector from '@/components/Form/TagSelector';
import Tolerance from '@/components/Tolerance/Tolerance';
import {
  Contributor_Type_Enum,
  Indicator_Type_Enum,
} from '@/generated/graphql';

import { TestIds } from './IndicatorDetailsFormFieldsTestIds';

interface Props {
  readOnly?: boolean;
  isUpdate?: boolean;
  latestTestDate?: string;
}

const IndicatorsDetailsFormFields: FC<Props> = ({
  readOnly,
  isUpdate = false,
  latestTestDate,
}) => {
  const { control, watch } = useFormContext<IndicatorFormDataFields>();
  const { t: st } = useTranslation(['common'], {
    keyPrefix: 'indicators.fields',
  });
  const { t } = useTranslation(['common'], {
    keyPrefix: 'fields',
  });

  const { options: indicatorTypeOptions } = useRating('indicator_type');
  const indicatorType: Indicator_Type_Enum =
    watch('Type') || Indicator_Type_Enum.Number;

  const showNumberTypeFields = indicatorType === Indicator_Type_Enum.Number;
  const showTextTypeFields = indicatorType === Indicator_Type_Enum.Text;

  return (
    <CustomisableForm readOnly={readOnly}>
      <ControlledInput
        key={'title'}
        forceRequired={true}
        name={'Title'}
        label={st('title')}
        control={control}
        description={st('title_help')}
        placeholder={st('title_placeholder')}
        disabled={readOnly}
      />

      <FieldGroup key={'typeAndDetails'}>
        <ControlledSelect
          key={'type'}
          testId={TestIds.Type}
          control={control}
          forceRequired={true}
          name={'Type'}
          label={st('type')}
          description={st('type_help')}
          // TODO
          placeholder={'Select'}
          options={indicatorTypeOptions as OptionDefinition[]}
          disabled={readOnly || isUpdate}
        />

        <ConditionalField condition={showNumberTypeFields} key={'unit'}>
          <ControlledInput
            name={'Unit'}
            label={st('unit')}
            description={st('unit_help')}
            control={control}
            placeholder={st('unit_placeholder')}
            disabled={readOnly}
          />
        </ConditionalField>

        <FormField disableBottomPadding stretch>
          <Grid
            gridDefinition={[
              { colspan: 3 },
              { colspan: 3 },
              { colspan: 3 },
              { colspan: 3 },
            ]}
          >
            <ConditionalField
              condition={showNumberTypeFields}
              key={'lowerToleranceNum'}
            >
              <ControlledInput
                name={'LowerToleranceNum'}
                label={st('lower_tolerance_num')}
                description={st('lower_tolerance_num_help')}
                control={control}
                placeholder={st('tolerance_placeholder')}
                disabled={readOnly}
                type={'number'}
                testId={TestIds.LowerTolerance}
              />
            </ConditionalField>

            <ConditionalField
              condition={showNumberTypeFields}
              key={'lowerAppetiteNum'}
            >
              <ControlledInput
                name={'LowerAppetiteNum'}
                label={st('lower_appetite_num')}
                description={st('lower_appetite_num_help')}
                control={control}
                placeholder={st('tolerance_placeholder')}
                disabled={readOnly}
                type={'number'}
                testId={TestIds.LowerAppetite}
              />
            </ConditionalField>
            <ConditionalField
              condition={showNumberTypeFields}
              key={'upperAppetiteNum'}
            >
              <ControlledInput
                name={'UpperAppetiteNum'}
                label={st('upper_appetite_num')}
                description={st('upper_appetite_num_help')}
                control={control}
                placeholder={st('tolerance_placeholder')}
                disabled={readOnly}
                type={'number'}
                testId={TestIds.UpperAppetite}
              />
            </ConditionalField>

            <ConditionalField
              condition={showNumberTypeFields}
              key={'upperToleranceNum'}
            >
              <ControlledInput
                name={'UpperToleranceNum'}
                label={st('upper_tolerance_num')}
                description={st('upper_tolerance_num_help')}
                control={control}
                placeholder={st('tolerance_placeholder')}
                disabled={readOnly}
                type={'number'}
                testId={TestIds.UpperTolerance}
              />
            </ConditionalField>
          </Grid>
        </FormField>

        <ConditionalField condition={showNumberTypeFields} key={'tolerances'}>
          <div>
            <FormField stretch>
              <Tolerance
                upperTolerance={watch('UpperToleranceNum')}
                lowerTolerance={watch('LowerToleranceNum')}
                upperAppetite={watch('UpperAppetiteNum')}
                lowerAppetite={watch('LowerAppetiteNum')}
              />
            </FormField>
          </div>
        </ConditionalField>
        <ConditionalField condition={showTextTypeFields} key={'targetValueTxt'}>
          <ControlledInput
            key={'targetValueTxt'}
            forceRequired={true}
            testId={TestIds.TargetValueTxt}
            name={'TargetValueTxt'}
            label={st('target_text_value')}
            description={st('target_text_value_help')}
            control={control}
            placeholder={st('target_text_placeholder')}
            disabled={readOnly}
          />
        </ConditionalField>
      </FieldGroup>
      <ControlledTextarea
        key={'description'}
        name={'Description'}
        label={st('description')}
        placeholder={st('description_placeholder')}
        description={st('description_help')}
        control={control}
        disabled={readOnly}
      />
      <ControlledGroupAndUserContributorMultiSelect
        key={'owners'}
        testId={TestIds.Owners}
        control={control}
        includeGroups={true}
        inheritedContributorsName={'ancestorContributors'}
        contributorType={Contributor_Type_Enum.Owner}
        label={t('Owner')}
        name={'Owners'}
        placeholder={t('Owner_placeholder')}
        description={st('Owner_help')}
        disabled={readOnly}
        forceRequired={true}
      />
      <ControlledGroupAndUserContributorMultiSelect
        key={'contributors'}
        control={control}
        includeGroups={true}
        inheritedContributorsName={'ancestorContributors'}
        contributorType={Contributor_Type_Enum.Contributor}
        label={t('Contributor')}
        name={'Contributors'}
        placeholder={t('Contributor_placeholder')}
        description={st('Contributor_help')}
        disabled={readOnly}
      />
      <ControlledFileUpload
        key={'attachFiles'}
        label={t('newFiles')}
        description={t('newFiles_help')}
        control={control}
        name={'newFiles'}
        saveFilesName={'files'}
        testId={TestIds.Files}
        disabled={readOnly}
      />
      <TagSelector
        name={'tags'}
        key={'tags'}
        control={control}
        disabled={readOnly}
      />
      <DepartmentSelector
        key={'departments'}
        name={'departments'}
        control={control}
        disabled={readOnly}
      />
      <TestScheduleFields
        key={'testConfigFields'}
        control={control}
        readOnly={false}
        latestTestDate={latestTestDate ?? null}
        manualNextTestDueName={'schedule.ManualDueDate'}
        testFrequencyName={'schedule.Frequency'}
        testTimeToCompleteValueName={'schedule.TimeToCompleteValue'}
        testScheduleStartDateName={'schedule.StartDate'}
        testTimeToCompleteUnitName={'schedule.TimeToCompleteUnit'}
      />
    </CustomisableForm>
  );
};

export default IndicatorsDetailsFormFields;
