import i18n from '@risksmart-app/i18n/i18n';
import { t } from 'i18next';

import { ParentTypeEnum } from '../../../generated/graphql';
import { DataSourceType } from '../schema';
import { getAuditColumns } from './shared';
import { SharedDataset } from './types';

export const getAcceptances = () =>
  ({
    label: i18n.format(t('acceptance_other'), 'capitalize'),
    parentTypes: new Set<DataSourceType>(['risks']),
    customAttributeFormConfigurationParentTypes: [ParentTypeEnum.Acceptance],
    fields: {
      ...getAuditColumns(),
      title: {
        dataType: 'text',
        displayType: 'text',
        label: t('acceptances.columns.title'),
      },
      details: {
        dataType: 'text',
        displayType: 'text',
        label: t('acceptances.columns.details'),
      },
      id: { dataType: 'guid', displayType: 'text', label: t('columns.guid') },
      acceptedFrom: {
        dataType: 'date',
        displayType: 'date',
        label: t('acceptances.columns.accepted_from'),
      },
      acceptedTo: {
        dataType: 'date',
        displayType: 'date',
        label: t('acceptances.columns.accepted_to'),
      },
      sequentialId: {
        dataType: 'number',
        displayType: 'number',
        label: t('columns.id'),
        prefix: 'ACC-',
      },
      status: {
        dataType: 'text',
        displayType: 'rating',
        ratingKey: 'acceptance_status',
        label: t('acceptances.columns.status'),
      },
      detailsLink: {
        dataType: 'guid',
        label: t('acceptances.columns.details_link'),
        displayType: 'detailsLink',
        entityInfoParentType: ParentTypeEnum.Acceptance,
      },
    },
  }) satisfies SharedDataset;
