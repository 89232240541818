import SpaceBetween from '@cloudscape-design/components/space-between';
import Button from '@risksmart-app/components/Button';
import { useGetGuidParam } from '@risksmart-app/components/routes/routes.utils';
import type { FC } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { PageLayout } from 'src/layouts';

import ControlledTabs from '@/components/ControlledTabs';
import DeleteModal from '@/components/DeleteModal';
import {
  useDeleteThirdPartyMutation,
  useGetThirdPartyByIdQuery,
} from '@/generated/graphql';
import { useDeleteResultNotification } from '@/hooks/useMutationResultNotification';
import { evictField } from '@/utils/graphqlUtils';
import type { IssueTaxonomyKeys } from '@/utils/issueVariantUtils';

import { Permission } from '../../../rbac/Permission';
import { useGetDetailParentPath } from '../../../routes/useGetDetailParentPath';
import { useExporter } from './useExporter';
import { useTabs } from './useTabs';

interface Props {
  activeTabId?:
    | 'actions'
    | 'controls'
    | 'details'
    | 'linkedItems'
    | 'questionnaires'
    | IssueTaxonomyKeys;
  showDeleteButton?: boolean;
}

const Page: FC<Props> = ({ activeTabId, showDeleteButton }: Props) => {
  const thirdPartyId = useGetGuidParam('id');
  const parentUrl = useGetDetailParentPath(thirdPartyId);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const { data } = useGetThirdPartyByIdQuery({
    variables: { Id: thirdPartyId },
  });
  const navigate = useNavigate();

  const title = data?.third_party?.Title || '';
  const tabs = useTabs(data?.third_party);
  const { t } = useTranslation(['common']);
  const { t: st } = useTranslation(['common'], { keyPrefix: 'third_party' });

  const [mutate, deleteResult] = useDeleteThirdPartyMutation({
    update: (cache) => {
      evictField(cache, 'third_party');
      evictField(cache, 'third_party_by_pk');
    },
  });
  const onDelete = useDeleteResultNotification({
    entityName: st('entity_name'),
    asyncAction: async () => {
      if (!data) {
        return false;
      }
      await mutate({
        variables: {
          Id: thirdPartyId,
        },
      });
      await navigate(parentUrl);

      return true;
    },
  });

  const [exportItem, { loading: exporting }] = useExporter(thirdPartyId);

  return (
    <PageLayout
      title={title}
      actions={
        <SpaceBetween direction={'horizontal'} size={'xs'}>
          <Button
            iconName={'download'}
            disabled={exporting}
            onClick={exportItem}
          >
            {t('export')}
          </Button>

          {showDeleteButton && (
            <Permission
              permission={'delete:third_party'}
              parentObject={data?.third_party}
            >
              <Button
                variant={'normal'}
                formAction={'none'}
                onClick={() => {
                  setIsDeleteModalVisible(true);
                }}
              >
                {st('delete_button')}
              </Button>
            </Permission>
          )}
        </SpaceBetween>
      }
    >
      <ControlledTabs
        tabs={tabs}
        activeTabId={activeTabId}
        variant={'container'}
      />
      <DeleteModal
        loading={deleteResult.loading}
        isVisible={isDeleteModalVisible}
        header={st('delete_modal_title')}
        onDelete={onDelete}
        onDismiss={() => setIsDeleteModalVisible(false)}
        size={'medium'}
      >
        {st('confirm_delete_message')}
      </DeleteModal>
    </PageLayout>
  );
};

export default Page;
