import type { FC } from 'react';

type Props = React.HTMLProps<HTMLAnchorElement> & {
  icon?: JSX.Element;
  match?: boolean;
};

const SectionDropdown: FC<Props> = ({ match, icon, children, ...rest }) => {
  const defaultStyles = `transition duration-200 group no-underline px-[10px] py-3 rounded-md flex items-center space-x-4`;

  const sectionStyles = match
    ? 'text-navy bg-teal hover:text-navy hover:bg-teal'
    : 'text-white hover:text-white hover:bg-navy_light';

  const linkStyles = `${defaultStyles} ${sectionStyles}`;

  const iconStyles = `w-[24px] h-[24px] ${
    match ? 'text-inherit' : 'text-teal group-hover:text-teal'
  }`;

  return (
    <a href={'#'} {...rest} className={linkStyles}>
      {icon && <span className={iconStyles}>{icon}</span>}
      {children}
    </a>
  );
};

export default SectionDropdown;
