import type { FilteringProperty } from '@cloudscape-design/components/property-filter/interfaces';

import BadgeList from '@/components/BadgeList';

import type { AllowedField } from '../utils';
import type { ReportFieldType } from './types';

export const badgeList: ReportFieldType = {
  asyncOptionSuggestions: true,
  cell: ({ fieldData }) => {
    const value = fieldData.value;

    return <BadgeList badges={value as string[]} />;
  },
  getChartLabel: ({ fieldData }) => {
    return (fieldData.value as string) ?? '';
  },
  propertyConfig: function (field: AllowedField): FilteringProperty {
    return {
      key: field.value,
      groupValuesLabel: '',
      propertyLabel: field.label,
      operators: [
        {
          operator: '=',
        },
      ],
    };
  },
};
