import type {
  CategoryType,
  GigawidgetCommonProps,
  WidgetDataSource,
} from './types';
import type { GigaBarWidgetProps } from './widgetTypes/bar/GigaBarWidget';
import { GigaBarWidget } from './widgetTypes/bar/GigaBarWidget';
import type { GigaNumberWidgetProps } from './widgetTypes/number/GigaNumberWidget';
import { GigaNumberWidget } from './widgetTypes/number/GigaNumberWidget';
import type { GigaPieWidgetProps } from './widgetTypes/pie/GigaPieWidget';
import { GigaPieWidget } from './widgetTypes/pie/GigaPieWidget';
import { PlacematWidget } from './widgetTypes/placemat/PlacematWidget';
import type { GigaTableWidgetProps } from './widgetTypes/table/GigaTableWidget';
import { GigaTableWidget } from './widgetTypes/table/GigaTableWidget';

type Props<
  TDataSource extends WidgetDataSource,
  TCategory extends CategoryType,
  TSubCategory extends CategoryType | never,
> = GigawidgetCommonProps<TDataSource> &
  (
    | ({
        type: 'bar' | 'stacked-bar';
      } & Omit<
        GigaBarWidgetProps<TDataSource, TCategory, TSubCategory>,
        'stackedBars'
      >)
    | ({ type: 'donut' | 'pie' } & Omit<
        GigaPieWidgetProps<TDataSource, TCategory>,
        'donut'
      >)
    | ({
        type: 'kpi';
      } & GigaNumberWidgetProps<TDataSource>)
    | ({
        type: 'placemat' | 'table';
      } & GigaTableWidgetProps<TDataSource>)
  );

export const Gigawidget = <
  TDataSource extends WidgetDataSource,
  TCategory extends CategoryType,
  TSubCategory extends CategoryType | never,
>(
  props: Props<TDataSource, TCategory, TSubCategory>
) => {
  switch (props.type) {
    case 'bar':
    case 'stacked-bar':
      return (
        <GigaBarWidget {...props} stackedBars={props.type === 'stacked-bar'} />
      );
    case 'pie':
    case 'donut':
      return <GigaPieWidget {...props} donut={props.type === 'donut'} />;
    case 'kpi':
      return <GigaNumberWidget {...props} />;
    case 'table':
      return <GigaTableWidget {...props} />;
    case 'placemat':
      return <PlacematWidget {...props} />;
    default:
      return null;
  }
};
