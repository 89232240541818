import type { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import ControlledInput from '@/components/Form/ControlledInput';
import ControlledMultiselect from '@/components/Form/ControlledMultiselect';

import ConnectedCustomDatasourcePropertyFilter from './ConnectedCustomDatasourcePropertyFilter';
import DatasourceTree from './DatasourceTree';
import {
  defaultValues,
  type ReportDefinitionFormData,
} from './definitionSchema';
import type { CustomAttributeSchemaLookup } from './types';
import { getAllowedFields } from './utils';

export type Props = {
  customAttributeSchemaLookup: CustomAttributeSchemaLookup;
  mode: 'create' | 'update';
};

export const CustomDatasourceFormFields: FC<Props> = ({
  customAttributeSchemaLookup,
  mode,
}) => {
  const { control, watch, setValue } =
    useFormContext<ReportDefinitionFormData>();
  const dataSource = watch('dataSource');
  const allFields = getAllowedFields(dataSource, customAttributeSchemaLookup);
  const { t } = useTranslation(['common'], {
    keyPrefix: 'customDatasources.fields',
  });

  return (
    <>
      <ControlledInput
        testId={'title'}
        name={'title'}
        label={t('title')}
        control={control}
      />
      <DatasourceTree
        testId={'dataSource'}
        name={'dataSource'}
        disabled={mode === 'update'}
        onChange={() => {
          setValue('fields', defaultValues.fields);
          setValue('filters', defaultValues.filters);
        }}
      />
      {dataSource.type && (
        <ControlledMultiselect
          control={control}
          label={t('fields')}
          disabled={mode === 'update'}
          name={'fields'}
          filteringType={'auto'}
          testId={'fields'}
          hideTokens={false}
          options={allFields}
        />
      )}
      {allFields.length > 0 && (
        <ConnectedCustomDatasourcePropertyFilter
          rootDataSource={dataSource}
          allFields={allFields}
        />
      )}
    </>
  );
};
