import { useTools } from '@risksmart-app/components/Tools/useTools';
import { useTranslation } from 'react-i18next';

import { useDeleteWizardMutation } from '@/generated/graphql';

import { useWizardStore } from '../store/useWizardStore';
import { StepStatus } from '../types';

export const useCloseWizard = (riskId: string) => {
  const { setCurrentStep, steps, setSteps, removeActiveWizard, setRiskId } =
    useWizardStore();
  const [_, setToolsContent] = useTools();
  const { t } = useTranslation('common', { keyPrefix: 'wizard' });

  const [deleteWizardMutation] = useDeleteWizardMutation({
    variables: {
      RiskId: riskId,
    },
  });

  return {
    closeWizard: (isLastStep = false) => {
      const inProgressStep = steps?.findIndex(
        (step) => step.status === StepStatus.InProgress
      );
      if (isLastStep) {
        setSteps(t('steps'));
        setCurrentStep(0);
        deleteWizardMutation();
        removeActiveWizard(riskId);
        setRiskId('');
      } else {
        setCurrentStep(inProgressStep);
      }

      setToolsContent(undefined);
    },
  };
};
