import type { PropertyFilterToken } from '@cloudscape-design/collection-hooks';
import type {
  PropertyFilterQuery,
  PropertyFilterTokenGroup,
  SortingState,
} from '@cloudscape-design/collection-hooks/cjs/interfaces';
import type {
  TableOptions,
  TypedPropertyFilterQuery,
} from '@risksmart-app/components/Table/tableUtils';
import {
  queryStringToTableOptions,
  tableOptionsToQueryString,
} from '@risksmart-app/components/Table/tableUtils';
import { useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { notEmpty } from 'src/utilityTypes';

import type { DefaultSortingState, TableFields, TableRecord } from '../types';

export const convertTokenOrTokenGroup = (
  tg: PropertyFilterToken | PropertyFilterTokenGroup
) => {
  if ('operator' in tg) {
    const token = tg as PropertyFilterToken;

    return convertStringifiedToken(token);
  } else if ('tokens' in tg) {
    const tGroup = tg as PropertyFilterTokenGroup;
    const tokenGroup: PropertyFilterTokenGroup = {
      ...tGroup,
      tokens: tGroup.tokens.map(convertTokenOrTokenGroup),
    };

    return tokenGroup;
  } else {
    throw new Error('Unsupported token');
  }
};

/**
 * Converts stringifies values into object
 * @param query
 * @returns
 */
export const convertObjectValues = (
  query: PropertyFilterQuery
): PropertyFilterQuery => {
  return {
    ...query,
    tokenGroups: query.tokenGroups
      ?.map(convertTokenOrTokenGroup)
      .filter(notEmpty),
    tokens: query.tokens.map(convertStringifiedToken),
  };
};

const convertStringifiedToken = (
  token: PropertyFilterToken
): PropertyFilterToken => {
  try {
    const parsedValue = JSON.parse(token.value);

    return {
      ...token,
      value: typeof parsedValue === 'object' ? parsedValue : token.value,
    };
  } catch {
    return token;
  }
};

/**
 * Store and retrieve filters and sorting in the url after the hash
 *
 * @returns
 */
export const useFiltersFromUrlHash = <T extends TableRecord>(
  fields: TableFields<T>,
  defaultSortingState?: DefaultSortingState<T> | undefined,
  enabledTokenGroups?: boolean
) => {
  const { hash } = useLocation();

  const navigate = useNavigate();

  const updateUrlHash = useCallback(
    (options: TableOptions<T>) => {
      const queryString = tableOptionsToQueryString<T>(
        { ...options },
        enabledTokenGroups
      );

      navigate({ hash: queryString }, { replace: true });
    },
    [enabledTokenGroups, navigate]
  );

  const sortingState = useMemo<SortingState<T> | undefined>(() => {
    const tableOptions = queryStringToTableOptions(
      hash.substring(1, hash.length)
    );
    if (tableOptions.sorting) {
      const sortingField = tableOptions.sorting.sortingColumn.sortingField;

      return {
        ...tableOptions.sorting,
        sortingColumn: {
          sortingField,
          sortingComparator: sortingField
            ? fields[sortingField]?.sortingComparator
            : undefined,
        },
      };
    }
    if (defaultSortingState) {
      return {
        isDescending: defaultSortingState.sortingDirection === 'desc',
        sortingColumn: {
          sortingField: defaultSortingState.sortingColumn as string,
          sortingComparator: defaultSortingState.sortingColumn
            ? fields[defaultSortingState.sortingColumn]?.sortingComparator
            : undefined,
        },
      };
    }

    return undefined;
    // ignoring fields as this should never change after first being defined
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash]);

  const propertyFilter = useMemo<PropertyFilterQuery | undefined>(() => {
    const tableOptions = queryStringToTableOptions(
      hash.substring(1, hash.length)
    );

    if (tableOptions.filtering) {
      return convertObjectValues(tableOptions.filtering);
    }

    return undefined;
    // ignoring fields as this should never change after first being defined
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash]);

  const setSortingState = (sortingState: SortingState<T>) => {
    updateUrlHash({
      sorting: sortingState,
      filtering: propertyFilter as TypedPropertyFilterQuery<T>,
    });
  };

  const setPropertyFilter = useCallback(
    (propertyFilter: PropertyFilterQuery) => {
      updateUrlHash({
        sorting: sortingState,
        filtering: propertyFilter as TypedPropertyFilterQuery<T>,
      });
    },
    [sortingState, updateUrlHash]
  );

  const setPropertyFilterAndSortingState = useCallback(
    ({
      propertyFilter,
      sortingState,
    }: {
      propertyFilter: PropertyFilterQuery;
      sortingState: SortingState<T>;
    }) => {
      updateUrlHash({
        sorting: sortingState,
        filtering: propertyFilter as TypedPropertyFilterQuery<T>,
      });
    },
    [updateUrlHash]
  );

  return {
    sortingState,
    propertyFilter,
    setSortingState,
    setPropertyFilter,
    setPropertyFilterAndSortingState,
    hash,
  };
};
