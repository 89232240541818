import { PageNotFound } from '@risksmart-app/components/errors/errors';
import { useGetGuidParam } from '@risksmart-app/components/routes/routes.utils';
import type { FC } from 'react';

import ControlledTabs from '@/components/ControlledTabs/ControlledTabs';
import Loading from '@/components/Loading';
import { useGetDocumentFileByIdQuery } from '@/generated/graphql';

import { PageLayout } from '../../../../../../layouts';
import { useTabs } from './useTabs';

type Props = {
  activeTabId: 'attestations' | 'details';
};

const DocumentFileModal: FC<Props> = ({ activeTabId }) => {
  const tabs = useTabs();
  const documentFileId = useGetGuidParam('id');

  const { data: documentFileData, loading } = useGetDocumentFileByIdQuery({
    fetchPolicy: 'no-cache',
    variables: { id: documentFileId! },
    skip: !documentFileId,
  });

  if (loading) {
    return (
      <PageLayout>
        <Loading />
      </PageLayout>
    );
  }

  if (documentFileData?.document_file.length === 0) {
    throw new PageNotFound(`Version with id ${documentFileId} not found`);
  }

  const entity = documentFileData?.document_file?.[0];
  const title = entity?.parent?.Title ? `${entity.parent.Title}` : 'Version';
  const counter = entity?.Version ? `(${entity.Version})` : '';

  return (
    <PageLayout title={title} counter={counter}>
      <ControlledTabs
        activeTabId={activeTabId}
        tabs={tabs}
        variant={'container'}
      />
    </PageLayout>
  );
};

export default DocumentFileModal;
