import { useTranslation } from 'react-i18next';

import type { FilterModal } from '@/components/CustomisableRibbon/customisableRibbonModalSchema';

export const useGetDefaultRibbonFilters = (): FilterModal[] => {
  const { t } = useTranslation(['common'], {
    keyPrefix: 'tiers',
  });

  const defaultRibbonFilters: FilterModal[] = [
    {
      id: crypto.randomUUID(),
      title: t('1'),
      itemFilterQuery: {
        tokens: [],
        tokenGroups: [
          {
            operator: '=',
            propertyKey: 'TierLabelled',
            value: t('1'),
          },
        ],
        operation: 'and',
      },
    },
    {
      id: crypto.randomUUID(),
      title: t('2'),
      itemFilterQuery: {
        tokens: [],
        tokenGroups: [
          {
            operator: '=',
            propertyKey: 'TierLabelled',
            value: t('2'),
          },
        ],
        operation: 'and',
      },
    },
    {
      id: crypto.randomUUID(),
      title: t('3'),
      itemFilterQuery: {
        tokens: [],
        tokenGroups: [
          {
            operator: '=',
            propertyKey: 'TierLabelled',
            value: t('3'),
          },
        ],
        operation: 'and',
      },
    },
    {
      id: crypto.randomUUID(),
      title: 'All risks',
      itemFilterQuery: {
        tokens: [],
        tokenGroups: [],
        operation: 'and',
      },
    },
  ];

  return defaultRibbonFilters;
};
