import SpaceBetween from '@cloudscape-design/components/space-between';
import i18n from '@risksmart-app/i18n/i18n';
import {
  Activity,
  AlertTriangle,
  Asterisk02,
  BezierCurve02,
  Certificate02,
  CheckCircleBroken,
  CheckVerified03,
  FileCheck01,
  Settings04,
  UsersPlus,
  Zap,
} from '@untitled-ui/icons-react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getAllOwnersCellValue } from 'src/rbac/contributorHelper';

import Link from '@/components/Link';
import type {
  GetLinkedItemsQuery,
  OwnerGroupPartsFragment,
  OwnerPartsFragment,
} from '@/generated/graphql';
import type { Parent_Type_Enum } from '@/generated/graphql';
import useEntityInfo from '@/hooks/getEntityInfo';
import { useIsFeatureVisibleToOrg } from '@/utils/featureFlags';
import { getFriendlyId } from '@/utils/friendlyId';
import type { ParentIssueTypes } from '@/utils/issueVariantUtils';
import { IssueTypeMapping } from '@/utils/issueVariantUtils';
import { useGetOwnersFieldConfig } from '@/utils/table/hooks/useGetOwnersFieldConfig';
import { useGetTableProps } from '@/utils/table/hooks/useGetTableProps';
import type { TableFields } from '@/utils/table/types';
import {
  actionDetailsUrl,
  assessmentActivitiesDetailsUrl,
  causeDetailsUrl,
  complianceMonitoringAssessmentActivitiesDetailsUrl,
  consequenceDetailsUrl,
  controlDetailsUrl,
  impactDetailsUrl,
  internalAuditReportActivitiesDetailsUrl,
  obligationDetailsUrl,
} from '@/utils/urls';

import type { ObjectWithContributors } from '../../rbac/Permission';
import type { LinkedItemsTableFields } from './types';

const useGetProperties = () => {
  const { t } = useTranslation('taxonomy');
  const getEntityInfo = useEntityInfo();

  return (item: LinkedItemsTableFields) => {
    if (item.asSource) {
      if (!item.target_node) {
        throw new Error('Missing target node');
      }

      if (item.target_risk) {
        const entityInfo = getEntityInfo(item.target_node?.ObjectType);
        const url = entityInfo.url(item.Target);

        return {
          typelabel: i18n.format(entityInfo.singular, 'capitalize'),
          title: item.target_risk.Title,
          url,
          icon: <Zap />,
          sequentialId: getFriendlyId(
            item.target_node.ObjectType,
            item.target_node.SequentialId
          ),
        };
      }
      if (item.target_third_party) {
        const entityInfo = getEntityInfo(item.target_node?.ObjectType);
        const url = entityInfo.url(item.Target);

        return {
          typelabel: i18n.format(entityInfo.singular, 'capitalize'),
          title: item.target_third_party.Title,
          url,
          icon: <UsersPlus />,
          sequentialId: getFriendlyId(
            item.target_node.ObjectType,
            item.target_node.SequentialId
          ),
        };
      }
      if (item.target_control) {
        const entityInfo = getEntityInfo(item.target_node?.ObjectType);

        return {
          typelabel: i18n.format(entityInfo.singular, 'capitalize'),
          title: item.target_control.Title,
          url: controlDetailsUrl(
            item.target_control.Id,
            item.parentId,
            item.parentType
          ),
          icon: <Settings04 />,
          sequentialId: getFriendlyId(
            item.target_node.ObjectType,
            item.target_node.SequentialId
          ),
        };
      }
      if (item.target_control_group) {
        const entityInfo = getEntityInfo(item.target_node?.ObjectType);
        const url = entityInfo.url(item.Target);

        return {
          typelabel: i18n.format(
            `${t('control_one')} ${t('control_group_one')}`,
            'capitalize'
          ),
          title: item.target_control_group.Title,
          url,
          icon: <Settings04 />,
        };
      }
      if (item.target_assessment) {
        const entityInfo = getEntityInfo(item.target_node?.ObjectType);
        const url = entityInfo.url(item.Target);

        return {
          typelabel: i18n.format(entityInfo.singular, 'capitalize'),
          title: item.target_assessment.Title,
          url,
          icon: <Certificate02 />,
          sequentialId: getFriendlyId(
            item.target_node.ObjectType,
            item.target_node.SequentialId
          ),
        };
      }
      if (item.target_internal_audit_report) {
        const entityInfo = getEntityInfo(item.target_node?.ObjectType);
        const url = entityInfo.url(item.Target);

        return {
          typelabel: i18n.format(entityInfo.singular, 'capitalize'),
          title: item.target_internal_audit_report.Title,
          url,
          icon: <BezierCurve02 />,
          sequentialId: getFriendlyId(
            item.target_node.ObjectType,
            item.target_node.SequentialId
          ),
        };
      }

      if (item.target_internal_audit_entity) {
        const entityInfo = getEntityInfo(item.target_node?.ObjectType);
        const url = entityInfo.url(item.Target);

        return {
          typelabel: i18n.format(entityInfo.singular, 'capitalize'),
          title: item.target_internal_audit_entity.Title,
          url,
          icon: <BezierCurve02 />,
          sequentialId: getFriendlyId(
            item.target_node.ObjectType,
            item.target_node.SequentialId
          ),
        };
      }

      if (item.target_assessment_activity) {
        let activityUrl;
        if (item.target_assessment_activity?.parentAssessment?.Id) {
          activityUrl = assessmentActivitiesDetailsUrl;
        }
        if (item.target_assessment_activity?.parentInternalAuditReport?.Id) {
          activityUrl = internalAuditReportActivitiesDetailsUrl;
        }
        if (
          item.target_assessment_activity?.parentComplianceMonitoringAssessment
            ?.Id
        ) {
          activityUrl = complianceMonitoringAssessmentActivitiesDetailsUrl;
        }

        return {
          typelabel: i18n.format(t('assessment_activity_one'), 'capitalize'),
          title: item.target_assessment_activity.Title,
          url: activityUrl
            ? `${activityUrl(
                item.target_assessment_activity.ParentId,
                item.target_assessment_activity.Id
              )}`
            : undefined,
          icon: <Settings04 />,
        };
      }
      if (item.target_impact) {
        const entityInfo = getEntityInfo(item.target_node?.ObjectType);
        const url = entityInfo.url(item.Target);

        return {
          typelabel: i18n.format(entityInfo.singular, 'capitalize'),
          title: item.target_impact.Name,
          url,
          icon: <Asterisk02 />,
          sequentialId: getFriendlyId(
            item.target_node.ObjectType,
            item.target_node.SequentialId
          ),
        };
      }
      if (item.target_impact_rating) {
        return {
          typelabel: i18n.format(t('impact_rating_one'), 'capitalize'),
          title: item.target_impact_rating.impact.Name,
          url: `${impactDetailsUrl(
            item.target_impact_rating.impact.Id
          )}/rating`,
          icon: <Asterisk02 />,
          sequentialId: getFriendlyId(
            item.target_node.ObjectType,
            item.target_node.SequentialId
          ),
        };
      }
      if (item.target_obligation_impact) {
        return {
          typelabel: `${i18n.format(
            t('obligation_one'),
            'capitalize'
          )} ${i18n.format(t('impact_one'), '')}`,
          title: item.target_obligation_impact.Description,
          url: `${obligationDetailsUrl(
            item.target_obligation_impact.ParentObligationId
          )}/impact`,
          icon: <Asterisk02 />,
        };
      }
      if (item.target_action) {
        const entityInfo = getEntityInfo(item.target_node?.ObjectType);
        const url = entityInfo.url(item.Target);

        return {
          typelabel: i18n.format(entityInfo.singular, 'capitalize'),
          title: item.target_action.Title,
          url,
          icon: <CheckCircleBroken />,
          sequentialId: getFriendlyId(
            item.target_node.ObjectType,
            item.target_node.SequentialId
          ),
        };
      }
      if (item.target_action_update) {
        return {
          typelabel: i18n.format(t('action_update_one'), 'capitalize'),
          title: item.target_action_update.Title,
          url: `${actionDetailsUrl(
            item.target_action_update.ParentActionId
          )}/updates`,
          icon: <CheckCircleBroken />,
        };
      }
      if (item.target_indicator) {
        const entityInfo = getEntityInfo(item.target_node?.ObjectType);
        const url = entityInfo.url(item.Target);

        return {
          typelabel: i18n.format(entityInfo.singular, 'capitalize'),
          title: item.target_indicator.Title,
          url,
          icon: <Activity />,
          sequentialId: getFriendlyId(
            item.target_node.ObjectType,
            item.target_node.SequentialId
          ),
        };
      }
      if (item.target_acceptance) {
        const entityInfo = getEntityInfo(item.target_node?.ObjectType);
        const url = entityInfo.url(item.Target);

        return {
          typelabel: i18n.format(entityInfo.singular, 'capitalize'),
          title: item.target_acceptance.Title,
          url,
          icon: <Zap />,
          sequentialId: getFriendlyId(
            item.target_node.ObjectType,
            item.target_node.SequentialId
          ),
        };
      }
      if (item.target_appetite) {
        const entityInfo = getEntityInfo(item.target_node?.ObjectType);
        const url = entityInfo.url(item.Target);

        return {
          typelabel: i18n.format(entityInfo.singular, 'capitalize'),
          // @TODO: appetites don't have a title, work out how to display them (data set, lower / upper appetite etc, friendly ID etc)
          title: 'Appetite',
          url,
          icon: <Zap />,
          sequentialId: getFriendlyId(
            item.target_node.ObjectType,
            item.target_node.SequentialId
          ),
        };
      }
      if (item.target_issue) {
        const issueMapping =
          IssueTypeMapping[item.target_issue.Type as ParentIssueTypes];

        return {
          typelabel: i18n.format(t(issueMapping.entityLabel), 'capitalize'),
          title: item.target_issue.Title,
          url: issueMapping.detailsUrl(item.target_issue.Id),
          icon: <AlertTriangle />,
          sequentialId: getFriendlyId(
            issueMapping.type,
            item.target_issue.SequentialId
          ),
        };
      }
      if (item.target_issue_update) {
        return {
          typelabel: i18n.format(t('issue_update_one'), 'capitalize'),
          title: item.target_issue_update.Title,
          url: `${IssueTypeMapping[item.target_issue_update.issue!.Type as ParentIssueTypes].detailsUrl(item.target_issue_update.ParentIssueId)}/updates`,
          icon: <AlertTriangle />,
        };
      }
      if (item.target_document) {
        const entityInfo = getEntityInfo(item.target_node?.ObjectType);
        const url = entityInfo.url(item.Target);

        return {
          typelabel: i18n.format(entityInfo.singular, 'capitalize'),
          title: item.target_document.Title,
          url,
          icon: <FileCheck01 />,
          sequentialId: getFriendlyId(
            item.target_node.ObjectType,
            item.target_node.SequentialId
          ),
        };
      }
      if (item.target_obligation) {
        const entityInfo = getEntityInfo(item.target_node?.ObjectType);
        const url = entityInfo.url(item.Target);

        return {
          typelabel: i18n.format(entityInfo.singular, 'capitalize'),
          title: item.target_obligation.Title,
          url,
          icon: <CheckVerified03 />,
          sequentialId: getFriendlyId(
            item.target_node.ObjectType,
            item.target_node.SequentialId
          ),
        };
      }
      if (item.target_consequence) {
        return {
          typelabel: i18n.format(t('consequence_one'), 'capitalize'),
          title: item.target_consequence.Title,
          url: consequenceDetailsUrl(
            item.target_consequence.ParentIssueId,
            item.target_consequence.Id
          ),
          icon: <AlertTriangle />,
        };
      }
      if (item.target_cause) {
        return {
          typelabel: i18n.format(t('cause_one'), 'capitalize'),
          title: item.target_cause.Title,
          url: causeDetailsUrl(
            item.target_cause.ParentIssueId,
            item.target_cause.Id
          ),
          icon: <AlertTriangle />,
        };
      }
      if (item.target_test_result) {
        return {
          typelabel: i18n.format(t('result_one'), 'capitalize'),
          title: item.target_test_result.Title,
          url: `${controlDetailsUrl(item.Source)}/performance`,
          icon: <Settings04 />,
        };
      }
    }
    if (item.asTarget) {
      if (!item.source_node) {
        throw new Error('Missing source node');
      }

      if (item.source_risk) {
        const entityInfo = getEntityInfo(item.source_node?.ObjectType);
        const url = entityInfo.url(item.Source);

        return {
          typelabel: i18n.format(entityInfo.singular, 'capitalize'),
          title: item.source_risk.Title,
          url,
          icon: <Zap />,
          sequentialId: getFriendlyId(
            item.source_node.ObjectType,
            item.source_node.SequentialId
          ),
        };
      }
      if (item.source_third_party) {
        const entityInfo = getEntityInfo(item.source_node?.ObjectType);
        const url = entityInfo.url(item.Source);

        return {
          typelabel: i18n.format(entityInfo.singular, 'capitalize'),
          title: item.source_third_party.Title,
          url,
          icon: <UsersPlus />,
          sequentialId: getFriendlyId(
            item.source_node.ObjectType,
            item.source_node.SequentialId
          ),
        };
      }
      if (item.source_control) {
        const entityInfo = getEntityInfo(item.source_node?.ObjectType);
        const url = entityInfo.url(item.Source);

        return {
          typelabel: i18n.format(entityInfo.singular, 'capitalize'),
          title: item.source_control.Title,
          url,
          icon: <Settings04 />,
          sequentialId: getFriendlyId(
            item.source_node.ObjectType,
            item.source_node.SequentialId
          ),
        };
      }
      if (item.source_control_group) {
        const entityInfo = getEntityInfo(item.source_node?.ObjectType);
        const url = entityInfo.url(item.Source);

        return {
          typelabel: i18n.format(
            `${t('control_one')} ${t('control_group_one')}`,
            'capitalize'
          ),
          title: item.source_control_group.Title,
          url,
          icon: <Settings04 />,
        };
      }
      if (item.source_assessment) {
        const entityInfo = getEntityInfo(item.source_node?.ObjectType);
        const url = entityInfo.url(item.Source);

        return {
          typelabel: i18n.format(entityInfo.singular, 'capitalize'),
          title: item.source_assessment.Title,
          url,
          icon: <Certificate02 />,
          sequentialId: getFriendlyId(
            item.source_node.ObjectType,
            item.source_node.SequentialId
          ),
        };
      }

      if (item.source_internal_audit_report) {
        const entityInfo = getEntityInfo(item.source_node?.ObjectType);
        const url = entityInfo.url(item.Source);

        return {
          typelabel: i18n.format(entityInfo.singular, 'capitalize'),
          title: item.source_internal_audit_report.Title,
          url,
          icon: <BezierCurve02 />,
          sequentialId: getFriendlyId(
            item.source_node.ObjectType,
            item.source_node.SequentialId
          ),
        };
      }

      if (item.source_internal_audit_entity) {
        const entityInfo = getEntityInfo(item.source_node?.ObjectType);
        const url = entityInfo.url(item.Source);

        return {
          typelabel: i18n.format(entityInfo.singular, 'capitalize'),
          title: item.source_internal_audit_entity.Title,
          url,
          icon: <BezierCurve02 />,
          sequentialId: getFriendlyId(
            item.source_node.ObjectType,
            item.source_node.SequentialId
          ),
        };
      }

      if (item.source_assessment_activity) {
        let activityUrl;
        if (item.source_assessment_activity?.parentAssessment?.Id) {
          activityUrl = assessmentActivitiesDetailsUrl;
        }
        if (item.source_assessment_activity?.parentInternalAuditReport?.Id) {
          activityUrl = internalAuditReportActivitiesDetailsUrl;
        }
        if (
          item.source_assessment_activity?.parentComplianceMonitoringAssessment
            ?.Id
        ) {
          activityUrl = complianceMonitoringAssessmentActivitiesDetailsUrl;
        }

        return {
          typelabel: i18n.format(t('assessment_activity_one'), 'capitalize'),
          title: item.source_assessment_activity.Title,
          url: activityUrl
            ? `${activityUrl(
                item.source_assessment_activity.ParentId,
                item.source_assessment_activity.Id
              )}`
            : undefined,
          icon: <Settings04 />,
        };
      }

      if (item.source_impact) {
        const entityInfo = getEntityInfo(item.source_node.ObjectType);
        const url = entityInfo.url(item.Source);

        return {
          typelabel: i18n.format(entityInfo.singular, 'capitalize'),
          title: item.source_impact.Name,
          url,
          icon: <Asterisk02 />,
          sequentialId: getFriendlyId(
            item.source_node.ObjectType,
            item.source_node.SequentialId
          ),
        };
      }
      if (item.source_action) {
        const entityInfo = getEntityInfo(item.source_node.ObjectType);
        const url = entityInfo.url(item.Source);

        return {
          typelabel: i18n.format(t('action_one'), 'capitalize'),
          title: item.source_action.Title,
          url,
          icon: <CheckCircleBroken />,
          sequentialId: getFriendlyId(
            item.source_node.ObjectType,
            item.source_node.SequentialId
          ),
        };
      }
      if (item.source_indicator) {
        const entityInfo = getEntityInfo(item.source_node.ObjectType);
        const url = entityInfo.url(item.Source);

        return {
          typelabel: i18n.format(entityInfo.singular, 'capitalize'),
          title: item.source_indicator.Title,
          url,
          icon: <Activity />,
          sequentialId: getFriendlyId(
            item.source_node.ObjectType,
            item.source_node.SequentialId
          ),
        };
      }
      if (item.source_issue) {
        const issueMapping =
          IssueTypeMapping[item.source_issue.Type as ParentIssueTypes];

        return {
          typelabel: i18n.format(t(issueMapping.entityLabel), 'capitalize'),
          title: item.source_issue.Title,
          url: issueMapping.detailsUrl(item.source_issue.Id),
          icon: <AlertTriangle />,
          sequentialId: getFriendlyId(
            issueMapping.type,
            item.source_issue.SequentialId
          ),
        };
      }
      if (item.source_document) {
        const entityInfo = getEntityInfo(item.source_node.ObjectType);
        const url = entityInfo.url(item.Source);

        return {
          typelabel: i18n.format(entityInfo.singular, 'capitalize'),
          title: item.source_document.Title,
          url,
          icon: <FileCheck01 />,
          sequentialId: getFriendlyId(
            item.source_node.ObjectType,
            item.source_node.SequentialId
          ),
        };
      }
      if (item.source_obligation) {
        const entityInfo = getEntityInfo(item.source_node.ObjectType);
        const url = entityInfo.url(item.Source);

        return {
          typelabel: i18n.format(entityInfo.singular, 'capitalize'),
          title: item.source_obligation.Title,
          url,
          icon: <CheckVerified03 />,
          sequentialId: getFriendlyId(
            item.source_node.ObjectType,
            item.source_node.SequentialId
          ),
        };
      }
      if (item.source_acceptance) {
        const entityInfo = getEntityInfo(item.source_node.ObjectType);
        const url = entityInfo.url(item.Source);

        return {
          typelabel: i18n.format(entityInfo.singular, 'capitalize'),
          title: item.source_acceptance.Title,
          url,
          icon: <Zap />,
          sequentialId: getFriendlyId(
            item.source_node.ObjectType,
            item.source_node.SequentialId
          ),
        };
      }
      if (item.source_appetite) {
        const entityInfo = getEntityInfo(item.source_node.ObjectType);
        const url = entityInfo.url(item.Source);

        return {
          typelabel: i18n.format(entityInfo.singular, 'capitalize'),
          title: item.source_appetite.Id,
          url,
          sequentialId: getFriendlyId(
            item.source_node.ObjectType,
            item.source_node.SequentialId
          ),
          icon: <Zap />,
        };
      }
      if (item.source_consequence) {
        return {
          typelabel: i18n.format(t('consequence_one'), 'capitalize'),
          title: item.source_consequence.Title,
          url: consequenceDetailsUrl(
            item.source_consequence.ParentIssueId,
            item.source_consequence.Id
          ),
          icon: <AlertTriangle />,
        };
      }
      if (item.source_cause) {
        return {
          typelabel: i18n.format(t('cause_one'), 'capitalize'),
          title: item.source_cause.Title,
          url: causeDetailsUrl(
            item.source_cause.ParentIssueId,
            item.source_cause.Id
          ),
          icon: <AlertTriangle />,
        };
      }
    }
  };
};

const getOwners = (item: LinkedItemsTableFields) => {
  for (const val of Object.values(item)) {
    if (
      // eslint-disable-next-line no-prototype-builtins
      val?.hasOwnProperty('owners') &&
      // eslint-disable-next-line no-prototype-builtins
      val?.hasOwnProperty('ownerGroups')
    ) {
      return getAllOwnersCellValue(
        val as {
          owners: OwnerPartsFragment[];
          ownerGroups: OwnerGroupPartsFragment[];
        }
      );
    }
  }

  return [];
};

const useGetLabelledFields = (
  data: GetLinkedItemsQuery | undefined,
  parentType: Parent_Type_Enum | undefined,
  parent: ObjectWithContributors
) => {
  const getProperties = useGetProperties();
  const hasAppetiteLinks = useIsFeatureVisibleToOrg('appetite_links');
  const hasThirdParties = useIsFeatureVisibleToOrg('third_party');

  return useMemo<LinkedItemsTableFields[]>(() => {
    return [
      ...(data?.as_source ?? [])
        .filter(
          (li) =>
            li.target_acceptance ??
            li.target_action ??
            li.target_action_update ??
            (hasAppetiteLinks ? li.target_appetite : undefined) ??
            (hasThirdParties ? li.target_third_party : undefined) ??
            li.target_assessment ??
            li.target_internal_audit_report ??
            li.target_assessment_activity ??
            li.target_cause ??
            li.target_consequence ??
            li.target_control ??
            li.target_control_group ??
            li.target_document ??
            li.target_impact ??
            li.target_impact_rating ??
            li.target_indicator ??
            li.target_issue ??
            li.target_issue_update ??
            li.target_obligation ??
            li.target_obligation_impact ??
            li.target_risk ??
            li.target_test_result
        )
        .map((li) => {
          const item = {
            ...li,
            SequentialId: '',
            asSource: true,
            parentId: parent.Id,
            allOwners: [],
            parentType,
            Name: '',
            Type: '',
            url: '',
          };

          const allOwners = getOwners(item);
          const props = getProperties(item);

          return {
            ...item,
            SequentialId: props?.sequentialId ?? '-',
            Name: props?.title ?? '-',
            Type: props?.typelabel ?? '-',
            url: props?.url ?? '',
            allOwners,
          };
        }),
      ...(data?.as_target ?? []).map((li) => {
        const item = {
          ...li,
          SequentialId: '',
          asTarget: true,
          parentId: parent.Id,
          parentType,
          allOwners: [],
          Name: '',
          Type: '',
          url: '',
        };

        const allOwners = getOwners(item);
        const props = getProperties(item);

        return {
          ...item,
          SequentialId: props?.sequentialId ?? '-',
          Name: props?.title ?? '-',
          Type: props?.typelabel ?? '-',
          url: props?.url ?? '',
          allOwners,
        };
      }),
    ];
  }, [
    data?.as_source,
    data?.as_target,
    hasThirdParties,
    hasAppetiteLinks,
    parent.Id,
    parentType,
    getProperties,
  ]);
};

const useColumnDefinitions = () => {
  const getProperties = useGetProperties();
  const allOwners = useGetOwnersFieldConfig<LinkedItemsTableFields>();
  const columnDefinitions: TableFields<LinkedItemsTableFields> = {
    SequentialId: {
      id: 'id',
      header: 'ID',
      isRowHeader: true,
    },
    Name: {
      id: 'name',
      header: 'Name',
      cell: (item) => {
        return (
          <Link variant={'secondary'} href={item.url}>
            {item.Name}
          </Link>
        );
      },
      isRowHeader: true,
    },
    Type: {
      id: 'type',
      header: 'Type',
      cell: (item) => {
        const props = getProperties(item);

        return (
          <SpaceBetween size={'s'} direction={'horizontal'}>
            <div>{props?.icon}</div>
            <div>{item.Type}</div>
          </SpaceBetween>
        );
      },
      isRowHeader: true,
    },
    allOwners,
  };

  return columnDefinitions;
};

export const useGetLinkedItemsTableProps = (
  data: GetLinkedItemsQuery | undefined,
  parentType: Parent_Type_Enum | undefined,
  parent: ObjectWithContributors
) => {
  const { t } = useTranslation('common', { keyPrefix: 'linkedItems' });
  const labelledFields = useGetLabelledFields(data, parentType, parent);

  const columnDefinitions = useColumnDefinitions();

  return useGetTableProps({
    data: labelledFields,
    entityLabel: t('entity_name'),
    emptyCollectionAction: <></>,
    preferencesStorageKey: 'LinkedItemsTable-PreferencesV1',
    enableFiltering: true,
    initialColumns: ['SequentialId', 'Name', 'Type', 'allOwners'],
    fields: columnDefinitions,
  });
};
