import type { OptionDefinition } from '@cloudscape-design/components/internal/components/option/interfaces';
import useRisksmartUser from '@risksmart-app/components/hooks/useRisksmartUser';
import { PromptId } from '@risksmart-app/shared/ai/PromptId';
import type { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import type { ControlFormFieldData } from 'src/pages/controls/update/forms/controlSchema';

import ControlledAutosuggest from '@/components/Form/ControlledAutosuggest';
import ControlledGroupAndUserContributorMultiSelect from '@/components/Form/ControlledGroupAndUserContributorMultiSelect';
import ControlledSelect from '@/components/Form/ControlledSelect';
import ControlledTextarea from '@/components/Form/ControlledTextarea';
import DepartmentSelector from '@/components/Form/DepartmentSelector';
import CustomisableForm from '@/components/Form/Form/CustomisableForm/CustomisableForm';
import TagSelector from '@/components/Form/TagSelector';
import {
  Contributor_Type_Enum,
  useGetControlsByUserQuery,
} from '@/generated/graphql';
import { useLibrary } from '@/hooks/use-library';

import TestScheduleFields from './TestScheduleFields';

type SuggestedTitle = {
  value: string;
  label: string;
  type: 'existing' | 'library';
};

type Props = {
  readOnly?: boolean;
  latestTestDate: null | string;
};

const ControlFormFields: FC<Props> = ({ readOnly, latestTestDate }) => {
  const { control, watch, setValue } = useFormContext<ControlFormFieldData>();

  const controlLibrary = useLibrary('controls');
  const { t } = useTranslation(['common']);
  const { t: st } = useTranslation(['common'], { keyPrefix: 'controls' });

  const { user } = useRisksmartUser();

  const { data: userControls } = useGetControlsByUserQuery({
    variables: {
      _eq: user?.userId,
    },
  });

  const autoPopulateDescription = (selectedTitleOption?: OptionDefinition) => {
    if (!selectedTitleOption) {
      return;
    }
    const selectedOption = selectedTitleOption as SuggestedTitle;
    if (selectedOption.type === 'library') {
      const description = controlLibrary.find(
        (control) => control.title === selectedOption?.value
      )?.description;
      if (description) {
        setValue('Description', description);
      }
    } else if (selectedOption?.type === 'existing') {
      const control = userControls?.control.find(
        (c) => c.Title === selectedOption?.value
      );
      if (control?.Description) {
        setValue('Description', control.Description);
      }
    }
  };

  const existingControlOptions: SuggestedTitle[] =
    userControls?.control.map((c) => ({
      value: c.Title || '-',
      label: c.Title || '-',
      type: 'existing',
    })) || [];

  const controlLibraryOptions: SuggestedTitle[] = controlLibrary.map((c) => ({
    value: c.title,
    label: c.title,
    type: 'library',
  }));
  const types = st('type');
  const typeOptions = Object.keys(types).map((value) => ({
    value,
    label: types[value as keyof typeof types],
  }));

  const title = watch('Title');

  return (
    <CustomisableForm readOnly={readOnly}>
      <ControlledSelect
        forceRequired={false}
        key={'type'}
        testId={'type'}
        filteringType={'auto'}
        label={st('fields.Type')}
        name={'Type'}
        description={st('fields.Type_help')}
        addEmptyOption={true}
        // todo: translation
        placeholder={'Select'}
        control={control}
        options={typeOptions}
        disabled={readOnly}
      />
      <ControlledAutosuggest
        key={'title'}
        forceRequired={true}
        name={'Title'}
        label={st('fields.Title')}
        description={st('fields.Title_help')}
        placeholder={st('fields.Title_placeholder')}
        control={control}
        onSelect={(e) => {
          autoPopulateDescription(e.detail.selectedOption);
        }}
        options={[
          {
            // TODO: missing translation
            value: 'Created controls',
            label: st('title_groups.createdControls'),
            options: existingControlOptions,
          },
          {
            // TODO: missing translation
            value: 'Library',
            label: st('title_groups.library'),
            options: controlLibraryOptions,
          },
        ]}
        disabled={readOnly}
      />
      <ControlledTextarea
        defaultRequired={true}
        key={'description'}
        name={'Description'}
        label={st('fields.Description')}
        description={st('fields.Description_help')}
        placeholder={st('fields.Description_placeholder')}
        control={control}
        disabled={readOnly}
        additionalPrompts={[
          {
            id: PromptId.GenerateAControlDescription,
            text: t('textInference.general.generateAControlDescription'),
            altPromptText: title,
          },
        ]}
      />
      <ControlledGroupAndUserContributorMultiSelect
        key={'owners'}
        forceRequired={true}
        control={control}
        includeGroups={true}
        label={t('fields.Owner')}
        description={st('fields.Owner_help')}
        inheritedContributorsName={'ancestorContributors'}
        contributorType={Contributor_Type_Enum.Owner}
        name={'Owners'}
        testId={'owners'}
        placeholder={t('fields.Owner_placeholder')}
        disabled={readOnly}
      />
      <ControlledGroupAndUserContributorMultiSelect
        key={'contributors'}
        control={control}
        includeGroups={true}
        inheritedContributorsName={'ancestorContributors'}
        contributorType={Contributor_Type_Enum.Contributor}
        label={t('fields.Contributor')}
        description={st('fields.Contributor_help')}
        name={'Contributors'}
        placeholder={t('fields.Contributor_placeholder')}
        disabled={readOnly}
      />

      <TagSelector
        name={'tags'}
        key={'tags'}
        control={control}
        disabled={readOnly}
      />
      <DepartmentSelector
        key={'departments'}
        name={'departments'}
        control={control}
        disabled={readOnly}
      />

      <TestScheduleFields
        key={'testConfigFields'}
        control={control}
        readOnly={false}
        latestTestDate={latestTestDate ?? null}
        manualNextTestDueName={'schedule.ManualDueDate'}
        testFrequencyName={'schedule.Frequency'}
        testTimeToCompleteValueName={'schedule.TimeToCompleteValue'}
        testScheduleStartDateName={'schedule.StartDate'}
        testTimeToCompleteUnitName={'schedule.TimeToCompleteUnit'}
      />
    </CustomisableForm>
  );
};

export default ControlFormFields;
