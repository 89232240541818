import BarChart from '@cloudscape-design/components/bar-chart';
import type { MixedLineBarChartProps } from '@cloudscape-design/components/mixed-line-bar-chart/interfaces';
import type { DataType } from '@risksmart-app/shared/reporting/datasets/types';
import dayjs from 'dayjs';
import { type FC, useRef } from 'react';

import { genericCategoricalPalette } from '@/utils/colours';

import { dateFormats } from '../../../UniversalWidget/util';
import { NoWidgetData } from '../../../widgets/NoWidgetData';
import { useXDomain } from '../../useXDomain';
import { getClickedLabel } from '../barChart';
import type { DatePrecision, Series } from '../types';
import type { SegmentData } from '../WidgetPieChart';
import styles from './styles.module.scss';

export type Props = {
  loading: boolean;
  series: Series[];
  xAxisDataType: DataType;
  xAxisDatePrecision: DatePrecision | null;
  stackedBars?: boolean;
  onSegmentClick?: (data: SegmentData) => void;
};

export const WidgetBarChart: FC<Props> = ({
  loading,
  series,
  xAxisDataType,
  xAxisDatePrecision,
  stackedBars,
  onSegmentClick,
}) => {
  const xDomain = useXDomain({
    datePrecision: xAxisDatePrecision,
    dataType: xAxisDataType,
    xAxisData: series.flatMap((s) => s.data).map((d) => d.x as number),
  });
  const ref = useRef<HTMLDivElement>(null);

  const handleClick = () => {
    const label = getClickedLabel(ref);
    if (label) {
      const dataItem = series
        .flatMap((s) => s.data)
        .find((d) => d.label === label);
      if (dataItem) {
        onSegmentClick?.({ value: dataItem.xValue });
      }
    }
  };

  return (
    <div className={styles.barChart} ref={ref} onClick={handleClick}>
      <BarChart<Date | number | string>
        statusType={loading ? 'loading' : 'finished'}
        hideFilter={true}
        height={0}
        fitHeight={true}
        empty={<NoWidgetData />}
        hideLegend={series.length <= 1}
        xDomain={xDomain}
        stackedBars={stackedBars}
        series={series.map((d, i) => ({
          title: d.title as string,
          type: 'bar',
          color: d.color ?? genericCategoricalPalette(i),
          data: d.data as ReadonlyArray<MixedLineBarChartProps.Datum<number>>,
        }))}
        xTickFormatter={(value) =>
          xAxisDataType === 'date'
            ? dayjs(value).format(dateFormats[xAxisDatePrecision ?? 'day'])
            : String(value)
        }
      />
    </div>
  );
};
