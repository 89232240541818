import { useMultiParentFileUpdate } from '@risksmart-app/components/File/useFileUpdate';
import type { FC, ReactNode } from 'react';
import { useNavigate } from 'react-router';
import type { ObjectWithContributors } from 'src/rbac/Permission';
import { useHasPermission } from 'src/rbac/useHasPermission';

import { ModalBodyWrapper } from '@/components/Form/Form/ModalBodyWrapper';
import { PageWrapper } from '@/components/Form/Form/PageWrapper';
import {
  Parent_Type_Enum,
  useGetRiskAssessmentResultByIdQuery,
  useInsertRiskAssessmentResultsMutation,
  useUpdateRiskAssessmentResultMutation,
} from '@/generated/graphql';
import { evictField } from '@/utils/graphqlUtils';

import type { AssessmentTypeEnum } from '../useAssessmentTypeConfig';
import { useAssessmentTypeConfig } from '../useAssessmentTypeConfig';
import RiskAssessmentResultForm from './RiskAssessmentResultForm';
import type { RiskAssessmentResultFormDataFields } from './riskAssessmentResultSchema';
import { defaultValues } from './riskAssessmentResultSchema';

type Props = {
  readonly: boolean;
  navigateToResults: boolean;
  isModalForm: boolean;
  assessmentId?: string;
  assessmentMode: AssessmentTypeEnum;
  assessedItem?: ObjectWithContributors;
  id?: string;
  onDismiss?: (saved: boolean) => void;
  beforeFieldsSlot?: ReactNode;
  showAssessmentSelector?: boolean;
  riskIds?: string[];
  header?: string;
};

const ConnectedRiskAssessmentResultForm: FC<Props> = ({
  readonly,
  assessmentId,
  assessedItem,
  id,
  onDismiss,
  beforeFieldsSlot,
  showAssessmentSelector,
  navigateToResults,
  assessmentMode,
  isModalForm,
  riskIds,
  header,
}) => {
  const navigate = useNavigate();
  const { updateFiles } = useMultiParentFileUpdate();

  const { data } = useGetRiskAssessmentResultByIdQuery({
    variables: {
      Id: id!,
      Assessment: assessmentMode === 'rating',
      InternalAudit: assessmentMode === 'internal_audit_report',
      ComplianceMonitoring:
        assessmentMode === 'compliance_monitoring_assessment',
    },
    skip: !id,
    fetchPolicy: 'no-cache',
  });
  const {
    routing: { resultsRegisterUrl },
  } = useAssessmentTypeConfig(assessmentMode);

  const riskAssessmentResult = data?.risk_assessment_result?.[0];
  const canUpdateRiskAssessmentResult = useHasPermission(
    'update:risk_assessment_result'
  );

  const [insertRiskAssessmentResult] = useInsertRiskAssessmentResultsMutation({
    update: (cache) => {
      evictField(cache, 'risk_assessment_result');
      evictField(cache, 'assessment');
      evictField(cache, 'internal_audit_report');
      evictField(cache, 'compliance_monitoring_assessment');
      evictField(cache, 'risk_assessment_result_aggregate');
    },
  });

  const [updateRiskAssessmentResult] = useUpdateRiskAssessmentResultMutation({
    update: (cache) => {
      evictField(cache, 'risk_assessment_result');
      evictField(cache, 'assessment');
      evictField(cache, 'internal_audit_report');
      evictField(cache, 'compliance_monitoring_assessment');
      evictField(cache, 'risk_assessment_result_aggregate');
    },
  });
  riskIds = riskIds ?? [];
  const parentRisk = riskAssessmentResult?.parents.find((p) => p.risk);
  if (parentRisk?.risk?.Id) {
    riskIds.push(parentRisk.risk.Id!);
  } else if (assessedItem?.Id) {
    riskIds.push(assessedItem.Id!);
  }

  const onSave = async (values: RiskAssessmentResultFormDataFields) => {
    const { files, newFiles } = values;
    const riskAssessmentResultIds: string[] = [];
    if (riskAssessmentResult) {
      const result = await updateRiskAssessmentResult({
        variables: {
          ...values,
          CustomAttributeData: values.CustomAttributeData ?? null,
          Id: riskAssessmentResult.Id,
        },
      });
      if (result.data?.updateChildRiskAssessmentResult?.affected_rows === 0) {
        throw new Error('Risk assessment result update failed');
      }
      riskAssessmentResultIds.push(id!);
    } else {
      const result = await insertRiskAssessmentResult({
        variables: {
          ...values,
          CustomAttributeData: values.CustomAttributeData ?? null,
          AssessmentId:
            assessmentMode == 'rating'
              ? (assessmentId ?? values.AssessmentId)
              : undefined,
          ComplianceMonitoringAssessmentId:
            assessmentMode == 'compliance_monitoring_assessment'
              ? (assessmentId ?? values.ComplianceMonitoringAssessmentId)
              : undefined,
          InternalAuditReportId:
            assessmentMode == 'internal_audit_report'
              ? (assessmentId ?? values.InternalAuditReportId)
              : undefined,
          RiskIds: values?.RiskIds?.map((r) => r.value),
        },
      });

      if (!result.data?.insertChildRiskAssessmentResult?.Ids) {
        throw new Error('Risk assessment result id is missing');
      }
      riskAssessmentResultIds.push(
        ...result.data.insertChildRiskAssessmentResult.Ids
      );
    }

    await updateFiles({
      parentIds: riskAssessmentResultIds,
      parentType: Parent_Type_Enum.RiskAssessmentResult,
      newFiles,
      selectedFiles: files,
      originalFiles: riskAssessmentResult?.files ?? [],
    });

    if (navigateToResults && assessmentId) {
      navigate(resultsRegisterUrl(assessmentId));
    }
  };

  const value: RiskAssessmentResultFormDataFields | undefined =
    riskAssessmentResult
      ? {
          Rating: riskAssessmentResult.Rating,
          Rationale: riskAssessmentResult.Rationale,
          Likelihood: riskAssessmentResult.Likelihood,
          Impact: riskAssessmentResult.Impact,
          ControlType: riskAssessmentResult?.ControlType,
          AssessmentId:
            riskAssessmentResult.parents.find((p) => p.assessment)?.assessment
              ?.Id ?? null,
          ComplianceMonitoringAssessmentId:
            riskAssessmentResult.parents.find(
              (p) => p.complianceMonitoringAssessment
            )?.complianceMonitoringAssessment?.Id ?? null,
          InternalAuditReportId:
            riskAssessmentResult.parents.find((p) => p.internalAuditReport)
              ?.internalAuditReport?.Id ?? null,
          RiskIds: riskIds.map((c) => ({ value: c })),
          TestDate: riskAssessmentResult?.TestDate,
          CustomAttributeData: riskAssessmentResult?.CustomAttributeData,
          files: riskAssessmentResult?.files ?? [],
        }
      : undefined;

  return (
    <RiskAssessmentResultForm
      noLoadingOnParentTypeChange={true}
      header={header}
      defaultValues={{
        ...defaultValues,
        AssessmentId: assessmentId,
        RiskIds: riskIds.map((c) => ({ value: c })),
      }}
      values={value}
      onSave={onSave}
      readOnly={readonly && !canUpdateRiskAssessmentResult}
      onDismiss={onDismiss}
      renderTemplate={(renderProps) =>
        isModalForm ? (
          <ModalBodyWrapper {...renderProps} />
        ) : (
          <PageWrapper {...renderProps} />
        )
      }
      disableRiskSelector={id != undefined}
      beforeFieldsSlot={beforeFieldsSlot}
      showSelector={showAssessmentSelector ? assessmentMode : undefined}
      assessmentMode={assessmentMode}
    />
  );
};

export default ConnectedRiskAssessmentResultForm;
