import i18n from '@risksmart-app/i18n/i18n';
import { useGetEnterpriseRiskSmartWidgetTableProps } from 'src/pages/enterprise-risk/config';
import type { EnterpriseRiskRegisterFields } from 'src/pages/enterprise-risk/types';

import type { GetEnterpriseRisksQuery } from '@/generated/graphql';
import type { Enterprise_Risk_Bool_Exp } from '@/generated/graphql.typed';
import {
  GetEnterpriseRisksDocument,
  Parent_Type_Enum,
} from '@/generated/graphql.typed';
import type { Colour } from '@/utils/colours';
import { colours } from '@/utils/colours';
import { enterpriseRiskRegisterUrl } from '@/utils/urls';

import { UNRATED } from '../../Gigawidget/types';
import { dateRangeFilter } from '../../Gigawidget/util/filterHelpers';
import { createDataSource } from '../createDataSource';
import {
  dashboardDateRangeClickthroughFilter,
  dateClickthroughFilter,
  defaultClickthroughFilter,
} from '../dataSourceHelpers';

export default createDataSource<
  EnterpriseRiskRegisterFields,
  { where?: Enterprise_Risk_Bool_Exp | undefined },
  GetEnterpriseRisksQuery
>({
  hasAccess: (_, isFeatureVisibleToOrg) =>
    !!isFeatureVisibleToOrg('enterprise_risk'),
  useRelativeCustomAttributeDates: true,
  parentTypes: [Parent_Type_Enum.EnterpriseRisk],
  documentNode: GetEnterpriseRisksDocument,
  useDefaultVariables: () => {
    return {};
  },
  useTablePropsHook: (data, options) => {
    return useGetEnterpriseRiskSmartWidgetTableProps(
      data?.enterprise_risk,
      data?.form_configuration[0]?.customAttributeSchema ?? null,
      options
    );
  },
  entityNamePlural: 'enterprise_risk_other',
  entityNameSingular: 'enterprise_risk_one',
  fields: 'enterpriseRisks.fields',
  dashboardFilterConfig: {
    tagsFilter: (_tags) => ({}),
    departmentsFilter: (_departments) => ({}),
    dateFilter: (_dateRange, _precision) => ({}),
    dateClickthroughFilter:
      dashboardDateRangeClickthroughFilter('CreatedAtTimestamp'),
  },
  clickThroughUrl: (filter) => enterpriseRiskRegisterUrl(filter),
  categoryGetters: [
    {
      id: 'tier',
      name: () => i18n.t('risks.columns.risk_tier'),
      categoryGetter: (item) => item.TierLabelled,
      clickthroughFilter: defaultClickthroughFilter('TierLabelled'),
    },
    {
      id: 'treatment',
      name: () => i18n.t('risks.columns.risk_treatment'),
      categoryGetter: (item) => item.TreatmentLabelled,
      clickthroughFilter: defaultClickthroughFilter('TreatmentLabelled'),
    },
    {
      id: 'residualRatingMean',
      name: () => i18n.t('enterpriseRisks.columns.residualRatingMean'),
      categoryGetter: (item) => ({
        key: item.ResidualMeanLabelled ?? UNRATED,
        label: item.ResidualMeanLabelled ?? UNRATED,
        sortKey: item.ResidualMeanLabelled ?? UNRATED,
      }),
      ratingColourKey: 'risk_controlled',
      clickthroughFilter: defaultClickthroughFilter('ResidualMeanLabelled', {
        unratedValue: UNRATED,
      }),
      categoryOverrideFunction: (category, _ratingFns, riskFormatters) => {
        const rating = riskFormatters({
          residualRating: category.data[0]?.score?.ResidualRatingMean,
        }).getResidualOption();

        return {
          title: rating?.label ?? UNRATED,
          color:
            colours[(rating?.color ?? 'light-grey') as Colour]
              ?.backgroundColor ?? rating?.color,
        };
      },
    },
    {
      id: 'residualRatingWorstCase',
      name: () => i18n.t('enterpriseRisks.columns.residualRatingWorstCase'),
      categoryGetter: (item) => ({
        key: item.ResidualWorstCaseLabelled ?? UNRATED,
        label: item.ResidualWorstCaseLabelled ?? UNRATED,
        sortKey: item.ResidualWorstCaseLabelled ?? UNRATED,
      }),
      ratingColourKey: 'risk_controlled',
      clickthroughFilter: defaultClickthroughFilter(
        'ResidualWorstCaseLabelled',
        {
          unratedValue: UNRATED,
        }
      ),
      categoryOverrideFunction: (category, _ratingFns, riskFormatters) => {
        const rating = riskFormatters({
          residualRating: category.data[0]?.score?.ResidualRatingWorstCase,
        }).getResidualOption();

        return {
          title: rating?.label ?? UNRATED,
          color:
            colours[(rating?.color ?? 'light-grey') as Colour]
              ?.backgroundColor ?? rating?.color,
        };
      },
    },
    {
      id: 'inherentRatingMean',
      name: () => i18n.t('enterpriseRisks.columns.inherentRatingMean'),
      categoryGetter: (item) => ({
        key: item.InherentMeanLabelled ?? UNRATED,
        label: item.InherentMeanLabelled ?? UNRATED,
        sortKey: item.InherentMeanLabelled ?? UNRATED,
      }),
      ratingColourKey: 'risk_uncontrolled',
      clickthroughFilter: defaultClickthroughFilter('InherentMeanLabelled', {
        unratedValue: UNRATED,
      }),
      categoryOverrideFunction: (category, _ratingFns, riskFormatters) => {
        const rating = riskFormatters({
          inherentRating: category.data[0]?.score?.InherentRatingMean,
        }).getInherentOption();

        return {
          title: rating?.label ?? UNRATED,
          color:
            colours[(rating?.color ?? 'light-grey') as Colour]
              ?.backgroundColor ?? rating?.color,
        };
      },
    },
    {
      id: 'inherentRatingWorstCase',
      name: () => i18n.t('enterpriseRisks.columns.inherentRatingWorstCase'),
      categoryGetter: (item) => ({
        key: item.InherentWorstCaseLabelled ?? UNRATED,
        label: item.InherentWorstCaseLabelled ?? UNRATED,
        sortKey: item.InherentWorstCaseLabelled ?? UNRATED,
      }),
      ratingColourKey: 'risk_uncontrolled',
      clickthroughFilter: defaultClickthroughFilter(
        'InherentWorstCaseLabelled',
        {
          unratedValue: UNRATED,
        }
      ),
      categoryOverrideFunction: (category, _ratingFns, riskFormatters) => {
        const rating = riskFormatters({
          inherentRating: category.data[0]?.score?.InherentRatingWorstCase,
        }).getInherentOption();

        return {
          title: rating?.label ?? UNRATED,
          color:
            colours[(rating?.color ?? 'light-grey') as Colour]
              ?.backgroundColor ?? rating?.color,
        };
      },
    },
    {
      id: 'createdDate',
      name: () => i18n.t('columns.created_on'),
      categoryGetter: (data) => new Date(data.CreatedAtTimestamp),
      date: true,
      dashboardDateFilterOverride: (dateRange, precision) => ({
        where: { CreatedAtTimestamp: dateRangeFilter(dateRange, precision) },
      }),
      clickthroughFilter: dateClickthroughFilter('CreatedAtTimestamp'),
    },
  ],
});
