import type { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import type { IssueUpdatesFields } from 'src/schemas/issueUpdates';

import { ControlledFileUpload } from '@/components/Form/ControlledFileUpload/ControlledFileUpload';
import ControlledInput from '@/components/Form/ControlledInput';
import ControlledTextarea from '@/components/Form/ControlledTextarea';
import CustomisableForm from '@/components/Form/Form/CustomisableForm/CustomisableForm';

type Props = {
  readOnly?: boolean;
};

const IssueUpdateForm: FC<Props> = ({ readOnly }) => {
  const { control } = useFormContext<IssueUpdatesFields>();

  const { t: st } = useTranslation(['common'], { keyPrefix: 'actionUpdates' });
  const { t } = useTranslation(['common']);

  return (
    <CustomisableForm readOnly={readOnly}>
      <ControlledInput
        key={'title'}
        forceRequired={true}
        name={'Title'}
        label={t('fields.Title')}
        control={control}
        placeholder={st('fields.Title_placeholder') ?? ''}
        disabled={readOnly}
      />

      <ControlledTextarea
        key={'description'}
        defaultRequired={true}
        name={'Description'}
        label={t('fields.Description')}
        placeholder={st('fields.Description_placeholder') ?? ''}
        control={control}
        disabled={readOnly}
      />
      <ControlledFileUpload
        key={'attachFiles'}
        label={t('fields.newFiles')}
        description={t('fields.newFiles_help')}
        control={control}
        name={'newFiles'}
        saveFilesName={'files'}
        disabled={readOnly}
      />
    </CustomisableForm>
  );
};

export default IssueUpdateForm;
