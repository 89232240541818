import type { FilteringProperty } from '@cloudscape-design/components/property-filter/interfaces';

import SelectFilter from '../filters/SelectFilter';
import type { AllowedField } from '../utils';
import type { Helpers, ReportFieldType } from './types';

export const commonLookup: ReportFieldType = {
  getChartLabel({ fieldData, fieldDef, helpers }) {
    if (fieldDef.displayType !== 'commonLookup') {
      throw new Error('commonLookup filed type used out of context');
    }

    return (
      helpers.getCommonLookupByValue(
        fieldDef.i18nKey,
        fieldData.value as number | string
      )?.label ?? ''
    );
  },

  cell: ({ field, fieldData, helpers }) => {
    const fieldDef = field.fieldDef;
    const value = fieldData.value;
    if (fieldDef.displayType !== 'commonLookup') {
      throw new Error('commonLookup filed type used out of context');
    }

    return (
      helpers.getCommonLookupByValue(fieldDef.i18nKey, value as number | string)
        ?.label ?? ''
    );
  },
  propertyConfig: function (
    field: AllowedField,
    { getCommonLookupByValue, getCommonLookupOptions }: Helpers
  ): FilteringProperty {
    const fieldDef = field.fieldDef;
    if (fieldDef.displayType !== 'commonLookup') {
      throw new Error('commonLookup filed type used out of context');
    }

    return {
      key: field.value,
      groupValuesLabel: '',
      propertyLabel: field.label,
      operators: [
        {
          operator: '=',
          format: (value) => {
            const rating = getCommonLookupByValue(fieldDef.i18nKey, value);

            return rating?.label ?? '';
          },
          form: (props) => {
            return (
              <SelectFilter
                {...props}
                options={getCommonLookupOptions(fieldDef.i18nKey).map((o) => ({
                  value: (o.value as unknown as string) ?? undefined,
                  label: o.label,
                }))}
              />
            );
          },
        },
      ],
    };
  },
};
