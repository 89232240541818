import { useGetGuidParam } from '@risksmart-app/components/routes/routes.utils';
import { useTranslation } from 'react-i18next';

import ControlledTabs from '@/components/ControlledTabs';

import PageLayout from '../../../../layouts/PageLayout';
import { useTabs } from '../useTabs';

const Page = () => {
  const riskId = useGetGuidParam('riskId');
  const tabs = useTabs({ parentId: riskId });
  const { t: st } = useTranslation(['common'], { keyPrefix: 'acceptances' });

  return (
    <PageLayout title={st('create_modal_title')}>
      <ControlledTabs tabs={tabs} variant={'container'} />
    </PageLayout>
  );
};

export default Page;
