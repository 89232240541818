import { useRating } from '@risksmart-app/components/hooks/useRating';
import type { FC } from 'react';

import SimpleRatingBadge from '@/components/SimpleRatingBadge';

import { getPerformanceRatingFromPerformanceScore } from '../impacts/ratings/performanceCalculation';
import type { CardType } from './types';
import { RiskAttribute } from './types';

const SelectedRiskAttribute: FC<{
  data: CardType;
  selectedRiskAttribute: RiskAttribute;
}> = ({ data, selectedRiskAttribute }) => {
  const { getByValue: getAppetitePerformanceByValue } = useRating(
    'appetite_performance'
  );
  const { getByValue: getImpactPerformanceByValue } =
    useRating('impact_performance');
  const { getByLabel: getControlledRatingByLabel } =
    useRating('risk_controlled');
  const { getByLabel: getUncontrolledRatingByLabel } =
    useRating('risk_uncontrolled');
  if (data.unlinked) {
    return;
  }
  let rating: { color?: string; label: string; tooltip?: string } | undefined =
    undefined;

  switch (selectedRiskAttribute) {
    case RiskAttribute.ControlledRating:
      rating = getControlledRatingByLabel(data.ControlledRatingLabelled);
      break;
    case RiskAttribute.UncontrolledRating:
      rating = getUncontrolledRatingByLabel(data.UncontrolledRatingLabelled);
      break;
    case RiskAttribute.ImpactPerformance:
      {
        if (data.impactRatings.length === 0) {
          return '-';
        }
        const impactPerformanceRating = getImpactPerformanceByValue(
          getPerformanceRatingFromPerformanceScore(data.ImpactPerformanceScore)
        );
        rating = {
          ...impactPerformanceRating,
          label: data.ImpactPerformanceScore?.toString() ?? '',
          tooltip: impactPerformanceRating?.label,
        };
      }
      break;
    case RiskAttribute.RiskStatus:
      return (
        <div className={'text-xs w-[75px] text-center'}>
          {data.StatusLabelled}
        </div>
      );
    case RiskAttribute.AppetitePerformance:
      if (!data.AppetitePerformance) {
        return '-';
      }
      rating = getAppetitePerformanceByValue(data.AppetitePerformance) ?? {
        label: 'Undefined',
        color: 'light-grey',
      };
  }

  return <SimpleRatingBadge rating={rating} />;
};

export default SelectedRiskAttribute;
