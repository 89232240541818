import { useRating } from '@risksmart-app/components/hooks/useRating';
import type { GroupByDatePrecision } from '@risksmart-app/shared/reporting/api/schema';
import type { FieldDefinition } from '@risksmart-app/shared/reporting/datasets/types';
import _ from 'lodash';
import { fieldTypes } from 'src/pages/reporting/update/fieldTypes';
import type { Helpers } from 'src/pages/reporting/update/fieldTypes/types';

import useEntityInfo from '@/hooks/getEntityInfo';
import { useCommonLookupLazy } from '@/hooks/useCommonLookupLazy';
import { genericCategoricalPalette, getColorStyles } from '@/utils/colours';

import type { ReportField, Series } from './WidgetChart/types';

/**
 * Converts raw report data into series data as used by charts.
 * It removes entries with null values
 * adds colours and labels to data points
 * sorts data by x-axis
 * converts x values to Date objects for date based fields
 * @param param0
 * @returns
 */
export const useMapReportDataToSeries = ({
  x1FieldDefinition,
  x1GroupByDatePrecision,
  x2FieldDefinition,
  x2GroupByDatePrecision,
  reportingData,
}: {
  x1FieldDefinition: FieldDefinition | null;
  x1GroupByDatePrecision: GroupByDatePrecision | null;
  x2FieldDefinition: FieldDefinition | null;
  x2GroupByDatePrecision: GroupByDatePrecision | null;
  reportingData: ReportField[][];
}): Series[] => {
  const getEntityInfo = useEntityInfo();
  const {
    getByValueAndRatingKey: getRatingByValue,
    getOptionsByRatingKey: getRatingOptions,
  } = useRating();
  const {
    getByValue: getCommonLookupByValue,
    getOptions: getCommonLookupOptions,
  } = useCommonLookupLazy();

  if (!x1FieldDefinition) {
    return [
      {
        title: '',
        data: [
          {
            xValue: null,
            x: null,
            y: reportingData?.[0]?.[0].value,
            label: '',
          },
        ],
      },
    ];
  }
  const helpers: Helpers = {
    getRatingByValue,
    getCommonLookupByValue,
    getRatingOptions,
    getCommonLookupOptions,
    getEntityInfo,
  };

  const x1FieldConfig = fieldTypes[x1FieldDefinition.displayType];
  const x2FieldConfig = x2FieldDefinition
    ? fieldTypes[x2FieldDefinition.displayType]
    : null;
  const seriesData =
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (
      reportingData?.map((d) =>
        d.length === 2
          ? { x1: d[0].value, y: d[1].value, sourceX1: d[0] }
          : {
              x1: d[0].value,
              sourceX1: d[0],
              x2: d[1].value,
              sourceX2: d[1],
              y: d[2].value,
            }
      ) ?? []
    ).map((d) => {
      const ratingColour = x1FieldConfig.getChartColor?.({
        fieldData: d.sourceX1,
        fieldDef: x1FieldDefinition,
        helpers,
        groupByDatePrecision: x1GroupByDatePrecision,
      });
      const color = ratingColour
        ? getColorStyles(ratingColour).backgroundColor
        : undefined;
      const label = x1FieldConfig.getChartLabel?.({
        fieldData: d.sourceX1,
        fieldDef: x1FieldDefinition,
        helpers,
        groupByDatePrecision: x1GroupByDatePrecision,
      });
      let x1;
      switch (x1FieldDefinition.displayType) {
        case 'date':
          x1 = new Date(d.x1 as string);
          break;
        default:
          x1 = d.x1;
      }

      return {
        ...d,
        xValue: d.x1,
        x1,
        x2: d.x2,

        label,
        color,
      };
    });

  if (x2FieldDefinition) {
    const groupings = _.groupBy(seriesData, 'x2');

    return Object.keys(groupings).map((x2, i) => {
      const sourceX2 = groupings[x2][0].sourceX2!;
      const x2Val = groupings[x2][0].x2; // use first item x2 rather then x2 to ensure type isn't always a string
      const label = x2FieldConfig?.getChartLabel?.({
        fieldData: sourceX2,
        fieldDef: x2FieldDefinition,
        helpers,
        groupByDatePrecision: x2GroupByDatePrecision,
      });
      const ratingColour = x2FieldConfig?.getChartColor?.({
        fieldData: sourceX2,
        fieldDef: x2FieldDefinition,
        helpers,
        groupByDatePrecision: x2GroupByDatePrecision,
      });
      const color = ratingColour
        ? getColorStyles(ratingColour).backgroundColor
        : undefined;

      let title;
      switch (x2FieldDefinition.displayType) {
        case 'date':
          title = new Date(x2Val as string);
          break;
        case 'rating':
        case 'commonLookup':
          title = label;
          break;
        default:
          title = x2Val;
      }

      return {
        title: String(title),
        color: color ?? genericCategoricalPalette(i),
        data: groupings[x2].map((sd) => ({
          xValue: sd.xValue,
          x: sd.x1,
          y: sd.y,
          color: sd.color,
          label: sd.label,
        })),
      };
    });
  } else {
    return [
      {
        title: '',
        data: seriesData.map((sd) => ({
          xValue: sd.xValue,
          x: sd.x1,
          y: sd.y,
          label: sd.label,
          color: sd.color,
        })),
      },
    ];
  }
};
