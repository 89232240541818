import { groupByDatePrecision } from '@risksmart-app/shared/reporting/api/schema';
import { z } from 'zod';

import { getAggregateTypeConfig } from './aggregationTypeOptions';
import { getChartTypeConfig } from './chartTypesOptions';

export const Precision = ['day', 'month', 'year'];

export const aggregationTypes = ['count', 'sum', 'avg', 'max', 'min'] as const;

export const chartTypes = [
  'bar',
  'pie',
  'donut',
  'table',
  'kpi',
  'stacked-bar',
] as const;
const chartTypeSchema = z.enum(chartTypes, { message: 'Required' });

export type ChartType = z.infer<typeof chartTypeSchema>;

export const customDataSourceWidgetSettingsSchema = z
  .object({
    customDataSourceId: z
      .string({ invalid_type_error: 'Required' })
      .uuid({ message: 'Required' }),
    chartType: chartTypeSchema,
    x1FieldId: z.string().nullish(),
    x1FieldDatePrecision: groupByDatePrecision.nullish(),
    x2FieldId: z.string().nullish(),
    aggregationType: z.enum(aggregationTypes).nullish(),
    yFieldId: z.string().nullish(),
  })
  .superRefine((values, ctx) => {
    const aggregateTypeConfig = getAggregateTypeConfig();
    const chartTypeConfig = getChartTypeConfig();
    const chartType = chartTypeConfig[values.chartType];

    if (values.x1FieldId && values.x1FieldId === values.x2FieldId) {
      ctx.addIssue({
        path: ['x2FieldId'],
        code: 'custom',
        message: 'Field must be different to category',
      });
    }

    if (chartType.category && !values.x1FieldId) {
      ctx.addIssue({
        path: ['x1FieldId'],
        code: 'custom',
        message: 'Required',
      });
    }
    if (chartType.aggregation && !values.aggregationType) {
      ctx.addIssue({
        path: ['aggregationType'],
        code: 'custom',
        message: 'Required',
      });
    }

    if (
      values.aggregationType &&
      aggregateTypeConfig[values.aggregationType].yFieldRequired &&
      !values.yFieldId
    ) {
      ctx.addIssue({
        path: ['yFieldId'],
        code: 'custom',
        message: 'Required',
      });
    }
  });

export type CustomDataSourceWidgetSettings = z.infer<
  typeof customDataSourceWidgetSettingsSchema
>;

export const defaultValues: CustomDataSourceWidgetSettings = {
  customDataSourceId: undefined as unknown as string,
  chartType: undefined as unknown as 'bar',
  x1FieldId: undefined,
  x1FieldDatePrecision: undefined,
  yFieldId: undefined,
  aggregationType: undefined,
};
