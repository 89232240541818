import { useRating } from '@risksmart-app/components/hooks/useRating';
import type { ContentTable } from 'pdfmake/interfaces';
import { useTranslation } from 'react-i18next';

import { useGetConsequencesByParentIssueIdLazyQuery } from '@/generated/graphql';
import { createTable, tableHeaders } from '@/utils/pdf/table';

const useConsequenceExportTable = (
  issueId: string
): [() => Promise<ContentTable>, boolean] => {
  const { getLabel: getCriticalityLabel } = useRating('criticality');
  const [getConsequences, getConsequencesResult] =
    useGetConsequencesByParentIssueIdLazyQuery({
      variables: {
        _eq: issueId,
      },
    });
  const { t: st } = useTranslation(['common'], {
    keyPrefix: 'consequences',
  });
  const { t: columns } = useTranslation(['common'], {
    keyPrefix: 'columns',
  });
  const { t: consequencesColumns } = useTranslation(['common'], {
    keyPrefix: 'consequences.columns',
  });
  const createExportTable = async () => {
    const { data: consequencesData } = await getConsequences();
    const consequencesTableData = (consequencesData?.consequence ?? []).map(
      (i) => [
        i.Title,
        st('costType')[i.CostType],
        i.CostValue,
        i.Criticality ? getCriticalityLabel(i.Criticality) : '-',
        i.Description,
      ]
    );

    return createTable({
      widths: ['*', 60, 40, 70, '*'],
      body: [
        tableHeaders([
          consequencesColumns('title'),
          consequencesColumns('costType'),
          consequencesColumns('costValue'),
          consequencesColumns('criticality'),
          columns('description'),
        ]),
        ...consequencesTableData,
      ],
    });
  };

  return [createExportTable, getConsequencesResult.loading];
};

export default useConsequenceExportTable;
