import { useRating } from '@risksmart-app/components/hooks/useRating';
import type { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import ControlledDatePicker from '@/components/Form/ControlledDatePicker';
import { ControlledFileUpload } from '@/components/Form/ControlledFileUpload/ControlledFileUpload';
import ControlledGroupAndUserSelect from '@/components/Form/ControlledGroupAndUserSelect';
import ControlledInput from '@/components/Form/ControlledInput';
import ControlledRadioGroup from '@/components/Form/ControlledRadioGroup';
import { noTransform } from '@/components/Form/ControlledRadioGroup/radioGroupUtils';
import ControlledTextarea from '@/components/Form/ControlledTextarea';
import ConditionalField from '@/components/Form/Form/CustomisableForm/ConditionalField';
import CustomisableForm from '@/components/Form/Form/CustomisableForm/CustomisableForm';
import { Acceptance_Status_Enum } from '@/generated/graphql';
import { useIsFeatureVisibleToOrg } from '@/utils/featureFlags';

import type { AcceptanceFormDataFields } from './acceptanceSchema';

type Props = {
  readOnly?: boolean;
};

const AcceptanceFormFields: FC<Props> = ({ readOnly }) => {
  const { control, watch } = useFormContext<AcceptanceFormDataFields>();

  const { options } = useRating('acceptance_status');

  const updating = !!watch('Id');
  const approvalsEnabled = useIsFeatureVisibleToOrg('approvers');

  const statusOptions = options
    .map((option) => ({
      ...option,
      value: String(option.value),
    }))
    .filter((option) =>
      (
        [
          Acceptance_Status_Enum.Pending,
          Acceptance_Status_Enum.Open,
          Acceptance_Status_Enum.Closed,
          Acceptance_Status_Enum.Declined,
        ] as string[]
      ).includes(option.value)
    );
  const { t: st } = useTranslation(['common'], {
    keyPrefix: 'acceptances.fields',
  });

  return (
    <CustomisableForm readOnly={readOnly}>
      <ControlledInput
        key={'title'}
        forceRequired={true}
        disabled={readOnly}
        name={'Title'}
        label={st('Title')}
        description={st('Title_help')}
        control={control}
        placeholder={st('Title_placeholder') ?? ''}
      />

      <ControlledDatePicker
        forceRequired={true}
        key={'dateAcceptedFrom'}
        name={'DateAcceptedFrom'}
        label={st('DateAcceptedFrom')}
        description={st('DateAcceptedFrom_help')}
        control={control}
        disabled={readOnly}
      />

      <ControlledDatePicker
        forceRequired={true}
        key={'dateAcceptedTo'}
        name={'DateAcceptedTo'}
        label={st('DateAcceptedTo')}
        description={st('DateAcceptedTo_help')}
        control={control}
        disabled={readOnly}
      />
      {!approvalsEnabled && (
        <ControlledGroupAndUserSelect
          key={'requestedBy'}
          name={'requestedBy'}
          label={st('requestedBy')}
          description={st('requestedBy_help')}
          control={control}
          includeGroups={true}
          disabled={readOnly}
          addEmptyOption={true}
        />
      )}

      {!approvalsEnabled && (
        <ControlledGroupAndUserSelect
          key={'approvedBy'}
          name={'approvedBy'}
          label={st('approvedBy')}
          description={st('approvedBy_help')}
          control={control}
          includeGroups={true}
          disabled={readOnly}
          addEmptyOption={true}
        />
      )}

      <ConditionalField
        key={'status'}
        condition={updating || !approvalsEnabled}
      >
        <ControlledRadioGroup
          forceRequired={true}
          label={st('Status')}
          testId={'status'}
          description={st('Status_help')}
          name={'Status'}
          control={control}
          transform={noTransform}
          items={statusOptions}
          disabled={readOnly}
        />
      </ConditionalField>

      <ControlledTextarea
        key={'details'}
        defaultRequired={true}
        name={'Details'}
        label={st('Details')}
        description={st('Details_help')}
        placeholder={st('Details_placeholder') ?? ''}
        control={control}
        disabled={readOnly}
      />

      <ControlledFileUpload
        key={'newFiles'}
        label={st('newFiles')}
        description={st('newFiles_help')}
        control={control}
        name={'newFiles'}
        saveFilesName={'files'}
        disabled={readOnly}
      />
    </CustomisableForm>
  );
};

export default AcceptanceFormFields;
