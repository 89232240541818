import { t } from 'i18next';

import { ParentTypeEnum } from '../../../generated/graphql';
import { DataSourceType } from '../schema';
import { getAuditColumns } from './shared';
import { SharedDataset } from './types';

export const getIndicatorResults = () =>
  ({
    label: 'Indicator results',
    parentTypes: new Set<DataSourceType>(['indicators']),
    customAttributeFormConfigurationParentTypes: [
      ParentTypeEnum.IndicatorResult,
    ],
    fields: {
      ...getAuditColumns(),
      details: {
        dataType: 'text',
        displayType: 'text',
        label: t('indicator_results.columns.description'),
      },
      id: { dataType: 'guid', displayType: 'text', label: t('columns.guid') },
      date: {
        dataType: 'date',
        displayType: 'date',
        label: t('indicator_results.columns.date_time'),
      },
      numberValue: {
        dataType: 'number',
        displayType: 'number',
        label: 'Result (number)',
      },
      textValue: {
        dataType: 'text',
        displayType: 'text',
        label: 'Result (text)',
      },
    },
  }) satisfies SharedDataset;
